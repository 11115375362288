import { usePermissions } from '@core';
import styled from '@emotion/styled';
import { Add, Clear, Search } from '@mui/icons-material';
import { Button, Grid, IconButton, TextField, Typography } from '@mui/material';
import { GridToolbarColumnsButton, GridToolbarFilterButton } from '@mui/x-data-grid';

interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
    resource: string;
    title?: string;
    onAdd?: () => void;
}

const Container = styled('div')`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 8px 16px 0;
`;

const SearchContainer = styled('div')`
    justify-content: flex-end;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-left: auto;
`;

const AddButton = styled(Button)`
    margin-left: 8px;
`;

export function QuickSearchToolbar({ title, value, clearSearch, onChange, onAdd }: QuickSearchToolbarProps) {
    const { canCreate } = usePermissions();
    return (
        <Container>
            {title && (
                <Grid item xs>
                    <Typography variant="h6">
                        {title}
                        {canCreate && onAdd ? (
                            <AddButton variant="outlined" onClick={onAdd} startIcon={<Add />}>
                                Add
                            </AddButton>
                        ) : null}
                    </Typography>
                </Grid>
            )}
            <SearchContainer>
                {/* <TextField
                    variant="standard"
                    value={value}
                    onChange={onChange}
                    placeholder="Search…"
                    InputProps={{
                        startAdornment: <Search fontSize="small" />,
                        endAdornment: (
                            <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{ visibility: value ? 'visible' : 'hidden' }}
                                onClick={clearSearch}
                            >
                                <Clear fontSize="small" />
                            </IconButton>
                        ),
                    }}
                /> */}
                <div>
                    <GridToolbarFilterButton />
                    <GridToolbarColumnsButton />
                </div>
            </SearchContainer>
        </Container>
    );
}
