import styled from '@emotion/styled';
import { Divider, Tab, Tabs } from '@mui/material';
import type { ReactNode } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { useNavigator } from './Navigation/hooks';

export type LinkTabConfig = {
    url: string;
    label: ReactNode;
    absolute?: boolean;
} & ({ component: React.ComponentType<unknown> } | { render: (routeProps: RouteComponentProps) => React.ReactNode });

interface Props {
    tabs: LinkTabConfig[];
    orientation?: 'vertical' | 'horizontal';
}

function useNavigatorTabs() {
    const { getSetParamsUrl, getEndpoint } = useNavigator();
    const params = getEndpoint()?.params || {};
    return {
        tab: params['tab'],
        getTabUrl: (tab: string) => getSetParamsUrl({ ...params, tab }),
    };
}

function TabRenderer(props: { tab?: LinkTabConfig }) {
    return (
        <>
            {(() => {
                const tab = props.tab;
                if (tab === undefined) {
                    return null;
                } else if ('component' in tab) {
                    const Type = tab.component;
                    return <Type />;
                } else {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    return tab.render({ history: useHistory(), location: useLocation(), match: useRouteMatch() });
                }
            })()}
        </>
    );
}

export function LinkTabs(props: Props) {
    const { tab, getTabUrl } = useNavigatorTabs();
    const defaultTab = props.tabs.slice().shift()?.url || '';
    const selectedTab = props.tabs.find((t) => !tab || t.url === tab);
    const history = useHistory();
    const selectedTabUrl = getTabUrl(tab || defaultTab);
    const navigate = (tab: string) => {
        history.push(tab);
    };

    return (
        <TabsPage orientation={props.orientation || 'horizontal'}>
            <TabContainer className="tab-container">
                <Tabs
                    orientation={props.orientation || 'horizontal'}
                    scrollButtons="auto"
                    variant="scrollable"
                    value={selectedTabUrl}
                    onChange={(evt, value) => navigate(value)}
                >
                    {props.tabs.map((t) => (
                        <Tab key={t.url} value={getTabUrl(t.url)} label={t.label}></Tab>
                    ))}
                </Tabs>
            </TabContainer>
            <Divider />
            <ContentContainer>
                <TabRenderer tab={selectedTab} />
            </ContentContainer>
        </TabsPage>
    );
}

const TabContainer = styled.div`
    flex: 0 0 auto;
`;

const TabsPage = styled.div<{ orientation: 'vertical' | 'horizontal' }>`
    height: 100%;
    display: flex;
    flex-direction: ${(p) => (p.orientation === 'vertical' ? 'row' : 'column')};
    overflow: hidden;
    .tab-container {
        border-right: solid ${(p) => (p.orientation === 'vertical' ? 1 : 0)}px #8882;
    }
`;

const ContentContainer = styled.div`
    flex: 1 1 auto;
    height: 100%;
    overflow: auto;
`;
