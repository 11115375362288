import { confirm, monitor } from '@components';
import { useEvent, useEventValue } from '@core';
import { Save, Undo } from '@mui/icons-material';
import { Button, Divider, LinearProgress } from '@mui/material';
import { PageBody, PageContent, Toolbar } from 'features/shell/layout';
import { useCallback, useEffect, useState } from 'react';

import { RolesPageService } from './Models';
import { RolesAndPermissionForm } from './RolesAndPermissionForm';

function RolesToolbar({ rolesPageService }: { rolesPageService: RolesPageService }) {
    useEvent(rolesPageService.changeTracker.onChange);
    const save = useCallback(async () => {
        await monitor({
            action: () => rolesPageService.save(),
            failureMessage: 'Failed to Save Role Changes!',
            successMessage: 'Role Changes Saved!',
        });
    }, [rolesPageService]);
    const undo = useCallback(async () => {
        if (await confirm('Undo Changes', 'Are you sure you want to undo your changes?')) {
            rolesPageService.reload();
        }
    }, [rolesPageService]);
    return (
        <Toolbar>
            <Button onClick={save} variant="contained" disabled={!rolesPageService.changeTracker.hasChanges()}>
                <Save /> Save Changes
            </Button>
            <Button onClick={undo} disabled={!rolesPageService.changeTracker.hasChanges()}>
                <Undo /> Undo Changes
            </Button>
        </Toolbar>
    );
}

export function RolesPage(props: { organizationId?: string }) {
    const [rolesPageSvc, setRolesPageSvc] = useState<RolesPageService>();
    const loading = useEventValue(rolesPageSvc?.loadingChanged, true);
    useEffect(() => {
        setRolesPageSvc(new RolesPageService(props.organizationId));
    }, [props.organizationId, setRolesPageSvc]);
    useEffect(() => {
        rolesPageSvc?.init();
    }, [rolesPageSvc]);

    return loading ? (
        <LinearProgress />
    ) : !rolesPageSvc ? null : (
        <PageContent>
            <PageBody noPadding>
                <RolesAndPermissionForm rolesPageService={rolesPageSvc} />
            </PageBody>
            <Divider />
            <RolesToolbar rolesPageService={rolesPageSvc} />
        </PageContent>
    );
}
