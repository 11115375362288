import { Add, ArrowDownward, ArrowUpward, Remove } from '@mui/icons-material';
import type { IconButtonProps as MuiIconButtonProps } from '@mui/material';
import { IconButton as MuiIconButton } from '@mui/material';

const mappings: any = {
    remove: Remove,
    plus: Add,
    'arrow-up': ArrowUpward,
    'arrow-down': ArrowDownward,
};

type IconButtonProps = MuiIconButtonProps & {
    icon: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    iconProps?: object;
};

export const IconButton = (props: IconButtonProps) => {
    const { icon, iconProps, ...otherProps } = props;
    const IconComp = mappings[icon];
    return (
        <MuiIconButton {...otherProps} size="small">
            <IconComp {...iconProps} />
        </MuiIconButton>
    );
};
