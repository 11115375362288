/* eslint-disable @typescript-eslint/ban-types */
import { useState } from 'react';

export class KeyProvider {
    private map = new WeakMap<object, string>();
    private nextId = 1;
    public getKey = (item: object) => {
        if (!this.map.has(item)) {
            this.map.set(item, this.nextId.toString());
            this.nextId++;
        }
        return this.map.get(item)!;
    };
}

export function useObjectKey() {
    const [{ getKey }] = useState(() => new KeyProvider());
    return getKey;
}
