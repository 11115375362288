import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export const BridgeableBlockchainError = (props: { isOpen: boolean; onClose: () => void }) => {
    return (
        <Dialog open={props.isOpen}>
            <DialogTitle>Bridging Error</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    An error occurred updating the token to allow bridging. <br /> Please try again.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={props.onClose}>
                    Okay
                </Button>
            </DialogActions>
        </Dialog>
    );
};
