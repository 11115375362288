import { FormGrid, FormSection, modal, notify, silentMonitor, TextInput } from '@components';
import { usePermissions } from '@core';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Box, Button, Divider, FormControl, FormLabel, Grid, MenuItem, TextField, Toolbar } from '@mui/material';
import { useGetApiItems } from '@services/items/items';
import type {
    BulkGrantItemsToPlayerRequest,
    BulkGrantItemsToPlayerResponse,
    BulkGrantItemsToPlayerRequestItems
} from '@services/model';
import { PageBody, PageContent } from 'features/shell/layout';
import _, { forEach } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useFieldArray, useForm, useFormContext, useFormState } from 'react-hook-form';
import { orvalRequestor } from 'shared/core/GameServiceApiOrvalFacade';
import type { InferType } from 'yup';
import { array, number, object, string } from 'yup';
import * as yup from 'yup';
import { buildEntityListContentsColumns, buildEntityListColumns } from '../Shared/EntityListService';
import { DataGrid } from '@mui/x-data-grid';
import type { GridColDef, GridSelectionModel, GridRowsProp, GridRowModel } from '@mui/x-data-grid';
import { postApiItemsQueryForAddContent } from '@services/items/items';
import { searchBuilder } from '@core';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import { useQuery } from 'shared/hooks/useQuery';   

function BulkGrantItemsControl() {
    
    interface ItemPickerProps {
        isGranting: boolean;
        itemRows: GridRowsProp;
        onSelectedItemsAdded:  (items: GridRowModel[]) => void;
    }

    function ItemPicker(props: ItemPickerProps) {
        
        const itemForm = useForm({
            mode: 'onChange',
            resolver: yupResolver(
                yup.object({
                    itemIndex: yup.number().required(),
                    count: yup.number().required('Count is required.').positive('Must be a positive number.').max(2147483647, 'Cannot be larger than max integer size.').typeError('Count is required and must be a positive number.'),
                }),
            ),
            reValidateMode: 'onChange'
        });

        function buildAutoCompleteOptions(data: readonly GridRowModel[]) {
            return (
                data?.map((x, index) => {
                    return {
                        label: x.displayName,
                        id: x.id,
                        index: index
                    };
                }) ?? []
            );
        }
        
        function onAutoCompleteIndexChanged(e: React.SyntheticEvent, value: any){
            itemForm.setValue('itemIndex', value!.index);
        }

        const onBtnAddItemClicked = async () => {

            const formValues = itemForm.getValues();
            const item = props.itemRows?.[formValues.itemIndex] ?? {};
            props.onSelectedItemsAdded([{ ...item, count: formValues.count }]);
        };

        return (
            <FormControl fullWidth sx={{ '> * + *': { marginTop: '0.85rem' } }}>
                <Autocomplete
                    renderOption={(props, option) => {
                        return (
                        <li {...props} key={option.id}>
                            {option.label}
                        </li>
                        );
                    }}
                    renderInput={(params) => <TextField {...params} label="Item to Add" />}
                    options={buildAutoCompleteOptions(props.itemRows)}
                    {...itemForm.register('itemIndex')}
                    id="item-selection"
                    sx={{ width: '100%' }}
                    onChange={onAutoCompleteIndexChanged}
                />
                <FormLabel sx={{ width: '100%' }}>Count</FormLabel>
                <TextField {...itemForm.register('count')} id="item-count" placeholder="1" />
                <Box sx={{ color: '#d32f2f' }}>
                    <ErrorMessage errors={itemForm.formState.errors} name="count" />
                </Box>
                <Button onClick={onBtnAddItemClicked} variant="contained" disabled={!itemForm.formState.isValid || !itemForm.formState.isDirty}>Add Item</Button>
            </FormControl>
        );
    }
        
    interface SelectedItemListViewProps {
        columns: GridColDef[];
        rows: GridRowModel[];
        onSelectedItemsRemoved: (items: GridRowModel[]) => void;
    }
    function SelectedItemListView({ columns, rows, onSelectedItemsRemoved }: SelectedItemListViewProps) {

        const [selectedRows, setSelectedRows] = useState<GridRowModel[]>([]);
        const [enableRemoveButton, setEnableRemoveButton] = useState(false);
        
        function onItemRowSelected(selections: GridSelectionModel) {
            if (selections.length > 0) {
                setEnableRemoveButton(true);
            } else {
                setEnableRemoveButton(false);
            }

            const selectedIDs = new Set(selections);
            const rowsToDelete = rows.filter((row) => selectedIDs.has(row?.id.toString()));
            setSelectedRows(rows.filter((row) => !rowsToDelete.find((f) => f.id === row.id)));
        }

        function onBtnRemoveClicked() {
            setEnableRemoveButton(false);
            onSelectedItemsRemoved(selectedRows);
        }

        return (
            <div>
                <Box>
                    <DataGrid
                        sx={{ height: '43vh' }}
                        columns={columns}
                        rows={rows}
                        rowCount={rows.length}
                        pageSize={10}
                        checkboxSelection
                        onSelectionModelChange={onItemRowSelected}
                        getRowId={(row) => row.id}
                    />
                    <Button sx={{ marginTop: '10px' }} disabled={!enableRemoveButton} onClick={onBtnRemoveClicked} variant="contained">
                        Remove
                    </Button>
                </Box>
            </div>
        );
    }

    const { canUpdate: canGrant } = usePermissions('Player');
    const [isGranting, setIsGranting] = useState(false);
    const [progress, setProgress] = useState(0);
    const [selectedItems, setSelectedItems] = useState<GridRowModel[]>([]);
    const wasCancelledRef = useRef(false);     

    const itemTableColumns = buildEntityListContentsColumns('Item');
    const [itemsQuery] = useState(() =>
        searchBuilder('Item')
            .leftJoin('ItemToken', ([it, tk]) => ({ eq: { [tk.nameof.itemId]: it.id } }))
            .select(([it, tk]) => ({
                id: it.id,
                displayName: it.displayName,
                maxSupply: tk.maxSupply
            }))
            .sortAsc((it) => it.displayName)
            .prepare(postApiItemsQueryForAddContent)
    );
    const { rows: itemRows } = useQuery({ query: itemsQuery, columns: itemTableColumns });
    
    const bulkGrantItemsFormSchema = object()
        .shape({
            playerId: string().required('PlayerId is Required'),
            items: array(
                object({
                    itemId: string(),
                    count: number().required().min(0, 'The count cannot be negative').typeError('Count must be a number'),
                })
            )
        })
        .required();


    type IBulkGrantItemsFormInput = InferType<typeof bulkGrantItemsFormSchema>;
    const bulkGrantItemsForm = useForm<IBulkGrantItemsFormInput>({
        mode: 'onChange',
        resolver: yupResolver(bulkGrantItemsFormSchema),
        reValidateMode: 'onBlur'
    });
    const { isDirty } = useFormState(bulkGrantItemsForm);

    const postApiBulkGrantItemsToPlayer = useCallback((request: BulkGrantItemsToPlayerRequest) => {
        return orvalRequestor<BulkGrantItemsToPlayerResponse>({
            url: '/api/players/bulk-grant-items',
            method: 'post',
            data: request,
        });
    }, []);

    const executeBulkGrantItemsRequest = useCallback(
        async (playerId: string, items: BulkGrantItemsToPlayerRequestItems): Promise<BulkGrantItemsToPlayerResponse> => {
            let response : BulkGrantItemsToPlayerResponse = {};
            let error = null;
                        
            const request: BulkGrantItemsToPlayerRequest = {
                playerId: playerId,
                items : items
            };

            await silentMonitor({
                failureMessage: '',
                successMessage: '',
                action: async () => {
                    
                    response = await postApiBulkGrantItemsToPlayer(request);
                },
                onError: (e) => {
                    error = e;
                },
            });

            if (error) {
                throw new Error(error);
            }

            toast.success(`Tracking Id : ${response.trackingId}`);
            return response;
        },
        [postApiBulkGrantItemsToPlayer]
    );

    const submitBulkGrantItemsRequest = useCallback(async () => {
        const data = bulkGrantItemsForm.getValues();        
        if (data.items.length === 0) {
            notify({ type: 'error', content: 'Please select an item to grant' });
            return;
        }

        setIsGranting(true);
        wasCancelledRef.current = false;
        setProgress(0);

        try {
            
            let itemArray: GridRowModel[] = data.items;  
            const bulkGrantItemsToPlayerRequestItems: { [key: string]: number } = {}
            itemArray.forEach((element) => {
                const dictKey = element.id;
                bulkGrantItemsToPlayerRequestItems[dictKey] = element.count;
              })
                
            await executeBulkGrantItemsRequest(data.playerId, bulkGrantItemsToPlayerRequestItems);
        } finally {
            setIsGranting(false);
        }
    }, [bulkGrantItemsForm, executeBulkGrantItemsRequest]);
       
    function onSelectedItemsAdded(items: GridRowModel[]) {
       
        const addedId = items?.[0].id;
        if (selectedItems.some(cur => cur.id === addedId)) {
            toast.error('Please remove then re-add the existing item entry to change its properties.');
        }
        else {
            let newItems: GridRowModel[] = [];
            let itemsInState = [...selectedItems];

            items.forEach((i) => {
                let itemIndex = itemsInState.findIndex((x) => x.id === i.id);

                if (itemIndex !== -1) {
                    let itemToUpdate = itemsInState[itemIndex];
                    itemToUpdate.count++
                } else {
                    newItems.push({ id: addedId, count: 1, ...i });
                }
            });
            let theSelectedItems = itemsInState.concat(newItems);
            setSelectedItems(theSelectedItems);
            bulkGrantItemsForm.setValue('items', theSelectedItems);
        }        
    }
    
    function onSelectedItemsRemoved(theSelectedItems: GridRowModel[]) {
        setSelectedItems(theSelectedItems);
        bulkGrantItemsForm.setValue('items', theSelectedItems);
    }

    return (
        <PageContent>
            <PageBody>
                <FormProvider {...bulkGrantItemsForm}>
                    <FormGrid>
                        <Grid item container xs={12} spacing={2}>
                            <FormSection header="Grant Items to Player">
                                <form >
                                    <Grid columns={2} container={true}>
                                        <Box sx={{ width: '45%', padding: '24px' }} >
                                            <TextInput
                                                width="full"
                                                label="Player Id"
                                                name="playerId"
                                                helperText="Enter player id"
                                                disabled={isGranting}
                                            />
                                            <ItemPicker isGranting={isGranting} itemRows={itemRows} onSelectedItemsAdded={ onSelectedItemsAdded} />
                                        </Box>
                                        <Box sx={{ width: '55%' }}>
                                            <Box sx={{ padding: '24px' }}>Selected Items</Box>
                                            <SelectedItemListView columns={itemTableColumns} rows={selectedItems} onSelectedItemsRemoved={onSelectedItemsRemoved} />
                                        </Box>
                                    </Grid>
                                </form>
                            </FormSection>
                        </Grid>
                    </FormGrid>
                </FormProvider>
            </PageBody>
            <Divider />
            {canGrant && (
                <Toolbar>
                    {!isGranting && (
                        <Button onClick={submitBulkGrantItemsRequest} variant="contained" disabled={!bulkGrantItemsForm.formState.isValid || !isDirty}>
                            Grant
                        </Button>
                    )}
                    {isGranting && (
                        <Button
                            onClick={() => {
                                wasCancelledRef.current = true;
                            }}
                            variant="contained"
                            disabled={wasCancelledRef.current}
                        >
                            Cancel
                        </Button>
                    )}
                </Toolbar>
            )}
        </PageContent>
    );
}

export function PlayersBulkGrantItems() {
    return <BulkGrantItemsControl />;
}
