import { BoolInput, CurrencyInput, ImageInput, IntegerInput, modal, monitor, TextInput } from '@components';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid } from '@mui/material';
import { postApiItemTokens } from '@services/item-tokens/item-tokens';
import type { ItemToken } from '@services/model';
import { Toolbar } from 'features/shell/layout';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

const itemTokenFormSchema = yup.object().shape({
    token: yup.object().shape({
        marketplaceInfo : yup.object().shape({
            name: yup.string().required('Marketplace Name is required!'),
            description: yup.string().required('Marketplace Description is required!'),
            itemImages: yup.object().test('Image Test', 'Marketplace Front Image is required!', function (value) {
                return (this.parent.itemImages != undefined && this.parent.itemImages["Front"] != undefined)
            }),
        })
    }),
});
interface CreateTokenFormProps {
    itemId: string;
    onCreated: () => void;
    close: () => void;
}
export const createTokenPrompt = async (itemId: string) => {
    let created = false;
    const onCreated = (close: () => void) => {
        created = true;
        close();
    };
    await modal('Create Token', (close) => <CreateTokenForm onCreated={() => onCreated(close)} close={close} itemId={itemId} />);
    return created;
};

export function CreateTokenForm({ itemId, onCreated, close }: CreateTokenFormProps) {
    const form = useForm({
        defaultValues: {
            token: {
                itemId,
                marketplaceInfo: {},
            } as ItemToken,
            agreementIds: '',
        },
        resolver: yupResolver(itemTokenFormSchema),
        mode: 'onBlur',
        reValidateMode: 'onSubmit'
    });
    const save = useCallback(async () => {
        monitor({
            failureMessage: 'Error Saving Token!',
            successMessage: 'Token Saved',
            action: async () => {
                const { token, agreementIds } = form.getValues();
                token.agreementIds = agreementIds.split('\n');
                await postApiItemTokens(token);
                onCreated();
            }
        });
        
    }, [form, onCreated]);
    return (
        <FormProvider {...form}>
            <Grid container columnSpacing={2}>
                <Grid item sm={6}>
                    <TextInput width="full" label="Token Name" helperText="System name of token on the blockchain" name="token.tokenName" />
                    <IntegerInput width="full" label="Max Supply" helperText="How many can be owned?" name="token.maxSupply" />
                    <TextInput width="full" label="Symbol" helperText="The symbol for this token" name="token.symbol" />
                    <TextInput width="full" label="Agreement IDs" helperText="One agreement ID per line" multiline name="agreementIds" />
                    <TextInput
                        width="full"
                        label="Marketplace Name"
                        helperText="Display name of item in Marketplace"
                        name="token.marketplaceInfo.name"
                    />
                    <TextInput
                        width="full"
                        label="Marketplace Description"
                        helperText="Description of item in Marketplace"
                        name="token.marketplaceInfo.description"
                    />
                    <CurrencyInput
                        width="full"
                        label="Base Price"
                        helperText="Marketplace base price (USD)"
                        name="token.marketplaceInfo.basePriceUsd"
                    />
                    <TextInput width="full" label="Item Class" helperText="Marketplace Display Class" name="token.marketplaceInfo.itemClass" />
                    
                </Grid>
                <Grid item sm={6}>
                    <BoolInput width="full" label="Sellable" helperText="Can this item be resold?" name="token.sellable" />

                    {/* Disabling because saga does not honor these values
                    <BoolInput width="full" label="Transferable" helperText="Can this item be transferred?" name="token.transferable" /> */}
                    
                    <BoolInput width="full" label="Burnable" helperText="Can this item be used up?" name="token.burnable" />
                    <BoolInput width="full" label="Randomize" helperText="Can this item be randomized?" name="token.randomize" />

                    {/* Disabling this until Saga resolves the issues causing item tokens to fail with this state set to true
                    <BoolInput width="full" label="Withdrawable" helperText="Can this be withdrawn?" name="token.withdrawable" /> */}
                    
                    <ImageInput
                        width="full"
                        label="Front Image"
                        helperText="Marketplace Image"
                        name="token.marketplaceInfo.itemImages.Front"
                        uploadUrl="/api/Organizations/Asset"
                    />
                    <Box sx={{ color: '#d32f2f', fontSize: '0.75rem' }}>
                        <ErrorMessage errors={form.formState.errors} name="token.marketplaceInfo.itemImages" />
                    </Box>
                </Grid>
            </Grid>
            <Toolbar>
                <Button onClick={form.handleSubmit(save)} variant="contained" color="primary" disabled={!form.formState.isDirty}>
                    Create
                </Button>
                <Button onClick={close}>Cancel</Button>
            </Toolbar>
        </FormProvider>
    );
}
