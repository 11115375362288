import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, Modal, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import type { ShopTab } from '@services/model';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export interface EditShopTabModalProps {
    isOpen: boolean;
    onClose: () => void;
    onEditShopTab: (tab: ShopTab) => void;
    shopTab: ShopTab;
}

export function EditShopTabModal(props: EditShopTabModalProps) {
    const { isOpen, onClose, onEditShopTab, shopTab } = props;

    const tabSchema = yup
        .object({
            name: yup.string().required(),
        })
        .required();

    const { register, handleSubmit, formState } = useForm({
        resolver: yupResolver(tabSchema),
    });

    return (
        <Modal open={isOpen}>
            <Box
                component="form"
                sx={{
                    position: 'absolute',
                    top: '25%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                }}
                onSubmit={handleSubmit(onEditShopTab)}
            >
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Edit Shop Tab
                </Typography>
                <TextField
                    error={formState.errors?.name?.message}
                    {...register('name')}
                    defaultValue={shopTab.name}
                    label="Name"
                    helperText="Name will be visible in store."
                    variant="outlined"
                />
                <input hidden {...register('id')} defaultValue={shopTab.id as string} />
                <DialogActions>
                    <Button type="submit" variant="contained">
                        Update Tab
                    </Button>
                    <Button variant="outlined" onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Box>
        </Modal>
    );
}
