import { AdminTable, TextInput, confirm, modal, monitor, CellNavigation, useNavigator } from '@components';
import { useSiteConfig } from '@core';
import { yupResolver } from '@hookform/resolvers/yup';
import { CallSplit, Create, Delete } from '@mui/icons-material';
import { Box, Button, LinearProgress } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid';
import type { VirtualCurrency } from '@services/model';
import {
    useDeleteApiVirtualCurrencyId,
    useGetApiVirtualCurrencyByEnvironment,
    usePostApiVirtualCurrency,
    usePutApiVirtualCurrencyId,
} from '@services/virtual-currency/virtual-currency';
import { format, parseISO } from 'date-fns';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';
import { PageBody, PageContent } from '../../../features/shell/layout';
import { titlePageRegistry } from '../PageRegistry';

const tableColumns: GridColDef[] = [
    { 
        field: 'name', 
        headerName: 'Name', 
        flex: 1 ,
        renderCell: (f) => <CellNavigation to="virtual-currency-detail" id={String(f.row.id)} value={String(f.value)} tab="details" />,
    },
    { 
        field: 'currencyCode', 
        headerName: 'Currency Code', 
        flex: 0.5 
    },
    { 
        field: 'initialGrant', 
        headerName: 'Initial Grant Amount', 
        flex: 0.5 
    },
    { 
        field: 'createdAt', 
        headerName: 'Created', 
        flex: 0.5, 
        valueFormatter: (params) => format(parseISO(String(params.value)), 'Ppp') 
    },
];

export function VirtualCurrenciesGrid(props: { gameEnvironmentId: string }) {
    const { isLoading: vcLoading, data: vcData, refetch } = useGetApiVirtualCurrencyByEnvironment();
    const { mutateAsync: add } = usePostApiVirtualCurrency();
    const { mutateAsync: update } = usePutApiVirtualCurrencyId();
    const { mutateAsync: remove } = useDeleteApiVirtualCurrencyId();
    const nav = useNavigator();

    const onAdd = async () => {
        const virtualCurrencyDefaults: VirtualCurrency = { name: '', gameEnvironmentId: props.gameEnvironmentId, currencyCode: '', initialGrant: 0 };
        await modal('Create Virtual Currency', (close) => (
            <VirtualCurrencyPrompt onClose={close} handleSave={(e) => save(e)} defaultValues={virtualCurrencyDefaults} />
        ));
    };

    const onDelete = async (e: Partial<VirtualCurrency>) => {
        const confirmation = await confirm(`Delete ${e.name ?? ''}?`, `Are you sure you want to delete this virtual currency?`);
        if (confirmation) {
            remove({ id: e.id ?? '' }).then(() => {
                refetch();
            });
        }
    };

    const save = (virtualCurrency: VirtualCurrency) => {
        if (virtualCurrency.id) {
            update({ id: String(virtualCurrency.id), data: virtualCurrency }).then(() => {
                refetch();
            });
        } else {
            add({ data: virtualCurrency }).then(() => {
                refetch();
            });
        }
    };

    return vcLoading ? (
        <LinearProgress />
    ) : (
        <PageContent>
            <PageBody noPadding>
                <AdminTable<VirtualCurrency>
                    title="Virtual Currencies"
                    columns={tableColumns}
                    resource="VirtualCurrency"
                    data={vcData ? vcData : []}
                    menuActions={[
                        { type: 'button', icon: Create, label: 'Edit', onClick: (r) => nav.descend('virtual-currency-detail', { id: String(r.id) }) },
                        { type: 'button', icon: Delete, label: 'Delete', onClick: onDelete },
                    ]}
                    onAdd={onAdd}
                />
            </PageBody>
        </PageContent>
    );
}

interface NewVirtualCurrencyPromptProps {
    onClose: () => void;
    handleSave: (e: VirtualCurrency) => void;
    defaultValues?: VirtualCurrency;
}

const VirtualCurrencyPrompt = ({ handleSave, onClose, defaultValues }: NewVirtualCurrencyPromptProps) => {
    const resolverConfig = yup.object().shape({
        name: yup.string().required('Currency name is required'),
        currencyCode: yup
            .string()
            .required('Currency code is required')
            .min(2, 'Currency Code must be at least 2 characters')
            .max(40, 'Currency Code can be no more than 40 characters'),
    });

    const form = useForm({
        resolver: yupResolver(resolverConfig),
        defaultValues: {
            id: defaultValues?.id,
            name: defaultValues?.name,
            currencyCode: defaultValues?.currencyCode,
            gameEnvironmentId: defaultValues?.gameEnvironmentId,
            initialGrant: defaultValues?.initialGrant,
            description: defaultValues?.description
        },
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });

    const { isDirty } = useFormState({ control: form.control });
    const save = async () => {

        monitor({
            failureMessage: 'Error Saving Virtual Currency!',
            successMessage: 'Virtual Currency Saved',
            action: async () => {
                const values = form.getValues();
                onClose();
                handleSave({ ...values });
            },
        });
    };

    return (
        <>
            <Box px={2} width={400}>
                <FormProvider {...form}>
                    <TextInput width="100%" label="Virtual Currency Name" name={'name'} />
                    <TextInput width="100%" label="Description" name={'description'} />
                    <TextInput width="100%" label="Currency Code" name={'currencyCode'} />
                    <TextInput width="100%" label="Initial Grant" name={'initialGrant'} />
                </FormProvider>
            </Box>
            <Box p={2} justifyContent="flex-end" display="flex">
                <Button variant="contained" color="primary" disabled={!isDirty} onClick={form.handleSubmit(save)}>
                    Submit
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </Box>
        </>
    );
};

export function VirtualCurrencies() {
    const config = useSiteConfig();
    return (
        <PageContent>
            <VirtualCurrenciesGrid gameEnvironmentId={config.id} />
        </PageContent>
    );
}

titlePageRegistry.register({ page: VirtualCurrencies, path: 'virtualcurrencies', name: 'Virtual Currencies', icon: <CallSplit /> });

