import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export interface BridgeableBlockchainConfirmationDialogProps {
    isOpen: boolean;
    onCancel(): void;
    onConfirm(): void;
    blockchainName: string;
}

export const BridgeableBlockchainConfirmationDialog = (props: BridgeableBlockchainConfirmationDialogProps) => {
    const cancel = () => {
        props.onCancel();
    };

    const confirm = () => {
        props.onConfirm();
    };

    return (
        <Dialog open={props.isOpen}>
            <DialogTitle>Allow Bridging</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Confirm you want to allow bridging this item token to the <strong>{props.blockchainName}</strong> blockchain. This modification cannot be undone.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={cancel}>
                    Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={confirm}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};
