import { AdminTable, confirm, Copyable, Link, modal, monitor, TextInput, UnauthorizedAccess, useNavigator } from '@components';
import { buildSearch, useApiListener, usePermissions } from '@core';
import { yupResolver } from '@hookform/resolvers/yup';
import { Create, Delete } from '@mui/icons-material';
import { Box, Button, Divider, LinearProgress, Typography } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid';
import {
    useDeleteApiGameEnvironmentsId,
    usePostApiGameEnvironments,
    usePostApiGameEnvironmentsFindBy,
} from '@services/game-environments/game-environments';
import { useGetApiGamesId } from '@services/games/games';
import type { GameEnvironment, GameEnvironmentResult, SaveGameEnvironmentRequest } from '@services/model';
import { format, parseISO } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { useMount } from 'react-use';
import * as yup from 'yup';

import { PageBody, PageContent, PageHeader } from '../../../features/shell/layout';

export function TitleEnvironments(props: { titleId?: string }) {
    const { canRead } = usePermissions('GameEnvironment');
    if (!canRead) {
        return <UnauthorizedAccess />;
    }
    const { mutateAsync: remove } = useDeleteApiGameEnvironmentsId();
    const { mutate, data, isLoading } = usePostApiGameEnvironmentsFindBy();
    const { isLoading: titleLoading, data: titleData, refetch } = useGetApiGamesId(props.titleId || '');
    const { saga } = useFlags();

    const nav = useNavigator();
    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Id', flex: 0.5, renderCell: (cellValues) => <Copyable>{cellValues.row['id']}</Copyable> },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderCell: (cellValues) => <Link to={`/${cellValues.row['id']}`}>{cellValues.row['name']}</Link>,
        },
        {
            field: 'environmentId',
            headerName: 'Environment ID',
            flex: 1,
            renderCell: ({ row }) => (row.sagaTitleId ? row.sagaTitleId : row.iviEnvironmentId),
        },
        { field: 'createdAt', headerName: 'Created', flex: 0.5, valueFormatter: (params) => format(parseISO(String(params.value)), 'Ppp') },
        {
            field: 'blockchainEnvironment',
            headerName: 'Blockchain Environment',
            flex: 0.5,
            renderCell: ({ row }) => buildBlockchainEnvironment(row.isSandbox),
        },
        {
            field: 'releasesOn',
            headerName: 'Release',
            flex: 0.5,
            valueFormatter: (params) => (params.value ? format(parseISO(String(params.value)), 'Ppp') : ''),
        },
    ];

    function buildBlockchainEnvironment(isSandbox?: boolean): any {
        const currentEnvironment = window._env_ || '';

        if (isSandbox == null) {
            return '';
        }

        // PROD is the only environment that can have an environment use production saga
        // all other environments are hitting sandbox regardless of the isSandbox setting
        if (currentEnvironment.toUpperCase() == 'PROD') {
            return isSandbox == true ? 'Sandbox' : 'Production';
        } else {
            return 'Sandbox';
        }
    }

    const search = () => {
        mutate(buildSearch<GameEnvironmentResult>(1, 1000, { eq: { gameId: props.titleId } }));
    };

    useMount(search);

    const onDelete = async (e: Partial<GameEnvironmentResult>) => {
        const didConfirm = await confirm(`Delete ${e.name ?? ''}?`, `Are you sure you want to delete this Environment?`);
        if (didConfirm) {
            await remove({ id: e.id ?? '' });
        }
    };

    function getExistingGameEnvironmentNames(idToExclude?: string | null | undefined) : string[] {
        if (data?.items == undefined) {
            return [];
        }

        if (idToExclude == null) {
            return data.items.map((x) => x.name!);
        } else {
            return data.items.filter((x) => x.id !== idToExclude).map((x) => x.name!);
        }
    }

    const onAdd = async () => {
        const existingEnvironments = getExistingGameEnvironmentNames();
        await modal('Create Environment', (close) => (
            <NewEnvPrompt gameId={props.titleId || ''} onClose={close} existingEnvironments={existingEnvironments ?? []} sagaEnabled={saga} />
        ));
        search();
    };

    useApiListener('gameenvironment', search);

    return isLoading || titleLoading ? (
        <LinearProgress />
    ) : (
        <PageContent>
            <PageHeader>
                <Typography variant="h6">{titleData?.name}</Typography>
            </PageHeader>
            <Divider />
            <PageBody noPadding>
                <AdminTable<GameEnvironmentResult>
                    title="Environments"
                    columns={columns}
                    resource="GameEnvironment"
                    data={data && data?.items ? data?.items : []}
                    menuActions={[
                        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                        {
                            type: 'button',
                            icon: Create,
                            label: 'Edit',
                            onClick: (r) => nav.descend('environment-details', { id: String(r.id), titleId: String(props.titleId) }),
                        },
                        { type: 'button', icon: Delete, label: 'Delete', onClick: onDelete },
                    ]}
                    onAdd={onAdd}
                />
            </PageBody>
        </PageContent>
    );
}

interface NewEnvInfoFormProps {
    gameId: string;
    onClose: () => void;
    existingEnvironments: string[];
    sagaEnabled: boolean;
}

function NewEnvPrompt({ gameId, onClose, existingEnvironments, sagaEnabled }: NewEnvInfoFormProps) {
    const { mutateAsync: add, isLoading: isSaving } = usePostApiGameEnvironments();

    function buildEnvironmentSchema(existingEnvironments: string[]) {
        return yup.object().shape({
            name: yup
                .string()
                .required('Environment Name is required')
                .test('Unique', 'Environment name alredy in use.', (x) => {
                    return x != undefined ? !existingEnvironments.includes(x) : true;
                }),
        });
    }

    const form = useForm({
        defaultValues: {
            name: '',
        },
        resolver: yupResolver(buildEnvironmentSchema(existingEnvironments ?? [])),
        mode: 'onBlur',
        reValidateMode: 'onSubmit',
    });

    const { isDirty } = useFormState({ control: form.control });

    const save = async () => {
        monitor({
            failureMessage: 'Error updating environment!',
            successMessage: 'Environment updated successfully!',
            action: async () => {
                const data = form.getValues();

                const saveRequest: SaveGameEnvironmentRequest = {
                    iviListenerEnabled: !sagaEnabled,
                    sagaSubscriberEnabled: sagaEnabled,
                    name: data.name,
                    gameId: gameId,
                };

                await add({ data: { ...saveRequest } });
                form.reset(data);
                onClose();
            },
        });
    };

    return isSaving ? (
        <LinearProgress />
    ) : (
        <>
            <Box px={2} width={340}>
                <FormProvider {...form}>
                    <TextInput width="full" label="Environment Name" helperText="Give the environment a meaningful name" name="name" />
                </FormProvider>
            </Box>
            <Divider />
            <Box p={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                    <Button onClick={onClose}>Close</Button>
                    <Button onClick={form.handleSubmit(save)} disabled={!isDirty} variant="contained">
                        Save Changes
                    </Button>
                </Box>
            </Box>
        </>
    );
}
