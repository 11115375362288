import { AdminTable, CellNavigation, confirm, DropdownInput, modal, TextInput, useNavigator } from '@components';
import { buildSearch, Event, searchBuilder, useApiListener } from '@core';
import { yupResolver } from '@hookform/resolvers/yup';
import { Create, Delete } from '@mui/icons-material';
import { Box, Button, MenuItem } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid';
import { postApiBundlesQuery, useDeleteApiBundlesId, usePostApiBundles } from '@services/bundles/bundles';
import { usePostApiGameVersionsFindBy } from '@services/game-versions/game-versions';
import type { GameVersion, InventoryContainer, SaveInventoryContainerRequest } from '@services/model';
import { PageContent } from 'features/shell/layout';
import { useState } from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { useMount } from 'react-use';
import * as yup from 'yup';

export const BundlesGrid = () => {
    const nav = useNavigator();
    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'Id',
            type: 'uuid',
            flex: 0.5,
            hide: true,
        },
        {
            field: 'displayName',
            headerName: 'Display Name',
            flex: 0.5,
            type: 'string',
            renderCell: (f) => <CellNavigation to="bundle-details" id={String(f.row.id)} value={String(f.value)} tab="details" />,
        },
        {
            field: 'externalId',
            headerName: 'External Id',
            flex: 0.5,
            type: 'string',
        },
        {
            field: 'minGameVersion',
            headerName: 'Min Game Version',
            flex: 3,
            renderCell: (f) => (
                <CellNavigation to="versions" id={f.row.minGameVersion} value={minGameVersionLookup.get(f.row['minGameVersion']) || ''} />
            ),
        },
    ];

    const [invalidateData] = useState(() => new Event<void>());
    const [minGameVersionLookup, setMinGameVersionLookup] = useState(new Map<string, string>());
    const { mutateAsync: findGameVersion, data: versions } = usePostApiGameVersionsFindBy();

    useMount(() => {
        findGameVersion(buildSearch()).then(({ items }) => {
            setMinGameVersionLookup(new Map((items || []).map((o) => [o.id || '', o.name || ''] as [string, string])));
        });
    });

    const [query] = useState(() =>
        searchBuilder('InventoryContainer')
            .select((bn) => ({
                id: bn.id,
                displayName: bn.displayName,
                minGameVersion: bn.minGameVersion,
                inventoryContainerType: bn.inventoryContainerType,
                externalId: bn.externalId,
            }))
            .where((bn) => ({ eq: { [bn.nameof.inventoryContainerType]: 1 } }))
            .prepare(postApiBundlesQuery)
    );

    const { mutateAsync: remove } = useDeleteApiBundlesId();
    const { mutateAsync: add } = usePostApiBundles();

    const onDelete = async (e: Partial<InventoryContainer>) => {
        const didConfirm = await confirm(`Delete ${e.displayName ?? ''}?`, `Are you sure you want to delete this Bundle?`);
        if (didConfirm) {
            await remove({ id: e.id ?? '' });
        }
    };

    const onAdd = async () => {
        let bundle = undefined;
        await modal('Add Bundle', (close) => (
            <NewBundlePrompt
                onClose={close}
                versions={versions && versions.items ? versions.items : []}
                onChange={(e: SaveInventoryContainerRequest) => (bundle = e)}
            />
        ));
        if (bundle) {
            await add({ data: { ...(bundle as InventoryContainer) } });
        }
    };

    useApiListener('bundle', () => invalidateData.raise());

    return (
        <AdminTable
            title="Bundles"
            resource="Item"
            columns={columns}
            data={query}
            dataInvalidationEvent={invalidateData}
            menuActions={[
                { type: 'button', icon: Create, label: 'Edit', onClick: (r) => nav.descend('bundle-details', { id: String(r.id) }) },
                { type: 'button', icon: Delete, label: 'Delete', onClick: onDelete },
            ]}
            onAdd={onAdd}
        />
    );
};

const BundlesSchema = yup.object().shape({
    displayName: yup.string().required('Display Name is required!'),
    minGameVersion: yup.string().required('Minimum Game Version required!'),
});

function NewBundlePrompt(props: { versions: GameVersion[]; onChange: (bundle: SaveInventoryContainerRequest) => void; onClose: () => void }) {
    const defaultValues: SaveInventoryContainerRequest = {
        displayName: '',
        minGameVersion: '',
        externalId: null
    };

    const form = useForm({ resolver: yupResolver(BundlesSchema), defaultValues, mode: 'onBlur', reValidateMode: 'onBlur' });
    const { isDirty } = useFormState({ control: form.control });
    const save = () => {
        const values = form.getValues();
        props.onChange(values);
        props.onClose();
    };

    return (
        <>
            <FormProvider {...form}>
                <Box px={4} maxWidth={500}>
                    <TextInput label="Display Name" width="full" name="displayName" helperText="What's the bundle display name?" />
                    <TextInput label="External Id" width="full" name="externalId" helperText="What's the bundle external id?" />
                    <DropdownInput
                        width="full"
                        helperText="Select version for bundle to be associated to."
                        label="Game Version"
                        name="minGameVersion"
                    >
                        <MenuItem value="">None</MenuItem>
                        {props.versions &&
                            props.versions.map((c) => (
                                <MenuItem key={c.id} value={c.id || ''}>
                                    {c.name}
                                </MenuItem>
                            ))}
                    </DropdownInput>
                </Box>
            </FormProvider>
            <Box p={2} justifyContent="flex-end" display="flex">
                <Button variant="contained" color="primary" disabled={!isDirty} onClick={form.handleSubmit(save)}>
                    Create Bundle
                </Button>
                <Button onClick={props.onClose}>Cancel</Button>
            </Box>
        </>
    );
}

export function Bundles() {
    return (
        <PageContent>
            <BundlesGrid />
        </PageContent>
    );
}
