import type { ReactNode } from 'react';
import { createContext, useContext } from 'react';

interface ResourceProviderProps {
    value: string;
    children: ReactNode;
}

const ResourceContext = createContext('');

export const ResourceProvider = ({ value, children }: ResourceProviderProps) => {
    return <ResourceContext.Provider value={value}>{children}</ResourceContext.Provider>;
};

export function useResource() {
    return useContext(ResourceContext);
}
