import { FormGrid, FormSection, silentMonitor,monitor, TextInput } from '@components';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Divider, Grid, Toolbar } from '@mui/material';
import type {
    TrackBulkGrantItemsToPlayerResponse,
    TrackBulkGrantItemsToPlayerResponseGrantedItems
} from '@services/model';
import { PageBody, PageContent } from 'features/shell/layout';
import { useCallback, useState } from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { orvalRequestor } from 'shared/core/GameServiceApiOrvalFacade';
import type { InferType} from 'yup';
import { array, number, object, string } from 'yup';
import { toast } from 'react-toastify';

function TrackPlayerBulkGrantItemsControl() {

    const [status, setStatus] = useState<string | null | undefined>("");
    const [errors, setErrors] = useState<string | null | undefined>("");
    const [grantedItems, setGrantedItems] = useState<TrackBulkGrantItemsToPlayerResponseGrantedItems | undefined>(undefined);
    
    const trackBulkGrantItemsFormSchema = object()
        .shape({
            trackingId: string().required('TrackingId is Required')
        })
        .required();


    type ITrackBulkGrantItemsFormInput = InferType<typeof trackBulkGrantItemsFormSchema>;
    const trackBulkGrantItemsForm = useForm<ITrackBulkGrantItemsFormInput>({
        mode: 'onChange',
        resolver: yupResolver(trackBulkGrantItemsFormSchema),
        reValidateMode: 'onBlur',
        defaultValues: {
            trackingId: ""
        },
    });
    const { isDirty } = useFormState(trackBulkGrantItemsForm);


    const getApiTrackBulkGrantItemsToPlayer = useCallback((trackingId: string) => {
        return orvalRequestor<TrackBulkGrantItemsToPlayerResponse>({
            url: `/api/players/track-bulk-grant-items/${trackingId}`,
            method: 'get'
        });
    }, []);

    const executeTrackBulkGrantItemsRequest = useCallback(
        async (trackingId: string): Promise<TrackBulkGrantItemsToPlayerResponse> => {
            let response = {};
            let error = null;

            await silentMonitor({
                failureMessage: "",
                action: async () => {
                    response = await getApiTrackBulkGrantItemsToPlayer(trackingId);
                },
                onError: (e) => {
                    error = e;
                },
            });

            if (error) {
                const data = trackBulkGrantItemsForm.getValues();
                trackBulkGrantItemsForm.reset(data);
                toast.error(`Error fetching results for tracking id: ${trackingId}`);
            }

            return response;
        },
        [getApiTrackBulkGrantItemsToPlayer]
    );

    const submitTrackBulkGrantItemsRequest = useCallback(async () => {

        setStatus("");
        setGrantedItems(undefined);
        setErrors("");

        let response: TrackBulkGrantItemsToPlayerResponse = {};

        const data = trackBulkGrantItemsForm.getValues();
        response = await executeTrackBulkGrantItemsRequest(data.trackingId);

        console.log(response);

        if (response.status) { setStatus(response.status); }
        if (response.grantedItems) { setGrantedItems(response.grantedItems);}
        if (response.errors) { setErrors(response.errors);}

    }, [trackBulkGrantItemsForm, executeTrackBulkGrantItemsRequest]);

    return (
        <PageContent>
            <PageBody>
                <FormProvider {...trackBulkGrantItemsForm}>
                    <FormGrid>
                        <Grid item container xs={12} spacing={2}>
                            <FormSection header="Track Bulk Grant Items to Player Operation">
                                <form >
                                <Box sx={{ width: '100%', padding: '24px' }} >
                                        <TextInput
                                            width="full"
                                            label="Tracking Id"
                                            name="trackingId"
                                            helperText="Enter tracking id"
                                        />
                                    </Box>
                                    <Box sx={{ width: '100%', padding: '24px' }} >
                                        
                                        {status != '' && (
                                        <div style={{ padding: '4px' }}><span style={{ fontWeight: 'bold' }}>Status:</span><span>{status}</span></div>                                    
                                        )}
                                        {grantedItems != undefined && (
                                            <div style={{ padding: '4px' }}>
                                                <span style={{ fontWeight: 'bold' }}>Granted Items:</span>
                                                <span>
                                                <ul>
                                                    {grantedItems != undefined && Object.entries(grantedItems).map(([key, value]) => (
                                                        <li key={key}>
                                                            <span>{key}</span>
                                                            <span>&nbsp;&nbsp;count:&nbsp;&nbsp;</span>
                                                            <span>{value}</span>
                                                    </li>
                                                    ))}
                                                </ul>
                                                </span>
                                            </div>                                        
                                        )}
                                        {errors != "" && errors != "[]" && (
                                            <div style={{ padding: '4px' }}>
                                                <span style={{ fontWeight: 'bold' }}>Errors:</span>
                                                <span>{errors}</span>
                                            </div>
                                        )}
                                    </Box>

                                    <Divider />
                                    <Toolbar>
                                        <Button type="button" onClick={submitTrackBulkGrantItemsRequest} variant="contained" disabled={!isDirty}>
                                            Track
                                        </Button>
                                    </Toolbar>
                                </form>
                            </FormSection>
                        </Grid>
                    </FormGrid>
                </FormProvider>
            </PageBody>
        </PageContent>
    );
}

export function TrackPlayerBulkGrantItems() {
    return <TrackPlayerBulkGrantItemsControl/>;
}
