import type { UserPreferences } from '@core';
import styled from '@emotion/styled';
import { Menu } from '@mui/icons-material';
import type { AppBarProps as MuiAppBarProps } from '@mui/material';
import { AppBar as MuiAppBar, IconButton, Toolbar } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import tinycolor from 'tinycolor2';

import { Logo } from './layout';
import { ProfileMenu } from './ProfileMenu';
import { SitePicker } from './SitePicker';
import { sizes } from './theme';

interface AppBarProps {
    prefs: UserPreferences;
    handleDrawerOpen: () => void;
}

interface StyledAppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const StyledAppBar = muiStyled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<StyledAppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    height: `${sizes.headerSize}px`,
    flex: `0 0 auto`,
    background: tinycolor(theme.palette.primary.main).darken(50).toHexString(),
    color: '#fff',
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: sizes.navWide,
        width: `calc(100% - ${sizes.navWide}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const ToolbarItemsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const ToolbarContent = styled.div`
    display: flex;
`;

export const AppBar = ({ prefs, handleDrawerOpen }: AppBarProps) => (
    <StyledAppBar position="fixed" open={!prefs.navBarCollapsed}>
        <Toolbar variant="dense">
            <ToolbarItemsContainer>
                <ToolbarContent>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: '36px',
                            ...(!prefs.navBarCollapsed && { display: 'none' }),
                        }}
                    >
                        <Menu />
                    </IconButton>
                    <Logo />
                    <SitePicker />
                </ToolbarContent>
                <ToolbarContent>
                    <ProfileMenu />
                </ToolbarContent>
            </ToolbarItemsContainer>
        </Toolbar>
    </StyledAppBar>
);
