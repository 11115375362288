import { AdminTable, CellNavigation, confirm, modal, UnauthorizedAccess, useNavigator} from '@components';
import { useApiListener , usePermissions } from '@core';
import { Business, Create, Delete } from '@mui/icons-material';
import { LinearProgress } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid';
import type { Organization } from '@services/model';
import { useDeleteApiOrganizationsId, useGetApiOrganizations } from '@services/organizations/organizations';
import { format, parseISO } from 'date-fns';
import { PageContent } from 'features/shell/layout';

import { adminPageRegistry } from '../PageRegistry';
import { OrgSetup } from './OrgSetup';

export const OrganizationGrid = () => {
    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Id', flex: 0.5, hide: true },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderCell: (cellValues) => <CellNavigation id={String(cellValues?.id)} value={String(cellValues?.value)} to="org-details" />,
        },
        // Saving for later - but it was requested this be hidden (GSC-374) for the time being. Don't want to delete the code as it works
        // {
        //     field: 'link',
        //     headerName: 'Link',
        //     flex: 0.5,
        //     renderCell: (cellValues) => (
        //         <Link to={`/${cellValues.row['id']}`}>
        //             <Box display="flex" alignItems="center">
        //                 <Outbound />
        //                 Go to Site
        //             </Box>
        //         </Link>
        //     ),
        // },
        { field: 'createdAt', headerName: 'Created', flex: 0.5, valueFormatter: (params) => format(parseISO(String(params.value)), 'Ppp') },
    ];

    const { canRead } = usePermissions('Organization');
    if(!canRead) {
        return (<UnauthorizedAccess />);
    }

    const { isLoading, data, refetch } = useGetApiOrganizations({ SortBy: 'Name', SortOrder: 'ASC' as 'ASC' | 'DESC', Page: 1, PageSize: 1000 });
    const { mutateAsync: remove } = useDeleteApiOrganizationsId();

    const nav = useNavigator();

    const handleModalClosure = (result: string) => {
        if (result) {
            nav.move('org-details', { id: result });
        }
    };
    const onAdd = async () => {
        await modal('Create Organization', (close) => (
            <>
                <OrgSetup
                    onClose={(newOrgId) => {
                        close();
                        handleModalClosure(newOrgId);
                    }}
                />
            </>
        ));
    };

    const onEdit = (e: Organization) => nav.move('org-details', { id: e.id || '', tab: 'config' });
    const onDelete = async (e: Organization) => {
        const didConfirm = await confirm(`Delete ${e.name ?? ''}?`, `Are you sure you want to delete this Org?`);
        if (didConfirm) {
            await remove({ id: e.id ?? '' });
        }
    };

    useApiListener('organization', () => refetch());

    return isLoading ? (
        <LinearProgress />
    ) : (
        <>
            <AdminTable<Organization>
                title="Organizations"
                resource="Organization"
                columns={columns}
                data={data && data?.items ? data?.items : []}
                menuActions={[
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    { type: 'button', icon: Create, label: 'Edit', onClick: (o) => onEdit(o) },
                    { type: 'button', icon: Delete, label: 'Delete', onClick: (o) => onDelete(o) },
                ]}
                onAdd={onAdd}
            />
        </>
    );
};

export function OrgsPage() {
    return (
        <PageContent>
            <OrganizationGrid />
        </PageContent>
    );
}
adminPageRegistry.register({ page: OrgsPage, icon: <Business />, name: 'Organizations', path: 'home' });
