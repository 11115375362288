import { AdminTable } from '@components';
import { Button, Checkbox, DialogActions, Modal } from '@mui/material';
import { Box } from '@mui/system';
import type { GridCellValue, GridColDef } from '@mui/x-data-grid';
import { InventoryContainer } from '@services/model';
import { useEffect, useState } from 'react';
import { useSet } from 'react-use';
import { InventoryContainerShopGrid } from './BundleSelectModal';

export interface ContainerSelectModalProps {
    containers: InventoryContainer[];
    onSubmit(containers: InventoryContainerShopGrid[]): void;
    onCancel(): void;
    isOpen: boolean;
}

export function ContainerSelectModal(props: ContainerSelectModalProps) {
    const { containers, onSubmit, onCancel, isOpen } = props;
    const [ selections, { toggle, has } ] = useSet<InventoryContainerShopGrid>();
    const [ shopContainers, setShopContainers ] = useState<InventoryContainerShopGrid[]>([]);

    useEffect(() => {
        setShopContainers(
            containers?.map((container) => ({
                ...container,
                eligibleQuantity: 1
            })) as InventoryContainerShopGrid[]
        );
    }, [containers]);

    const columns: GridColDef[] = [
        {
            field: 'check',
            headerName: '',
            flex: 0,
            align: 'center',
            valueGetter: ({ row }) => has(row as InventoryContainerShopGrid),
            renderCell: ({ row }) => <Checkbox onClick={() => { toggle(row as InventoryContainerShopGrid); }}/>
        },
        {
            field: 'displayName',
            flex: 1,
            headerName: 'Name',
        },
        {
            field: 'eligibleQuantity',
            headerName: 'Eligible Quantity',
            flex: 1,
            editable: true,
            valueParser: (value: GridCellValue) => {
                return value as number >= 0 ? value : 1;
            },
        }
    ];

    return (
        <Modal open={isOpen}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '33%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box width={500}>
                    <Box width={500} height={500}>
                        <AdminTable<InventoryContainerShopGrid>
                            resource="Item"
                            title=""
                            menuActions={[]}
                            actions={[]}
                            data={shopContainers}
                            columns={columns}
                        />
                    </Box>
                </Box>
                <DialogActions>
                    <Button variant="contained" onClick={() => onSubmit(Array.from(selections))}>
                        Done (selections: {selections.size})
                    </Button>
                    <Button variant="outlined" onClick={onCancel}>
                        Cancel
                    </Button>
                </DialogActions>
            </Box>
        </Modal>
    );
}
