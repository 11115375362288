/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.14.2.2422
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ShopTabItem,
  SaveShopTabItemRequest,
  ShopTabItemIPagedResultList,
  EntitySearch,
  ObjectIPagedResultList
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const postApiShopTabItems = (
    saveShopTabItemRequest: SaveShopTabItemRequest,
 ) => {
      return orvalRequestor<ShopTabItem>(
      {url: `/api/shop-tab-items`, method: 'post',
      data: saveShopTabItemRequest
    },
      );
    }
  


    export const usePostApiShopTabItems = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiShopTabItems>, TError,{data: SaveShopTabItemRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiShopTabItems>, {data: SaveShopTabItemRequest}> = (props) => {
          const {data} = props || {};

          return  postApiShopTabItems(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiShopTabItems>, TError, {data: SaveShopTabItemRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const putApiShopTabItemsId = (
    id: string,
    saveShopTabItemRequest: SaveShopTabItemRequest,
 ) => {
      return orvalRequestor<ShopTabItem>(
      {url: `/api/shop-tab-items/${id}`, method: 'put',
      data: saveShopTabItemRequest
    },
      );
    }
  


    export const usePutApiShopTabItemsId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiShopTabItemsId>, TError,{id: string;data: SaveShopTabItemRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiShopTabItemsId>, {id: string;data: SaveShopTabItemRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiShopTabItemsId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiShopTabItemsId>, TError, {id: string;data: SaveShopTabItemRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const deleteApiShopTabItemsId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/shop-tab-items/${id}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiShopTabItemsId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiShopTabItemsId>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiShopTabItemsId>, {id: string}> = (props) => {
          const {id} = props || {};

          return  deleteApiShopTabItemsId(id,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiShopTabItemsId>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiShopTabItemsId = (
    id: string,
 ) => {
      return orvalRequestor<ShopTabItem>(
      {url: `/api/shop-tab-items/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiShopTabItemsIdQueryKey = (id: string,) => [`/api/shop-tab-items/${id}`];

    
export const useGetApiShopTabItemsId = <TData = AsyncReturnType<typeof getApiShopTabItemsId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiShopTabItemsId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiShopTabItemsIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiShopTabItemsId>> = () => getApiShopTabItemsId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiShopTabItemsId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const postApiShopTabItemsFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ShopTabItemIPagedResultList>(
      {url: `/api/shop-tab-items/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiShopTabItemsFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiShopTabItemsFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiShopTabItemsFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiShopTabItemsFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiShopTabItemsFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiShopTabItemsQuery = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ObjectIPagedResultList>(
      {url: `/api/shop-tab-items/query`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiShopTabItemsQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiShopTabItemsQuery>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiShopTabItemsQuery>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiShopTabItemsQuery(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiShopTabItemsQuery>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    