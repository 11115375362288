import { AdminTable } from '@components';
import { Button, Checkbox, DialogActions, Modal } from '@mui/material';
import { Box } from '@mui/system';
import type { GridCellValue, GridColDef } from '@mui/x-data-grid';
import type { InventoryContainer, InventoryContainerType } from '@services/model';
import { useEffect, useState } from 'react';
import { useSet } from 'react-use';

export interface BundleSelectModalProps {
    bundles: InventoryContainer[];
    onSubmit(bundles: InventoryContainerShopGrid[]): void;
    onCancel(): void;
    isOpen: boolean;
}

interface IInventoryContainerShopGrid extends InventoryContainer {
    eligibleQuantity: number;
}

export class InventoryContainerShopGrid implements IInventoryContainerShopGrid {
    
    constructor() {
        this.eligibleQuantity = 1;
        this.displayName = "";
        this.inventoryContainerType = "Bundle"
    }
    
    eligibleQuantity: number;
    id?: string | null | undefined;
    createdAt?: string | null | undefined;
    modifiedAt?: string | null | undefined;
    deletedAt?: string | null | undefined;
    gameEnvironmentId?: string | undefined;
    displayName: string;
    minGameVersion?: string | undefined;
    inventoryContainerType: InventoryContainerType;
    minGrantableItems?: number | undefined;
    maxGrantableItems?: number | undefined;
}

export function BundleSelectModal(props: BundleSelectModalProps) {
    const { bundles, onSubmit, onCancel, isOpen } = props;
    const [ selections, { toggle, has } ] = useSet<InventoryContainerShopGrid>();
    const [ shopBundles, setShopBundles ] = useState<InventoryContainerShopGrid[]>([]);

    useEffect(() => {
        setShopBundles(
            bundles?.map((bundle) => ({
                ...bundle,
                eligibleQuantity: 1
            })) as InventoryContainerShopGrid[]
        );
    }, [bundles]);

    const columns: GridColDef[] = [
        {
            field: 'check',
            headerName: '',
            flex: 0,
            align: 'center',
            valueGetter: ({ row }) => has(row as InventoryContainerShopGrid),
            renderCell: ({ row }) => <Checkbox onClick={() => { toggle(row as InventoryContainerShopGrid); }}/>
        },
        {
            field: 'displayName',
            flex: 1,
            headerName: 'Name',
        },
        {
            field: 'eligibleQuantity',
            headerName: 'Eligible Quantity',
            flex: 1,
            editable: true,
            valueParser: (value: GridCellValue) => {
                return value as number >= 0 ? value : 1;
            },
        }
    ];

    return (
        <Modal open={isOpen}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '33%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box width={500}>
                    <Box width={500} height={500}>
                        <AdminTable<InventoryContainerShopGrid>
                            resource="Item"
                            title=""
                            menuActions={[]}
                            actions={[]}
                            data={shopBundles}
                            columns={columns}
                        />
                    </Box>
                </Box>
                <DialogActions>
                    <Button variant="contained" onClick={() => onSubmit(Array.from(selections))}>
                        Done (selections: {selections.size})
                    </Button>
                    <Button variant="outlined" onClick={onCancel}>
                        Cancel
                    </Button>
                </DialogActions>
            </Box>
        </Modal>
    );
}
