import {
    BoolInput,
    DropdownInput,
    DurationInput,
    FormGrid,
    FormSection,
    ImageInput,
    IntegerInput,
    LinkTabs,
    monitor,
    Notes,
    ReadonlyInput,
    ResourceProvider,
    SecureImage,
    TextInput,
    useUrlParams,
} from '@components';
import { buildSearchAll, usePermissions } from '@core';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Divider, Grid, LinearProgress, MenuItem } from '@mui/material';
import { postApiItemClassesFindBy } from '@services/item-classes/item-classes';
import { postApiItemTokenMetadata, postApiItemTokenMetadataFindBy, putApiItemTokenMetadataId } from '@services/item-token-metadata/item-token-metadata';
import { postApiItemTokensFindBy } from '@services/item-tokens/item-tokens';
import { getApiItemsId, putApiItemsId } from '@services/items/items';
import type { Item, ItemClassModel, ItemToken, ItemTokenMetadata, SagaMetadata, SaveItemTokenMetadataRequest } from '@services/model';
import { PageBody, PageContent, PageHeader, Toolbar } from 'features/shell/layout';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';

import { titlePageRegistry } from '../PageRegistry';
import { BridgeableBlockchains } from './Bridging/BridgeableBlockchains';
import { createTokenPrompt } from './CreateTokenForm';
import { ItemData } from './ItemData';
import { ItemTokenMetadataContents } from './ItemTokenMetadataContents';
import { useFlags } from 'launchdarkly-react-client-sdk';

const itemFormSchema = yup.object().shape({
    item: yup.object().shape({
        itemName: yup.string().required('Item Name is Required!'),
        displayName: yup.string().required('Display Name is Required!'),
    }),
    consumable: yup.bool(),
    agreementIds: yup.string(),
});
interface DetailsFormProps {
    item: Item;
    classes: ItemClassModel[];
}
function DetailsForm({ item, classes }: DetailsFormProps) {
    const { canUpdate: canUpdateItem, canCreate: canCreateItem } = usePermissions('Item');
    const [token, setToken] = useState<ItemToken>();
    const [itemTokenMetadata, setItemTokenMetadata] = useState<ItemTokenMetadata>();
    const canSave = canUpdateItem || canCreateItem;
    const { itemTokenBridging } = useFlags();

    const form = useForm({
        defaultValues: {
            item,
            consumable: item.consumable,
        },
        resolver: yupResolver(itemFormSchema),
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });

    const { isDirty } = useFormState(form);

    async function loadData() {
        const result = await postApiItemTokensFindBy(buildSearchAll<ItemToken>({ eq: { itemId: item.id } }).data);
        if (result?.items?.length) {
            var itemToken = result.items[0];

            if (itemToken?.id) {
                const tokenMetadataResult = await postApiItemTokenMetadataFindBy(buildSearchAll<ItemTokenMetadata>({ eq: { itemTokenId: itemToken.id } }).data);
    
                if (tokenMetadataResult?.items?.length) {
                    var metadata = tokenMetadataResult.items[0];
                    setItemTokenMetadata(metadata);
                } else {
                    setItemTokenMetadata(undefined);
                }
            }

            setToken(itemToken);

        } else {
            setToken(undefined);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const save = async () => {
        const { item, consumable } = form.getValues();
        item.consumable = consumable;
        monitor({
            failureMessage: 'Error Saving Item!',
            successMessage: 'Item Saved',
            action: async () => {
                const updatedItem = await putApiItemsId(item.id!, item);
                form.reset({ item: updatedItem, consumable });
            },
        });
    };

    const createToken = useCallback(async () => {
        if (await createTokenPrompt(item.id!)) {
            loadData();
        }
    }, [item.id, loadData]);

    const handleSaveMetadata = async (sagaMetadata: SagaMetadata) => {
        monitor({
            failureMessage: 'Error Saving Token Metadata!',
            successMessage: 'Token Metadata Saved',
            action: async () => {

                if (itemTokenMetadata?.id && sagaMetadata !== undefined) {
                    var request : SaveItemTokenMetadataRequest = {
                        ...itemTokenMetadata,
                        tokenMetadata: {
                            ...sagaMetadata,
                        }
                    };
                    setItemTokenMetadata(await putApiItemTokenMetadataId(itemTokenMetadata.id, request));
                    return;
                }
                if (token?.id && sagaMetadata !== undefined) {
                    var request : SaveItemTokenMetadataRequest = {
                        itemTokenId: token?.id,
                        tokenMetadata: {
                            ...sagaMetadata,
                        }
                    };
                    setItemTokenMetadata(await postApiItemTokenMetadata(request));
                    return;
                }
            }
        });
    };

    const consumable = form.watch('consumable');

    return (
        <PageContent>
            <PageBody>
                <FormProvider {...form}>
                    <FormGrid>
                        <Grid item container md={4} xs={12} xl={6} spacing={2} alignContent="baseline">
                            <FormSection header="Item Details" sm={12} xl={6}>
                                <ResourceProvider value="Item">
                                    <ReadonlyInput label="Item ID" name="item.id" />
                                    <DropdownInput width="full" label="Item Class" name="item.itemClassId">
                                        {classes.map((c) => (
                                            <MenuItem key={c.id!} value={c.id!}>
                                                {!!c.description ? `${c.itemClass} - ${c.description}` : c.itemClass}
                                            </MenuItem>
                                        ))}
                                    </DropdownInput>
                                    <TextInput width="full" label="Item Name" name="item.itemName" />
                                    <TextInput width="full" label="Display Name" name="item.displayName" multiline />
                                    <TextInput width="full" label="External Id" name="item.externalId" multiline />
                                    <BoolInput width="full" label="Consumable" helperText="Is this item consumable?" name="consumable" />
                                    <ImageInput 
                                        width="full"
                                        label="Item Image"
                                        helperText="Image"
                                        name="item.imageUrl"
                                        uploadUrl="/api/Organizations/Asset"/>
                                    {!consumable ? null : (
                                        <>
                                            <IntegerInput
                                                width="full"
                                                label="Usage Count"
                                                helperText="How many times can the item be used, 0 - zero for unlimited"
                                                name="item.consumableUsageCount"
                                            />
                                            <DurationInput
                                                width="full"
                                                label="Usage Duration"
                                                name="item.consumableTimespan"
                                                helperText="How long can this item be used? zeros for unlimited"
                                            />
                                        </>
                                    )}
                                </ResourceProvider>
                            </FormSection>
                            <FormSection header="Token Details" sm={12} xl={6}>
                                <ResourceProvider value="Item">
                                    {token ? (
                                        <>
                                            <ReadonlyInput
                                                width="full"
                                                label="State"
                                                value={token.lastIviState ? `${token.lastIviState || 'None'} - ${token.iviUpdatedAt || 'Never'}` :
                                                `${token.blockchainState || 'None'} - ${token.blockchainStateModifiedAt || 'Never'}` }
                                            />
                                            <ReadonlyInput width="full" label="Token Name" value={token.tokenName} />
                                            <ReadonlyInput width="full" label="Marketplace Name" value={token.marketplaceInfo?.name} />
                                            <ReadonlyInput width="full" label="Marketplace Description" value={token.marketplaceInfo?.description} />
                                            <ReadonlyInput width="full" label="Current Supply" value={token.currentSupply || 0} />
                                            <ReadonlyInput width="full" label="Issued Supply" value={token.issuedSupply || 0} />
                                            <ReadonlyInput width="full" label="Max Supply" value={token.maxSupply || 0} />
                                            <ReadonlyInput width="full" label="Symbol" value={token.symbol} />
                                            <ReadonlyInput width="full" label="Sellable" format="yesno" value={token.sellable || false} />

                                            {/* Disabling because saga does not honor these values
                                            <ReadonlyInput width="full" label="Transferable" format="yesno" value={token.transferable || false} /> */}

                                            <ReadonlyInput width="full" label="Burnable" format="yesno" value={token.burnable || false} />
                                            <ReadonlyInput width="full" label="Randomize" format="yesno" value={token.randomize || false} />

                                            {/* Disabling this until Saga resolves the issues causing item tokens to fail with this state set to true
                                            <ReadonlyInput width="full" label="Withdrawable" format="yesno" value={token.withdrawable || false} /> */}

                                            {token.marketplaceInfo?.itemImages?.['Front'] ? (
                                                <SecureImage url={token.marketplaceInfo?.itemImages?.['Front'] || ''} width="100%" />
                                            ) : null}
                                        </>
                                    ) : !token ? (
                                        <Button onClick={createToken}>Set up Token...</Button>
                                    ) : null}
                                </ResourceProvider>
                            </FormSection>
                        </Grid>
                        <Grid item container xs={12} md={8} xl={6} spacing={2}>
                            {itemTokenBridging && token && token.id ? <BridgeableBlockchains itemTokenId={token.id} /> : null}
                            <FormSection header="Notes">
                                <Notes type="Item" typeId={item.id!} hideTitle />
                            </FormSection>
                        </Grid>
                        <Grid item container xs={12} spacing={2}>
                            {token && (
                                <FormSection header="Manage Token Metadata">
                                    <ItemTokenMetadataContents
                                        itemTokenMetadata={itemTokenMetadata}
                                        onSave={handleSaveMetadata}
                                    />
                                </FormSection>
                            )}
                        </Grid>
                    </FormGrid>
                </FormProvider>
            </PageBody>
            <Divider />
            {canSave && (
                <Toolbar>
                    <Button onClick={form.handleSubmit(save)} variant="contained" disabled={!isDirty}>
                        Save Changes
                    </Button>
                </Toolbar>
            )}
        </PageContent>
    );
}

function Details(props: { itemId: string }) {
    const [item, setItem] = useState<Item>();
    const [classes, setClasses] = useState<ItemClassModel[]>();
    const loadItem = async () => {
        const [itemResult, classResults] = await Promise.all([getApiItemsId(props.itemId), postApiItemClassesFindBy(buildSearchAll().data)]);
        setItem(itemResult);
        setClasses(classResults.items || []);
    };
    useEffect(() => {
        loadItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.itemId]);

    return !item || !classes ? <LinearProgress /> : <DetailsForm item={item} classes={classes} />;
}

function Ownership() {
    return (
        <PageContent>
            <PageHeader>Ownership coming soon</PageHeader>
        </PageContent>
    );
}

export function ItemDetail() {
    const { params } = useUrlParams();
    return (
        <LinkTabs
            orientation="vertical"
            tabs={[
                { label: 'Details', render: () => <Details itemId={params.id} />, url: 'details' },
                { label: 'Data', render: () => <ItemData id={params.id || ''} />, url: 'data' },
                // Hiding per GSC-382 - applies to custom data schema
                //{ label: 'Ownership', component: Ownership, url: 'ownership' },
            ]}
        />
    );
}

titlePageRegistry.register({ page: ItemDetail, path: 'item-detail', name: 'Item' });
