import { CustomData } from '@components';
import { PageBody, PageContent } from 'features/shell/layout';
import type { CustomData as CustomDataModel, CustomDataIPagedResultList, EntitySearch } from '@services/model';
import { postApiPlayerCustomDataFindBy, useDeleteApiPlayerCustomDataId, usePostApiPlayerCustomData } from '@services/player-custom-data/player-custom-data';


export const PlayerData = ({ id }: { id: string }) => {
    const { mutateAsync: remove } = useDeleteApiPlayerCustomDataId();
    const { mutateAsync: addCustomData } = usePostApiPlayerCustomData();

    async function handleFindBy(data: EntitySearch) : Promise<CustomDataIPagedResultList> {
        return await postApiPlayerCustomDataFindBy(data);
    }
    
    async function handleSave(data: { data: CustomDataModel }) : Promise<CustomDataModel> {
        return await addCustomData(data);
    }

    async function handleDelete(data: {id: string}) {
        await remove(data);
    }

    return (
        <PageContent>
            <PageBody>
                <CustomData 
                    type="Player" 
                    entityId={id}
                    resource="PlayerCustomData"
                    onFindBy={handleFindBy}
                    onSave={handleSave}
                    onDelete={handleDelete}
                />
            </PageBody>
        </PageContent>
    );
};
