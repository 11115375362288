import { CustomData } from "@components";
import { LinearProgress } from "@mui/material";
import { postApiDropTablesCustomDataFindBy, useDeleteApiDropTablesCustomDataId, usePostApiDropTablesCustomData } from "@services/drop-tables-custom-data/drop-tables-custom-data";
import { useGetApiDropTablesId } from "@services/drop-tables/drop-tables";
import type { CustomData as CustomDataModel, CustomDataIPagedResultList, EntitySearch } from '@services/model';
import { PageContent, PageBody } from "features/shell/layout";

export const DropTableData = ({ id }: { id: string} ) => {
    const { data: dropTable } = useGetApiDropTablesId(id);
    const { mutateAsync: remove } = useDeleteApiDropTablesCustomDataId();
    const { mutateAsync: addCustomData } = usePostApiDropTablesCustomData();

    async function handleFindBy(data: EntitySearch) : Promise<CustomDataIPagedResultList> {
        return await postApiDropTablesCustomDataFindBy(data);
    }
    
    async function handleSave(data: { data: CustomDataModel }) : Promise<CustomDataModel> {
        return await addCustomData(data);
    }

    async function handleDelete(data: {id: string}) {
        await remove(data);
    }

    return !dropTable ? 
        (<LinearProgress />) : 
        (<PageContent>
            <PageBody noPadding>
                <CustomData
                    type="DropTable"
                    entityId={id}
                    resource="Item"
                    onFindBy={handleFindBy}
                    onSave={handleSave}
                    onDelete={handleDelete}
                />
            </PageBody>
        </PageContent>);
}