import type { SiteConfig, SiteTheme, UserPreferences } from '@core';
import { defaultSiteConfig, useSiteConfig, useUserPrefs } from '@core';
import { css, Global, ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { createTheme, CssBaseline, ThemeProvider as MuiThemeProvider } from '@mui/material';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import tinycolor from 'tinycolor2';

declare module '@emotion/react' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    export interface Theme extends SiteTheme, LayoutTheme {}
}

interface LayoutTheme {
    sizes: {
        headerSize: number;
        navNarrow: number;
        navWide: number;
    };
}

export const sizes = {
    headerSize: 50,
    navNarrow: 58,
    navWide: 240,
};

export const globalStyles = css`
    html,
    body,
    #root {
        margin: 0;
        padding: 0;
        height: 100%;
    }
`;

export const createMuiTheme = (config: SiteConfig, prefs?: UserPreferences) => {
    const theme = config.theme || defaultSiteConfig.theme;
    const bg = prefs?.darkMode ? theme.darkBg : theme.lightBg;

    return createTheme({
        palette: {
            primary: { main: theme.primary },
            secondary: { main: theme.secondary },
            mode: prefs?.darkMode ? 'dark' : 'light',
            background: {
                paper: tinycolor(bg).lighten(8).toHex8String(),
                default: bg,
            },
        },
        components: {
            MuiTable: {},
            MuiGrid: {
                defaultProps: {
                    alignContent: 'baseline',
                },
            },
        },
    });
};

export function ThemeProvider(props: { children: ReactNode }) {
    const config = useSiteConfig();
    const { prefs } = useUserPrefs();
    const [muiTheme, setMuiTheme] = useState(createMuiTheme(config, prefs));
    const theme = config.theme || defaultSiteConfig.theme;

    useEffect(() => setMuiTheme(createMuiTheme(config, prefs)), [prefs]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MuiThemeProvider theme={muiTheme}>
                <EmotionThemeProvider theme={{ sizes, ...theme }}>
                    <CssBaseline />
                    <Global styles={globalStyles} />
                    {props.children}
                </EmotionThemeProvider>
            </MuiThemeProvider>
        </LocalizationProvider>
    );
}
