import { confirm } from '@components';
import { DateTimePicker } from '@mui/lab';
import type { SelectChangeEvent } from '@mui/material';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import type { Player } from '@services/model';
import { usePutApiPlayersId } from '@services/players/players';
import { formatISO } from 'date-fns';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';

interface ModerationFieldProps {
    modType: 'ban' | 'mute';
    player: Player;
    accessor: keyof Player;
    label: string;
}

const timePresentUnit = 86400000; // 1 day
const timePresets: { name: string; value: number }[] = [
    { name: '1 day', value: 1 },
    { name: '3 days', value: 3 },
    { name: '7 days', value: 7 },
    { name: '30 days', value: 30 },
];

const getTimestamp = (duration: number, until?: Date) => {
    if (duration < 0) {
        return 0;
    }

    let timestamp = 0;
    if (duration === 0) {
        if (!until || isNaN(until.getTime())) {
            return 0;
        }
        timestamp = until.getTime();
    } else {
        timestamp = new Date().getTime() + duration * timePresentUnit;
    }
    return new Date(timestamp);
};

export const ModerationField = ({ modType, player, accessor, label }: ModerationFieldProps) => {
    const [until, setUntil] = useState<Date | null>();
    const [duration, setDuration] = useState(-1);
    const [disable, setDisable] = useState(true);
    const [dateChanged, setDateChanged] = useState(false);
    const modTypeCase = _.startCase(modType);
    const { mutateAsync: update } = usePutApiPlayersId();

    useEffect(() => {
        setUntil(new Date(String(player[accessor])));
    }, [accessor, modType, player, player.bannedUntil, player.mutedUntil]);

    useEffect(() => {
        if (duration < 0 || (duration === 0 && (!until || until.getTime() <= new Date().getTime()))) {
            setDisable(true);
        } else {
            setDisable(false);
            setDateChanged(true);
        }
    }, [duration, until]);

    const onActionClick = useCallback(async () => {
        const timestamp = getTimestamp(duration, until!);
        if (!timestamp) {
            return;
        }
        const didConfirm = await confirm(
            `${modTypeCase} ${player.displayName ?? ''}?`,
            `Are you want to ${modType} ${player.displayName ?? ''} until ${timestamp.toLocaleString()}?`
        );
        if (didConfirm) {
            (player[accessor] as unknown) = timestamp.toISOString();
            await update({ id: String(player.id), data: player });
            setDateChanged(false);
        }
    }, [duration, until, modTypeCase, player, modType, accessor, update]);

    const onUndoClick = useCallback(async () => {
        const didConfirm = await confirm(`Un${modType} ${player.displayName ?? ''}?`, `Are you want to ${modType} ${player.displayName ?? ''}?`);
        if (didConfirm) {
            (player[accessor] as unknown) = undefined;
            await update({ id: String(player.id), data: { ...player } });
            setDateChanged(false);
        }
    }, [accessor, modType, player, update]);

    const onDurationChange = useCallback((e: SelectChangeEvent<number>) => {
        setDuration(Number(e.target.value));
        if (e.target.value === 0) {
            setUntil(new Date());
        }
    }, []);

    return (
        <>
            {until && !dateChanged && until.getTime() > new Date().getTime() ? (
                <>
                    <Grid item xs={10}>
                        <Typography variant="subtitle1">
                            {label} until: {until.toLocaleString()}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" color="primary" onClick={onUndoClick}>
                            Un{modType}
                        </Button>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid item xs={10}>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel>{modTypeCase} duration</InputLabel>
                            <Select value={duration} onChange={onDurationChange}>
                                {duration === -1 && (
                                    <MenuItem value={-1}>
                                        <em>Select one</em>
                                    </MenuItem>
                                )}
                                {timePresets.map((t) => (
                                    <MenuItem key={t.value} value={t.value}>
                                        {t.name}
                                    </MenuItem>
                                ))}
                                <MenuItem value={0}>Custom</MenuItem>
                            </Select>
                        </FormControl>
                        {duration === 0 && (
                            <Box mt={1}>
                                <DateTimePicker
                                    label={`${modTypeCase} until`}
                                    ampm={false}
                                    disablePast
                                    value={until}
                                    onChange={(until) => setUntil(until)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </Box>
                        )}
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" color="primary" fullWidth disabled={disable} onClick={onActionClick}>
                            {modTypeCase}
                        </Button>
                    </Grid>
                </>
            )}
        </>
    );
};
