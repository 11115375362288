/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.14.2.2422
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  VirtualCurrencyToken,
  CreateVirtualCurrencyTokenRequest
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const postApiVirtualCurrencyTokensCreate = (
    createVirtualCurrencyTokenRequest: CreateVirtualCurrencyTokenRequest,
 ) => {
      return orvalRequestor<VirtualCurrencyToken>(
      {url: `/api/virtual-currency-tokens/create`, method: 'post',
      data: createVirtualCurrencyTokenRequest
    },
      );
    }
  


    export const usePostApiVirtualCurrencyTokensCreate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiVirtualCurrencyTokensCreate>, TError,{data: CreateVirtualCurrencyTokenRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiVirtualCurrencyTokensCreate>, {data: CreateVirtualCurrencyTokenRequest}> = (props) => {
          const {data} = props || {};

          return  postApiVirtualCurrencyTokensCreate(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiVirtualCurrencyTokensCreate>, TError, {data: CreateVirtualCurrencyTokenRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiVirtualCurrencyTokensVirtualCurrencyVirtualCurrencyId = (
    virtualCurrencyId: string,
 ) => {
      return orvalRequestor<VirtualCurrencyToken>(
      {url: `/api/virtual-currency-tokens/virtual-currency/${virtualCurrencyId}`, method: 'get'
    },
      );
    }
  

export const getGetApiVirtualCurrencyTokensVirtualCurrencyVirtualCurrencyIdQueryKey = (virtualCurrencyId: string,) => [`/api/virtual-currency-tokens/virtual-currency/${virtualCurrencyId}`];

    
export const useGetApiVirtualCurrencyTokensVirtualCurrencyVirtualCurrencyId = <TData = AsyncReturnType<typeof getApiVirtualCurrencyTokensVirtualCurrencyVirtualCurrencyId>, TError = unknown>(
 virtualCurrencyId: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiVirtualCurrencyTokensVirtualCurrencyVirtualCurrencyId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiVirtualCurrencyTokensVirtualCurrencyVirtualCurrencyIdQueryKey(virtualCurrencyId);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiVirtualCurrencyTokensVirtualCurrencyVirtualCurrencyId>> = () => getApiVirtualCurrencyTokensVirtualCurrencyVirtualCurrencyId(virtualCurrencyId, );

  const query = useQuery<AsyncReturnType<typeof getApiVirtualCurrencyTokensVirtualCurrencyVirtualCurrencyId>, TError, TData>(queryKey, queryFn, {enabled: !!(virtualCurrencyId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

