import googleTagManager from '@analytics/google-tag-manager';
import { useAppConfig } from '@core';
import { Analytics } from 'analytics';

export function useAnalytics(props?: Parameters<typeof Analytics>) {
    const config = useAppConfig();
    const analytics = Analytics({
        ...props,
        version: '2',
        app: 'game-service-web-admin',
        plugins: [
            googleTagManager({
                containerId: config.gtmId,
            }),
        ],
    });
    return analytics;
}
