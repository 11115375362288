import { monitor, TextInput, DateInput, useUrlParams, BreadcrumbLink, LinkTabs } from '@components';
import { buildSearch, useApiListener } from '@core';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { Person } from '@mui/icons-material';
import { Box, Button, Divider, Grid, LinearProgress, Toolbar } from '@mui/material';
import { GridRowData } from '@mui/x-data-grid';
import {
    getApiGameEnvironmentsSettingId,
    putApiGameEnvironmentsSettingId,
    useGetApiGameEnvironmentsSettingId,
    usePostApiGameEnvironmentsFindBy,
} from '@services/game-environments/game-environments';
import type { GameEnvironment, GameEnvironmentSetting, ItemUpdateNotificationSetting, SaveGameEnvironmentSettingsRequest } from '@services/model';
import { adminPageRegistry } from 'features/admin';
import { orgPageRegistry } from 'features/org';
import { PageBody, PageContent } from 'features/shell/layout';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';
import { BoolInput, FormGrid, FormSection } from '../Forms';
import { EnvSettingsContents, UpdateType } from './EnvSettingsContents';

//TODO might need to add buildBlockchainEnvironment to show on the details page since its a computed field
function buildEnvironmentSchema(existingEnvironments: string[]) {
    return yup.object().shape({
        env: yup.object().shape({
            name: yup
                .string()
                .required('Environment Name is required')
                .test('Unique', 'Environment name alredy in use.', (x) => {
                    return x != undefined ? !existingEnvironments.includes(x) : true;
                }),
            releasesOn: yup.date().nullable().default(null),
        }),
    });
}

interface EnvInfoFormProps {
    env: GameEnvironmentSetting;
    sagaEnabled: boolean | undefined;
    existingEnvironments: string[];
}

function DetailsForm({ env, sagaEnabled, existingEnvironments }: EnvInfoFormProps) {
    const [settings, setSettings] = useState<GridRowData[]>([]);
    const [settingsChanged, setSettingsChanged] = useState(false);
    
    useEffect(() => {
        setSettingRows();
        setSettingsChanged(false);
    }, [env]);

    const setSettingRows = () => {
        setSettings(buildSettingRows(env.itemUpdateNotificationSettings));
    };

    function buildSettingRows(entities: ItemUpdateNotificationSetting[] | null | undefined): GridRowData[] {
        return (
            entities?.map((x) => {
                return { ...x, entryId: nanoid() };
            }) ?? []
        );
    }

    const form = useForm({
        defaultValues: {
            env: env,
        },
        resolver: yupResolver(buildEnvironmentSchema(existingEnvironments)),
        mode: 'onBlur',
        reValidateMode: 'onSubmit',
    });

    const { isDirty } = useFormState({ control: form.control });

    function handleSetSettings(settingData: GridRowData[], updateType: UpdateType) {
        setSettingsChanged(true);
        
        if (updateType == UpdateType.Add) {
            let newSettings: GridRowData[] = [];

            let settingsInState = [...settings];

            settingData.forEach((b) => {
                let settingIndex = settingsInState.findIndex((x) => x.entryId === b.entryId);

                if (settingIndex !== -1) {
                    let settingToUpdate = settings[settingIndex];
                    settingToUpdate.count++;
                } else {
                    newSettings.push({ entryId: nanoid(), ...b });
                }
            });

            setSettings(settingsInState.concat(newSettings));
        } else {
            setSettings(settingData);
        }
    }

    const save = async () => {
        monitor({
            failureMessage: 'Error updating environment!',
            successMessage: 'Environment updated successfully!',
            action: async () => {
                const data = form.getValues();
                var env = data.env;
                if (sagaEnabled) {
                    env.iviListenerEnabled = !env.sagaSubscriberEnabled;
                } else {
                    env.sagaSubscriberEnabled = !env.iviListenerEnabled;
                }

                // @ts-ignore
                const request: SaveGameEnvironmentSettingsRequest = {
                    ...env,
                    itemUpdateNotificationSettings: settings
                };

                var updatedGameEnvironment = await putApiGameEnvironmentsSettingId(env.id || '', request);
                form.reset({ env: updatedGameEnvironment });
                setSettingsChanged(false);
            },
        });
    };

    return (
        <PageContent>
            <PageBody>
                <FormProvider {...form}>
                    <FormGrid>
                        <Grid item container md={4} xs={12} xl={6} spacing={2} alignContent="baseline">
                            <FormSection header="Environment Details" sm={12} xl={6}>
                                <TextInput name="env.name" label="Environment Name" />
                                <br />
                                {!sagaEnabled ? (
                                    <div>
                                        <TextInput name="env.iviEnvironmentId" label="IVI Environment" />
                                        <br />
                                        <BoolInput name="env.iviListenerEnabled" label="Enable IVI Listener" />
                                    </div>
                                ) : (
                                    <div>
                                        <BoolInput name="env.sagaSubscriberEnabled" label="Enable Saga Subscriber" />
                                    </div>
                                )}
                                <DateInput
                                    label="Release Date"
                                    includeTime
                                    width="full"
                                    name="env.releasesOn"
                                    helperText="What's the release date?"
                                />
                                <Box sx={{ color: '#d32f2f' }}>
                                    <ErrorMessage errors={form.formState.errors} name="name" />
                                </Box>
                            </FormSection>

                            <Grid item container xs={12} spacing={2}>
                                <FormSection header="Manage Environment Notification Settings">
                                    <EnvSettingsContents environmentSettings={settings} setSettings={handleSetSettings} />
                                </FormSection>
                            </Grid>
                        </Grid>
                    </FormGrid>
                </FormProvider>
            </PageBody>
            <Divider />
            <Toolbar>
                <Button onClick={form.handleSubmit(save)} variant="contained" disabled={!isDirty && !settingsChanged}>
                    Save Changes
                </Button>
            </Toolbar>
        </PageContent>
    );
}

function Details(props: { gameEnvironmentId: string; titleId: string }) {
    const [gameEnvironment, setGameEnvironment] = useState<GameEnvironmentSetting>();
    const [existingEnvironments, setExistingEnvironments] = useState<string[]>([]);
    const { saga } = useFlags();
    const { mutate, data } = usePostApiGameEnvironmentsFindBy();

    const loadGameEnvironment = async () => {
        const gameEnvironmentResult = await Promise.resolve(getApiGameEnvironmentsSettingId(props.gameEnvironmentId));
        setGameEnvironment(gameEnvironmentResult);
        search();
        setExistingEnvironments(getExistingGameEnvironmentNames(props.gameEnvironmentId));
    };

    const search = () => {
        mutate(buildSearch<GameEnvironment>(1, 1000, { eq: { gameId: props.titleId } }));
    };

    function getExistingGameEnvironmentNames(idToExclude?: string | null | undefined): string[] {
        if (data?.items == undefined) {
            return [];
        }

        return idToExclude == null ? data.items.map((x) => x.name!) : data.items.filter((x) => x.id !== idToExclude).map((x) => x.name!);
    }

    useEffect(() => {
        loadGameEnvironment();
    }, [props.gameEnvironmentId]);

    return !gameEnvironment ? (
        <LinearProgress />
    ) : (
        <DetailsForm env={gameEnvironment} sagaEnabled={saga} existingEnvironments={existingEnvironments} />
    );
}

function GameEnvironmentBreadcrumb() {
    const { params } = useUrlParams();
    const { isLoading, data, refetch } = useGetApiGameEnvironmentsSettingId(params.id);
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    useApiListener('game-environments', () => refetch());

    return (
        <BreadcrumbLink>
            <Person /> Game Environment ({isLoading ? 'Loading...' : data?.name})
        </BreadcrumbLink>
    );
}

export function GameEnvironmentDetails() {
    const { params } = useUrlParams();
    return (
        <LinkTabs
            orientation="vertical"
            tabs={[{ label: 'Details', render: () => <Details gameEnvironmentId={params.id} titleId={params.titleId} />, url: 'details' }]}
        />
    );
}

orgPageRegistry.register({ page: GameEnvironmentDetails, path: 'environment-details', name: GameEnvironmentBreadcrumb });
adminPageRegistry.register({ page: GameEnvironmentDetails, path: 'environment-details', name: GameEnvironmentBreadcrumb });
