import 'reflect-metadata';

import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { StrictMode } from 'react';
import { render } from 'react-dom';

import { App } from './App';
import {AppConfigService} from "@core";

const configSvc = new AppConfigService();
configSvc.start();
const LDProvider = withLDProvider({
    clientSideID: configSvc.getConfig().ldClientId.toString() || "",
    context: {
        kind: 'user',
        anonymous: true
      },
})(App);

const rootElement = document.getElementById("root");

render(
<StrictMode>
    <LDProvider />
</StrictMode>, rootElement);