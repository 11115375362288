import { AdminTable, BoolInput, CellNavigation, confirm, modal, monitor, TextInput } from '@components';
import { usePermissions } from '@core';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { Add, Create, Delete, Sync } from '@mui/icons-material';
import { DateTimePicker, TabContext, TabPanel } from '@mui/lab';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    FormHelperText,
    FormLabel,
    Grid,
    LinearProgress,
    MenuItem,
    Select,
    Tab,
    Tabs,
    TextField,
    Typography,
} from '@mui/material';
import type { GridColDef, GridRowData } from '@mui/x-data-grid';
import { useGetApiBundles } from '@services/bundles/bundles';
import { useGetApiContainers } from '@services/containers/containers';
import { useGetApiDropTables } from '@services/drop-tables/drop-tables';
import { useGetApiItems } from '@services/items/items';
import type {
    AdjustSingleCurrencyRequest,
    AdminUpdatePlayerItemLockRequest,
    BaseBackofficeResult,
    GrantItemsToPlayerRequest,
    InventoryContainer,
    Item,
    PlayerInventoryResult,
    PlayerItemAggregate,
    SyncOnChainItemsRequest,
    VirtualCurrency,
} from '@services/model';
import {
    getApiPlayerItemsInventoryId,
    postApiPlayerItemsSync,
    useDeleteApiPlayerItemsPlayerItemId,
    useGetApiPlayerItemsInventoryId,
    usePostApiPlayerItems,
    usePutApiPlayerItemsPlayerItemIdLock,
} from '@services/player-items/player-items';
import { usePostApiPlayerVirtualCurrencyPlayerCurrencyAdjustment } from '@services/player-virtual-currency/player-virtual-currency';
import { useGetApiVirtualCurrencyByEnvironment } from '@services/virtual-currency/virtual-currency';
import { format, parseISO } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';

export function PlayerInventory(props: { playerId?: string }) {
    const { isLoading, data } = useGetApiPlayerItemsInventoryId(String(props.playerId));
    const { mutateAsync: addItems } = usePostApiPlayerItems();
    const { mutateAsync: addCurrency } = usePostApiPlayerVirtualCurrencyPlayerCurrencyAdjustment();
    const { data: items } = useGetApiItems({ SortBy: 'Id', SortOrder: 'ASC' as 'ASC' | 'DESC', Page: 1, PageSize: 100000 });
    const { data: bundles } = useGetApiBundles({ SortBy: 'Id', SortOrder: 'ASC' as 'ASC' | 'DESC', Page: 1, PageSize: 100000 });
    const { data: containers } = useGetApiContainers({ SortBy: 'Id', SortOrder: 'ASC' as 'ASC' | 'DESC', Page: 1, PageSize: 100000 });
    const { data: dropTables } = useGetApiDropTables({ SortBy: 'Id', SortOrder: 'ASC' as 'ASC' | 'DESC', Page: 1, PageSize: 100000 });
    const [currentTab, setCurrentTab] = useState('0');
    const { mutateAsync: remove } = useDeleteApiPlayerItemsPlayerItemId();
    const { canCreate } = usePermissions('Player');
    const [inventoryItemGridData, setInventoryItemGridData] = useState<InventoryItemGrid[]>([]);
    const [inventoryContainerGridData, setInventoryContainerGridData] = useState<InventoryContainerGrid[]>([]);
    const [inventoryCurrencyGridData, setInventoryCurrencyGridData] = useState<GridRowData[]>([]);
    const { data: currencies } = useGetApiVirtualCurrencyByEnvironment();
    const { mutateAsync: update } = usePutApiPlayerItemsPlayerItemIdLock();

    useEffect(() => {
        if (data != null) {
            handleSetInventoryGridData(data);
        }
    }, [data]);

    const itemColumns: GridColDef[] = [
        { field: 'id', headerName: 'itemId', flex: 0.5, hide: true },
        {
            field: 'name',
            headerName: 'Display Name',
            flex: 1,
            renderCell: (cellValues) => <CellNavigation id={String(cellValues?.id)} value={String(cellValues?.value)} to="inventory-item-detail" />,
        },
        {
            field: 'dgoodSerial',
            headerName: 'Serial Number',
            type: 'string',
            flex: 1,
        },
        {
            field: 'createdAt',
            headerName: 'Created',
            flex: 0.5,
            valueFormatter: (params) => format(parseISO(String(params.value)), 'Ppp'),
        },
    ];

    const containerColumns: GridColDef[] = [
        { field: 'id', headerName: 'containerId', flex: 0.5, hide: true },
        {
            field: 'name',
            headerName: 'Display Name',
            flex: 1,
            renderCell: (cellValues) => (
                <CellNavigation id={String(cellValues?.id)} value={String(cellValues?.value)} to="inventory-container-detail" />
            ),
        },
        {
            field: 'openedOn',
            headerName: 'Opened On',
            flex: 1,
            valueFormatter: (params) => formatOpenedOn(String(params.value)),
        },
        {
            field: 'createdAt',
            headerName: 'Created',
            flex: 0.5,
            valueFormatter: (params) => format(parseISO(String(params.value)), 'Ppp'),
        },
    ];

    const currencyColumns: GridColDef[] = [
        { field: 'id', headerName: 'currencyId', flex: 0.5, hide: true },
        {
            field: 'name',
            headerName: 'Display Name',
            flex: 1,
            renderCell: (cellValues) => (
                <CellNavigation id={String(cellValues?.id)} value={String(cellValues?.value)} to="inventory-currency-detail" />
            ),
        },
        {
            field: 'currencyCode',
            headerName: 'Currency Code',
            flex: 0.5,
        },
        {
            field: 'amount',
            headerName: 'Amount',
            flex: 0.5,
        },
        {
            field: 'createdAt',
            headerName: 'Created',
            flex: 0.5,
            valueFormatter: (params) => format(parseISO(String(params.value)), 'Ppp'),
        },
    ];

    function formatOpenedOn(value: string) {
        if (value != 'N/A') {
            return format(parseISO(value), 'Ppp');
        }

        return value;
    }

    function handleSetInventoryGridData(playerInventory: PlayerInventoryResult | undefined) {
        if (playerInventory?.items) {
            const grantedItems: InventoryItemGrid[] = playerInventory?.items?.map((x) => {
                return {
                    id: x.id,
                    itemId: x.itemId,
                    name: x.displayName,
                    dgoodSerial: formatDgoodSerial(x.dgoodSerial, x.blockchainState),
                    createdAt: x.createdAt,
                    locked: x.locked,
                    lockedReason: x.lockedReason,
                    lockedByBlockchain: x.lockedByBlockchain,
                };
            });
            setInventoryItemGridData(grantedItems);
        }

        if (playerInventory?.containers) {
            const grantedContainers: InventoryContainerGrid[] = playerInventory?.containers?.map((x) => {
                return {
                    id: x.id,
                    name: x.displayName,
                    createdAt: x.createdAt,
                    locked: x.locked,
                    openedOn: x.openedOn === null ? 'N/A' : x.openedOn,
                };
            });
            setInventoryContainerGridData(grantedContainers);
        }

        if (playerInventory?.virtualCurrencies && currencies != undefined) {
            const virtualCurrencies: GridRowData[] = playerInventory?.virtualCurrencies?.map((x) => {
                return {
                    ...currencies.find((vc) => vc.id === x.currencyId),
                    id: x.id,
                    amount: x.amount,
                };
            });
            setInventoryCurrencyGridData(virtualCurrencies);
        }
    }

    function formatDgoodSerial(dGoodSerial: number | null | undefined, state: string | null | undefined): string {
        if (state?.toLocaleLowerCase() == 'failed') {
            return 'Failed';
        }

        if (dGoodSerial == null) {
            return 'N/A';
        }

        // When an item is pending issue, it has a serial number of 0.  Once the item is issued and the
        // listener acknowledges that, it's updated to the true value.  The serial number always starts with 1.
        // That said, the word 'Pending' is much cleaner than a serial number of 0 :)
        if (dGoodSerial === 0) {
            return 'Pending';
        }

        return dGoodSerial.toString();
    }

    async function refetch() {
        const playerInventory = await getApiPlayerItemsInventoryId(String(props.playerId));
        handleSetInventoryGridData(playerInventory);
    }

    const onAdd = async () => {
        let grantItemsRequest: GrantItemsToPlayerRequest = { playerId: '', items: [], bundles: [], containers: [], dropTables: [] };
        let grantCurrencyCommand: AdjustSingleCurrencyRequest | null = null;
        await modal('Grant to Player', (close) => (
            <GrantEntityModal
                onClose={close}
                items={items?.items}
                bundles={bundles?.items}
                containers={containers?.items}
                dropTables={dropTables?.items}
                currencies={currencies}
                playerId={String(props.playerId)}
                onInventoryGrant={(e) => (grantItemsRequest = e)}
                onCurrencyGrant={(e) => (grantCurrencyCommand = e)}
                wantsSingleSelection={false}
                excludeTabs={['4']}
            />
        ));
        if (grantItemsRequest.playerId.length > 0) {
            monitor({
                failureMessage: `Error adding items to player's inventory!`,
                successMessage: `Item(s) added to player's inventory`,
                action: async () => {
                    await addItems({ data: { ...(grantItemsRequest as GrantItemsToPlayerRequest) } });
                    refetch();
                },
            });
        }

        if (grantCurrencyCommand) {
            monitor({
                failureMessage: `Error adding currency to player's inventory!`,
                successMessage: `Currency added to player's inventory`,
                action: async () => {
                    await addCurrency({ data: { ...(grantCurrencyCommand as AdjustSingleCurrencyRequest) } });
                    refetch();
                },
            });
        }
    };

    const onDelete = async (e: Partial<InventoryItemGrid>) => {
        const didConfirm = await confirm(
            `Delete ${e.name ?? ''}?`,
            `Are you sure you want to remove this item from the player's inventory? Note that you cannot remove items associated with a token.`
        );
        if (didConfirm) {
            monitor({
                failureMessage: `Error removing item from player's inventory. Please ensure it is not associated with a token.`,
                successMessage: `Item removed from player's inventory`,
                action: async () => {
                    await remove({ playerItemId: e.id ?? '' });
                    refetch();
                },
            });
        }
    };

    const onEdit = async (e: InventoryItemGrid) => {
        let itemToLock = e;
        await modal('Lock or unlock item', (close) => (
            <EditItemLockModal item={e} onClose={close} onChange={(e: InventoryItemGrid) => (itemToLock = e)} />
        ));
        if (itemToLock) {
            monitor({
                failureMessage: 'Error updating lock status on item!',
                successMessage: 'Lock status updated',
                action: async () => {
                    await update({ playerItemId: itemToLock.id || '', data: { ...(itemToLock as AdminUpdatePlayerItemLockRequest) } });
                    refetch();
                },
            });
        }
    };

    const onSync = async () => {
        await modal('Sync On Chain Items', (close) => <SyncPrompt onClose={close} playerId={props.playerId} />);
        refetch();
    };

    function SyncPrompt(props: { onClose: () => void; playerId?: string }) {
        const [isLoading, setIsLoading] = useState<boolean>();
        const [syncResponse, setSyncResponse] = useState<BaseBackofficeResult | null>(null);
        const [createdAt, setCreatedAt] = useState<Date | null>();
        const [dateChosen, setDateChosen] = useState<boolean>(false);

        const handleOnClose = () => {
            setSyncResponse(null);
            setIsLoading(false);
            props.onClose();
        };

        const handleSync = async () => {
            const request: SyncOnChainItemsRequest = {
                playerId: props.playerId!,
                createdAt: createdAt?.toISOString() || '',
            };

            const syncResult = await Promise.resolve(postApiPlayerItemsSync(request));
            setSyncResponse(syncResult);
        };

        const handleDateChange = (startDate: Date | null | undefined) => {
            setCreatedAt(startDate);
            setDateChosen(true);
        };

        return (
            <div>
                <div>Sync Player Inventory to Align with Blockchain?</div>

                {syncResponse && syncResponse.hasError && (
                    <Alert sx={{ marginTop: '15px', marginBottom: '15px' }} severity="error">
                        {syncResponse.errorMessage}
                    </Alert>
                )}
                {syncResponse && !syncResponse.hasError && (
                    <Alert sx={{ marginTop: '15px', marginBottom: '15px' }} severity="success">
                        {syncResponse.successMessage}
                    </Alert>
                )}

                <Box sx={{ paddingTop: '15px' }}>
                    <DateTimePicker
                        label="Please Select Start Date for Sync"
                        onChange={(startDate) => handleDateChange(startDate)}
                        renderInput={(props) => {
                            return <TextField {...props} name="startDate" variant="outlined" />;
                        }}
                        value={createdAt}
                    />
                </Box>

                <Box p={2} justifyContent="flex-end" display="flex">
                    {isLoading && <CircularProgress size={20} />}

                    <Button variant="contained" color="primary" disabled={isLoading || !dateChosen} onClick={handleSync}>
                        Sync
                    </Button>
                    <Button onClick={handleOnClose}>Cancel</Button>
                </Box>
            </div>
        );
    }

    return isLoading ? (
        <LinearProgress />
    ) : (
        <>
            <TabContext value={currentTab ?? '0'}>
                <Grid item xs>
                    <Typography variant="h6">
                        {'Player Inventory'}
                        {canCreate && onAdd ? (
                            <div>
                                <Button sx={{ marginLeft: '8px' }} variant="outlined" onClick={onAdd} startIcon={<Add />}>
                                    Add
                                </Button>
                                <Button sx={{ marginLeft: '8px' }} variant="outlined" onClick={onSync} startIcon={<Sync />}>
                                    Sync On Chain Items
                                </Button>
                            </div>
                        ) : null}
                    </Typography>
                </Grid>

                <Box>
                    <Tabs onChange={(_, tab) => setCurrentTab(tab)} variant="scrollable" scrollButtons="auto" value={currentTab}>
                        <Tab value="0" label="Items" />
                        <Tab value="1" label="Containers" />
                        <Tab value="2" label="Currencies" />
                    </Tabs>
                </Box>

                <TabPanel sx={{ height: '75vh' }} value="0">
                    <AdminTable<InventoryItemGrid>
                        title="Items in Inventory"
                        resource="Player"
                        columns={itemColumns}
                        data={inventoryItemGridData ? inventoryItemGridData : []}
                        menuActions={[
                            { type: 'button', icon: Create, label: 'Edit', onClick: (o) => onEdit(o as InventoryItemGrid) },
                            { type: 'button', icon: Delete, label: 'Delete', onClick: onDelete },
                        ]}
                    />
                </TabPanel>

                <TabPanel sx={{ height: '75vh' }} value="1">
                    <AdminTable<InventoryContainerGrid>
                        title="Containers in Inventory"
                        resource="Player"
                        columns={containerColumns}
                        data={inventoryContainerGridData ? inventoryContainerGridData : []}
                    />
                </TabPanel>

                <TabPanel sx={{ height: '75vh' }} value="2">
                    <AdminTable
                        title="Currencies in Inventory"
                        resource="Player"
                        columns={currencyColumns}
                        data={inventoryCurrencyGridData ? inventoryCurrencyGridData : []}
                    />
                </TabPanel>
            </TabContext>
        </>
    );
}

const itemFormSchema = yup.object().shape({
    item: yup.object().shape({
        lockedReason: yup.string(),
    }),
    locked: yup.bool(),
});

interface ItemLockFormProps {
    item: InventoryItemGrid;
    onChange: (item: InventoryItemGrid) => void;
    onClose: () => void;
}

function EditItemLockModal({ item, onChange, onClose }: ItemLockFormProps) {
    const form = useForm({
        resolver: yupResolver(itemFormSchema),
        defaultValues: {
            item,
            locked: item.locked,
        },
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });

    const { isDirty } = useFormState({ control: form.control });

    const save = () => {
        const values = form.getValues();
        const request = values.item;
        request.locked = values.locked;

        onChange(request);
        onClose();
    };

    const locked = form.watch('locked');

    return (
        <>
            <FormProvider {...form}>
                <Box sx={{ height: '20vh', width: '30vh' }}>
                    {!item.lockedByBlockchain ? (
                        <Box>
                            <FormHelperText sx={{ margin: 0 }}>
                                {'Once an item is locked, you cannot edit it, you can only unlock it.'}
                            </FormHelperText>
                            <BoolInput width="full" label="Locked" helperText="Is the item locked or unlocked?" name="locked" />
                            {!locked ? null : (
                                <>
                                    <TextInput
                                        label="Locked Reason"
                                        helperText="Why is this item locked?"
                                        name="item.lockedReason"
                                        width={'full'}
                                        multiline
                                    />
                                </>
                            )}
                        </Box>
                    ) : (
                        <Box>This item has been locked by the chain and cannot be edited at this time.</Box>
                    )}
                </Box>
            </FormProvider>
            <Box p={2} justifyContent="flex-end" display="flex">
                <Button variant="contained" color="primary" disabled={!isDirty || (item.locked === true && locked === true)} onClick={save}>
                    Save Changes
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </Box>
        </>
    );
}

interface GrantEntityProps {
    onInventoryGrant: (command: GrantItemsToPlayerRequest) => void;
    playerId: string;
    grantedItems?: PlayerItemAggregate[];
    items?: Item[] | null;
    bundles?: InventoryContainer[] | null;
    containers?: InventoryContainer[] | null;
    dropTables?: InventoryContainer[] | null;
    currencies?: VirtualCurrency[] | undefined;
    onClose: () => void;
    onCurrencyGrant: (request: AdjustSingleCurrencyRequest) => void;
    wantsSingleSelection?: boolean;
    excludeTabs?: string[];
}

interface GrantItems {
    playerId: string;
    itemIds: string[];
    bundleIds: string[];
    containerIds: string[];
    dropTableIds: string[];
    currencyToAdd: number | null;
    amountToAdd: number | null;
    filter: string;
}

interface InventoryItemGrid {
    id?: string | null;
    itemId?: string | null;
    displayName?: string | null;
    name?: string | null;
    dgoodSerial?: string;
    createdAt?: string | null;
    locked?: boolean;
    lockedReason?: string | null;
    lockedByUser?: string | null;
    lockedByBlockchain?: boolean;
}

interface InventoryContainerGrid {
    id?: string | null;
    displayName?: string | null;
    createdAt?: string | null;
    locked?: boolean;
    openedOn?: string | null;
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}

export function GrantEntityModal({
    onInventoryGrant,
    playerId,
    onClose,
    items,
    bundles,
    containers,
    dropTables,
    currencies,
    onCurrencyGrant,
    wantsSingleSelection,
    excludeTabs,
}: GrantEntityProps) {
    const [currentTab, setCurrentTab] = useState('0');
    const [currentTabName, setCurrentTabName] = useState('Items');
    const [filterValue, setFilterValue] = useState('');

    const defaultValues: GrantItems = {
        playerId: playerId,
        itemIds: [],
        bundleIds: [],
        containerIds: [],
        dropTableIds: [],
        currencyToAdd: null,
        amountToAdd: null,
        filter: 'test',
    };

    // sort the items by display name and apply any filtering
    const sortedItems = useMemo(() => {
        return items
            ?.sort((a, b) => a.displayName.localeCompare(b.displayName))
            .filter((i) => (filterValue ? i.displayName.includes(filterValue) : true));
    }, [filterValue, items]);

    // sort the bundles by display name and apply any filtering
    const sortedBundles = useMemo(() => {
        return bundles
            ?.sort((a, b) => a.displayName.localeCompare(b.displayName))
            .filter((b) => (filterValue ? b.displayName.includes(filterValue) : true));
    }, [bundles, filterValue]);

    // sort the containers by display name and apply any filtering
    const sortedContainers = useMemo(() => {
        return containers
            ?.sort((a, b) => a.displayName.localeCompare(b.displayName))
            .filter((c) => (filterValue ? c.displayName.includes(filterValue) : true));
    }, [containers, filterValue]);

    // sort the drop tables by display name and apply any filtering
    const sortedDropTables = useMemo(() => {
        return dropTables
            ?.sort((a, b) => a.displayName.localeCompare(b.displayName))
            .filter((c) => (filterValue ? c.displayName.includes(filterValue) : true));
    }, [dropTables, filterValue]);

    const form = useForm({
        mode: 'onChange',
        defaultValues: defaultValues,
        resolver: yupResolver(
            yup.object({
                itemIds: yup.array().notRequired(),
                bundleIds: yup.array().notRequired(),
                containerIds: yup.array().notRequired(),
                dropTableIds: yup.array().notRequired(),
                currencyToAdd: yup
                    .number()
                    .notRequired()
                    .nullable()
                    .test('test', '', function (value) {
                        return (this.parent.amountToAdd !== null && value !== null) || (this.parent.amountToAdd == null && value == null);
                    }),
                amountToAdd: yup
                    .number()
                    .notRequired()
                    .nullable()
                    .transform((_, val) => (val ? Number(val) : null))
                    .typeError('Must be a number.')
                    .test('test', '', function (value) {
                        return (this.parent.currencyToAdd !== null && value !== null) || (this.parent.currencyToAdd == null && value == null);
                    }),
            })
        ),
    });

    const { isDirty, isValid, errors } = useFormState({ control: form.control });

    const [selectionsAreDisabled, setSelectionsAreDisabled] = useState(false);
    const onCheckboxChange = useCallback(
        (isChecked: boolean) => {
            // if we're not handling single selections, we don't care about changes
            if (!wantsSingleSelection) return;

            // toggle disabled state with the checkbox change
            setSelectionsAreDisabled(isChecked);
        },
        [wantsSingleSelection]
    );

    function uniqueByReduce<T>(array: T[]): T[] {
        if (array == null) return [];

        return array.reduce((acc: T[], cur: T) => {
            if (!acc.includes(cur)) {
                acc.push(cur);
            }
            return acc;
        }, []);
    }

    const save = () => {
        const values = form.getValues();
        const filteredItems = uniqueByReduce(values?.itemIds?.filter(notEmpty));
        const filteredBundles = uniqueByReduce(values?.bundleIds?.filter(notEmpty));
        const filteredContainers = uniqueByReduce(values?.containerIds.filter(notEmpty));
        const filteredDropTables = uniqueByReduce(values?.dropTableIds.filter(notEmpty));

        if (filteredItems.length > 0 || filteredBundles.length > 0 || filteredContainers.length > 0 || filteredDropTables.length > 0) {
            onInventoryGrant({
                playerId: playerId,
                items: filteredItems,
                bundles: filteredBundles ?? [],
                containers: filteredContainers ?? [],
                dropTables: filteredDropTables ?? [],
            });
        }

        if (values.currencyToAdd != null && values.amountToAdd != null) {
            const currency = currencies?.[values.currencyToAdd];

            onCurrencyGrant({
                playerId: playerId,
                currencyCode: currency!.currencyCode,
                amount: values.amountToAdd,
            });
        }

        onClose();
    };

    function handleTabSwitch(tab: any) {
        setCurrentTab(tab);
        switch (tab) {
            case '0':
                setCurrentTabName('Items');
                break;
            case '1':
                setCurrentTabName('Bundles');
                break;
            case '2':
                setCurrentTabName('Containers');
                break;
            case '3':
                setCurrentTabName('Drop Tables');
                break;
            case '4':
                setCurrentTabName('Currencies');
                break;
            default:
                break;
        }
    }

    return (
        <>
            <FormProvider {...form}>
                <Box mt={1} mb={1}>
                    <TextField label="Filter" variant="outlined" fullWidth name="filter" onChange={(test) => setFilterValue(test.target.value)} />
                </Box>
                <TabContext value={currentTab ?? '0'}>
                    <Box>
                        <Tabs
                            onChange={(_, tab) => {
                                handleTabSwitch(tab);
                            }}
                            variant="scrollable"
                            scrollButtons="auto"
                            value={currentTab}
                        >
                            {(excludeTabs == null || !excludeTabs.includes('0')) && <Tab value="0" label="Items" disabled={selectionsAreDisabled} />}
                            {(excludeTabs == null || !excludeTabs.includes('1')) && (
                                <Tab value="1" label="Bundles" disabled={selectionsAreDisabled} />
                            )}
                            {(excludeTabs == null || !excludeTabs.includes('2')) && (
                                <Tab value="2" label="Containers" disabled={selectionsAreDisabled} />
                            )}
                            {(excludeTabs == null || !excludeTabs.includes('3')) && (
                                <Tab value="3" label="Drop Tables" disabled={selectionsAreDisabled} />
                            )}
                            {(excludeTabs == null || !excludeTabs.includes('4')) && (
                                <Tab value="4" label="Currencies" disabled={selectionsAreDisabled} />
                            )}
                        </Tabs>
                    </Box>

                    <TabPanel value="0">
                        <Box sx={{ overflowY: 'auto', height: '30vh', width: '50vh' }}>
                            {sortedItems?.map((item, index: number) => (
                                <Box px={2} py={1} key={item.id}>
                                    <label>
                                        <Checkbox
                                            control={form.control}
                                            name={`itemIds[${index}]`}
                                            value={String(item.id)}
                                            disabled={selectionsAreDisabled}
                                            onChange={onCheckboxChange}
                                        />{' '}
                                        {item.displayName}
                                    </label>
                                </Box>
                            ))}
                        </Box>
                    </TabPanel>
                    <TabPanel value="1">
                        <Box sx={{ overflowY: 'auto', height: '30vh', width: '50vh' }}>
                            {sortedBundles?.map((bundle, index: number) => (
                                <Box px={2} py={1} key={bundle.id}>
                                    <label>
                                        <Checkbox
                                            control={form.control}
                                            name={`bundleIds[${index}]`}
                                            value={String(bundle.id)}
                                            disabled={selectionsAreDisabled}
                                            onChange={onCheckboxChange}
                                        />{' '}
                                        {bundle.displayName}
                                    </label>
                                </Box>
                            ))}
                        </Box>
                    </TabPanel>
                    <TabPanel value="2">
                        <Box sx={{ overflowY: 'auto', height: '30vh', width: '50vh' }}>
                            {sortedContainers?.map((container, index: number) => (
                                <Box px={2} py={1} key={container.id}>
                                    <label>
                                        <Checkbox
                                            control={form.control}
                                            name={`containerIds[${index}]`}
                                            value={String(container.id)}
                                            disabled={selectionsAreDisabled}
                                            onChange={onCheckboxChange}
                                        />{' '}
                                        {container.displayName}
                                    </label>
                                </Box>
                            ))}
                        </Box>
                    </TabPanel>
                    <TabPanel value="3">
                        <Box sx={{ overflowY: 'auto', height: '30vh', width: '50vh' }}>
                            {sortedDropTables?.map((dropTable, index: number) => (
                                <Box px={2} py={1} key={dropTable.id}>
                                    <label>
                                        <Checkbox
                                            control={form.control}
                                            name={`dropTableIds[${index}]`}
                                            value={String(dropTable.id)}
                                            disabled={selectionsAreDisabled}
                                            onChange={onCheckboxChange}
                                        />{' '}
                                        {dropTable.displayName}
                                    </label>
                                </Box>
                            ))}
                        </Box>
                    </TabPanel>
                    <TabPanel value="4">
                        <Box sx={{ overflowY: 'auto', height: '30vh', width: '50vh' }}>
                            <FormLabel filled sx={{ width: '100%' }}>
                                Currency to Adjust
                            </FormLabel>
                            <Select
                                {...form.register('currencyToAdd')}
                                id="virtual-currency-selection"
                                sx={{ width: '100%', marginTop: '0.85rem', marginBottom: '0.85rem' }}
                            >
                                {currencies?.map((vc, index) => (
                                    <MenuItem value={index} key={vc.id?.toString()}>
                                        {vc.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <ErrorMessage errors={errors} name="currencyToAdd" />
                            <FormLabel sx={{ width: '100%' }}>Amount to Add (positive) or Remove (negative) from Selected Player</FormLabel>
                            <TextField
                                sx={{ marginTop: '0.85rem', marginBottom: '0.85rem', width: '100%' }}
                                {...form.register('amountToAdd')}
                                id="virtual-currency-amount"
                                placeholder="e.g, 1 or -1"
                            />
                            <ErrorMessage errors={errors} name="amountToAdd" />
                        </Box>
                    </TabPanel>
                </TabContext>
            </FormProvider>
            <Box p={2} justifyContent="flex-end" display="flex">
                <Button variant="contained" color="primary" disabled={!isDirty || !isValid} onClick={form.handleSubmit(save)}>
                    Save
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </Box>
        </>
    );
}

// This is a temp checkbox for granting entities, a better UI should be considered.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Checkbox = (props: any) => (
    <Controller
        {...props}
        render={({ field }) => {
            return (
                <input
                    {...field}
                    type="checkbox"
                    value={props.value}
                    checked={field.value === props.value}
                    onChange={(event) => {
                        field.onChange(event.target.checked ? props.value : undefined);
                        props.onChange(event.target.checked);
                    }}
                    disabled={field.value !== props.value && props.disabled}
                />
            );
        }}
    />
);
