import { Delete, Folder, FolderOpen } from '@mui/icons-material';
import { Collapse, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import type { CustomData as CustomDataModel, GameVersion } from '@services/model';
import { useState } from 'react';

interface CollapseListProps {
    gameVersionLookup: Map<string, GameVersion>;
    customData: CustomDataModel[];
    lookupValue: string;
    selectedItem?: CustomDataModel | null;
    onChange: (data: CustomDataModel | undefined) => void;
    onDelete: (model: CustomDataModel) => Promise<void>;
    canDelete: boolean | undefined;
}

export const VersionFolders = ({ gameVersionLookup, customData, lookupValue, selectedItem, onChange, onDelete, canDelete }: CollapseListProps) => {
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <>
            <ListItemButton onClick={handleClick}>
                <ListItemIcon>{open ? <FolderOpen /> : <Folder />}</ListItemIcon>
                <ListItemText primary={gameVersionLookup.get(String(lookupValue))?.name} />
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {customData.map((d) => (
                        <ListItem key={d.id} disablePadding dense selected={d.dataKey === selectedItem?.dataKey && d.id === selectedItem?.id}>
                            <ListItemButton dense onClick={() => onChange(d)}>
                                <ListItemText primary={d.dataKey} />
                            </ListItemButton>
                            <IconButton aria-label="delete" onClick={() => onDelete(d)} disabled={!canDelete}>
                                <Delete />
                            </IconButton>
                        </ListItem>
                    ))}
                </List>
            </Collapse>
        </>
    );
};
