/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.14.2.2422
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  Shop,
  SaveShopRequest,
  ShopIPagedResultList,
  EntitySearch,
  ObjectIPagedResultList
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const postApiShops = (
    saveShopRequest: SaveShopRequest,
 ) => {
      return orvalRequestor<Shop>(
      {url: `/api/shops`, method: 'post',
      data: saveShopRequest
    },
      );
    }
  


    export const usePostApiShops = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiShops>, TError,{data: SaveShopRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiShops>, {data: SaveShopRequest}> = (props) => {
          const {data} = props || {};

          return  postApiShops(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiShops>, TError, {data: SaveShopRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const putApiShopsId = (
    id: string,
    saveShopRequest: SaveShopRequest,
 ) => {
      return orvalRequestor<Shop>(
      {url: `/api/shops/${id}`, method: 'put',
      data: saveShopRequest
    },
      );
    }
  


    export const usePutApiShopsId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiShopsId>, TError,{id: string;data: SaveShopRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiShopsId>, {id: string;data: SaveShopRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiShopsId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiShopsId>, TError, {id: string;data: SaveShopRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiShopsId = (
    id: string,
 ) => {
      return orvalRequestor<Shop>(
      {url: `/api/shops/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiShopsIdQueryKey = (id: string,) => [`/api/shops/${id}`];

    
export const useGetApiShopsId = <TData = AsyncReturnType<typeof getApiShopsId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiShopsId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiShopsIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiShopsId>> = () => getApiShopsId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiShopsId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const deleteApiShopsId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/shops/${id}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiShopsId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiShopsId>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiShopsId>, {id: string}> = (props) => {
          const {id} = props || {};

          return  deleteApiShopsId(id,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiShopsId>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiShopsFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ShopIPagedResultList>(
      {url: `/api/shops/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiShopsFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiShopsFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiShopsFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiShopsFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiShopsFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiShopsQuery = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ObjectIPagedResultList>(
      {url: `/api/shops/query`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiShopsQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiShopsQuery>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiShopsQuery>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiShopsQuery(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiShopsQuery>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    