import { IQueryable, useEvent, Event } from "@core";
import { GridColDef, GridRowData } from "@mui/x-data-grid";
import { useCallback, useEffect, useState } from "react";
import { GridQueryAdapter } from "shared/components/AdminTable/GridQueryAdapter";

export interface UseQueryProps<T> {
    query: T[] | IQueryable<T>;
    columns: GridColDef[];
}

export function useQuery<T = unknown>(props : UseQueryProps<T>) {
    const { query, columns } = props;
    const [queryAdapter] = useState(() => new GridQueryAdapter(columns));
    const [rows, setRows] = useState<readonly GridRowData[]>([]);
    const [rowCount, setRowCount] = useState(0);
    
    const runQuery = useCallback(async () => {
        queryAdapter.pageSize = 10000;
        if ('where' in query) {
            const result = await queryAdapter.applyToQuery(query).execute();
            setRows((result.items as T[]) || []);
            setRowCount(result.totalCount || 0);
        }
    }, [query, queryAdapter]);
    useEffect(() => {runQuery()}, [runQuery]);
    useEffect(() => queryAdapter.updateColumns(columns), [columns, queryAdapter]);
    useEvent(queryAdapter.onChange, runQuery);

    return {rows, rowCount};
}

