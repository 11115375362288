import { AdminTable, CellNavigation, confirm, DropdownInput, SecureImage, modal, TextInput, useNavigator, UnauthorizedAccess } from '@components';
import { buildSearch, Event, searchBuilder, useApiListener, useSiteConfig, usePermissions } from '@core';
import { yupResolver } from '@hookform/resolvers/yup';
import { Create, Delete } from '@mui/icons-material';
import { Box, Button, MenuItem } from '@mui/material';
import type { DataGridProps, GridColDef } from '@mui/x-data-grid';
import { usePostApiGameVersionsFindBy } from '@services/game-versions/game-versions';
import { usePostApiItemClassesFindBy } from '@services/item-classes/item-classes';
import { postApiItemsQuery, useDeleteApiItemsId, usePostApiItems } from '@services/items/items';
import type { GameVersion, Item, ItemClassModel } from '@services/model';
import { format, parseISO } from 'date-fns';
import { PageContent } from 'features/shell/layout';
import { useState } from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { useMount } from 'react-use';
import * as yup from 'yup';

interface ItemsGridProps extends ItemProps {
    gameEnvironmentId: string;
    hideToolbar?: boolean;
    hideMenuActions?: boolean;
}

export const ItemsGrid = ({ gameEnvironmentId, hideMenuActions, ...rest }: ItemsGridProps) => {
    const { canRead } = usePermissions('Item');
    if (!canRead) {
        return <UnauthorizedAccess />;
    }

    const nav = useNavigator();
    const columns: GridColDef[] = [
        {
            field: ' ',
            flex: 0.21,
            type: 'string',
            renderCell: (f) => {
                const imageUrl = f.row['itemImageUrl'] ||JSON.parse(f.row['marketplaceInfo'])?.ItemImages?.['Front'];
                return <SecureImage url={imageUrl || ''} height="100px" width="100px" alt=" " />;
            },
        },
        {
            field: 'id',
            headerName: 'Id',
            type: 'uuid',
            flex: 0.5,
            hide: true,
        },
        {
            field: 'displayName',
            headerName: 'Display Name',
            flex: 0.75,
            type: 'string',
            renderCell: (f) => <CellNavigation to="item-detail" tab="details" id={f.row.id} value={String(f.value)} />,
        },
        {
            field: 'externalId',
            headerName: 'External Id',
            flex: 0.33,
            type: 'string',
        },
        {
            field: 'itemName',
            headerName: 'Item Name',
            flex: 1,
            hide: true,
            type: 'string',
            renderCell: (f) => <CellNavigation to="item-detail" tab="details" id={f.row.id} value={String(f.value)} />,
        },
        {
            field: 'minGameVersion',
            headerName: 'Min Game Version',
            flex: 0.33,
            renderCell: (f) => (
                <CellNavigation to="versions" id={f.row.minGameVersion} value={minGameVersionLookup.get(f.row['minGameVersion']) || ''} />
            ),
        },
        {
            field: 'itemClass',
            headerName: 'Item Class',
            type: 'string',
            flex: 0.5,
        },
        {
            field: 'token',
            headerName: 'Token Name',
            type: 'string',
            flex: 0.5,
        },
        {
            field: 'tokenId',
            headerName: 'Token ID',
            type: 'uuid',
            flex: 1,
        },
        { field: 'createdAt', headerName: 'Created', flex: 0.5, valueFormatter: (params) => format(parseISO(String(params.value)), 'Ppp') },
    ];
    const [invalidateData] = useState(() => new Event<void>());
    const { mutateAsync: findItemClasses, data: itemClasses } = usePostApiItemClassesFindBy();
    const [minGameVersionLookup, setMinGameVersionLookup] = useState(new Map<string, string>());
    const { mutateAsync: findGameVersion, data: versions } = usePostApiGameVersionsFindBy();
    useMount(() => {
        findGameVersion(buildSearch()).then(({ items }) => {
            setMinGameVersionLookup(new Map((items || []).map((o) => [o.id || '', o.name || ''] as [string, string])));
        });
        findItemClasses(buildSearch()).then(({ items: classes }) => {
            if (classes?.length == 0) {
                const confirmRedirect = confirm(
                    `Hey There!`,
                    `We noticed you don't have an Item Class configured yet. In order to create/manage items, you first must have an Item Class record. Would you like to go to the Item Class page?`,
                    'Yes',
                    'No'
                ).then((confirmation) => {
                    if (confirmation) {
                        nav.move('items@tab=classes');
                    }
                });
            }
        });
    });

    const [query] = useState(() =>
        searchBuilder('Item')
            .leftJoin('ItemClassModel', ([it, ic]) => ({ eq: { [it.itemClassId]: ic.id } }))
            .leftJoin('ItemToken', ([it, _ic, tk]) => ({ eq: { [tk.nameof.itemId]: it.id } }))
            .select(([it, ic, tk]) => ({
                marketplaceInfo: tk.marketplaceInfo,
                id: it.id,
                displayName: it.displayName,
                itemName: it.itemName,
                minGameVersion: it.minGameVersion,
                itemClass: ic.itemClass,
                token: tk.tokenName,
                tokenId: tk.externalId,
                createdAt: it.createdAt,
                itemImageUrl: it.imageUrl,
                externalId: it.externalId
            }))
            .prepare(postApiItemsQuery)
    );
    const { mutateAsync: remove } = useDeleteApiItemsId();
    const { mutateAsync: add } = usePostApiItems();
    const onDelete = async (e: Partial<Item>) => {
        const didConfirm = await confirm(`Delete ${e.displayName ?? ''}?`, `Are you sure you want to delete this Item?`);
        if (didConfirm) {
            await remove({ id: e.id ?? '' });
        }
    };
    const onAdd = async () => {
        let item = undefined;
        await modal('Add Item', (close) => (
            <NewItemPrompt
                onClose={close}
                versions={versions && versions.items ? versions.items : []}
                classes={itemClasses?.items || []}
                onChange={(e: Item) => (item = e)}
            />
        ));
        if (item) {
            await add({ data: { ...(item as Item) } });
        }
    };
    useApiListener('items', () => invalidateData.raise());

    return (
        <AdminTable
            title="Items"
            resource="Item"
            columns={columns}
            data={query}
            dataInvalidationEvent={invalidateData}
            menuActions={
                !hideMenuActions
                    ? [
                          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                          { type: 'button', icon: Create, label: 'Edit', onClick: (r) => nav.descend('item-detail', { id: String(r.id) }) },
                          { type: 'button', icon: Delete, label: 'Delete', onClick: onDelete },
                      ]
                    : undefined
            }
            onAdd={onAdd}
            {...rest}
        />
    );
};
const ItemsSchema = yup.object().shape({
    itemName: yup.string().required('Item Name is required!'),
    displayName: yup.string().required('Display Name is required!'),
    itemClassId: yup.string().required('Item Class is required!'),
    minGameVersion: yup.string().required('Minium Game Version required!'),
});
function NewItemPrompt(props: { classes?: ItemClassModel[]; versions: GameVersion[]; onChange: (item: Item) => void; onClose: () => void }) {
    const defaultValues: Item = {
        itemName: '',
        displayName: '',
        itemClassId: '',
        minGameVersion: '',
        externalId: null
    };
    const form = useForm({ resolver: yupResolver(ItemsSchema), defaultValues, mode: 'onBlur', reValidateMode: 'onBlur' });
    const { isDirty } = useFormState({ control: form.control });
    const save = () => {
        const values = form.getValues();
        props.onChange(values);
        props.onClose();
    };
    return (
        <>
            <FormProvider {...form}>
                <Box px={4} maxWidth={500}>
                    <TextInput label="Item Name" width="full" name="itemName" helperText="What's the actual name of the item?" />
                    <TextInput label="Display Name" width="full" name="displayName" helperText="What's item display name?" />
                    <TextInput label="External Id" width="full" name="externalId" helperText="What's item external id?" />
                    <DropdownInput width="full" helperText="Select an Item Class" label="Item Class" name="itemClassId">
                        <MenuItem value="">None</MenuItem>
                        {props.classes &&
                            props.classes.map((c) => (
                                <MenuItem key={c.id} value={c.id || ''}>
                                    {c.itemClass}
                                </MenuItem>
                            ))}
                    </DropdownInput>
                    <DropdownInput width="full" helperText="Select version for item to be associated to." label="Game Version" name="minGameVersion">
                        <MenuItem value="">None</MenuItem>
                        {props.versions &&
                            props.versions.map((c) => (
                                <MenuItem key={c.id} value={c.id || ''}>
                                    {c.name}
                                </MenuItem>
                            ))}
                    </DropdownInput>
                </Box>
            </FormProvider>
            <Box p={2} justifyContent="flex-end" display="flex">
                <Button variant="contained" color="primary" disabled={!isDirty} onClick={form.handleSubmit(save)}>
                    Create Item
                </Button>
                <Button onClick={props.onClose}>Cancel</Button>
            </Box>
        </>
    );
}

interface ItemProps extends Omit<DataGridProps, 'columns' | 'rows'> {
    hideToolbar?: boolean;
    hideMenuActions?: boolean;
}

export function Items(props: ItemProps) {
    const config = useSiteConfig();
    return (
        <PageContent>
            <ItemsGrid gameEnvironmentId={config.id} {...props} />
        </PageContent>
    );
}
