import {
    BreadcrumbLink,
    FormGrid,
    FormSection,
    LinkTabs,
    monitor,
    Notes,
    ReadonlyInput,
    ResourceProvider,
    TextInput,
    useUrlParams,
} from '@components';
import { useApiListener, usePermissions } from '@core';
import { yupResolver } from '@hookform/resolvers/yup';
import { Person } from '@mui/icons-material';
import { Button, Divider, Grid, LinearProgress, Toolbar } from '@mui/material';
import type { GridRowData } from '@mui/x-data-grid';
import { getApiBundlesAggregateId, putApiBundlesAggregateId, useGetApiBundlesId } from '@services/bundles/bundles';
import type { BundleAggregateViewModel,  InventoryContainerChildListViewModel,  InventoryContainerChildVirtualCurrencyListViewModel,  SaveBundleCommand } from '@services/model';
import { PageBody, PageContent } from 'features/shell/layout';
import { BundleContents } from 'features/title/Bundles/BundleContents';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { titlePageRegistry } from '../PageRegistry';
import { UpdateType } from '../Shared/EntityModels';
import { BundleData } from './BundleData';

const bundleFormSchema = yup.object().shape({
    bundle: yup.object().shape({
        itemName: yup.string().required('Item Name is Required'),
        displayName: yup.string().required('Display Name is Required'),
    }),
    consumable: yup.bool(),
});

interface DetailsFormProps {
    bundle: BundleAggregateViewModel;
}

function DetailsForm({ bundle }: DetailsFormProps) {
    const { canUpdate: canUpdateBundle, canCreate: canCreateBundle } = usePermissions('Item');
    const canSave = canUpdateBundle;
    const [itemsInBundle, setItemsInBundle] = useState<GridRowData[]>([]);
    const [bundlesInBundle, setBundlesInBundle] = useState<GridRowData[]>([]);
    const [currenciesInBundle, setCurrenciesInBundle] = useState<GridRowData[]>([]);
    const [bundleContentsChanged, setBundleContentsChanged] = useState(false);
    const [containersInBundle, setContainersInBundle] = useState<GridRowData[]>([]);
    const [dropTablesInBundle, setDropTablesInBundle] = useState<GridRowData[]>([]);

    useEffect(() => {
        setEntityRows();
        setBundleContentsChanged(false);
    }, [bundle]);

    const setEntityRows = () => {
        setBundlesInBundle(buildEntityRows(bundle?.bundles));
        setItemsInBundle(buildEntityRows(bundle?.items));
        setCurrenciesInBundle(buildVirtualCurrenyRows(bundle?.virtualCurrencyItems));
        setContainersInBundle(buildEntityRows(bundle?.containers));
        setDropTablesInBundle(buildEntityRows(bundle?.dropTables));
    };

    function buildEntityRows(entities: InventoryContainerChildListViewModel[] | null | undefined): GridRowData[] {
        return (
            entities?.map((x) => {
                return { ...x, entryId: nanoid() };
            }) ?? []
        );
    }

    function buildVirtualCurrenyRows(entities: InventoryContainerChildVirtualCurrencyListViewModel[] | null | undefined): GridRowData[] {
        return (
            entities?.map((x) => {
                return { ...x, entryId: nanoid(), name: x.displayName };
            }) ?? []
        );
    }

    function handleSetItemsInBundle(items: GridRowData[], updateType: UpdateType) {
        setBundleContentsChanged(true);

        if (updateType == UpdateType.Add) {
            let newItems: GridRowData[] = [];

            let itemsInState = [...itemsInBundle];

            items.forEach((i) => {
                let itemIndex = itemsInState.findIndex((x) => x.id === i.id);

                if (itemIndex !== -1) {
                    let itemToUpdate = itemsInState[itemIndex];
                    itemToUpdate.count++;
                }
                else {
                    newItems.push({entryId: nanoid(), count: 1, ...i})
                }
            });

            setItemsInBundle(itemsInState.concat(newItems));
        } else {
            setItemsInBundle(items);
        }
    }

    function handleSetCurrenciesInBundle(currencies: GridRowData[]) {
        setBundleContentsChanged(true);
        setCurrenciesInBundle(currencies);
    }

    function addCurrenciesToBundle(currencies: GridRowData[]) {
        setBundleContentsChanged(true);
        var duplicateCurrencies = currenciesInBundle.filter(x => x.id == currencies?.[0].id);
        if (duplicateCurrencies && duplicateCurrencies.map((cur) => cur.amount).includes(currencies?.[0].amount)) {
            toast.error('Cannot add a duplicate currency with the same amount.');
        } else {
            setCurrenciesInBundle((current) => [...current, ...currencies.map((curr) => ({ ...curr, entryId: nanoid() }))]);
        }
    }

    function removeCurrenciesFromBundle(currencies: GridRowData[]) {
        setBundleContentsChanged(true);
        const currencyIds = currencies.map((curr) => curr.entryId);
        setCurrenciesInBundle((current) => current.filter((cur) => currencyIds.includes(cur.entryId)));
    }

    function handleSetBundlesInBundle(bundles: GridRowData[], updateType: UpdateType) {
        setBundleContentsChanged(true);

        if (updateType == UpdateType.Add) {
            let newBundles: GridRowData[] = [];

            let bundlesInState = [...bundlesInBundle];

            bundles.forEach((b) => {
                let bundleIndex = bundlesInState.findIndex((x) => x.id === b.id);

                if (bundleIndex !== -1) {
                    let bundleToUpdate = bundlesInBundle[bundleIndex];
                    bundleToUpdate.count++;
                }
                else {
                    newBundles.push({entryId: nanoid(), count: 1, ...b})
                }
            });

            setBundlesInBundle(bundlesInState.concat(newBundles));
        } else {
            setBundlesInBundle(bundles);
        }
    }

    function handleSetContainersInBundle(containers: GridRowData[], updateType: UpdateType) {
        setBundleContentsChanged(true);

        if (updateType == UpdateType.Add) {
            let newContainers: GridRowData[] = [];

            let containersInState = [...containersInBundle];

            containers.forEach((b) => {
                let containerIndex = containersInState.findIndex((x) => x.id === b.id);

                if (containerIndex !== -1) {
                    let containerToUpdate = containersInBundle[containerIndex];
                    containerToUpdate.count++;
                }
                else {
                    newContainers.push({entryId: nanoid(), count: 1, ...b})
                }
            });

            setContainersInBundle(containersInState.concat(newContainers));
        } else {
            setContainersInBundle(containers);
        }
    }

    function handleSetDropTablesInBundle(dropTables: GridRowData[], updateType: UpdateType) {
        setBundleContentsChanged(true);

        if (updateType == UpdateType.Add) {
            let newDropTables: GridRowData[] = [];

            let dropTablessInState = [...dropTablesInBundle];

            dropTables.forEach((b) => {
                let dropTableIndex = dropTablessInState.findIndex((x) => x.id === b.id);

                if (dropTableIndex !== -1) {
                    let dropTableToUpdate = dropTablesInBundle[dropTableIndex];
                    dropTableToUpdate.count++;
                }
                else {
                    newDropTables.push({entryId: nanoid(), count: 1, ...b})
                }
            });

            setDropTablesInBundle(dropTablessInState.concat(newDropTables));
        } else {
            setDropTablesInBundle(dropTables);
        }
    }

    const form = useForm({
        defaultValues: {
            bundle
        },
        resolver: yupResolver(bundleFormSchema),
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });

    const { isDirty } = useFormState(form);
    const save = async () => {
        const { bundle } = form.getValues();
        monitor({
            failureMessage: 'Error Saving Bundle!',
            successMessage: 'Bundle Saved',
            action: async () => {
                const request : SaveBundleCommand = {
                    id: bundle.bundle?.id ?? "",
                    displayName: bundle.bundle?.displayName ?? "",
                    minGameVersion: bundle.bundle?.minGameVersion ?? "",
                    externalId: bundle.bundle?.externalId ?? null,
                    items: itemsInBundle.map((x) => ({id: x.id, count: x.count })) ?? [],
                    bundles: bundlesInBundle.map((x) => ({id: x.id, count: x.count })) ?? [],
                    virtualCurrencyItems: currenciesInBundle?.map((x) => ({ id: x.id, amount: x.amount })) ?? [],
                    containers: containersInBundle.map((x) => ({id: x.id, count: x.count })) ?? [],
                    dropTables: dropTablesInBundle.map((x) => ({id: x.id, count: x.count })) ?? []
                };

                let bundleId = bundle.bundle?.id ?? '';
                const updatedBundle = await putApiBundlesAggregateId(bundleId, request);
                form.reset({ bundle: updatedBundle });
                setBundleContentsChanged(false);
            },
        });
    };

    return (
        <PageContent>
            <PageBody>
                <FormProvider {...form}>
                    <FormGrid>
                        <Grid item container md={4} xs={12} xl={6} spacing={2} alignContent="baseline">
                            <FormSection header="Bundle Details" sm={12} xl={6}>
                                <ResourceProvider value="Item">
                                    <ReadonlyInput label="Bundle ID" name="bundle.bundle.id" />
                                    <TextInput width="full" label="Display Name" name="bundle.bundle.displayName" multiline />
                                    <TextInput width="full" label="External Id" name="bundle.bundle.externalId" multiline />
                                </ResourceProvider>
                            </FormSection>
                        </Grid>
                        <Grid item container xs={12} md={8} xl={6} spacing={2}>
                            <FormSection header="Notes">
                                <Notes type="Bundle" typeId={bundle.bundle?.id!} hideTitle />
                            </FormSection>
                        </Grid>
                        <Grid item container xs={12} spacing={2}>
                            <FormSection header="Manage Content">
                                <BundleContents
                                    bundle={bundle}
                                    addCurrenciesToBundle={addCurrenciesToBundle}
                                    removeCurrenciesFromBundle={removeCurrenciesFromBundle}
                                    setCurrenciesInBundle={handleSetCurrenciesInBundle}
                                    setBundlesInBundle={handleSetBundlesInBundle}
                                    setItemsInBundle={handleSetItemsInBundle}
                                    setContainersInBundle={handleSetContainersInBundle}
                                    setDropTablesInBundle={handleSetDropTablesInBundle}
                                    itemsInBundle={itemsInBundle}
                                    bundlesInBundle={bundlesInBundle}
                                    currenciesInBundle={currenciesInBundle}
                                    containersInBundle={containersInBundle}
                                    dropTablesInBundle={dropTablesInBundle}
                                />
                            </FormSection>
                        </Grid>
                    </FormGrid>
                </FormProvider>
            </PageBody>
            <Divider />
            {canSave && (
                <Toolbar>
                    <Button onClick={save} variant="contained" disabled={!isDirty && !bundleContentsChanged}>
                        Save Changes
                    </Button>
                </Toolbar>
            )}
        </PageContent>
    );
}

function Details(props: { bundleId: string }) {
    const [bundle, setBundle] = useState<BundleAggregateViewModel>();

    const loadBundle = async () => {
        const bundleResult = await Promise.resolve(getApiBundlesAggregateId(props.bundleId));
        setBundle(bundleResult);
    };

    useEffect(() => {
        loadBundle();
    }, [props.bundleId]);

    return !bundle ? <LinearProgress /> : <DetailsForm bundle={bundle} />;
}

function BundleBreadcrumb() {
    const { params } = useUrlParams();
    const { isLoading, data, refetch } = useGetApiBundlesId(params.id);
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    useApiListener('bundle', () => refetch());

    return (
        <BreadcrumbLink>
            <Person /> Bundle ({isLoading ? 'Loading...' : data?.displayName})
        </BreadcrumbLink>
    );
}

export function BundleDetails() {
    const { params } = useUrlParams();
    return (
        <LinkTabs
            orientation="vertical"
            tabs={[
                { label: 'Details', render: () => <Details bundleId={params.id} />, url: 'details' },
                { label: 'Data', render: () => <BundleData id={params.id || ''} />, url: 'data' },
            ]}
        />
    );
}

titlePageRegistry.register({ page: BundleDetails, path: 'bundle-details', name: BundleBreadcrumb });
