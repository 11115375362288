import { AdminTable, CellNavigation, useNavigator } from '@components';
import type { IQueryable } from '@core';
import { Event, searchBuilder, useApiListener, useSiteConfig } from '@core';
import { Create } from '@mui/icons-material';
import type { GridColDef } from '@mui/x-data-grid';
import { getGridStringOperators } from '@mui/x-data-grid';
import type { Player } from '@services/model';
import { postApiPlayersQuery } from '@services/players/players';
import { format, parseISO } from 'date-fns';
import { PageBody, PageContent } from 'features/shell/layout';
import { useState } from 'react';

import type { RowMenuAction } from '../../../shared/components/AdminTable';

const idFieldFilterOperators = getGridStringOperators().filter((x) => x.value === 'contains' || x.value === 'startsWith');

const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', type: 'string', flex: 1, hide: true, filterOperators: idFieldFilterOperators },
    {
        field: 'displayName',
        headerName: 'Name',
        flex: 1,
        type: 'string',
        renderCell: (cellValues) => <CellNavigation id={String(cellValues?.id)} value={String(cellValues?.value)} to="player-details" tab="info" />,
    },
    {
        field: 'email',
        headerName: 'Email',
        flex: 1,
        type: 'string',
    },
    {
        field: 'userAccountId',
        headerName: 'User Account Id',
        type: 'uuid',
        flex: 1,
        hide: true,
    },
    {
        field: 'state',
        headerName: 'State',
        type: 'string',
        flex: 0.3,
        renderCell: ({ row }) => (!row.iviState ? row.sagaState : row.iviState),
    },
    {
        field: 'bannedUntil',
        headerName: 'Banned',
        type: 'dateTime',
        flex: 0.5,
    },
    {
        field: 'mutedUntil',
        headerName: 'Muted',
        type: 'dateTime',
        flex: 0.5,
    },
    {
        field: 'createdAt',
        headerName: 'Created',
        flex: 0.5,
        valueFormatter: (params) => format(parseISO(String(params.value)), 'Ppp'),
    },
];

export function PlayerGrid(props: { gameEnvironmentId?: string }) {
    const nav = useNavigator();
    const [dataInvalidated] = useState(new Event<void>());
    const [query] = useState(
        () =>
            searchBuilder('Player')
                .leftJoin('UserAccount', ([p, u]) => ({ eq: { [p.nameof.userAccountId]: u.id } }))
                .select(([p, u]) => ({
                    id: p.id,
                    displayName: p.displayName,
                    email: u.email,
                    userAccountId: u.id,
                    iviState: p.iviState,
                    sagaState: p.sagaState,
                    bannedUntil: p.bannedUntil,
                    mutedUntil: p.mutedUntil,
                    createdAt: p.createdAt,
                }))
                .prepare(postApiPlayersQuery) as IQueryable<Player>
    );

    let menuActions: RowMenuAction<Player>[] | ((item: Player) => RowMenuAction<Player>[]) = [];
    const onEdit = (e: Player) => nav.descend('player-details', { id: e.id || '', tab: 'info' });

    menuActions = [
        //eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        { type: 'button', icon: Create, label: 'Edit', onClick: onEdit },
        // { type: 'button', icon: Delete, label: 'Delete', onClick: onDelete },
    ];

    useApiListener('players', () => dataInvalidated.raise());
    return (
        <AdminTable
            title="Players"
            resource="Player"
            columns={columns}
            data={query}
            dataInvalidationEvent={dataInvalidated}
            menuActions={menuActions}
        />
    );
}

export const Players = () => {
    const config = useSiteConfig();
    return (
        <PageContent>
            <PageBody noPadding>
                <PlayerGrid gameEnvironmentId={config.id} />
            </PageBody>
        </PageContent>
    );
};
