/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.14.2.2422
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  SimpleOrderRecord,
  OrderDetailResult,
  SagaStateHistoryRecord
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const getApiOrderByPlayerPlayerId = (
    playerId: string,
 ) => {
      return orvalRequestor<SimpleOrderRecord[]>(
      {url: `/api/order/by-player/${playerId}`, method: 'get'
    },
      );
    }
  

export const getGetApiOrderByPlayerPlayerIdQueryKey = (playerId: string,) => [`/api/order/by-player/${playerId}`];

    
export const useGetApiOrderByPlayerPlayerId = <TData = AsyncReturnType<typeof getApiOrderByPlayerPlayerId>, TError = unknown>(
 playerId: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiOrderByPlayerPlayerId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiOrderByPlayerPlayerIdQueryKey(playerId);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiOrderByPlayerPlayerId>> = () => getApiOrderByPlayerPlayerId(playerId, );

  const query = useQuery<AsyncReturnType<typeof getApiOrderByPlayerPlayerId>, TError, TData>(queryKey, queryFn, {enabled: !!(playerId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const getApiOrderOrderIdDetail = (
    orderId: string,
 ) => {
      return orvalRequestor<OrderDetailResult>(
      {url: `/api/order/${orderId}/detail`, method: 'get'
    },
      );
    }
  

export const getGetApiOrderOrderIdDetailQueryKey = (orderId: string,) => [`/api/order/${orderId}/detail`];

    
export const useGetApiOrderOrderIdDetail = <TData = AsyncReturnType<typeof getApiOrderOrderIdDetail>, TError = unknown>(
 orderId: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiOrderOrderIdDetail>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiOrderOrderIdDetailQueryKey(orderId);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiOrderOrderIdDetail>> = () => getApiOrderOrderIdDetail(orderId, );

  const query = useQuery<AsyncReturnType<typeof getApiOrderOrderIdDetail>, TError, TData>(queryKey, queryFn, {enabled: !!(orderId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const getApiOrderOrderIdHistory = (
    orderId: string,
 ) => {
      return orvalRequestor<SagaStateHistoryRecord[]>(
      {url: `/api/order/${orderId}/history`, method: 'get'
    },
      );
    }
  

export const getGetApiOrderOrderIdHistoryQueryKey = (orderId: string,) => [`/api/order/${orderId}/history`];

    
export const useGetApiOrderOrderIdHistory = <TData = AsyncReturnType<typeof getApiOrderOrderIdHistory>, TError = unknown>(
 orderId: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiOrderOrderIdHistory>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiOrderOrderIdHistoryQueryKey(orderId);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiOrderOrderIdHistory>> = () => getApiOrderOrderIdHistory(orderId, );

  const query = useQuery<AsyncReturnType<typeof getApiOrderOrderIdHistory>, TError, TData>(queryKey, queryFn, {enabled: !!(orderId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

