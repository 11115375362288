import { ResourceProvider } from '@components';
import type { Event, IQueryable } from '@core';
import { useEvent, usePermissions } from '@core';
import { MoreVert } from '@mui/icons-material';
import type { IconProps } from '@mui/material';
import { debounce, IconButton } from '@mui/material';
import type { DataGridProps, GridColDef, GridRowData, GridState } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import type { ChangeEvent, ReactElement } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import type { RowMenuAction } from '.';
import { QuickSearchToolbar, RowMenu } from '.';
import { GridQueryAdapter } from './GridQueryAdapter';

export interface Action<RowData> {
    disabled?: boolean;
    icon: string | (() => ReactElement<any>);
    isFreeAction?: boolean;
    position?: 'auto' | 'toolbar' | 'toolbarOnSelect' | 'row';
    tooltip?: string;
    onClick: (event: MouseEvent, data: RowData | RowData[]) => void;
    iconProps?: IconProps;
    hidden?: boolean;
}

interface AdminTableProps<T> extends Omit<DataGridProps, 'rows'> {
    menuActions?: RowMenuAction<T>[] | ((item: T) => RowMenuAction<T>[]);
    title?: string;
    resource: string;
    data: T[] | IQueryable<T>;
    columns: GridColDef[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: any;
    actions?: (Action<T> | ((rowData: T) => Action<T>))[];
    onAdd?: () => void;
    hideToolbar?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataInvalidationEvent?: Event<any>;
}

function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function AdminTable<T>({
    data,
    columns,
    actions,
    menuActions,
    title,
    resource,
    error,
    onAdd,
    dataInvalidationEvent,
    ...rest
}: AdminTableProps<T>) {
    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | undefined>(undefined);
    const [menuItem, setMenuItem] = useState<T>();
    const { canRead, canDelete, canUpdate } = usePermissions(resource);
    const [searchText, setSearchText] = useState('');
    const [rows, setRows] = useState<readonly GridRowData[] | undefined>();
    const [rowCount, setRowCount] = useState(0);
    const [columnsState, setColumnsState] = useState<GridColDef[] | undefined>([...columns]);
    const [queryAdapter] = useState(() => new GridQueryAdapter(columns));
    const queryTimestamp = useRef(0);

    const runQuery = useCallback(async () => {
        if ('where' in data) {
            const query = queryAdapter.applyToQuery(data);

            const timestamp = Date.now();
            queryTimestamp.current = timestamp;
            const result = await query.execute();

            if (timestamp === queryTimestamp.current) {
                setRows((result.items as T[]) || []);
                setRowCount(result.totalCount || 0);
            }
            // else it was an older query, so ignore the results
        }
    }, [data, queryAdapter]);
    useEffect(() => queryAdapter.updateColumns(columns), [columns, queryAdapter]);
    useEvent(queryAdapter.onChange, runQuery);
    useEvent(dataInvalidationEvent, runQuery);
    const dataMode = 'where' in data ? 'server' : 'client';
    useEffect(() => {
        const cols = [...columns];
        if (menuActions) {
            const menuColumn: GridColDef = {
                field: '',
                flex: 0.08,
                resizable: false,
                disableColumnMenu: true,
                sortable: false,
                filterable: false,
                renderCell: (cellValues) => {
                    return (
                        <IconButton
                            aria-label="actions"
                            onClick={(event) => {
                                setMenuAnchor(event.currentTarget);
                                setMenuItem(cellValues.row as T);
                            }}
                        >
                            <MoreVert />
                        </IconButton>
                    );
                },
            };
            if (
                Array.isArray(menuActions) &&
                menuActions.length > 0 &&
                ((canUpdate && menuActions.find((ma) => ma.type === 'button' && ma.label === 'Edit') != undefined) ||
                    (canDelete && menuActions.find((ma) => ma.type === 'button' && ma.label === 'Delete')) ||
                    (canRead && menuActions.find((ma) => ma.type === 'button' && ma.label === 'View Details')))
            ) {
                cols.unshift(menuColumn);
            }
        }
        setColumnsState(cols);
        // setActionsState(a.length ? a : undefined);
    }, [actions, menuActions, data, columns, canRead, canDelete, canUpdate]);

    const requestSearch = (searchValue: string) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        if ('filter' in data) {
            const filteredRows = data.filter((row: any) => {
                return Object.keys(row).some((field: any) => {
                    return searchRegex.test(row[field].toString());
                });
            });
            setRows(filteredRows);
        } else {
            queryAdapter.updateGlobalSearch(searchValue);
        }
    };

    useEffect(() => {
        if ('filter' in data) {
            setRows(data);
            setRowCount(data.length);
        } else {
            runQuery();
        }
    }, [data, runQuery]);

    const handleMenuOnClose = useCallback(() => {
        setMenuAnchor(undefined);
        setMenuItem(undefined);
    }, []);

    const handleGridStateChange = useCallback(
        (state: GridState) => {
            if (dataMode === 'server') {
                queryAdapter.load(state);
            }
        },
        [dataMode, queryAdapter]
    );

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <ResourceProvider value={resource}>
                {columnsState && rows && (
                    <DataGrid
                        {...rest}
                        style={{ border: 'none' }}
                        onStateChange={handleGridStateChange}
                        rowCount={rowCount}
                        filterMode={dataMode}
                        sortingMode={dataMode}
                        paginationMode={dataMode}
                        components={{ Toolbar: rest.hideToolbar ? null : QuickSearchToolbar }}
                        rows={canRead ? rows : []}
                        columns={columnsState}
                        error={error}
                        componentsProps={{
                            toolbar: {
                                onAdd,
                                title: title,
                                value: searchText,
                                onChange: debounce((event: ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value), 500),
                                clearSearch: () => requestSearch(''),
                            },
                        }}
                    />
                )}
                {menuActions && menuAnchor && menuItem && (
                    <RowMenu menuActions={menuActions} onClose={handleMenuOnClose} anchorEl={menuAnchor} actionMenuItem={menuItem} />
                )}
            </ResourceProvider>
        </div>
    );
}
