import { useDi, useDiContainer, useSiteRootPath } from '@core';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { RegistryNavigator } from './Navigator';
import type { IPageRegistry } from './PageRegistry';
import type { RouteEndpoint } from './RegistryPageRenderer';
import { RouteEndpointDiToken, RouteManager } from './RegistryPageRenderer';

export function useNavigator() {
    useLocation();
    return useDi(RegistryNavigator);
}

export function useUrlParams() {
    const { getParams, setParams, updateParams } = useDi(RegistryNavigator);
    return { params: getParams() || {}, setParams, updateParams };
}

export function useFullRoute(registry: IPageRegistry) {
    const container = useDiContainer();
    const root = useSiteRootPath();
    const { pathname } = useLocation();
    const mgr = useDi(RouteManager);
    const [endpoints, setEndpoints] = useState(mgr.getEndpoints());
    useEffect(() => {
        mgr.reload(pathname, root, registry, container);
        setEndpoints(mgr.getEndpoints());
    }, [pathname, mgr, root, registry, container]);

    return endpoints;
}

export function useRouteInfo() {
    return useDi<() => RouteEndpoint>(RouteEndpointDiToken)();
}
