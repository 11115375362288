import type { ReactNode } from 'react';
import { createContext, useContext } from 'react';
import type { DependencyContainer, InjectionToken } from 'tsyringe';
import { container } from 'tsyringe';

export const DiContext = createContext<DependencyContainer>(container);

export function useDi<T>(token: InjectionToken<T>) {
    const container = useContext(DiContext);
    return container.resolve(token);
}

export function useDiContainer() {
    return useContext(DiContext);
}

export function DiProvider(props: { children: ReactNode; container: DependencyContainer }) {
    return <DiContext.Provider value={container}>{props.children}</DiContext.Provider>;
}
