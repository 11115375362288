import { buildSearch, usePermissions } from '@core';
import { Box, Divider } from '@mui/material';
import { usePostApiGameVersionsFindBy } from '@services/game-versions/game-versions';
import type { CustomData as CustomDataModel, CustomDataIPagedResultList, EntitySearch, GameVersionIPagedResultList } from '@services/model';
import { useCallback, useState } from 'react';
import { useMount } from 'react-use';
import { UnauthorizedAccess } from '../Auth/UnauthorizedAccess';

import { useUrlParams } from '../Navigation';
import { FormAndJsonEditor } from './FormAndJsonEditor';
import type { CustomDataType, InheritedKey } from './KeyNavList';
import { KeyNavList } from './KeyNavList';

interface CustomDataProps {
    type: CustomDataType;
    entityId: string;
    keyNav?: boolean;
    disableNewKeyCreation?: boolean;
    canViewSchema?: boolean;
    inheritedKeys?: InheritedKey[];
    resource?: string;
    onFindBy: (data: EntitySearch) => Promise<CustomDataIPagedResultList>;
    onSave: (data: { data: CustomDataModel }) => Promise<CustomDataModel>;
    onDelete: (data: { id: string }) => void;
}

export const CustomData = ({ type, entityId, keyNav = true, disableNewKeyCreation = false, canViewSchema, inheritedKeys, resource, onFindBy, onSave, onDelete }: CustomDataProps) => {
    const [customData, setCustomData] = useState<CustomDataModel>();
    const { params, updateParams } = useUrlParams();
    const { mutate, data: versions } = usePostApiGameVersionsFindBy();
    const { canCreate, canRead, canUpdate, canDelete } = usePermissions(resource);

    if(!canRead) {
        return (<UnauthorizedAccess />);
    }

    const search = () => {
        mutate(buildSearch<GameVersionIPagedResultList>());
    };

    useMount(() => {
        search();
    });

    function handleDelete(data: { id: string }) {
        onDelete(data);
        handleChange();
    }
    const handleChange = useCallback(
        (item?: CustomDataModel) => {
            updateParams({ key: item?.dataKey || '' });
            setCustomData(item);
        },
        [updateParams, setCustomData]
    );

    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            {!keyNav ? null : (
                <>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        {versions?.items && (
                            <>
                                <KeyNavList
                                    entityId={entityId}
                                    type={type}
                                    selectedItem={customData}
                                    versions={versions?.items}
                                    disableNewKeyCreation={disableNewKeyCreation}
                                    defaultKey={params.key}
                                    onChange={handleChange}
                                    inheritedKeys={inheritedKeys}
                                    onFindBy={onFindBy}
                                    onSave={onSave}
                                    onDelete={handleDelete}
                                    canCreate={canCreate}
                                    canRead={canRead}
                                    canUpdate={canUpdate}
                                    canDelete={canDelete}
                                />
                            </>
                        )}
                    </Box>
                    <Divider orientation="vertical" />
                </>
            )}
            {!customData ? null : <FormAndJsonEditor data={customData} canViewSchema={canViewSchema} canUpdate={canUpdate} />}
        </Box>
    );
};
