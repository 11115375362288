import { searchBuilder, useSiteConfig } from '@core';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Button, FormControl, FormLabel, Grid, MenuItem, Select, Tab, Tabs, TextField } from '@mui/material';
import type { GridColDef, GridRowData, GridSelectionModel } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import { postApiBundlesQuery } from '@services/bundles/bundles';
import { postApiItemsQueryForAddContent } from '@services/items/items';
import { postApiContainersQuery } from '@services/containers/containers';
import type { ContainerAggregateViewModel } from '@services/model';
import { useGetApiVirtualCurrencyByEnvironment } from '@services/virtual-currency/virtual-currency';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'shared/hooks/useQuery';
import * as yup from 'yup';
import { buildEntityListColumns, buildEntityListContentsColumns } from '../Shared/EntityListService';
import { UpdateType } from '../Shared/EntityModels';
import { postApiDropTablesQuery } from '@services/drop-tables/drop-tables';

interface AddContainerProps {
    container: ContainerAggregateViewModel;
    setBundlesInContainer: (bundles: GridRowData[], updateType: UpdateType) => void;
    setItemsInContainer: (items: GridRowData[], updateType: UpdateType) => void;
    setCurrenciesInContainer: (currencies: GridRowData[]) => void;
    addCurrenciesToContainer: (currencies: GridRowData[]) => void;
    removeCurrenciesFromContainer: (currencies: GridRowData[]) => void;
    setContainersInContainer: (containers: GridRowData[], updateType: UpdateType) => void;
    setDropTablesInContainer: (dropTables: GridRowData[], updateType: UpdateType) => void;
    itemsInContainer: GridRowData[];
    bundlesInContainer: GridRowData[];
    currenciesInContainer: GridRowData[];
    containersInContainer: GridRowData[];
    dropTablesInContainer: GridRowData[];
}

export const AddContainerContents = ({
    container,
    setBundlesInContainer,
    setItemsInContainer,
    setCurrenciesInContainer,
    addCurrenciesToContainer,
    removeCurrenciesFromContainer,
    setContainersInContainer,
    setDropTablesInContainer,
    itemsInContainer,
    bundlesInContainer,
    currenciesInContainer,
    containersInContainer,
    dropTablesInContainer,
}: AddContainerProps) => {
    const [currentTab, setCurrentTab] = useState('0');

    const [bundlesQuery] = useState(() =>
        searchBuilder('InventoryContainer')
            .select((bn) => ({
                id: bn.id,
                displayName: bn.displayName,
                inventoryContainerType: bn.inventoryContainerType,
            }))
            .where((bn) => ({ eq: { [bn.nameof.inventoryContainerType]: 1 } }))
            .sortAsc((bn) => bn.displayName)
            .prepare(postApiBundlesQuery)
    );

    const [itemsQuery] = useState(() =>
        searchBuilder('Item')
            .select((it) => ({
                id: it.id,
                displayName: it.displayName,
            }))
            .sortAsc((it) => it.displayName)
            .prepare(postApiItemsQueryForAddContent)
    );

    const [containersQuery] = useState(() =>
        searchBuilder('InventoryContainer')
            .select((bn) => ({
                id: bn.id,
                displayName: bn.displayName,
                inventoryContainerType: bn.inventoryContainerType,
            }))
            .where((bn) => ({ eq: { [bn.nameof.inventoryContainerType]: 2 } }))
            .sortAsc((bn) => bn.displayName)
            .prepare(postApiContainersQuery)
    );

    const [dropTablesQuery] = useState(() =>
        searchBuilder('InventoryContainer')
            .select((bn) => ({
                id: bn.id,
                displayName: bn.displayName,
                inventoryContainerType: bn.inventoryContainerType,
            }))
            .where((bn) => ({ eq: { [bn.nameof.inventoryContainerType]: 3 } }))
            .sortAsc((bn) => bn.displayName)
            .prepare(postApiDropTablesQuery)
    );

    const itemsListColumns = buildEntityListColumns('Item');
    const bundlesListColumns = buildEntityListColumns('Bundle');
    const itemsListContentsColumns = buildEntityListContentsColumns('Item');
    const bundlesListContentsColumns = buildEntityListContentsColumns('Bundle');
    const containersListColumns = buildEntityListColumns('Container');
    const containersListContentsColumns = buildEntityListContentsColumns('Container');
    const dropTablessListColumns = buildEntityListColumns('Drop Table');
    const dropTablesListContentsColumns = buildEntityListContentsColumns('Drop Table');

    const virtualCurrencyListColumns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'Id',
            type: 'uuid',
            hide: true,
        },
        {
            field: 'name',
            headerName: 'Currency Name',
            type: 'string',
            flex: 2,
        },
        {
            field: 'amount',
            headerName: 'Amount',
            type: 'string',
            flex: 2,
        },
        {
            field: 'currencyCode',
            headerName: 'Code',
            type: 'string',
            flex: 1,
        },
    ];

    const { rows: itemRows } = useQuery({ query: itemsQuery, columns: itemsListColumns });
    const { rows: bundleRows } = useQuery({ query: bundlesQuery, columns: bundlesListColumns });
    const { rows: containerRows } = useQuery({ query: containersQuery, columns: containersListColumns });
    const { rows: dropTableRows } = useQuery({ query: dropTablesQuery, columns: dropTablessListColumns });
    const { data: virtualCurrencyRows } = useGetApiVirtualCurrencyByEnvironment();

    const handleAddVirtualCurrency = useCallback(
        ({ currencyToAdd, amountToAdd }: { currencyToAdd: number; amountToAdd: string | number }) => {
            const currency = virtualCurrencyRows?.[currencyToAdd] ?? {};
            addCurrenciesToContainer([{ ...currency, amount: amountToAdd }]);
        },
        [addCurrenciesToContainer, currenciesInContainer, virtualCurrencyRows]
    );

    useEffect(() => {
        setCurrenciesInContainer(
            currenciesInContainer?.map((cur) => ({
                ...cur,
                ...virtualCurrencyRows?.find((vc) => vc.id === cur.id),
                amount: cur.amount,
            })) as GridRowData[]
        );
    }, [virtualCurrencyRows]);

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, isValid },
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(
            yup.object({
                currencyToAdd: yup.number().required(),
                amountToAdd: yup.number().required('Amount to add is required.').positive('Must be a positive number.'),
            })
        ),
    });

    return (
        <Grid columns={2} container={true} xs={12}>
            <Box sx={{ width: '50%' }}>
                <TabContext value={currentTab ?? '0'}>
                    <Box>
                        <Tabs onChange={(_, tab) => setCurrentTab(tab)} variant='scrollable' scrollButtons='auto' value={currentTab}>
                            <Tab value="0" label="Add Items" />
                            <Tab value="1" label="Add Bundles" />
                            <Tab value="2" label="Add Containers" />
                            <Tab value="3" label="Add Drop Tables" />
                            <Tab value="4" label="Add Currencies" />
                        </Tabs>
                    </Box>

                    <TabPanel sx={{ height: '50vh' }} value="0">
                        <EntityList rows={itemRows} columns={itemsListColumns} handleOnClick={setItemsInContainer} />
                    </TabPanel>
                    <TabPanel sx={{ height: '50vh' }} value="1">
                        <EntityList rows={bundleRows} columns={bundlesListColumns} handleOnClick={setBundlesInContainer} />
                    </TabPanel>
                    <TabPanel sx={{ height: '50vh' }} value="2">
                        <EntityList
                            rows={containerRows?.filter((row) => row.id !== container.container?.id)}
                            columns={containersListColumns}
                            handleOnClick={setContainersInContainer}
                        />
                    </TabPanel>
                    <TabPanel sx={{ height: '50vh' }} value="3">
                        <EntityList rows={dropTableRows} columns={dropTablessListColumns} handleOnClick={setDropTablesInContainer} />
                    </TabPanel>
                    <TabPanel sx={{ height: '50vh' }} value="4">
                        <form onSubmit={handleSubmit(handleAddVirtualCurrency)}>
                            <FormControl fullWidth sx={{ '> * + *': { marginTop: '0.85rem' } }}>
                                <FormLabel filled sx={{ width: '100%' }}>
                                    Currency to Add
                                </FormLabel>
                                <Select {...register('currencyToAdd')} id="virtual-currency-selection" sx={{ width: '100%' }}>
                                    {virtualCurrencyRows?.map((vc, index) => (
                                        <MenuItem value={index} key={vc.id?.toString()}>
                                            {vc.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormLabel sx={{ width: '100%' }}>Amount to Add</FormLabel>
                                <TextField {...register('amountToAdd')} id="virtual-currency-amount" placeholder="1,000" />
                                <ErrorMessage errors={errors} name="amountToAdd" />
                                <Button type="submit" variant="contained" disabled={!isDirty || !isValid}>
                                    Add Currency to Container
                                </Button>
                            </FormControl>
                        </form>
                    </TabPanel>
                </TabContext>
            </Box>

            <Box sx={{ width: '50%' }}>
                <Box sx={{ paddingBottom: '5px', paddingTop: '43px' }}>Container Contents</Box>
                {currentTab === '0' && (
                    <EntityListContents columns={itemsListContentsColumns} handleOnClick={setItemsInContainer} rowsInContainer={itemsInContainer} />
                )}
                {currentTab === '1' && (
                    <EntityListContents
                        columns={bundlesListContentsColumns}
                        handleOnClick={setBundlesInContainer}
                        rowsInContainer={bundlesInContainer}
                    />
                )}
                {currentTab === '2' && (
                    <EntityListContents
                        columns={containersListContentsColumns}
                        handleOnClick={setContainersInContainer}
                        rowsInContainer={containersInContainer}
                    />
                )}
                {currentTab === '3' && (
                    <EntityListContents
                        columns={dropTablesListContentsColumns}
                        handleOnClick={setDropTablesInContainer}
                        rowsInContainer={dropTablesInContainer}
                    />
                )}
                {currentTab === '4' && (
                    <EntityListContents
                        columns={virtualCurrencyListColumns}
                        handleOnClick={removeCurrenciesFromContainer}
                        rowsInContainer={currenciesInContainer}
                    />
                )}
            </Box>
        </Grid>
    );
};

interface EntityListProps<T extends GridRowData> {
    rows: readonly T[];
    columns: GridColDef[];
    handleOnClick: (data: T[], updateType: UpdateType) => void;
}

function EntityList<T extends GridRowData>(props: EntityListProps<T>) {
    const { rows, columns, handleOnClick } = props;
    const [selectedRowsToAdd, setSelectedRowsToAdd] = useState<T[]>([]);
    const [addSelection, setAddSelection] = useState(false);

    function onEntitiesToAdd(selections: GridSelectionModel) {
        if (selections.length > 0) {
            setAddSelection(true);
        } else {
            setAddSelection(false);
        }

        const selectedIDs = new Set(selections);
        setSelectedRowsToAdd(rows?.filter((row) => selectedIDs.has(row.id.toString())));
    }

    function onAddSelectionToContainer() {
        if (selectedRowsToAdd.length > 0) {
            handleOnClick(selectedRowsToAdd, UpdateType.Add);
        }
    }

    return (
        <div>
            <Box sx={{ height: '43vh' }}>
                <DataGrid
                    columns={columns}
                    rows={rows}
                    rowCount={rows.length}
                    pageSize={10}
                    checkboxSelection
                    onSelectionModelChange={onEntitiesToAdd}
                />
                <Button sx={{ marginTop: '10px' }} disabled={!addSelection} onClick={onAddSelectionToContainer} variant="contained">
                    Add to Container
                </Button>
            </Box>
        </div>
    );
}

interface EntityListContentProps {
    columns: GridColDef[];
    handleOnClick: (data: GridRowData[], updateType: UpdateType) => void;
    rowsInContainer: GridRowData[];
}

function EntityListContents({ columns, handleOnClick, rowsInContainer }: EntityListContentProps) {
    const [selectedRowsToDelete, setSelectedRowsToDelete] = useState<GridRowData[]>([]);
    const [deleteSelection, setDeleteSelection] = useState(false);

    function onEntitiesToRemove(selections: GridSelectionModel) {
        if (selections.length > 0) {
            setDeleteSelection(true);
        } else {
            setDeleteSelection(false);
        }

        const selectedIDs = new Set(selections);
        const rowsToDelete = rowsInContainer.filter((row) => selectedIDs.has(row?.entryId.toString()));
        setSelectedRowsToDelete(rowsInContainer.filter((row) => !rowsToDelete.find((f) => f.entryId === row.entryId)));
    }

    function onDeleteSelectionFromContainer() {
        handleOnClick(selectedRowsToDelete, UpdateType.Remove);
        setDeleteSelection(false);
    }

    return (
        <div>
            <Box sx={{ height: '43vh' }}>
                <DataGrid
                    columns={columns}
                    rows={rowsInContainer}
                    rowCount={rowsInContainer.length}
                    pageSize={10}
                    checkboxSelection
                    onSelectionModelChange={onEntitiesToRemove}
                    getRowId={(row) => row.entryId}
                />
                <Button sx={{ marginTop: '10px' }} disabled={!deleteSelection} onClick={onDeleteSelectionFromContainer} variant="contained">
                    Remove from Container
                </Button>
            </Box>
        </div>
    );
}

interface ContainerContentsProps {
    container: ContainerAggregateViewModel;
    setBundlesInContainer: (bundles: GridRowData[], updateType: UpdateType) => void;
    setItemsInContainer: (items: GridRowData[], updateType: UpdateType) => void;
    setCurrenciesInContainer: (currencies: GridRowData[]) => void;
    addCurrenciesToContainer: (currencies: GridRowData[]) => void;
    removeCurrenciesFromContainer: (currencies: GridRowData[]) => void;
    setContainersInContainer: (containers: GridRowData[], updateType: UpdateType) => void;
    setDropTablesInContainer: (dropTables: GridRowData[], updateType: UpdateType) => void;
    itemsInContainer: GridRowData[];
    bundlesInContainer: GridRowData[];
    currenciesInContainer: GridRowData[];
    containersInContainer: GridRowData[];
    dropTablesInContainer: GridRowData[];
}

export function ContainerContents(props: ContainerContentsProps) {
    const config = useSiteConfig();
    return (
        <AddContainerContents
            container={props.container}
            setBundlesInContainer={props.setBundlesInContainer}
            setItemsInContainer={props.setItemsInContainer}
            itemsInContainer={props.itemsInContainer}
            bundlesInContainer={props.bundlesInContainer}
            dropTablesInContainer={props.dropTablesInContainer}
            addCurrenciesToContainer={props.addCurrenciesToContainer}
            removeCurrenciesFromContainer={props.removeCurrenciesFromContainer}
            setCurrenciesInContainer={props.setCurrenciesInContainer}
            setContainersInContainer={props.setContainersInContainer}
            setDropTablesInContainer={props.setDropTablesInContainer}
            currenciesInContainer={props.currenciesInContainer}
            containersInContainer={props.containersInContainer}
        />
    );
}
