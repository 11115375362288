import { useAppConfig } from '@core';
import { Box, Card, Link, List, ListItem, Typography } from '@mui/material';
import { useGetApiGamesId } from '@services/games/games';

interface APIDocumentationProps {
    titleId: string;
}

export function APIDocumentation(props: APIDocumentationProps) {
    const { data: titleData } = useGetApiGamesId(props.titleId || '');
    const appConfig = useAppConfig();

    const titleSubdomain = {
        'Nitro Nation': 'nitronation',
    }[titleData?.name ?? ''];

    return (
        <Box sx={{ margin: '8px 16px 0px' }}>
            <div>
                <Typography variant="h6" sx={{ marginBottom: '16px' }}>
                    API Documentation
                </Typography>
                {titleSubdomain && (
                    <List>
                        <ListItem>
                            <Link href={generateDocLink(titleSubdomain, 'client', appConfig.env)}>Client API Documentation</Link>
                        </ListItem>
                        <ListItem sx={{ display: 'flex', alignItems: 'center' }}>
                            <Link href={generateDocLink(titleSubdomain, 'server', appConfig.env)}>Server API Documentation</Link>
                        </ListItem>
                    </List>
                )}
                {!titleSubdomain && <Card sx={{ padding: '16px' }}>There is currently no documentation available for this game title.</Card>}
            </div>
        </Box>
    );
}

const generateDocLink = (tenant: string, target: 'client' | 'server', environment: string) =>
    `https://${tenant}.${target}.${environment}.mythical.dev/swagger/index.html`;
