import { useObjectKey } from '@components';
import { useEvent } from '@core';
import { AutoStories, Business, CreateNewFolder, DeleteSweep, Edit, SportsEsports, VideoSettings } from '@mui/icons-material';
import { Divider, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import type { CanHave } from '@services/model';
import type { ReactNode } from 'react';
import { Fragment, useCallback, useState } from 'react';

import { CanHaves } from './Design';
import type { ExpansionState, PermissionBuilder, PermissionScope, PermissionSetBuilder } from './Models';
import { PermissionListItem } from './PermissionListItem';

export function ScopeListItem({ roleId, scope, permissionSetBuilder, expansion }: ScopeListItemProps) {
    const key = useObjectKey();
    const [permissions] = useState<PermissionBuilder[]>(permissionSetBuilder.getPermissionSet(scope.id, roleId));
    const toggleKey = `${roleId}/${scope.id}`;
    const open = expansion.isExpanded(toggleKey);
    const toggle = useCallback(() => {
        expansion.toggle(toggleKey);
    }, [expansion, toggleKey]);
    const bulkUpdate = useCallback(
        (can: keyof CanHave) => {
            permissionSetBuilder.bulkUpdate(scope.id, roleId, can);
        },
        [permissionSetBuilder, scope, roleId]
    );
    useEvent(expansion.expansionChanged);
    return (
        <>
            <ListItem disablePadding dense sx={{ paddingLeft: '4.5rem' }}>
                <ListItemButton onClick={toggle}>
                    <ListItemIcon sx={{ minWidth: '2rem' }}>{scope.type === 'Organization' ? <Business /> : <SportsEsports />}</ListItemIcon>
                    <ListItemText primary={`${scope.name} Permissions`} />
                </ListItemButton>
            </ListItem>
            <Divider />
            {open ? (
                <>
                    <ListItem disablePadding dense sx={{ paddingLeft: '7.5rem' }}>
                        <ListItemText primary={<strong>Resources</strong>} sx={{ fontWeight: 'bold' }} />
                        <CanHaves>
                            {bulkToggles.map((t) => (
                                <ListItemButton key={t.can} onClick={() => bulkUpdate(t.can)}>
                                    <ListItemIcon sx={{ minWidth: '2rem' }}>{t.icon}</ListItemIcon>
                                    <ListItemText primary={t.name} />
                                </ListItemButton>
                            ))}
                        </CanHaves>
                    </ListItem>
                    <Divider />
                    {permissions.map((p) => (
                        <Fragment key={key(p)}>
                            <PermissionListItem permission={p} />
                            <Divider />
                        </Fragment>
                    ))}
                </>
            ) : null}
        </>
    );
}
interface ScopeListItemProps {
    expansion: ExpansionState;
    roleId: string;
    scope: PermissionScope;
    permissionSetBuilder: PermissionSetBuilder;
}
const bulkToggles: { name: string; icon: ReactNode; can: keyof CanHave }[] = [
    { name: 'View', icon: <AutoStories />, can: 'canRead' },
    { name: 'Create', icon: <CreateNewFolder />, can: 'canCreate' },
    { name: 'Edit', icon: <Edit />, can: 'canUpdate' },
    { name: 'Execute', icon: <VideoSettings />, can: 'canExecute' },
    { name: 'Delete', icon: <DeleteSweep />, can: 'canDelete' },
];
