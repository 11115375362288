import { Box, Grid, Paper } from '@mui/material';
import type { ArrayFieldTemplateProps, IdSchema } from '@rjsf/core';
import { utils } from '@rjsf/core';

import { AddButton, IconButton } from '.';

const { isMultiSelect, getDefaultRegistry } = utils;

export const ArrayFieldTemplate = (props: ArrayFieldTemplateProps) => {
    const { schema, registry = getDefaultRegistry() } = props;

    // TODO: update types so we don't have to cast registry as any
    if (isMultiSelect(schema, (registry as any).rootSchema)) {
        return <DefaultFixedArrayFieldTemplate {...props} />;
    } else {
        return <DefaultNormalArrayFieldTemplate {...props} />;
    }
};

interface ArrayFieldTitleProps {
    TitleField: any;
    idSchema: IdSchema;
    title: string;
    required: boolean;
}

const ArrayFieldTitle = ({ TitleField, idSchema, title, required }: ArrayFieldTitleProps) => {
    if (!title) {
        return null;
    }

    const id = `${idSchema.$id}__title`;
    return <TitleField id={id} title={title} required={required} />;
};

interface ArrayFieldDescriptionProps {
    DescriptionField: any;
    idSchema: IdSchema;
    description: string;
}

const ArrayFieldDescription = ({ DescriptionField, idSchema, description }: ArrayFieldDescriptionProps) => {
    if (!description) {
        return null;
    }

    const id = `${idSchema.$id}__description`;
    return <DescriptionField id={id} description={description} />;
};

// Used in the two templates
const DefaultArrayItem = (props: any) => {
    const btnStyle = {
        flex: 1,
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold',
        minWidth: 0,
    };
    return (
        <Grid container={true} key={props.key} alignItems="center">
            <Grid item={true} xs style={{ overflow: 'auto' }}>
                <Box mb={2}>
                    <Paper elevation={2}>
                        <Box p={2}>{props.children}</Box>
                    </Paper>
                </Box>
            </Grid>

            {props.hasToolbar && (
                <Grid item={true}>
                    {(props.hasMoveUp || props.hasMoveDown) && (
                        <IconButton
                            icon="arrow-up"
                            className="array-item-move-up"
                            tabIndex={-1}
                            style={btnStyle as any}
                            iconProps={{ fontSize: 'small' }}
                            disabled={props.disabled || props.readonly || !props.hasMoveUp}
                            onClick={props.onReorderClick(props.index, props.index - 1)}
                        />
                    )}

                    {(props.hasMoveUp || props.hasMoveDown) && (
                        <IconButton
                            icon="arrow-down"
                            tabIndex={-1}
                            style={btnStyle as any}
                            iconProps={{ fontSize: 'small' }}
                            disabled={props.disabled || props.readonly || !props.hasMoveDown}
                            onClick={props.onReorderClick(props.index, props.index + 1)}
                        />
                    )}

                    {props.hasRemove && (
                        <IconButton
                            icon="remove"
                            tabIndex={-1}
                            style={btnStyle as any}
                            iconProps={{ fontSize: 'small' }}
                            disabled={props.disabled || props.readonly}
                            onClick={props.onDropIndexClick(props.index)}
                        />
                    )}
                </Grid>
            )}
        </Grid>
    );
};

const DefaultFixedArrayFieldTemplate = (props: ArrayFieldTemplateProps) => {
    return (
        <fieldset className={props.className}>
            <ArrayFieldTitle
                key={`array-field-title-${props.idSchema.$id}`}
                TitleField={props.TitleField}
                idSchema={props.idSchema}
                title={props.uiSchema['ui:title'] || props.title}
                required={props.required}
            />

            {(props.uiSchema['ui:description'] || props.schema.description) && (
                <div key={`field-description-${props.idSchema.$id}`}>{props.uiSchema['ui:description'] || props.schema.description}</div>
            )}
            <div key={`array-item-list-${props.idSchema.$id}`}>{props.items && props.items.map(DefaultArrayItem)}</div>

            {props.canAdd && <AddButton className="array-item-add" onClick={props.onAddClick} disabled={props.disabled || props.readonly} />}
        </fieldset>
    );
};

const DefaultNormalArrayFieldTemplate = (props: ArrayFieldTemplateProps) => {
    return (
        <Paper elevation={1}>
            <Box p={2}>
                <ArrayFieldTitle
                    key={`array-field-title-${props.idSchema.$id}`}
                    TitleField={props.TitleField}
                    idSchema={props.idSchema}
                    title={props.uiSchema['ui:title'] || props.title}
                    required={props.required}
                />

                {(props.uiSchema['ui:description'] || props.schema.description) && (
                    <ArrayFieldDescription
                        key={`array-field-description-${props.idSchema.$id}`}
                        DescriptionField={props.DescriptionField}
                        idSchema={props.idSchema}
                        description={props.uiSchema['ui:description'] || props.schema.description}
                    />
                )}

                <Grid container={true} key={`array-item-list-${props.idSchema.$id}`}>
                    {props.items && props.items.map((p) => DefaultArrayItem(p))}

                    {props.canAdd && (
                        <Grid container>
                            <Grid item={true}>
                                <Box mt={2}>
                                    <AddButton onClick={props.onAddClick} disabled={props.disabled || props.readonly} className={''} />
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Paper>
    );
};
