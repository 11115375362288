import { ReadonlyInput, TextInput, UnauthorizedAccess } from '@components';
import { usePermissions } from '@core';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import { useGetApiGamesId, usePutApiGamesId } from '@services/games/games';
import type { Game } from '@services/model';
import { PageBody, PageContent, PageHeader } from 'features/shell/layout';
import { useEffect } from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';

interface TitleProps {
    titleId: string;
}

const tenantConfigSchema = yup.object().shape({
    loadedData: yup.object().shape({
        name: yup.string().required(),
        internalName: yup.string().nullable(),
    }),
});

const TitleForm = ({ titleId }: TitleProps) => {
    const { canMutate } = usePermissions('Game');
    const { isLoading, data: titleData, refetch } = useGetApiGamesId(titleId || '');
    const { mutateAsync: update } = usePutApiGamesId();

    const defaultValues: { loadedData: Game } = {
        loadedData: {
            name: '',
            internalName: '',
            organizationId: '',
            siteConfigJson: '',
        },
    };
    const form = useForm({
        defaultValues,
        mode: 'onChange',
        reValidateMode: 'onBlur',
        resolver: yupResolver(tenantConfigSchema),
    });

    useEffect(() => {
        form.reset({ loadedData: titleData });
    }, [form, titleData]);

    const { isDirty, isValid } = useFormState(form);
    const undo = () => form.reset();
    const save = async () => {
        const newAudiences = form.getValues();
        await update({ id: titleId, data: { ...newAudiences.loadedData! } });
        form.reset(newAudiences);
    };

    if (isLoading) {
        return <LinearProgress />;
    }

    return (
        <PageContent>
            <PageBody noPadding>
                <FormProvider {...form}>
                    <Box p={2} width={400}>
                        {canMutate ? (
                            <>
                                <TextInput width="full" name="loadedData.name" label="Name" />
                                <TextInput
                                    width="full"
                                    name="loadedData.internalName"
                                    label="Internal Name"
                                    helperText="Used internally to identify game-specific resources"
                                />
                            </>
                        ) : (
                            <>
                                <ReadonlyInput
                                    width="full"
                                    name="loadedData.allowedAudienceServer"
                                    label="Server Allowed Audience"
                                    helperText="'aud' claim for server tenant JWT"
                                />
                                <ReadonlyInput
                                    width="full"
                                    name="loadedData.allowedAudienceClient"
                                    label="Client Allowed Audience"
                                    helperText="'aud' claim for client tenant JWT"
                                />
                            </>
                        )}
                    </Box>
                </FormProvider>
            </PageBody>
            {canMutate && (
                <PageHeader>
                    <Box textAlign="right" width={400}>
                        <Button onClick={form.handleSubmit(save)} disabled={!isDirty || !isValid} variant="contained">
                            Save Changes
                        </Button>
                        <Button onClick={undo} disabled={!isDirty}>
                            Undo Changes
                        </Button>
                    </Box>
                </PageHeader>
            )}
        </PageContent>
    );
};

export const Title = ({ titleId }: TitleProps) => {
    const { canRead } = usePermissions('Game');
    if (!canRead) {
        return <UnauthorizedAccess />;
    }
    return <TitleForm titleId={titleId} />;
};
