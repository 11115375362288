import type { FusionAuthAuthenticationService } from '@core';
import { useAuthenticationService } from '@core';
import { useEffect } from 'react';
import { useMount } from 'react-use';
import { useAnalytics } from 'shared/hooks/useAnalytics';

export function Close() {
    const analytics = useAnalytics();
    const authSvc = useAuthenticationService() as FusionAuthAuthenticationService;
    useMount(async () => {
        await authSvc.reloadLogin();
        authSvc.clearReloginToken();
        window.close();
    });

    useEffect(() => {
        analytics.page();
    }, []);

    return <></>;
}
