import { Home as HomeIcon } from '@mui/icons-material';
import { PageBody, PageContent, PageHeader } from 'features/shell/layout';

import { titlePageRegistry } from './PageRegistry';

export const Home = () => (
    <PageContent>
        <PageHeader></PageHeader>
        <PageBody></PageBody>
    </PageContent>
);
titlePageRegistry.register({ page: Home, path: 'home', name: 'Admin Home', icon: <HomeIcon /> });
