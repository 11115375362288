import { Link, SecureImage, useNavigator } from '@components';
import type { SiteConfig } from '@core';
import styled from '@emotion/styled';
import { ExitToApp, PeopleAlt, Settings, SportsEsports } from '@mui/icons-material';
import { Box, Card, CardContent, Divider, Tooltip, Typography } from '@mui/material';
import type { Organization } from '@services/model';
import { useState } from 'react';

export function OrgTile(props: { org: Organization }) {
    const [siteConfig] = useState(() => JSON.parse(props.org.siteConfigJson || '{}') as SiteConfig);
    const nav = useNavigator();
    return (
        <Box m={2} width={300}>
            <Card>
                <CardContent>
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="body1">{props.org.name}</Typography>
                        <CardToolbar>
                            <Link to={`/${props.org.id}/home`}>
                                <Tooltip title={`${props.org.name} Home`}>
                                    <ExitToApp />
                                </Tooltip>
                            </Link>
                            <Link to={`/${props.org.id}/titles`}>
                                <Tooltip title={`${props.org.name} Titles`}>
                                    <SportsEsports />
                                </Tooltip>
                            </Link>
                            <Link to={`/${props.org.id}/accounts`}>
                                <Tooltip title={`${props.org.name} Accounts`}>
                                    <PeopleAlt />
                                </Tooltip>
                            </Link>
                            <Link to={nav.getMoveUrl('org-details', { id: props.org.id! })}>
                                <Tooltip title={`Manage ${props.org.name}`}>
                                    <Settings />
                                </Tooltip>
                            </Link>
                        </CardToolbar>
                    </Box>
                    <Divider />
                    {siteConfig.theme ? <SecureImage height={50} url={siteConfig.theme.logo} /> : null}
                </CardContent>
            </Card>
        </Box>
    );
}

const CardToolbar = styled(Box)`
    a {
        padding: 0.25rem;
    }
`;
