import styled from '@emotion/styled';
import { Delete, Upload } from '@mui/icons-material';
import { Box, Button, FormControl, FormHelperText, IconButton } from '@mui/material';
import type { ImgHTMLAttributes } from 'react';
import { useRef } from 'react';
import { useFileUploader, useSecureMedia } from 'shared/core/FileUploadService';

import { makeHookablePermission, styleFromProps } from './HookAdapter';

interface ImageProps {
    maxHeight?: number;
    maxWidth?: number;
    uploadUrl: string;
}
export const ImageInput = makeHookablePermission<string | undefined, ImageProps>(function ImageInput(props) {
    const { prepare } = useFileUploader();
    const fileRef = useRef<HTMLInputElement>(null);
    const { url } = useSecureMedia(props.value || '');
    const onChange = (url: string) => {
        if (fileRef.current) {
            fileRef.current.value = '';
        }
        if (props.type === 'standard') {
            props.onChange(url);
        } else {
            props.hookOnChange({ target: { value: url } });
        }
    };
    const onFileChange = async (el: HTMLInputElement) => {
        const prepared = prepare(el);
        const uploadResult = await prepared?.upload(props.uploadUrl);
        if (uploadResult) {
            if (props.type === 'standard') {
                props.onChange(uploadResult.url);
            } else {
                props.hookOnChange({ target: { value: uploadResult.url } });
            }
        }
        el.blur();
    };
    return (
        <>
            <FormControl fullWidth={props.width === 'full'} style={{ ...styleFromProps(props) }} variant="standard" size="small">
                <FormHelperText>{props.label}</FormHelperText>
                <InputBox>
                    <ImagePlaceholder onClick={() => fileRef.current?.click()}>
                        <ImageBackground>
                            <IconButton size="small">
                                <Upload />
                            </IconButton>
                            {!url ? null : <img style={{ maxWidth: '100%', maxHeight: props.maxHeight || 200 }} src={url} alt={props.label} />}
                        </ImageBackground>
                    </ImagePlaceholder>
                    {url ? (
                        <IconButton size="small" onClick={() => onChange('')}>
                            <Delete />
                        </IconButton>
                    ) : null}
                </InputBox>
                <FileInput ref={fileRef} type="file" onChange={(e) => onFileChange(e.target as HTMLInputElement)} />
                <FormHelperText>{props.errorMessage ?? props.helperText}</FormHelperText>
            </FormControl>
        </>
    );
});

export function SecureImage(props: Exclude<ImgHTMLAttributes<HTMLImageElement> & { url?: string }, 'src'>) {
    const { url } = useSecureMedia(props.url || '');
    const urlProp = url ? { src: url } : {};
    return <img {...props} {...urlProp} alt={props.alt} />;
}

const InputBox = styled(Box)`
    display: flex;
    align-items: center;
`;

const FileInput = styled.input`
    display: none;
`;
const ImageBackground = styled.div`
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"><rect width="15" height="15" style="fill:rgb(128,128,128)"></rect><rect width="15" height="15" x="15" y="15" style="fill:rgb(128,128,128)"></rect></svg>');
    background-repeat: repeat;
    background-size: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
`;

const ImagePlaceholder = styled(Button)`
    border: dashed 1px #8888;
    width: 100%;
    button {
        opacity: 0;
        color: ${(p) => p.theme.primary};
        background: ${(p) => p.theme.lightBg};
        box-shadow: 0 0.125rem 3px #8888;
        position: absolute;
    }
    &:hover button {
        opacity: 1;
        background: ${(p) => p.theme.lightBg};
    }
`;
