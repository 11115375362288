import type { UserPreferences } from '@core';
import type { CSSObject } from '@emotion/react';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import type { Theme } from '@mui/material';
import { Divider, Drawer as MuiDrawer, IconButton, List } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import { Fragment } from 'react';

import type { SidebarItem } from './SidebarItems';
import { SidebarItems } from './SidebarItems';
import { sizes } from './theme';

const openedMixin = (theme: Theme): CSSObject => ({
    width: sizes.navWide,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    minHeight: '48px',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `${sizes.navNarrow}px`,
    minHeight: '48px',
    [theme.breakpoints.up('sm')]: {
        width: `${sizes.navNarrow}px`,
    },
});

const DrawerHeader = muiStyled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const StyledDrawer = muiStyled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: sizes.navWide,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflow: 'auto',
    flex: '0 0 auto',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

interface DrawerProps {
    prefs: UserPreferences;
    handleDrawerClose: () => void;
    theme: Theme;
    items?: SidebarItem[];
    handleDrawerOpen: () => void;
}
export const Drawer = ({ prefs, handleDrawerClose, theme, items, handleDrawerOpen }: DrawerProps) => (
    <StyledDrawer variant="permanent" open={!prefs.navBarCollapsed}>
        <DrawerHeader style={{ minHeight: 50 }}>
            <IconButton onClick={handleDrawerClose}>{theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}</IconButton>
        </DrawerHeader>
        <Divider />
        <List>
            {items &&
                items.length > 0 &&
                items.map((sidebarItem, index) => (
                    <Fragment key={`${sidebarItem.name}${index}`}>
                        {sidebarItem.name === 'divider' ? (
                            <Divider style={{ margin: '6px 0' }} />
                        ) : (
                            <SidebarItems expanded={!prefs.navBarCollapsed} item={sidebarItem} onExpandOpen={handleDrawerOpen} />
                        )}
                    </Fragment>
                ))}
        </List>
    </StyledDrawer>
);
