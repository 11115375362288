import type { IPageRegistry } from '@components';
import { RegistryBreadcrumb, RegistryPageRenderer, SecureImage, useNavigator } from '@components';
import {
    defaultSiteConfig,
    FusionAuthAuthenticationService,
    useAuthenticationService,
    useSiteConfig,
    useSiteReadyState,
    useSiteRootPath,
    useUserPrefs,
} from '@core';
import styled from '@emotion/styled';
import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { ThemeProvider } from './theme';
import { Divider } from '@mui/material';
import { useLDClient } from 'launchdarkly-react-client-sdk';

export function SiteContainer(props: { children: ReactNode }) {
    return (
        <ThemeProvider>
            <SiteWrapper>{props.children}</SiteWrapper>
        </ThemeProvider>
    );
}

export function Logo() {
    const config = useSiteConfig();
    const theme = config.theme || defaultSiteConfig.theme;
    return (
        <LogoEl>
            <SecureImage height={50} url={theme.logo} alt={config.name} />
        </LogoEl>
    );
}

function ReadySiteContent(props: { registry: IPageRegistry; defaultPage: string }) {
    const nav = useNavigator();
    const root = useSiteRootPath();
    const { pathname } = useLocation();

    // the ldClient is available after mount, so identify the user here instead of
    // at login
    const authSvc = useAuthenticationService() as FusionAuthAuthenticationService;
    const ldClient = useLDClient();

    useEffect(() => {
        const profile = authSvc.getProfile();
        ldClient?.identify({ key: profile.email, email: profile.email });
    }, []);

    useEffect(() => {
        if (pathname === root) {
            nav.replace(nav.getDescendUrl(props.defaultPage));
        }
    }, [pathname, nav, props.defaultPage, root]);
    return (
        <SiteContentEl>
            <RegistryBreadcrumb registry={props.registry}></RegistryBreadcrumb>
            <RegistryPageRenderer registry={props.registry}></RegistryPageRenderer>
        </SiteContentEl>
    );
}

export function SiteContent(props: { registry: IPageRegistry; defaultPage: string }) {
    const siteReady = useSiteReadyState();
    if (siteReady) {
        return <ReadySiteContent {...props} />;
    } else {
        return <></>;
    }
}

export const SiteContentEl = styled.main((props) => {
    const { prefs } = useUserPrefs();
    const navMargin = prefs.navBarCollapsed ? props.theme.sizes.navNarrow : props.theme.sizes.navWide;
    return {
        height: `calc(100% - ${props.theme.sizes.headerSize}px)`,
        flex: '1 1 auto',
        display: 'flex',
        width: `calc(100% - ${navMargin}px)`,
        marginLeft: `${navMargin}px`,
        flexDirection: 'column',
        overflow: 'auto',
    };
});

export const PageContent = styled.div`
    overflow: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const PageHeader = styled.div<{ noPadding?: boolean }>`
    flex: 0 0 auto;
    padding: ${(p) => (p.noPadding ? 0 : '1rem 1rem')};
`;

export const Toolbar = styled(PageHeader)<{ align?: 'flex-end' | 'flex-start' | 'space-between' | 'center' }>`
    display: flex;
    align-items: center;
    padding: ${(p) => (p.noPadding ? 0 : '1rem 1rem')};
    justify-content: ${(p) => p.align || 'flex-end'};
`;

export const PageBody = styled.div<{ noPadding?: boolean }>`
    flex: 1 1 auto;
    height: 100%;
    overflow: auto;
    padding: 0 ${(p) => (p.noPadding ? 0 : 1)}rem;
`;

const SiteWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const LogoEl = styled.div`
    text-align: center;
    display: contents;
`;

const Splittable = styled.div<{ split: boolean }>`
    height: 100%;
    display: flex;
    > div {
        width: ${(p) => (p.split ? 50 : 100)}%;
    }
`;

export const PageContentWithSidebar = (props: { toggle: boolean; children: [ReactNode, ReactNode | null] }) => {
    return (
        <Splittable split={props.toggle}>
            <PageContent>{props.children[0]}</PageContent>
            {props.toggle ? (
                <>
                    <Divider orientation="vertical" />
                    {props.children[1]}
                </>
            ) : null}
        </Splittable>
    );
};
