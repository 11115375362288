import { getApiAdminUserAuthorize } from '@services/admin-user/admin-user';
import type { CanHave } from '@services/model/canHave';
import { inject, singleton } from 'tsyringe';

import type { Authorization } from '../../__generated__/model/authorization';
import { useResource } from '../components/ResourceProvider';
import { useDi } from './DiService';
import { Event } from './Event';
import { Logger } from './Logger';
import { useSiteConfig } from './ShellService';

@singleton()
export class AuthorizationService {
    private _authData!: Authorization;
    private authChanged = new Event<Authorization>();
    public get authData() {
        return this._authData;
    }

    public constructor(@inject(Logger) private readonly logger: Logger) {}

    public async start() {
        this._authData = await this.getAuthData();
    }

    public async refresh() {
        try {
            const authData = await this.getAuthData();
            this._authData = authData;
            this.authChanged.raise(authData);
        } catch (err) {
            this.logger.fatal('Failed while trying to get Authorization data. ', err);
        }
    }

    private async getAuthData() {
        return getApiAdminUserAuthorize();
    }
}

export function usePermissions(resource?: string | undefined) {
    const authorizationService = useDi(AuthorizationService);    
    const res = resource ?? useResource();
    const config = useSiteConfig();
    //TODO : The following two lines are a quick workaround to the SuperAdmin type concept, we'll possibly rework
    const permissions = authorizationService.authData?.permissions;
    const permissionLookUpId = config.id === 'admin' ? '00000000-0000-0000-0000-000000000000' : config.id;
    const resourcePermissions: Partial<CanHave> = permissions && permissions[permissionLookUpId] ? permissions[permissionLookUpId]![res] : {};

    const canMutate =
        resourcePermissions?.canCreate || resourcePermissions?.canUpdate || resourcePermissions?.canDelete || resourcePermissions?.canExecute;
    return { ...resourcePermissions, canMutate };
}

export function getSiteNavigation() {
    const authorizationService = useDi(AuthorizationService);
    return authorizationService.authData?.siteNavigation;
}
