/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from 'react';

export class Event<T> {
    private listeners = new Set<(args: T) => void>();

    public listen(handler: (args: T) => void) {
        this.listeners.add(handler);
        return {
            dispose: () => {
                this.listeners.delete(handler);
            },
        };
    }
    public raise(value: T) {
        for (const listener of this.listeners) {
            try {
                listener(value);
            } catch (err) {
                console.error(`Event listener threw an exception`, err);
            }
        }
    }
}

export function useEvent<T>(evt?: Event<T>, handler?: (args: T) => void) {
    if (!handler) {
        const [, setState] = useState(0);
        useEffect(() => evt?.listen(() => setState((v) => v + 1)).dispose, [evt, setState]);
    } else {
        useEffect(() => evt?.listen(handler).dispose, [evt, handler]);
    }
}

export function useEventValue<T>(evt: Event<T> | undefined, initialValue: T) {
    const [value, setValue] = useState(() => ({ data: initialValue }));
    useEffect(() => {
        if (evt) {
            const { dispose } = evt.listen((v) => setValue({ data: v }));
            return dispose;
        }
    }, [evt]);
    return value.data;
}
