import { searchBuilder, useSiteConfig } from '@core';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Button, FormControl, FormLabel, Grid, MenuItem, Select, Tab, Tabs, TextField } from '@mui/material';
import type { GridColDef, GridRowData, GridSelectionModel } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import { postApiBundlesQuery } from '@services/bundles/bundles';
import { postApiContainersQuery } from '@services/containers/containers';
import { postApiDropTablesQuery } from '@services/drop-tables/drop-tables';
import { postApiItemsQueryForAddContent } from '@services/items/items';
import type { BundleAggregateViewModel } from '@services/model';
import { useGetApiVirtualCurrencyByEnvironment } from '@services/virtual-currency/virtual-currency';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'shared/hooks/useQuery';
import * as yup from 'yup';
import { buildEntityListColumns, buildEntityListContentsColumns } from '../Shared/EntityListService';
import { UpdateType } from '../Shared/EntityModels';

interface AddBundleProps {
    bundle: BundleAggregateViewModel;
    setBundlesInBundle: (bundles: GridRowData[], updateType: UpdateType) => void;
    setItemsInBundle: (items: GridRowData[], updateType: UpdateType) => void;
    setCurrenciesInBundle: (currencies: GridRowData[]) => void;
    setContainersInBundle: (containers: GridRowData[], updateType: UpdateType) => void;
    addCurrenciesToBundle: (currencies: GridRowData[]) => void;
    removeCurrenciesFromBundle: (currencies: GridRowData[]) => void;
    setDropTablesInBundle: (dropTables: GridRowData[], updateType: UpdateType) => void;
    itemsInBundle: GridRowData[];
    bundlesInBundle: GridRowData[];
    currenciesInBundle: GridRowData[];
    containersInBundle: GridRowData[];
    dropTablesInBundle: GridRowData[];
}

export const AddBundleContents = ({
    bundle,
    setBundlesInBundle,
    setItemsInBundle,
    setCurrenciesInBundle,
    setContainersInBundle,
    addCurrenciesToBundle,
    removeCurrenciesFromBundle,
    setDropTablesInBundle,
    itemsInBundle,
    bundlesInBundle,
    currenciesInBundle,
    containersInBundle,
    dropTablesInBundle,
}: AddBundleProps) => {
    const [currentTab, setCurrentTab] = useState('0');

    const [bundlesQuery] = useState(() =>
        searchBuilder('InventoryContainer')
            .select((bn) => ({
                id: bn.id,
                displayName: bn.displayName,
                inventoryContainerType: bn.inventoryContainerType,
            }))
            .where((bn) => ({ eq: { [bn.nameof.inventoryContainerType]: 1 } }))
            .sortAsc((bn) => bn.displayName)
            .prepare(postApiBundlesQuery)
    );

    const [itemsQuery] = useState(() =>
        searchBuilder('Item')
            .select((it) => ({
                id: it.id,
                displayName: it.displayName,
            }))
            .sortAsc((it) => it.displayName)
            .prepare(postApiItemsQueryForAddContent)
    );

    const [containersQuery] = useState(() =>
        searchBuilder('InventoryContainer')
            .select((bn) => ({
                id: bn.id,
                displayName: bn.displayName,
                inventoryContainerType: bn.inventoryContainerType,
            }))
            .where((bn) => ({ eq: { [bn.nameof.inventoryContainerType]: 2 } }))
            .sortAsc((bn) => bn.displayName)
            .prepare(postApiContainersQuery)
    );

    const [dropTablesQuery] = useState(() =>
        searchBuilder('InventoryContainer')
            .select((bn) => ({
                id: bn.id,
                displayName: bn.displayName,
                inventoryContainerType: bn.inventoryContainerType,
            }))
            .where((bn) => ({ eq: { [bn.nameof.inventoryContainerType]: 3 } }))
            .sortAsc((bn) => bn.displayName)
            .prepare(postApiDropTablesQuery)
    );

    const itemsListColumns = buildEntityListColumns('Item');
    const bundlesListColumns = buildEntityListColumns('Bundle');
    const itemsListContentsColumns = buildEntityListContentsColumns('Item');
    const bundlesListContentsColumns = buildEntityListContentsColumns('Bundle');
    const containersListColumns = buildEntityListColumns('Container');
    const containersListContentsColumns = buildEntityListContentsColumns('Container');
    const dropTablessListColumns = buildEntityListColumns('Drop Table');
    const dropTablesListContentsColumns = buildEntityListContentsColumns('Drop Table');

    const virtualCurrencyListColumns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'Id',
            type: 'uuid',
            hide: true,
        },
        {
            field: 'name',
            headerName: 'Currency Name',
            type: 'string',
            flex: 2,
        },
        {
            field: 'amount',
            headerName: 'Amount',
            type: 'string',
            flex: 2,
        },
        {
            field: 'currencyCode',
            headerName: 'Code',
            type: 'string',
            flex: 1,
        },
    ];

    const { rows: itemRows } = useQuery({ query: itemsQuery, columns: itemsListColumns });
    const { rows: bundleRows } = useQuery({ query: bundlesQuery, columns: bundlesListColumns });
    const { rows: containerRows } = useQuery({ query: containersQuery, columns: containersListColumns });
    const { rows: dropTableRows } = useQuery({ query: dropTablesQuery, columns: dropTablessListColumns });
    const { data: virtualCurrencyRows } = useGetApiVirtualCurrencyByEnvironment();

    const handleAddVirtualCurrency = useCallback(
        ({ currencyToAdd, amountToAdd }: { currencyToAdd: number; amountToAdd: string | number }) => {
            const currency = virtualCurrencyRows?.[currencyToAdd] ?? {};
            addCurrenciesToBundle([{ ...currency, amount: amountToAdd }]);
        },
        [addCurrenciesToBundle, currenciesInBundle, virtualCurrencyRows]
    );

    useEffect(() => {
        setCurrenciesInBundle(
            currenciesInBundle?.map((cur) => ({
                ...cur,
                ...virtualCurrencyRows?.find((vc) => vc.id === cur.id),
                value: cur.value,
            })) as GridRowData[]
        );
    }, [virtualCurrencyRows]);

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, isValid },
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(
            yup.object({
                currencyToAdd: yup.number().required(),
                amountToAdd: yup.number().required('Amount to add is required.').positive('Must be a positive number.'),
            })
        ),
    });

    return (
        <Grid columns={2} container={true} xs={12}>
            <Box sx={{ width: '50%' }}>
                <TabContext value={currentTab ?? '0'}>
                    <Box>
                        <Tabs onChange={(_, tab) => setCurrentTab(tab)} variant='scrollable' scrollButtons='auto' value={currentTab}>
                            <Tab value="0" label="Add Items" />
                            <Tab value="1" label="Add Bundles" />
                            <Tab value="2" label="Add Containers" />
                            <Tab value="3" label="Add Drop Tables" />
                            <Tab value="4" label="Add Currencies" />
                        </Tabs>
                    </Box>

                    <TabPanel sx={{ height: '50vh' }} value="0">
                        <EntityList rows={itemRows} columns={itemsListColumns} handleOnClick={setItemsInBundle} />
                    </TabPanel>
                    <TabPanel sx={{ height: '50vh' }} value="1">
                        <EntityList
                            rows={bundleRows?.filter((row) => row.id !== bundle.bundle?.id)}
                            columns={bundlesListColumns}
                            handleOnClick={setBundlesInBundle}
                        />
                    </TabPanel>
                    <TabPanel sx={{ height: '50vh' }} value="2">
                        <EntityList rows={containerRows} columns={containersListColumns} handleOnClick={setContainersInBundle} />
                    </TabPanel>
                    <TabPanel sx={{ height: '50vh' }} value="3">
                        <EntityList rows={dropTableRows} columns={dropTablessListColumns} handleOnClick={setDropTablesInBundle} />
                    </TabPanel>
                    <TabPanel sx={{ height: '50vh' }} value="4">
                        <form onSubmit={handleSubmit(handleAddVirtualCurrency)}>
                            <FormControl fullWidth sx={{ '> * + *': { marginTop: '0.85rem' } }}>
                                <FormLabel filled sx={{ width: '100%' }}>
                                    Currency to Add
                                </FormLabel>
                                <Select {...register('currencyToAdd')} id="virtual-currency-selection" sx={{ width: '100%' }}>
                                    {virtualCurrencyRows?.map((vc, index) => (
                                        <MenuItem value={index} key={vc.id?.toString()}>
                                            {vc.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormLabel sx={{ width: '100%' }}>Amount to Add</FormLabel>
                                <TextField {...register('amountToAdd')} id="virtual-currency-amount" placeholder="1,000" />
                                <ErrorMessage errors={errors} name="amountToAdd" />
                                <Button type="submit" variant="contained" disabled={!isDirty || !isValid}>
                                    Add Currency to Bundle
                                </Button>
                            </FormControl>
                        </form>
                    </TabPanel>
                </TabContext>
            </Box>

            <Box sx={{ width: '50%' }}>
                <Box sx={{ paddingBottom: '5px', paddingTop: '43px' }}>Bundle Contents</Box>
                {currentTab === '0' && (
                    <EntityListContents columns={itemsListContentsColumns} handleOnClick={setItemsInBundle} rowsInBundle={itemsInBundle} />
                )}
                {currentTab === '1' && (
                    <EntityListContents columns={bundlesListContentsColumns} handleOnClick={setBundlesInBundle} rowsInBundle={bundlesInBundle} />
                )}
                {currentTab === '2' && (
                    <EntityListContents
                        columns={containersListContentsColumns}
                        handleOnClick={setContainersInBundle}
                        rowsInBundle={containersInBundle}
                    />
                )}
                {currentTab === '3' && (
                    <EntityListContents
                        columns={dropTablesListContentsColumns}
                        handleOnClick={setDropTablesInBundle}
                        rowsInBundle={dropTablesInBundle}
                    />
                )}
                {currentTab === '4' && (
                    <EntityListContents
                        columns={virtualCurrencyListColumns}
                        handleOnClick={removeCurrenciesFromBundle}
                        rowsInBundle={currenciesInBundle}
                    />
                )}
            </Box>
        </Grid>
    );
};

interface EntityListProps<T extends GridRowData> {
    rows: readonly T[];
    columns: GridColDef[];
    handleOnClick: (data: T[], updateType: UpdateType) => void;
}

function EntityList<T extends GridRowData>(props: EntityListProps<T>) {
    const { rows, columns, handleOnClick } = props;
    const [selectedRowsToAdd, setSelectedRowsToAdd] = useState<T[]>([]);
    const [addSelection, setAddSelection] = useState(false);

    function onEntitiesToAdd(selections: GridSelectionModel) {
        if (selections.length > 0) {
            setAddSelection(true);
        } else {
            setAddSelection(false);
        }

        const selectedIDs = new Set(selections);
        setSelectedRowsToAdd(rows?.filter((row) => selectedIDs.has(row.id.toString())));
    }

    function onAddSelectionToBundle() {
        if (selectedRowsToAdd.length > 0) {
            handleOnClick(selectedRowsToAdd, UpdateType.Add);
        }
    }

    return (
        <div>
            <Box sx={{ height: '43vh' }}>
                <DataGrid
                    columns={columns}
                    rows={rows}
                    rowCount={rows.length}
                    pageSize={10}
                    checkboxSelection
                    onSelectionModelChange={onEntitiesToAdd}
                />
                <Button sx={{ marginTop: '10px' }} disabled={!addSelection} onClick={onAddSelectionToBundle} variant="contained">
                    Add to Bundle
                </Button>
            </Box>
        </div>
    );
}

interface EntityListContentProps {
    columns: GridColDef[];
    handleOnClick: (data: GridRowData[], updateType: UpdateType) => void;
    rowsInBundle: GridRowData[];
}

function EntityListContents({ columns, handleOnClick, rowsInBundle }: EntityListContentProps) {
    const [selectedRowsToDelete, setSelectedRowsToDelete] = useState<GridRowData[]>([]);
    const [deleteSelection, setDeleteSelection] = useState(false);

    function onEntitiesToRemove(selections: GridSelectionModel) {
        if (selections.length > 0) {
            setDeleteSelection(true);
        } else {
            setDeleteSelection(false);
        }

        const selectedIDs = new Set(selections);
        const rowsToDelete = rowsInBundle.filter((row) => selectedIDs.has(row?.entryId.toString()));
        setSelectedRowsToDelete(rowsInBundle.filter((row) => !rowsToDelete.find((f) => f.entryId === row.entryId)));
    }

    function onDeleteSelectionFromBundle() {
        handleOnClick(selectedRowsToDelete, UpdateType.Remove);
        setDeleteSelection(false);
    }

    return (
        <div>
            <Box sx={{ height: '43vh' }}>
                <DataGrid
                    columns={columns}
                    rows={rowsInBundle}
                    rowCount={rowsInBundle.length}
                    pageSize={10}
                    checkboxSelection
                    onSelectionModelChange={onEntitiesToRemove}
                    getRowId={(row) => row.entryId}
                />
                <Button sx={{ marginTop: '10px' }} disabled={!deleteSelection} onClick={onDeleteSelectionFromBundle} variant="contained">
                    Remove from Bundle
                </Button>
            </Box>
        </div>
    );
}

interface BundleContentsProps {
    bundle: BundleAggregateViewModel;
    setBundlesInBundle: (bundles: GridRowData[], updateType: UpdateType) => void;
    setItemsInBundle: (items: GridRowData[], updateType: UpdateType) => void;
    setCurrenciesInBundle: (currencies: GridRowData[]) => void;
    addCurrenciesToBundle: (currencies: GridRowData[]) => void;
    removeCurrenciesFromBundle: (currencies: GridRowData[]) => void;
    setContainersInBundle: (containers: GridRowData[], updateType: UpdateType) => void;
    setDropTablesInBundle: (dropTables: GridRowData[], updateType: UpdateType) => void;
    itemsInBundle: GridRowData[];
    bundlesInBundle: GridRowData[];
    currenciesInBundle: GridRowData[];
    containersInBundle: GridRowData[];
    dropTablesInBundle: GridRowData[];
}

export function BundleContents(props: BundleContentsProps) {
    const config = useSiteConfig();
    return (
        <AddBundleContents
            bundle={props.bundle}
            setBundlesInBundle={props.setBundlesInBundle}
            setItemsInBundle={props.setItemsInBundle}
            itemsInBundle={props.itemsInBundle}
            bundlesInBundle={props.bundlesInBundle}
            addCurrenciesToBundle={props.addCurrenciesToBundle}
            removeCurrenciesFromBundle={props.removeCurrenciesFromBundle}
            setCurrenciesInBundle={props.setCurrenciesInBundle}
            currenciesInBundle={props.currenciesInBundle}
            setContainersInBundle={props.setContainersInBundle}
            containersInBundle={props.containersInBundle}
            dropTablesInBundle={props.dropTablesInBundle}
            setDropTablesInBundle={props.setDropTablesInBundle}
        />
    );
}
