import { DropdownInput, FormGrid, FormSection, notify, silentMonitor, TextInput } from '@components';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Divider, Grid, MenuItem, Toolbar } from '@mui/material';
import type { BulkCreatePlayersRequest, BulkPlayerOperationResponse, BulkPlayerOperationStatus } from '@services/model';
import { PageBody, PageContent } from 'features/shell/layout';
import _ from 'lodash';
import { useCallback, useRef, useState } from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { usePermissions } from 'shared/core/AuthorizationService';
import { orvalRequestor } from 'shared/core/GameServiceApiOrvalFacade';
import * as yup from 'yup';

import { BulkResults } from './BulkResults';
import { getEmailsFromInput, getInvalidEmails } from './BulkUtils';

const NUM_PLAYERS_PER_BATCH = 5;

const addPlayersFormSchema = yup.object().shape({
    emails: yup.string().required('At least one email is required'),
    fusionAuthAccountMode: yup.string().required('FusionAuth account mode is required!'),
});

enum FusionAuthAccountMode {
    CreateIfNeeded = 1,
    MustAlreadyExist = 2,
}

function AddPlayersForm() {
    const { canCreate: canCreatePlayers, canUpdate: canUpdatePlayers } = usePermissions('Player');
    const canCreateBulkPlayers = canCreatePlayers && canUpdatePlayers;
    const [isAdding, setIsAdding] = useState(false);
    const wasCancelledRef = useRef(false);
    const [progress, setProgress] = useState(0);
    const [statuses, setStatuses] = useState<BulkPlayerOperationStatus[]>([]);

    const form = useForm({
        defaultValues: {
            emails: '',
            fusionAuthAccountMode: FusionAuthAccountMode.MustAlreadyExist,
        },
        resolver: yupResolver(addPlayersFormSchema),
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });

    const { emails, fusionAuthAccountMode } = form.getValues();

    const postApiBulkCreatePlayers = useCallback((request: BulkCreatePlayersRequest) => {
        return orvalRequestor<BulkCreatePlayersRequest>({
            url: '/api/players/bulk-create',
            method: 'post',
            data: request,
        });
    }, []);

    const sendBatch = useCallback(
        async (emails: string[], fusionAuthAccountMode: FusionAuthAccountMode): Promise<BulkPlayerOperationResponse> => {
            let response = {};
            let error = null;

            await silentMonitor({
                failureMessage: '',
                successMessage: '',
                action: async () => {
                    const request: BulkCreatePlayersRequest = {
                        emails,
                        wantsAccountsCreated: fusionAuthAccountMode === FusionAuthAccountMode.CreateIfNeeded,
                    };
                    response = await postApiBulkCreatePlayers(request);
                },
                onError: (e) => {
                    error = e;
                },
            });

            if (error) {
                throw new Error(error);
            }

            return response;
        },
        [postApiBulkCreatePlayers]
    );

    const addPlayers = useCallback(
        async (emails: string[], fusionAuthAccountMode: FusionAuthAccountMode, batchSize: number) => {
            setProgress(0);
            setStatuses([]);

            let batch: string[] = [];
            const playerStatusMap: { [key: string]: BulkPlayerOperationStatus } = {};
            const allPlayers: BulkPlayerOperationStatus[] = [];
            emails.forEach((email) => {
                const playersModel = { email };
                allPlayers.push(playersModel);
                playerStatusMap[email] = playersModel;
            });

            // set the initial state of all emails in results view
            const newBulkAddStatus = _.cloneDeep(allPlayers);
            setStatuses(newBulkAddStatus);

            for (let index = 0; index < emails.length; index++) {
                // if we haven't been cancelled, process a batch
                if (!wasCancelledRef.current) {
                    batch.push(emails[index]);
                    if (batch.length === batchSize || index === emails.length - 1) {
                        try {
                            const result = await sendBatch(batch, fusionAuthAccountMode);
                            result.statuses?.forEach((status) => {
                                const playerModel = playerStatusMap[status.email!];
                                playerModel.success = status.success;
                                playerModel.errorMessage = status.errorMessage;
                            });

                            setProgress(index + 1);
                        } catch (e) {
                            // fail the remaining email statuses
                            const emailsRemaining = [...batch, ...emails.slice(index)];
                            emailsRemaining.forEach((email) => {
                                const playerModel = playerStatusMap[email];
                                playerModel.success = false;
                                playerModel.errorMessage = `API error: ${e}`;
                            });

                            index = emails.length;
                            setProgress(emails.length);
                        }
                        batch = [];
                    }
                } else {
                    // we've been cancelled, so update remaining email statuses
                    const emailsRemaining = [...batch, ...emails.slice(index)];
                    emailsRemaining.forEach((email) => {
                        const playerModel = playerStatusMap[email];
                        playerModel.success = false;
                        playerModel.errorMessage = 'Cancelled';
                    });

                    index = emails.length;
                    setProgress(emails.length);
                }

                // update the states of all emails in results view
                const newBulkAddStatus = _.cloneDeep(allPlayers);
                setStatuses(newBulkAddStatus);
            }
        },
        [sendBatch]
    );

    // this triggers the form becoming dirty so the buttons will update
    const { isDirty } = useFormState(form);
    const createPlayers = useCallback(async () => {
        const emailList = getEmailsFromInput(emails);

        // make sure all emails are valid
        const invalidEmails = getInvalidEmails(emailList);
        if (invalidEmails.length) {
            notify({ type: 'error', content: `Some emails are invalid, like '${invalidEmails[0]}'.` });
            return;
        }

        setIsAdding(true);
        wasCancelledRef.current = false;
        setProgress(0);
        setStatuses([]);
        try {
            await addPlayers(emailList, fusionAuthAccountMode, NUM_PLAYERS_PER_BATCH);
        } finally {
            setIsAdding(false);
        }
    }, [emails, fusionAuthAccountMode, wasCancelledRef, addPlayers]);

    const subheader = isAdding ? `${progress} of ${statuses.length}` : '';

    return (
        <PageContent>
            <PageBody>
                <FormProvider {...form}>
                    <FormGrid>
                        <Grid item container xs={12} spacing={2}>
                            <FormSection header="Add Multiple Players">
                                <TextInput
                                    width="full"
                                    label="Email Addresses"
                                    name="emails"
                                    multiline
                                    helperText="Enter one email per line"
                                    disabled={isAdding}
                                    rows={10}
                                />
                                <Box mt={1}>
                                    <DropdownInput width="full" label="Fusion Auth Account" name="fusionAuthAccountMode" disabled={isAdding}>
                                        <MenuItem value={FusionAuthAccountMode.CreateIfNeeded}>Create If Needed</MenuItem>
                                        <MenuItem value={FusionAuthAccountMode.MustAlreadyExist}>Must Already Exist</MenuItem>
                                    </DropdownInput>
                                </Box>
                            </FormSection>
                        </Grid>
                        {(isAdding || statuses.length > 0) && <BulkResults header={'Results'} statuses={statuses} subheader={subheader} />}
                    </FormGrid>
                </FormProvider>
            </PageBody>
            <Divider />
            {canCreateBulkPlayers && (
                <Toolbar>
                    {!isAdding && (
                        <Button onClick={createPlayers} variant="contained" disabled={!isDirty}>
                            Add Players
                        </Button>
                    )}
                    {isAdding && (
                        <Button
                            onClick={() => {
                                wasCancelledRef.current = true;
                            }}
                            variant="contained"
                            disabled={wasCancelledRef.current}
                        >
                            Cancel
                        </Button>
                    )}
                </Toolbar>
            )}
        </PageContent>
    );
}

export function BulkAdd() {
    return <AddPlayersForm />;
}
