import { Home as HomeIcon } from '@mui/icons-material';
import { Box, Divider, Typography } from '@mui/material';
import { useGetApiOrganizations } from '@services/organizations/organizations';
import { PageBody, PageContent, PageHeader } from 'features/shell/layout';

import { OrgTile } from './OrgTile';
import { adminPageRegistry } from '../PageRegistry';

export const Cards = () => {
    const { data } = useGetApiOrganizations({ PageSize: 1000, Page: 1, SortBy: 'Name', SortOrder: 'ASC' });
    return (
        <PageContent>
            <PageHeader></PageHeader>
            <PageBody>
                {!data ? null : (
                    <>
                        <Typography>Organizations</Typography>

                        <Divider />
                        <Box display="flex" flexWrap="wrap">
                            {data.items?.map((d) => (
                                <OrgTile key={d.id} org={d} />
                            ))}
                        </Box>
                    </>
                )}
            </PageBody>
        </PageContent>
    );
};
adminPageRegistry.register({ page: Cards, path: 'cards', name: 'Admin Home', icon: <HomeIcon /> });
