import { searchBuilder, useSiteConfig } from '@core';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { TabContext, TabPanel } from '@mui/lab';
import {
    Autocomplete,
    Box,
    Button,
    Chip,
    FormControl,
    FormLabel,
    Grid,
    Tab,
    Tabs,
    TextField
} from '@mui/material';
import type { GridColDef, GridRowData, GridSelectionModel } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import { postApiBundlesQuery } from '@services/bundles/bundles';
import { postApiContainersQuery } from '@services/containers/containers';
import { postApiDropTablesQuery } from '@services/drop-tables/drop-tables';
import { postApiItemsQueryForAddContent } from '@services/items/items';
import { DropTableAggregateViewModel, VirtualCurrency } from '@services/model';
import { useGetApiVirtualCurrencyByEnvironment } from '@services/virtual-currency/virtual-currency';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'shared/hooks/useQuery';
import * as yup from 'yup';
import { buildDropTableEntityListContentsColumns, buildEntityListColumns } from '../Shared/EntityListService';
import { UpdateType } from '../Shared/EntityModels';

interface AddDropTableProps {
    dropTable: DropTableAggregateViewModel;
    setBundlesInDropTable: (bundles: GridRowData[], updateType: UpdateType) => void;
    setItemsInDropTable: (items: GridRowData[], updateType: UpdateType) => void;
    setCurrenciesInDropTable: (currencies: GridRowData[], updateType: UpdateType) => void;
    setContainersInDropTable: (containers: GridRowData[], updateType: UpdateType) => void;
    setDropTablesInDropTable: (containers: GridRowData[], updateType: UpdateType) => void;
    itemsInDropTable: GridRowData[];
    bundlesInDropTable: GridRowData[];
    currenciesInDropTable: GridRowData[];
    containersInDropTable: GridRowData[];
    dropTablesInDropTable: GridRowData[];
}

export const AddDropTableContents = ({
    dropTable,
    setBundlesInDropTable,
    setItemsInDropTable,
    setContainersInDropTable,
    setDropTablesInDropTable,
    setCurrenciesInDropTable,
    itemsInDropTable,
    bundlesInDropTable,
    currenciesInDropTable,
    containersInDropTable,
    dropTablesInDropTable
}: AddDropTableProps) => {
    const [currentTab, setCurrentTab] = useState('0');

    const [bundlesQuery] = useState(() =>
        searchBuilder('InventoryContainer')
            .select((bn) => ({
                id: bn.id,
                displayName: bn.displayName,
                inventoryContainerType: bn.inventoryContainerType
            }))
            .where((bn) => ({ eq: { [bn.nameof.inventoryContainerType]: 1 } }))
            .sortAsc((bn) => bn.displayName)
            .prepare(postApiBundlesQuery)
    );

    const [itemsQuery] = useState(() =>
        searchBuilder('Item')
            .leftJoin('ItemToken', ([it, tk]) => ({ eq: { [tk.nameof.itemId]: it.id } }))
            .select(([it, tk]) => ({
                id: it.id,
                displayName: it.displayName,
                maxSupply: tk.maxSupply
            }))
            .sortAsc((it) => it.displayName)
            .prepare(postApiItemsQueryForAddContent)
    );

    const [containersQuery] = useState(() =>
        searchBuilder('InventoryContainer')
            .select((bn) => ({
                id: bn.id,
                displayName: bn.displayName,
                inventoryContainerType: bn.inventoryContainerType,
            }))
            .where((bn) => ({ eq: { [bn.nameof.inventoryContainerType]: 2 } }))
            .sortAsc((bn) => bn.displayName)
            .prepare(postApiContainersQuery)
    );

    const [dropTablesQuery] = useState(() =>
        searchBuilder('InventoryContainer')
            .select((bn) => ({
                id: bn.id,
                displayName: bn.displayName,
                inventoryContainerType: bn.inventoryContainerType,
            }))
            .where((bn) => ({ eq: { [bn.nameof.inventoryContainerType]: 3 } }))
            .sortAsc((bn) => bn.displayName)
            .prepare(postApiDropTablesQuery)
    );

    const itemsListColumns = buildEntityListColumns('Item');
    const bundlesListColumns = buildEntityListColumns('Bundle');
    const itemsListContentsColumns = buildDropTableEntityListContentsColumns('Item');
    const bundlesListContentsColumns = buildDropTableEntityListContentsColumns('Bundle');
    const containersListColumns = buildEntityListColumns('Container');
    const containersListContentsColumns = buildDropTableEntityListContentsColumns('Container');
    const dropTablesListColumns = buildEntityListColumns('Drop Table');
    const dropTablesListContentsColumns = buildDropTableEntityListContentsColumns('Drop Table');

    const virtualCurrencyListColumns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'Id',
            type: 'uuid',
            hide: true,
        },
        {
            field: 'name',
            headerName: 'Currency Name',
            type: 'string',
            flex: 2,
        },
        {
            field: 'amount',
            headerName: 'Amount',
            type: 'string',
            flex: 1,
        },
        {
            field: 'currencyCode',
            headerName: 'Code',
            type: 'string',
            flex: 1,
        },
        {
            field: 'weight',
            headerName: 'Weight',
            type: 'string',
            flex: 1,
        }
    ];

    const { rows: itemRows } = useQuery({ query: itemsQuery, columns: itemsListColumns });
    const { rows: bundleRows } = useQuery({ query: bundlesQuery, columns: bundlesListColumns });
    const { rows: containerRows } = useQuery({ query: containersQuery, columns: containersListColumns });
    const { rows: dropTableRows } = useQuery({ query: dropTablesQuery, columns: dropTablesListColumns });
    const { data: virtualCurrencyRows } = useGetApiVirtualCurrencyByEnvironment();

    const handleAddVirtualCurrency = useCallback(
        ({ currencyToAdd, amountToAdd, weight }: { currencyToAdd: number; amountToAdd: string | number; weight: number; }) => {
            const currency = virtualCurrencyRows?.[currencyToAdd] ?? {};
            setCurrenciesInDropTable([{ ...currency, amount: amountToAdd, weight: weight }], UpdateType.Add);
        },
        [setCurrenciesInDropTable, currenciesInDropTable, virtualCurrencyRows]
    );

    const handleAddItem = useCallback(
        ({ entityToAdd, weight, maxCount }: { entityToAdd: number; weight: number; maxCount: number; }) => {
            const entity = itemRows?.[entityToAdd] ?? {};
            setItemsInDropTable([{ ...entity, weight: weight, maxCount: maxCount, countAvailable: maxCount }], UpdateType.Add);
        },
        [setItemsInDropTable, itemsInDropTable, itemRows]
    );

    const handleAddBundle = useCallback(
        ({ entityToAdd, weight }: { entityToAdd: number; weight: number; }) => {
            const entity = bundleRows?.[entityToAdd] ?? {};
            setBundlesInDropTable([{ ...entity, weight: weight }], UpdateType.Add);
        },
        [setBundlesInDropTable, bundlesInDropTable, bundleRows]
    );

    const handleAddContainer = useCallback(
        ({ entityToAdd, weight }: { entityToAdd: number; weight: number; }) => {
            const entity = containerRows?.[entityToAdd] ?? {};
            setContainersInDropTable([{ ...entity, weight: weight }], UpdateType.Add);
        },
        [setContainersInDropTable, containersInDropTable, containerRows]
    );

    const handleAddDropTable = useCallback(
        ({ entityToAdd, weight }: { entityToAdd: number; weight: number; }) => {
            const entity = dropTableRows?.filter((row) => row.id !== dropTable.dropTable?.id)?.[entityToAdd] ?? {};
            setDropTablesInDropTable([{ ...entity, weight: weight }], UpdateType.Add);
        },
        [setDropTablesInDropTable, dropTablesInDropTable, dropTableRows]
    );

    const currencyForm = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(
            yup.object({
                currencyToAdd: yup.number().required(),
                amountToAdd: yup.number().required('Amount to add is required.').positive('Must be a positive number.').typeError('Amount to Add is required and must be a positive number.'),
                weight: yup.number().required('Weight is required.').positive('Must be a positive number.').max(2147483647, 'Cannot be larger than max integer size.').typeError('Weight is required and must be a positive number.')
            })
        ),
    });

    const itemForm = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(
            yup.object({
                entityToAdd: yup.number().required(),
                weight: yup.number().required('Weight is required.').positive('Must be a positive number.').max(2147483647, 'Cannot be larger than max integer size.').typeError('Weight is required and must be a positive number.'),
                maxCount: yup.number().nullable().transform((_, val) => (val !== "" ? Number(val) : null))
            })
        ),
    });

    const bundleForm = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(
            yup.object({
                entityToAdd: yup.number().required(),
                weight: yup.number().required('Weight is required.').positive('Must be a positive number.').max(2147483647, 'Cannot be larger than max integer size.').typeError('Weight is required and must be a positive number.'),
            })
        ),
    });

    const containerForm = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(
            yup.object({
                entityToAdd: yup.number().required(),
                weight: yup.number().required('Weight is required.').positive('Must be a positive number.').max(2147483647, 'Cannot be larger than max integer size.').typeError('Weight is required and must be a positive number.'),
            })
        ),
    });

    const dropTableForm = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(
            yup.object({
                entityToAdd: yup.number().required(),
                weight: yup.number().required('Weight is required.').positive('Must be a positive number.').max(2147483647, 'Cannot be larger than max integer size.').typeError('Weight is required and must be a positive number.'),
            })
        ),
    });

    function updateMaxCountFromToken(itemIndex: number) {
        itemForm.setValue('entityToAdd', itemIndex);
        var item = itemRows[itemIndex];
        if (item.maxSupply) {
            itemForm.setValue('maxCount', item.maxSupply);
        }
    }

    function buildAutoCompleteOptions(data: readonly GridRowData[]) {
        return (
            data?.map((x, index) => {
                return {
                    label: x.displayName,
                    id: x.id,
                    index: index
                };
            }) ?? []
        );
    }

    function buildDropTableAutoCompleteOptions(data: readonly GridRowData[]) {
        return (
            data?.filter((row) => row.id !== dropTable.dropTable?.id).map((x, index) => {
                return {
                    label: x.displayName,
                    id: x.id,
                    index: index
                };
            }) ?? []
        );
    }

    function buildCurrencyAutoCompleteOptions(data: VirtualCurrency[] | undefined) {
        return (
            data?.map((x, index) => {
                return {
                    label: x.name,
                    id: x.id,
                    index: index
                };
            }) ?? []
        );
    }

    interface LabelWithChipProps {
        label: string;
        quantity: number;
    }
    const LabelWithChip = (props: LabelWithChipProps) => {
        return (
            <>
                {props.quantity > 0 ? (
                    <span>
                        {props.label} <Chip color="error" size="small" label={props.quantity} />
                    </span>
                ) : (
                    <span>{props.label}</span>
                )}
            </>
        );
    };

    return (
        <Grid columns={2} container={true} xs={12}>
            <Box sx={{ width: '45%' }}>
                <TabContext value={currentTab ?? '0'}>
                    <Box>
                        <Tabs onChange={(_, tab) => setCurrentTab(tab)} variant='scrollable' scrollButtons='auto' value={currentTab}>
                            <Tab value="0" label={<LabelWithChip label="Items" quantity={itemsInDropTable.length} />} />
                            <Tab value="1" label={<LabelWithChip label="Bundles" quantity={bundlesInDropTable.length} />} />
                            <Tab value="2" label={<LabelWithChip label="Containers" quantity={containersInDropTable.length} />} />
                            <Tab value="3" label={<LabelWithChip label="Drop Tables" quantity={dropTablesInDropTable.length} />} />
                            <Tab value="4" label={<LabelWithChip label="Currencies" quantity={currenciesInDropTable.length} />} />
                        </Tabs>
                    </Box>

                    <TabPanel sx={{ height: '50vh' }} value="0">
                        <form onSubmit={itemForm.handleSubmit(handleAddItem)}>
                            <FormControl fullWidth sx={{ '> * + *': { marginTop: '0.85rem' } }}>
                               
                                <Autocomplete 
                                    renderInput={(params) => <TextField {...params} label="Item to Add" />} 
                                    options={buildAutoCompleteOptions(itemRows)} 
                                    {...itemForm.register('entityToAdd')} 
                                    id="item-selection" 
                                    sx={{ width: '100%' }} 
                                    onChange={(e, value) => updateMaxCountFromToken(value!.index)}
                                />

                                <FormLabel sx={{ width: '100%' }}>Weight</FormLabel>
                                <TextField {...itemForm.register('weight')} id="item-weight" placeholder="10" />
                                <Box sx={{ color: '#d32f2f' }}>
                                    <ErrorMessage errors={itemForm.formState.errors} name="weight" />
                                </Box>

                                <FormLabel sx={{ width: '100%' }}>Max Count</FormLabel>
                                <TextField {...itemForm.register('maxCount')} id="item-max-count" placeholder="10" />
                                <Box sx={{ color: '#d32f2f' }}>
                                    <ErrorMessage errors={itemForm.formState.errors} name="maxCount" />
                                </Box>

                                <Button type="submit" variant="contained" disabled={!itemForm.formState.isDirty}>
                                    Add Item to Drop Table
                                </Button>
                            </FormControl>
                        </form>
                    </TabPanel>
                    <TabPanel sx={{ height: '50vh' }} value="1">
                        <form onSubmit={bundleForm.handleSubmit(handleAddBundle)}>
                            <FormControl fullWidth sx={{ '> * + *': { marginTop: '0.85rem' } }}>
                             
                                <Autocomplete 
                                    renderInput={(params) => <TextField {...params} label="Bundle to Add" />} 
                                    options={buildAutoCompleteOptions(bundleRows)} 
                                    {...bundleForm.register('entityToAdd')} 
                                    id="bundle-selection" 
                                    sx={{ width: '100%' }}
                                    onChange={(e, value) => bundleForm.setValue('entityToAdd', value!.index)}
                                />

                                <FormLabel sx={{ width: '100%' }}>Weight</FormLabel>
                                <TextField {...bundleForm.register('weight')} id="bundle-weight" placeholder="10" />
                                <Box sx={{ color: '#d32f2f' }}>
                                    <ErrorMessage errors={bundleForm.formState.errors} name="weight" />
                                </Box>

                                <Button type="submit" variant="contained" disabled={!bundleForm.formState.isDirty}>
                                    Add Bundle to Drop Table
                                </Button>
                            </FormControl>
                        </form>
                    </TabPanel>
                    <TabPanel sx={{ height: '50vh' }} value="2">
                        <form onSubmit={containerForm.handleSubmit(handleAddContainer)}>
                            <FormControl fullWidth sx={{ '> * + *': { marginTop: '0.85rem' } }}>
                        
                                <Autocomplete 
                                    renderInput={(params) => <TextField {...params} label="Container to Add" />} 
                                    options={buildAutoCompleteOptions(containerRows)} 
                                    {...containerForm.register('entityToAdd')} 
                                    id="container-selection" 
                                    sx={{ width: '100%' }}
                                    onChange={(e, value) => containerForm.setValue('entityToAdd', value!.index)}
                                />

                                <FormLabel sx={{ width: '100%' }}>Weight</FormLabel>
                                <TextField {...containerForm.register('weight')} id="container-weight" placeholder="10" />
                                <Box sx={{ color: '#d32f2f' }}>
                                    <ErrorMessage errors={containerForm.formState.errors} name="weight" />
                                </Box>

                                <Button type="submit" variant="contained" disabled={!containerForm.formState.isDirty}>
                                    Add Container to Drop Table
                                </Button>
                            </FormControl>
                        </form>
                    </TabPanel>
                    <TabPanel sx={{ height: '50vh' }} value="3">
                        <form onSubmit={dropTableForm.handleSubmit(handleAddDropTable)}>
                            <FormControl fullWidth sx={{ '> * + *': { marginTop: '0.85rem' } }}>
                                
                                <Autocomplete 
                                    renderInput={(params) => <TextField {...params} label="Drop Table to Add" />} 
                                    options={buildDropTableAutoCompleteOptions(dropTableRows)} 
                                    {...dropTableForm.register('entityToAdd')} 
                                    id="dropTable-selection" 
                                    sx={{ width: '100%' }}
                                    onChange={(e, value) => dropTableForm.setValue('entityToAdd', value!.index)}
                                />

                                <FormLabel sx={{ width: '100%' }}>Weight</FormLabel>
                                <TextField {...dropTableForm.register('weight')} id="dropTable-weight" placeholder="10" />
                                <Box sx={{ color: '#d32f2f' }}>
                                    <ErrorMessage errors={dropTableForm.formState.errors} name="weight" />
                                </Box>

                                <Button type="submit" variant="contained" disabled={!dropTableForm.formState.isDirty}>
                                    Add Drop Table to Drop Table
                                </Button>
                            </FormControl>
                        </form>
                    </TabPanel>
                    <TabPanel sx={{ height: '50vh' }} value="4">
                        <form onSubmit={currencyForm.handleSubmit(handleAddVirtualCurrency)}>
                            <FormControl fullWidth sx={{ '> * + *': { marginTop: '0.85rem' } }}>
                            
                                <Autocomplete 
                                    renderInput={(params) => <TextField {...params} label="Currency to Add" />} 
                                    options={buildCurrencyAutoCompleteOptions(virtualCurrencyRows)} 
                                    {...currencyForm.register('currencyToAdd')} 
                                    id="virtual-currency-selection" 
                                    sx={{ width: '100%' }}
                                    onChange={(e, value) => currencyForm.setValue('currencyToAdd', value!.index)}
                                />

                                <FormLabel sx={{ width: '100%' }}>Amount to Add</FormLabel>
                                <TextField {...currencyForm.register('amountToAdd')} id="virtual-currency-amount" placeholder="1,000" />
                                <Box sx={{ color: '#d32f2f' }}>
                                    <ErrorMessage errors={currencyForm.formState.errors} name="amountToAdd" />
                                </Box>

                                <FormLabel sx={{ width: '100%' }}>Weight</FormLabel>
                                <TextField {...currencyForm.register('weight')} id="virtual-currency-weight" placeholder="10" />
                                <Box sx={{ color: '#d32f2f' }}>
                                    <ErrorMessage errors={currencyForm.formState.errors} name="weight" />
                                </Box>

                                <Button type="submit" variant="contained" disabled={!currencyForm.formState.isDirty}>
                                    Add Currency to Drop Table
                                </Button>
                            </FormControl>
                        </form>
                    </TabPanel>
                </TabContext>
            </Box>

            <Box sx={{ width: '55%' }}>
                <Box sx={{ paddingBottom: '5px', paddingTop: '43px' }}>Drop Table Contents</Box>
                {currentTab === '0' && (
                    <EntityListContents columns={itemsListContentsColumns} handleOnClick={setItemsInDropTable} rowsInDropTable={itemsInDropTable} />
                )}
                {currentTab === '1' && (
                    <EntityListContents columns={bundlesListContentsColumns} handleOnClick={setBundlesInDropTable} rowsInDropTable={bundlesInDropTable} />
                )}
                {currentTab === '2' && (
                    <EntityListContents
                        columns={containersListContentsColumns}
                        handleOnClick={setContainersInDropTable}
                        rowsInDropTable={containersInDropTable}
                    />
                )}
                {currentTab === '3' && (
                    <EntityListContents
                        columns={dropTablesListContentsColumns}
                        handleOnClick={setDropTablesInDropTable}
                        rowsInDropTable={dropTablesInDropTable}
                    />
                )}
                {currentTab === '4' && (
                    <EntityListContents
                        columns={virtualCurrencyListColumns}
                        handleOnClick={setCurrenciesInDropTable}
                        rowsInDropTable={currenciesInDropTable}
                    />
                )}
            </Box>
        </Grid>
    );
};

interface EntityListContentProps {
    columns: GridColDef[];
    handleOnClick: (data: GridRowData[], updateType: UpdateType) => void;
    rowsInDropTable: GridRowData[];
}

function EntityListContents({ columns, handleOnClick, rowsInDropTable }: EntityListContentProps) {
    const [selectedRowsToDelete, setSelectedRowsToDelete] = useState<GridRowData[]>([]);
    const [deleteSelection, setDeleteSelection] = useState(false);

    function onEntitiesToRemove(selections: GridSelectionModel) {
        if (selections.length > 0) {
            setDeleteSelection(true);
        } else {
            setDeleteSelection(false);
        }

        const selectedIDs = new Set(selections);
        const rowsToDelete = rowsInDropTable.filter((row) => selectedIDs.has(row?.entryId.toString()));
        setSelectedRowsToDelete(rowsInDropTable.filter((row) => !rowsToDelete.find((f) => f.entryId === row.entryId)));
    }

    function onDeleteSelectionFromDropTable() {
        handleOnClick(selectedRowsToDelete, UpdateType.Remove);
        setDeleteSelection(false);
    }

    return (
        <div>
            <Box sx={{ height: '43vh' }}>
                <DataGrid
                    columns={columns}
                    rows={setPickPercentage(rowsInDropTable)}
                    rowCount={rowsInDropTable.length}
                    pageSize={10}
                    checkboxSelection
                    onSelectionModelChange={onEntitiesToRemove}
                    getRowId={(row) => row.entryId}
                />
                <Button sx={{ marginTop: '10px' }} disabled={!deleteSelection} onClick={onDeleteSelectionFromDropTable} variant="contained">
                    Remove from Drop Table
                </Button>
            </Box>
        </div>
    );
}

function setPickPercentage(rows: GridRowData[])
{    
    var rowsWithPercentage = [...rows];
    const totalWeight = rows
    .filter(canBeGranted)
    .reduce((sum, rowData) => sum + rowData.weight, 0);
    rowsWithPercentage.forEach( x => {
        if(totalWeight === 0 || !canBeGranted(x)) {
            x.percentage  = 0;
        } else {
            x.percentage = x.weight / totalWeight * 100;
        }        
    });
    return rowsWithPercentage;

    function canBeGranted(row: GridRowData)
    {
        if(row.maxCount > 0) return row.grantedCount < row.maxCount;
        return true;
    }
}

interface DropTableContentsProps {
    dropTable: DropTableAggregateViewModel;
    setBundlesInDropTable: (bundles: GridRowData[], updateType: UpdateType) => void;
    setItemsInDropTable: (items: GridRowData[], updateType: UpdateType) => void;
    setCurrenciesInDropTable: (currencies: GridRowData[], updateType: UpdateType) => void;
    setContainersInDropTable: (containers: GridRowData[], updateType: UpdateType) => void;
    setDropTablesInDropTable: (containers: GridRowData[], updateType: UpdateType) => void;
    itemsInDropTable: GridRowData[];
    bundlesInDropTable: GridRowData[];
    currenciesInDropTable: GridRowData[];
    containersInDropTable: GridRowData[];
    dropTablesInDropTable: GridRowData[];
}

export function DropTableContents(props: DropTableContentsProps) {
    const config = useSiteConfig();
    return (
        <AddDropTableContents
            dropTable={props.dropTable}
            setBundlesInDropTable={props.setBundlesInDropTable}
            setItemsInDropTable={props.setItemsInDropTable}
            itemsInDropTable={props.itemsInDropTable}
            bundlesInDropTable={props.bundlesInDropTable}
            setCurrenciesInDropTable={props.setCurrenciesInDropTable}
            currenciesInDropTable={props.currenciesInDropTable}
            setContainersInDropTable={props.setContainersInDropTable}
            containersInDropTable={props.containersInDropTable}
            setDropTablesInDropTable={props.setDropTablesInDropTable}
            dropTablesInDropTable={props.dropTablesInDropTable}
        />
    );
}
