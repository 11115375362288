import { AdminTable, CellNavigation, confirm, modal, TextInput, UnauthorizedAccess } from '@components';
import { Event, Op, searchBuilder, useApiListener, usePermissions } from '@core';
import { yupResolver } from '@hookform/resolvers/yup';
import { Create, Delete } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid';
import {
    postApiItemClassesQuery,
    useDeleteApiItemClassesId,
    usePostApiItemClasses,
    usePutApiItemClassesId,
} from '@services/item-classes/item-classes';
import type { ItemClassModel } from '@services/model';
import { format, parseISO } from 'date-fns';
import { PageContent } from 'features/shell/layout';
import { useState } from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';

export const ItemsClassesGrid = () => {
    const { canRead } = usePermissions('ItemClass');
    if (!canRead) {
        return <UnauthorizedAccess />;
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Id', type: 'uuid', flex: 0.5, hide: true },
        {
            field: 'itemClass',
            headerName: 'Class',
            flex: 1,
            type: 'string',
            renderCell: (cellValues) => <CellNavigation id={String(cellValues?.id)} value={String(cellValues?.value)} to="item-class-details" />,
        },
        {
            field: 'description',
            headerName: 'Description',
            type: 'string',
            flex: 1,
        },
        {
            field: 'items',
            headerName: '# of Items',
            filterable: false,
        },
        {
            field: 'createdAt',
            type: 'dateTime',
            headerName: 'Created',
            flex: 0.5,
            valueFormatter: (params) => format(parseISO(String(params.value)), 'Ppp'),
        },
    ];
    const [invalidateData] = useState(() => new Event<void>());
    const [query] = useState(() =>
        searchBuilder('ItemClassModel')
            .leftJoin('Item', ([ic, it]) => ({ eq: { [it.itemClassId]: ic.id } }))
            .select(([ic, it]) => ({
                id: ic.id,
                itemClass: ic.itemClass,
                description: ic.description,
                items: Op.Count(it.id),
                createdAt: ic.createdAt,
            }))
            .prepare(postApiItemClassesQuery)
    );
    const { mutateAsync: remove } = useDeleteApiItemClassesId();
    const { mutateAsync: add } = usePostApiItemClasses();
    const { mutateAsync: update } = usePutApiItemClassesId();
    const onDelete = async (e: Partial<ItemClassModel>) => {
        const didConfirm = await confirm(`Delete ${e.itemClass ?? ''}?`, `Are you sure you want to delete this Class?`);
        if (didConfirm) {
            await remove({ id: e.id ?? '' });
        }
    };
    const onAdd = async () => {
        let itemClass = undefined;
        await modal('Add Item Class', (close) => <NewItemPrompt onClose={close} onChange={(e: ItemClassModel) => (itemClass = e)} buttonText={"Create Class"} />);
        if (itemClass) {
            await add({ data: { ...(itemClass as ItemClassModel) } });
        }
    };

    const onEdit = async (e: ItemClassModel) => {
        let itemClass = e;
        await modal('Edit Item Class', (close) => <NewItemPrompt defaultValues={e} onClose={close} onChange={(e: ItemClassModel) => (itemClass = e)} buttonText={"Save Changes"} />);
        if (itemClass) {
            await update({ id: itemClass.id || '', data: { ...(itemClass as ItemClassModel) } });
        }
    };
    useApiListener('itemclasses', () => invalidateData.raise());

    return (
        <AdminTable<ItemClassModel>
            title="Item Classes"
            resource="ItemClass"
            columns={columns}
            data={query}
            menuActions={[
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                { type: 'button', icon: Create, label: 'Edit', onClick: (o) => onEdit(o as ItemClassModel) },
                { type: 'button', icon: Delete, label: 'Delete', onClick: onDelete },
            ]}
            onAdd={onAdd}
            dataInvalidationEvent={invalidateData}
        />
    );
};
const ItemsSchema = yup.object().shape({
    itemClass: yup.string().required('Class Name required'),
    description: yup.string(),
});
function NewItemPrompt(props: { defaultValues?: ItemClassModel; onChange: (item: ItemClassModel) => void; onClose: () => void, buttonText: string }) {
    const newItem = {
        itemClass: '',
        description: '',
    };
    const form = useForm({
        resolver: yupResolver(ItemsSchema),
        defaultValues: props.defaultValues ? props.defaultValues : newItem,
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });
    const { isDirty } = useFormState({ control: form.control });
    const save = () => {
        const values = form.getValues();
        props.onChange(values);
        props.onClose();
    };
    return (
        <>
            <FormProvider {...form}>
                <Box px={4} py={2}>
                    <TextInput label="Class Name" name="itemClass" helperText="What's the item class name?" />
                </Box>
                <Box px={4} py={2}>
                    <TextInput label="Description" name="description" helperText="Description of the class" />
                </Box>
            </FormProvider>
            <Box p={2} justifyContent="flex-end" display="flex">
                <Button variant="contained" color="primary" disabled={!isDirty} onClick={form.handleSubmit(save)}>
                    {props.buttonText}
                </Button>
                <Button onClick={props.onClose}>Cancel</Button>
            </Box>
        </>
    );
}

export function ItemClasses() {
    return (
        <PageContent>
            <ItemsClassesGrid />
        </PageContent>
    );
}
