import { useUrlParams } from '@components';
import type { SelectChangeEvent } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import type { ShopVersion } from '@services/model';
import { useEffect } from 'react';

import { useShop } from './ShopProvider';

export function ShopVersionSelector() {
    const { shopVersions, selectedShopVersion, selectShopVersion, getShopTabs, selectShopTab } = useShop();
    const { params, updateParams } = useUrlParams();

    const handleVersionChange = (version: ShopVersion) => {
        selectShopVersion(version);
        updateParams({ versionId: version.id as string });
        getShopTabs(version.id as string);
        selectShopTab(null);
    };

    const handleChange = (event: SelectChangeEvent) => {
        const versionId = event.target.value as string;
        const nextVersion = shopVersions.find((version) => version.id === versionId);
        if (nextVersion) {
            handleVersionChange(nextVersion);
        }
    };

    useEffect(() => {
        if (params.versionId) {
            const version = shopVersions.find((version) => version.id === params.versionId);
            if (version) {
                handleVersionChange(version);
            }
        } else {
            const version = shopVersions[0];
            if (version) {
                handleVersionChange(version);
            }
        }
    }, [shopVersions]);

    return (
        <FormControl>
            <InputLabel id="version-select-label">Select Shop Version</InputLabel>
            <Select
                onChange={handleChange}
                value={(selectedShopVersion?.id as string) ?? ''}
                label="Select Shop Version"
                labelId="version-select-label"
                sx={{ minWidth: '200px' }}
            >
                {shopVersions.map((version) => (
                    <MenuItem value={version.id as string} key={version.id}>
                        {version.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
