import { Add, Edit, Settings } from '@mui/icons-material';
import { Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper } from '@mui/material';
import type { ShopVersion } from '@services/model';
import { nanoid } from 'nanoid';
import { useState } from 'react';

import { AddShopVersionModal } from './AddShopVersionModal';
import { EditShopVersionModal } from './EditShopVersionModal';
import { useShop } from './ShopProvider';
import { UserPermissions } from './UserPermissions';

export function ShopVersionMenu() {
    const { addShopVersion, updateShopVersion, shop, selectedShopVersion, selectShopVersion, getShopVersions } = useShop();

    const [isEditModalOpen, setIsModalEditOpen] = useState<boolean>(false);
    const [isAddModalOpen, setIsModalAddOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();

    const handleCloseShopVersionMenu = () => {
        setAnchorEl(void 0);
    };

    const handleOpenEditVersion = () => {
        handleCloseShopVersionMenu();
        setIsModalEditOpen(true);
    };
    const handleOpenAddVersion = () => {
        handleCloseShopVersionMenu();
        setIsModalAddOpen(true);
    };

    const handleAddShopVersion = (shopVersion: ShopVersion) => {
        if (shop?.id) {
            addShopVersion(
                { data: { ...shopVersion, shopId: shop?.id } },
                {
                    onSuccess(data) {
                        getShopVersions();
                        selectShopVersion(data);
                        setIsModalAddOpen(false);
                    },
                }
            );
        }
    };

    const handleEditShopVersion = (shopVersion: ShopVersion) => {
        if (shop?.id) {
            updateShopVersion(
                { data: { ...shopVersion }, id: shopVersion.id as string },
                {
                    onSuccess(data) {
                        getShopVersions();
                        selectShopVersion(data);
                        setIsModalEditOpen(false);
                    },
                }
            );
        }
    };

    const handleCloseAddShopModal = () => {
        setIsModalAddOpen(false);
    };
    const handleCloseEditShopModal = () => {
        setIsModalEditOpen(false);
    };
    return (
        <>
            <IconButton sx={{ '&:hover': { background: 'none' } }} onClick={(event) => setAnchorEl(event.currentTarget)}>
                <Settings />
            </IconButton>
            <Paper>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseShopVersionMenu}>
                    <UserPermissions requires={['update']}>
                        <MenuItem onClick={handleOpenEditVersion}>
                            <ListItemIcon>
                                <Edit />
                            </ListItemIcon>
                            <ListItemText>Edit Version</ListItemText>
                        </MenuItem>
                        <Divider />
                    </UserPermissions>
                    <UserPermissions requires={['create']}>
                        <MenuItem onClick={handleOpenAddVersion}>
                            <ListItemIcon>
                                <Add />
                            </ListItemIcon>
                            <ListItemText>Add New Version</ListItemText>
                        </MenuItem>
                    </UserPermissions>
                </Menu>
            </Paper>
            <AddShopVersionModal isOpen={isAddModalOpen} key={nanoid()} onClose={handleCloseAddShopModal} onAddShopVersion={handleAddShopVersion} />
            {selectedShopVersion && (
                <EditShopVersionModal
                    key={selectedShopVersion.id}
                    isOpen={isEditModalOpen}
                    onClose={handleCloseEditShopModal}
                    onEditShopVersion={handleEditShopVersion}
                    shopVersion={selectedShopVersion}
                />
            )}
        </>
    );
}
