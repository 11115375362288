import { buildSearchAll } from '@core';
import type { Shop, ShopBundle, ShopContainer, ShopItem, ShopTab, ShopTabBundle, ShopTabContainer, ShopTabItem, ShopTabVirtualCurrency, ShopVersion, ShopVirtualCurrency } from '@services/model';
import { useDeleteApiShopBundleId, usePostApiShopBundle, usePostApiShopBundleFindBy, usePutApiShopBundleId } from '@services/shop-bundle/shop-bundle';
import { useDeleteApiShopContainerId, usePostApiShopContainer, usePostApiShopContainerFindBy, usePutApiShopContainerId } from '@services/shop-container/shop-container';
import { useDeleteApiShopVirtualCurrencyId, usePostApiShopVirtualCurrency, usePostApiShopVirtualCurrencyFindBy, usePutApiShopVirtualCurrencyId } from '@services/shop-virtual-currency/shop-virtual-currency';
import { useDeleteApiShopItemsId, usePostApiShopItems, usePostApiShopItemsFindBy, usePutApiShopItemsId } from '@services/shop-items/shop-items';
import {
    useDeleteApiShopTabBundleId,
    usePostApiShopTabBundle,
    usePostApiShopTabBundleFindBy,
    usePutApiShopTabBundleId,
} from '@services/shop-tab-bundle/shop-tab-bundle';
import { useDeleteApiShopTabContainerId, usePostApiShopTabContainer, usePostApiShopTabContainerFindBy, usePutApiShopTabContainerId } from '@services/shop-tab-container/shop-tab-container';
import {
    useDeleteApiShopTabItemsId,
    usePostApiShopTabItems,
    usePostApiShopTabItemsFindBy,
    usePutApiShopTabItemsId,
} from '@services/shop-tab-items/shop-tab-items';
import {
    useDeleteApiShopTabVirtualCurrencyId,
    usePostApiShopTabVirtualCurrency,
    usePostApiShopTabVirtualCurrencyFindBy,
    usePutApiShopTabVirtualCurrencyId
} from '@services/shop-tab-virtual-currency/shop-tab-virtual-currency';
import { useDeleteApiShopTabsId, usePostApiShopTabs, usePostApiShopTabsFindBy, usePutApiShopTabsId } from '@services/shop-tabs/shop-tabs';
import { usePostApiShopVersions, usePostApiShopVersionsFindBy, usePutApiShopVersionsId } from '@services/shop-versions/shop-versions';
import { useGetApiShopsId } from '@services/shops/shops';
import { noop } from 'lodash';
import { createContext, useContext, useState } from 'react';

interface ShopApiOperations {
    getShopVersions(): void;
    getShopTabs(shopVersionId: string): void;
    getShopItems(shopVersionId: string): void;
    getShopBundles(shopVersionId: string): void;
    getShopContainers(shopVersionId: string): void;
    getShopVirtualCurrencies(shopVersionId: string): void;
    getShopTabItems(shopTabId: string): void;
    getShopTabBundles(shopTabId: string): void;
    getShopTabContainers(shopTabId: string): void;
    getShopTabVirtualCurrencies(shopTabId: string): void;
    updateShopTab: ReturnType<typeof usePutApiShopTabsId>['mutateAsync'];
    updateShopVersion: ReturnType<typeof usePutApiShopVersionsId>['mutateAsync'];
    updateShopItem: ReturnType<typeof usePutApiShopItemsId>['mutateAsync'];
    updateShopBundle: ReturnType<typeof usePutApiShopBundleId>['mutateAsync'];
    updateShopContainer: ReturnType<typeof usePutApiShopContainerId>['mutateAsync'];
    updateShopVirtualCurrency: ReturnType<typeof usePutApiShopVirtualCurrencyId>['mutateAsync'];
    updateShopTabItem: ReturnType<typeof usePutApiShopTabItemsId>['mutateAsync'];
    updateShopTabBundle: ReturnType<typeof usePutApiShopTabBundleId>['mutateAsync'];
    updateShopTabContainer: ReturnType<typeof usePutApiShopTabContainerId>['mutateAsync'];
    updateShopTabVirtualCurrency: ReturnType<typeof usePutApiShopTabVirtualCurrencyId>['mutateAsync'];
    addShopTab: ReturnType<typeof usePostApiShopTabs>['mutateAsync'];
    addShopVersion: ReturnType<typeof usePostApiShopVersions>['mutateAsync'];
    addShopItem: ReturnType<typeof usePostApiShopItems>['mutateAsync'];
    addShopBundle: ReturnType<typeof usePostApiShopBundle>['mutateAsync'];
    addShopContainer: ReturnType<typeof usePostApiShopContainer>['mutateAsync'];
    addShopVirtualCurrency: ReturnType<typeof usePostApiShopVirtualCurrency>['mutateAsync'];
    addShopTabItem: ReturnType<typeof usePostApiShopTabItems>['mutateAsync'];
    addShopTabBundle: ReturnType<typeof usePostApiShopTabBundle>['mutateAsync'];
    addShopTabContainer: ReturnType<typeof usePostApiShopTabContainer>['mutateAsync'];
    addShopTabVirtualCurrency: ReturnType<typeof usePostApiShopTabVirtualCurrency>['mutateAsync'];
    removeShopTab: ReturnType<typeof useDeleteApiShopTabsId>['mutateAsync'];
    removeShopItem: ReturnType<typeof useDeleteApiShopItemsId>['mutateAsync'];
    removeShopTabItem: ReturnType<typeof useDeleteApiShopTabItemsId>['mutateAsync'];
    removeShopBundle: ReturnType<typeof useDeleteApiShopBundleId>['mutateAsync'];
    removeShopTabBundle: ReturnType<typeof useDeleteApiShopBundleId>['mutateAsync'];
    removeShopContainer: ReturnType<typeof useDeleteApiShopContainerId>['mutateAsync'];
    removeShopTabContainer: ReturnType<typeof useDeleteApiShopContainerId>['mutateAsync'];
    removeShopVirtualCurrency: ReturnType<typeof useDeleteApiShopVirtualCurrencyId>['mutateAsync'];
    removeShopTabVirtualCurrency: ReturnType<typeof useDeleteApiShopVirtualCurrencyId>['mutateAsync'];
}

interface ShopActions {
    selectShopVersion(shopVersion: ShopVersion | null): void;
    selectShopTab(shopTab: ShopTab | null): void;
}

export interface ShopContextData extends ShopActions, ShopApiOperations {
    selectedShopVersion: ShopVersion | null;
    selectedShopTab: ShopTab | null;
    shop: Shop | null;
    shopVersions: ShopVersion[];
    shopTabs: ShopTab[];
    shopItems: ShopItem[];
    shopBundles: ShopBundle[];
    shopContainers: ShopContainer[];
    shopVirtualCurrencies: ShopVirtualCurrency[];
    shopTabItems: ShopTabItem[];
    shopTabBundles: ShopTabBundle[];
    shopTabContainers: ShopTabContainer[];
    shopTabVirtualCurrencies: ShopTabVirtualCurrency[];
}

const ShopContext = createContext<ShopContextData>({
    selectShopVersion: noop,
    selectShopTab: noop,
    shop: null,
    selectedShopVersion: null,
    selectedShopTab: null,
    shopVersions: [],
    shopTabs: [],
    shopItems: [],
    shopBundles: [],
    shopContainers: [],
    shopVirtualCurrencies: [],
    shopTabItems: [],
    shopTabBundles: [],
    shopTabContainers: [],
    shopTabVirtualCurrencies: [],
    getShopVersions: noop,
    getShopTabs: noop,
    getShopItems: noop,
    getShopBundles: noop,
    getShopContainers: noop,
    getShopVirtualCurrencies: noop,
    getShopTabItems: noop,
    getShopTabBundles: noop,
    getShopTabContainers: noop,
    getShopTabVirtualCurrencies: noop,
    updateShopTab: async () => ({} as ShopTab),
    updateShopVersion: async () => ({} as ShopVersion),
    updateShopItem: async () => ({} as ShopItem),
    updateShopBundle: async () => ({} as ShopBundle),
    updateShopContainer: async () => ({} as ShopContainer),
    updateShopVirtualCurrency: async () => ({} as ShopVirtualCurrency),
    updateShopTabItem: async () => ({} as ShopTabItem),
    updateShopTabBundle: async () => ({} as ShopTabBundle),
    updateShopTabContainer: async () => ({} as ShopTabContainer),
    updateShopTabVirtualCurrency: async () => ({} as ShopTabVirtualCurrency),
    addShopTab: async () => ({} as ShopTab),
    addShopVersion: async () => ({} as ShopVersion),
    addShopItem: async () => ({} as ShopItem),
    addShopBundle: async () => ({} as ShopBundle),
    addShopContainer: async () => ({} as ShopContainer),
    addShopVirtualCurrency: async () => ({} as ShopVirtualCurrency),
    addShopTabItem: async () => ({} as ShopTabItem),
    addShopTabBundle: async () => ({} as ShopTabBundle),
    addShopTabContainer: async () => ({} as ShopTabContainer),
    addShopTabVirtualCurrency: async () => ({} as ShopTabVirtualCurrency),
    removeShopTab: async () => void 0,
    removeShopItem: async () => void 0,
    removeShopTabItem: async () => void 0,
    removeShopBundle: async () => void 0,
    removeShopTabBundle: async () => void 0,
    removeShopContainer: async () => void 0,
    removeShopTabContainer: async () => void 0,
    removeShopVirtualCurrency: async () => void 0,
    removeShopTabVirtualCurrency: async () => void 0,
});

export interface ShopProviderProps {
    children: React.ReactNode;
    shopId: string;
}

function ShopProvider(props: ShopProviderProps) {
    const { shopId, children } = props;

    if (!shopId) {
        console.warn('Shop ID must be provided for <ShopProvider />');
    }

    const { data: shopResult } = useGetApiShopsId(shopId);
    const { data: versionsResult, mutateAsync: fetchShopVersions } = usePostApiShopVersionsFindBy();
    const { data: tabsResult, mutateAsync: fetchShopTabs } = usePostApiShopTabsFindBy();
    const { data: shopItemsResult, mutateAsync: fetchShopItems } = usePostApiShopItemsFindBy();
    const { data: shopBundlesResult, mutateAsync: fetchShopBundles } = usePostApiShopBundleFindBy();
    const { data: shopContainersResult, mutateAsync: fetchShopContainers } = usePostApiShopContainerFindBy();
    const { data: shopVirtualCurrenciesResult, mutateAsync: fetchShopVirtualCurrencies } = usePostApiShopVirtualCurrencyFindBy();
    const { data: shopTabItemResult, mutateAsync: fetchShopTabItems } = usePostApiShopTabItemsFindBy();
    const { data: shopTabBundleResult, mutateAsync: fetchShopTabBundles } = usePostApiShopTabBundleFindBy();
    const { data: shopTabContainerResult, mutateAsync: fetchShopTabContainers } = usePostApiShopTabContainerFindBy();
    const { data: shopTabVirtualCurrencyResult, mutateAsync: fetchShopTabVirtualCurrencies } = usePostApiShopTabVirtualCurrencyFindBy();

    const { mutateAsync: updateShopTab } = usePutApiShopTabsId();
    const { mutateAsync: updateShopVersion } = usePutApiShopVersionsId();
    const { mutateAsync: updateShopItem } = usePutApiShopItemsId();
    const { mutateAsync: updateShopBundle } = usePutApiShopBundleId();
    const { mutateAsync: updateShopContainer } = usePutApiShopContainerId();
    const { mutateAsync: updateShopVirtualCurrency } = usePutApiShopVirtualCurrencyId();
    const { mutateAsync: updateShopTabItem } = usePutApiShopTabItemsId();
    const { mutateAsync: updateShopTabBundle } = usePutApiShopTabBundleId();
    const { mutateAsync: updateShopTabContainer } = usePutApiShopTabContainerId();
    const { mutateAsync: updateShopTabVirtualCurrency } = usePutApiShopTabVirtualCurrencyId();
    const { mutateAsync: addShopTab } = usePostApiShopTabs();
    const { mutateAsync: addShopVersion } = usePostApiShopVersions();
    const { mutateAsync: addShopItem } = usePostApiShopItems();
    const { mutateAsync: addShopBundle } = usePostApiShopBundle();
    const { mutateAsync: addShopContainer } = usePostApiShopContainer();
    const { mutateAsync: addShopVirtualCurrency } = usePostApiShopVirtualCurrency();
    const { mutateAsync: addShopTabItem } = usePostApiShopTabItems();
    const { mutateAsync: addShopTabBundle } = usePostApiShopTabBundle();
    const { mutateAsync: addShopTabContainer } = usePostApiShopTabContainer();
    const { mutateAsync: addShopTabVirtualCurrency } = usePostApiShopTabVirtualCurrency();
    const { mutateAsync: removeShopTab } = useDeleteApiShopTabsId();
    const { mutateAsync: removeShopItem } = useDeleteApiShopItemsId();
    const { mutateAsync: removeShopTabItem } = useDeleteApiShopTabItemsId();
    const { mutateAsync: removeShopBundle } = useDeleteApiShopBundleId();
    const { mutateAsync: removeShopTabBundle } = useDeleteApiShopTabBundleId();
    const { mutateAsync: removeShopContainer } = useDeleteApiShopContainerId();
    const { mutateAsync: removeShopTabContainer } = useDeleteApiShopTabContainerId();
    const { mutateAsync: removeShopVirtualCurrency } = useDeleteApiShopVirtualCurrencyId();
    const { mutateAsync: removeShopTabVirtualCurrency } = useDeleteApiShopTabVirtualCurrencyId();

    const getShopItems = (shopVersionId: string) => fetchShopItems(buildSearchAll<ShopItem>({ eq: { shopVersionId } }));
    const getShopBundles = (shopVersionId: string) => fetchShopBundles(buildSearchAll<ShopBundle>({ eq: { shopVersionId } }));
    const getShopContainers = (shopVersionId: string) => fetchShopContainers(buildSearchAll<ShopContainer>({ eq: { shopVersionId } }));
    const getShopVirtualCurrencies = (shopVersionId: string) => fetchShopVirtualCurrencies(buildSearchAll<ShopVirtualCurrency>({ eq: { shopVersionId } }));
    const getShopTabs = (shopVersionId: string) => fetchShopTabs(buildSearchAll<ShopTab>({ eq: { shopVersionId } }));
    const getShopTabItems = (shopTabId: string) => fetchShopTabItems(buildSearchAll<ShopTabItem>({ eq: { shopTabId } }));
    const getShopTabBundles = (shopTabId: string) => fetchShopTabBundles(buildSearchAll<ShopTabBundle>({ eq: { shopTabId } }));
    const getShopTabContainers = (shopTabId: string) => fetchShopTabContainers(buildSearchAll<ShopTabContainer>({ eq: { shopTabId } }));
    const getShopTabVirtualCurrencies = (shopTabId: string) => fetchShopTabVirtualCurrencies(buildSearchAll<ShopTabVirtualCurrency>({ eq: { shopTabId } }));
    const getShopVersions = () => fetchShopVersions(buildSearchAll<ShopVersion>({ eq: { shopId } }));

    const shop = shopResult ?? null;
    const shopVersions = versionsResult?.items ?? [];
    const shopTabs = tabsResult?.items ?? [];
    const shopItems = shopItemsResult?.items ?? [];
    const shopBundles = shopBundlesResult?.items ?? [];
    const shopContainers = shopContainersResult?.items ?? [];
    const shopVirtualCurrencies = shopVirtualCurrenciesResult?.items ?? [];
    const shopTabItems = shopTabItemResult?.items ?? [];
    const shopTabBundles = shopTabBundleResult?.items ?? [];
    const shopTabContainers = shopTabContainerResult?.items ?? [];
    const shopTabVirtualCurrencies = shopTabVirtualCurrencyResult?.items ?? [];
    const [selectedShopVersion, setSelectedShopVersion] = useState<ShopVersion | null>(null);
    const [selectedShopTab, setSelectedShopTab] = useState<ShopTab | null>(null);

    return (
        <ShopContext.Provider
            value={{
                selectShopVersion: setSelectedShopVersion,
                selectShopTab: setSelectedShopTab,
                selectedShopVersion,
                selectedShopTab,
                shop,
                shopVersions,
                shopTabs,
                shopItems,
                shopBundles,
                shopContainers,
                shopVirtualCurrencies,
                shopTabItems,
                shopTabBundles,
                shopTabContainers,
                shopTabVirtualCurrencies,
                getShopVersions,
                getShopTabs,
                getShopItems,
                getShopBundles,
                getShopContainers,
                getShopVirtualCurrencies,
                getShopTabItems,
                getShopTabBundles,
                getShopTabContainers,
                getShopTabVirtualCurrencies,
                updateShopTab,
                updateShopVersion,
                updateShopItem,
                updateShopBundle,
                updateShopContainer,
                updateShopVirtualCurrency,
                updateShopTabItem,
                updateShopTabBundle,
                updateShopTabContainer,
                updateShopTabVirtualCurrency,
                addShopTab,
                addShopVersion,
                addShopItem,
                addShopBundle,
                addShopContainer,
                addShopVirtualCurrency,
                addShopTabItem,
                addShopTabBundle,
                addShopTabContainer,
                addShopTabVirtualCurrency,
                removeShopTab,
                removeShopItem,
                removeShopTabItem,
                removeShopBundle,
                removeShopTabBundle,
                removeShopContainer,
                removeShopTabContainer,
                removeShopVirtualCurrency,
                removeShopTabVirtualCurrency
            }}
        >
            {children}
        </ShopContext.Provider>
    );
}

function useShop() {
    const context = useContext(ShopContext);
    if (context === undefined) {
        throw new Error('useShop must be used within a ShopProvider');
    }
    return context;
}

export { ShopProvider, useShop };
