import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { Box, styled } from '@mui/material';
// eslint-disable-next-line import/no-duplicates
import { format, getDay, parse, startOfWeek } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import enUS from 'date-fns/locale/en-US';
import type { View } from 'react-big-calendar';
import { Calendar as RbCalendar, dateFnsLocalizer } from 'react-big-calendar';
import tinycolor from 'tinycolor2';

const localizer = dateFnsLocalizer({ format, parse, startOfWeek, getDay, locales: { 'en-US': enUS } });
interface CalendarProps<T> {
    events: T[];
    onSelectEvent?: (event: T) => void;
    onRangeChange?: (range: Date[] | { start: string | Date; end: string | Date }, view: View | undefined) => void;
    defaultDate?: Date;
    startDateAccessor?: keyof T | ((event: T) => Date);
    endDateAccessor?: keyof T | ((event: T) => Date);
    titleAccessor?: keyof T | ((event: T) => string);
}
// eslint-disable-next-line @typescript-eslint/ban-types
export function Calendar<T extends object>(props: CalendarProps<T>) {
    return (
        <CalBg>
            <RbCalendar<T>
                localizer={localizer}
                events={props.events}
                onSelectEvent={props.onSelectEvent}
                defaultDate={props.defaultDate}
                onRangeChange={props.onRangeChange}
                startAccessor={props.startDateAccessor}
                endAccessor={props.endDateAccessor}
                titleAccessor={props.titleAccessor}
            />
        </CalBg>
    );
}

const CalBg = styled(Box)`
    height: 100%;
    padding: 1rem;
    .rbc-off-range-bg {
        background: #8888;
    }
    .rbc-today {
        background: ${(p) => tinycolor(p.theme.palette.secondary.main).setAlpha(0.2).toHex8String()};
    }
    button {
        color: inherit;
    }
`;
