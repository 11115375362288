import { notify } from '@components';
import { Event, useDi, useEventValue } from '@core';
import { Alert, Button, DialogActions, LinearProgress, styled, Typography } from '@mui/material';
import { useMount } from 'react-use';
import { singleton } from 'tsyringe';

import { modal } from './Dialog';

@singleton()
class ActionMonitorService {
    public loadingChanged = new Event<boolean>();
    private loadingHandles = new Set<symbol>();

    public async monitor(config: MonitorConfig) {
        const stopLoading = this.startLoading();
        try {
            await config.action();
            if (config.successMessage !== false) {
                notify({ type: 'success', content: config.successMessage || 'Success!' });
            }
        } catch (err) {
            modal('Error', (close) => <ErrorModal close={close} message={config.failureMessage || 'An unknown error occurred'} />);
        } finally {
            stopLoading();
            config.finally?.();
        }
    }

    public async silentMonitor(config: MonitorConfig) {
        try {
            await config.action();
        } catch (e) {
            config.onError?.(e);
        } finally {
            config.finally?.();
        }
    }

    private startLoading() {
        const handle = Symbol();
        this.loadingHandles.add(handle);
        this.tryRaiseLoading();
        return () => {
            this.loadingHandles.delete(handle);
            this.tryRaiseNotloading();
        };
    }

    private tryRaiseLoading() {
        if (this.loadingHandles.size === 1) {
            this.loadingChanged.raise(true);
        }
    }
    private tryRaiseNotloading() {
        if (this.loadingHandles.size === 0) {
            this.loadingChanged.raise(false);
        }
    }
}
let monitorService: () => ActionMonitorService = () => {
    throw new Error(`ActionMonitor not mounted`);
};
interface MonitorConfig {
    action: () => Promise<void>;
    finally?: () => void;
    onError?: (e: any) => void;
    successMessage?: string | false;
    failureMessage: string;
}
export const monitor = async (config: MonitorConfig) => {
    monitorService().monitor(config);
};

export const silentMonitor = async (config: MonitorConfig) => {
    await monitorService().silentMonitor(config);
};

export function ActionMonitor() {
    const monitorSvc = useDi(ActionMonitorService);
    const loading = useEventValue(monitorSvc.loadingChanged, false);
    useMount(() => (monitorService = () => monitorSvc));
    return loading ? (
        <ProgressContainer>
            <LinearProgress />
        </ProgressContainer>
    ) : null;
}

const ProgressContainer = styled('div')`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    z-index: ${(p) => p.theme.zIndex.modal};
`;

function ErrorModal({ message, close }: { message: string; close: () => void }) {
    return (
        <>
            <Alert color="error">
                <Typography>{message}</Typography>
                <Typography>Please contact support.</Typography>
            </Alert>
            <DialogActions>
                <Button variant="contained" onClick={close} color="error">
                    Close
                </Button>
            </DialogActions>
        </>
    );
}
