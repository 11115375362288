import { ReadonlyInput, useUrlParams } from '@components';
import { Box, Grid, LinearProgress, Paper, styled } from '@mui/material';
import { PlayerVirtualCurrency, VirtualCurrency } from '@services/model';
import { useGetApiPlayerVirtualCurrencyId } from '@services/player-virtual-currency/player-virtual-currency';
import { useGetApiVirtualCurrencyId } from '@services/virtual-currency/virtual-currency';

import { PageBody, PageContent } from '../../shell/layout';
import { titlePageRegistry } from '../PageRegistry';

interface InventoryCurrencyDetailProps {
    playerVirtualCurrency: PlayerVirtualCurrency | undefined;
    virtualCurrency: VirtualCurrency | undefined;
    isLoading: boolean;
}

export function InventoryCurrencyDetail() {
    const { params } = useUrlParams();
    const { isLoading: isPlayerVirtualCurrencyLoading, data: playerVirtualCurrency } = useGetApiPlayerVirtualCurrencyId(params.id);
    const { isLoading: isVirtualCurrencyLoading, data: virtualCurrency } = useGetApiVirtualCurrencyId(playerVirtualCurrency?.currencyId!);

    return <InventoryCurrencyDetailPage isLoading={isPlayerVirtualCurrencyLoading && isVirtualCurrencyLoading} playerVirtualCurrency={playerVirtualCurrency} virtualCurrency={virtualCurrency} />;
}

const InventoryCurrencyDetailPage = ({ playerVirtualCurrency, virtualCurrency, isLoading }: InventoryCurrencyDetailProps) => {
    if (playerVirtualCurrency == undefined || virtualCurrency == undefined) {
        if (isLoading) {
            return <LinearProgress />;
        } else {
            return <h2>Not Found</h2>;
        }
    }

    return (
        <PageContent>
            <PageBody>
                <Container>
                    <PageHeader>{virtualCurrency.name}</PageHeader>
                    <Container>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} alignContent="baseline">
                                <Box component={Paper} p={1}>
                                    <Header>Currency Details</Header>
                                    <ReadonlyInput width="full" label="Inventory Currency ID" value={playerVirtualCurrency.id} />
                                    <ReadonlyInput width="full" label="Amount Granted" value={playerVirtualCurrency.amount} />
                                    <ReadonlyInput width="full" label="Currency ID" value={playerVirtualCurrency.currencyId} />
                                    <ReadonlyInput width="full" label="Currency Name" value={virtualCurrency.name} />
                                    <ReadonlyInput width="full" label="Currency Code" value={virtualCurrency.currencyCode} />
                                    <ReadonlyInput width="full" label="Initial Grant" value={virtualCurrency.initialGrant} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Container>
            </PageBody>
        </PageContent>
    );
};

const PageHeader = styled('div')`
    font-weight: bold;
    font-size: large;
`;

const Container = styled('div')`
    margin-top: 1rem;
`;

const Header = styled('div')`
    display: block;
    width: 100%;
    border: none;
    color: inherit;
    background: none;
    border-bottom: solid 1px ${(p) => p.theme.palette.grey[500]};
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
`;
titlePageRegistry.register({ page: InventoryCurrencyDetail, path: 'inventory-currency-detail', name: 'Inventory Currency' });
