/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.14.2.2422
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  CustomData,
  SaveCustomDataRequest,
  CustomDataIPagedResultList,
  EntitySearch
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const getApiPlayerCustomDataId = (
    id: string,
 ) => {
      return orvalRequestor<CustomData>(
      {url: `/api/player-custom-data/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiPlayerCustomDataIdQueryKey = (id: string,) => [`/api/player-custom-data/${id}`];

    
export const useGetApiPlayerCustomDataId = <TData = AsyncReturnType<typeof getApiPlayerCustomDataId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiPlayerCustomDataId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiPlayerCustomDataIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiPlayerCustomDataId>> = () => getApiPlayerCustomDataId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiPlayerCustomDataId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const putApiPlayerCustomDataId = (
    id: string,
    saveCustomDataRequest: SaveCustomDataRequest,
 ) => {
      return orvalRequestor<CustomData>(
      {url: `/api/player-custom-data/${id}`, method: 'put',
      data: saveCustomDataRequest
    },
      );
    }
  


    export const usePutApiPlayerCustomDataId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiPlayerCustomDataId>, TError,{id: string;data: SaveCustomDataRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiPlayerCustomDataId>, {id: string;data: SaveCustomDataRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiPlayerCustomDataId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiPlayerCustomDataId>, TError, {id: string;data: SaveCustomDataRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const deleteApiPlayerCustomDataId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/player-custom-data/${id}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiPlayerCustomDataId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiPlayerCustomDataId>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiPlayerCustomDataId>, {id: string}> = (props) => {
          const {id} = props || {};

          return  deleteApiPlayerCustomDataId(id,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiPlayerCustomDataId>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiPlayerCustomDataFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<CustomDataIPagedResultList>(
      {url: `/api/player-custom-data/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiPlayerCustomDataFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiPlayerCustomDataFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiPlayerCustomDataFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiPlayerCustomDataFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiPlayerCustomDataFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiPlayerCustomData = (
    saveCustomDataRequest: SaveCustomDataRequest,
 ) => {
      return orvalRequestor<CustomData>(
      {url: `/api/player-custom-data`, method: 'post',
      data: saveCustomDataRequest
    },
      );
    }
  


    export const usePostApiPlayerCustomData = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiPlayerCustomData>, TError,{data: SaveCustomDataRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiPlayerCustomData>, {data: SaveCustomDataRequest}> = (props) => {
          const {data} = props || {};

          return  postApiPlayerCustomData(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiPlayerCustomData>, TError, {data: SaveCustomDataRequest}, TContext>(mutationFn, mutationOptions)
    }
    