import { usePermissions } from '@core';
import { useMemo } from 'react';

export interface UserPermissionsProps {
    requires?: Action[];
    requiresOneOf?: Action[];
    resource?: Resource;
    fallback?: React.ReactNode;
    children: React.ReactNode;
}
export type Action = 'create' | 'read' | 'update' | 'delete' | 'execute';

type Permissions = { [K in Action]: boolean };

export type Resource =
    | 'AdminUser'
    | 'AdminUserOrganization'
    | 'AdminUserRole'
    | 'Game'
    | 'GameEnvironment'
    | 'Permission'
    | 'Role'
    | 'VirtualCurrency'
    | 'AdminRequest'
    | 'GameTitleCustomData'
    | 'GameVersion'
    | 'Item'
    | 'ItemClass'
    | 'ItemClassCustomData'
    | 'Player'
    | 'PlayerCustomData'
    | 'Shop';

export function UserPermissions(props: UserPermissionsProps) {
    const { resource = null } = props;
    const { canCreate, canRead, canUpdate, canDelete, canExecute } = usePermissions(resource as string);

    const userPermissions = useMemo(() => {
        const permissions: Permissions = {
            create: Boolean(canCreate),
            read: Boolean(canRead),
            update: Boolean(canUpdate),
            delete: Boolean(canDelete),
            execute: Boolean(canExecute),
        };
        return getUserPermissions(props, permissions);
    }, [canCreate, canRead, canUpdate, canDelete, canExecute, props]);

    return userPermissions ? <>{props.children}</> : props.fallback ? <>{props.fallback}</> : <></>;
}

function getUserPermissions(props: UserPermissionsProps, permissions: Permissions) {
    const { requires, requiresOneOf } = props;

    let userHasPermissions = true;

    if (requiresOneOf) {
        userHasPermissions = false;

        for (const action of requiresOneOf) {
            userHasPermissions = userHasPermissions || permissions[action as Action];
        }
    }

    if (requires) {
        for (const action of requires) {
            userHasPermissions = userHasPermissions && permissions[action as Action];
        }
    }

    return userHasPermissions;
}
