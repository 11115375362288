/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
// rule disabled for orval

let orvalCustomRequestor: ((requestOptions: any) => any) | undefined;

export function setOrvalRequestor(requestor: (requestOptions: any) => any) {
    orvalCustomRequestor = requestor;
}

// eslint-disable-next-line import/no-default-export
export function orvalRequestor<T>(args: any) {
    return orvalCustomRequestor?.(args) as Promise<T>;
}
