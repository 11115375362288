/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.14.2.2422
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  CustomData,
  SaveCustomDataRequest,
  CustomDataIPagedResultList,
  EntitySearch
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const getApiItemClassCustomDataId = (
    id: string,
 ) => {
      return orvalRequestor<CustomData>(
      {url: `/api/item-class-custom-data/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiItemClassCustomDataIdQueryKey = (id: string,) => [`/api/item-class-custom-data/${id}`];

    
export const useGetApiItemClassCustomDataId = <TData = AsyncReturnType<typeof getApiItemClassCustomDataId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiItemClassCustomDataId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiItemClassCustomDataIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiItemClassCustomDataId>> = () => getApiItemClassCustomDataId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiItemClassCustomDataId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const putApiItemClassCustomDataId = (
    id: string,
    saveCustomDataRequest: SaveCustomDataRequest,
 ) => {
      return orvalRequestor<CustomData>(
      {url: `/api/item-class-custom-data/${id}`, method: 'put',
      data: saveCustomDataRequest
    },
      );
    }
  


    export const usePutApiItemClassCustomDataId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiItemClassCustomDataId>, TError,{id: string;data: SaveCustomDataRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiItemClassCustomDataId>, {id: string;data: SaveCustomDataRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiItemClassCustomDataId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiItemClassCustomDataId>, TError, {id: string;data: SaveCustomDataRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const deleteApiItemClassCustomDataId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/item-class-custom-data/${id}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiItemClassCustomDataId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiItemClassCustomDataId>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiItemClassCustomDataId>, {id: string}> = (props) => {
          const {id} = props || {};

          return  deleteApiItemClassCustomDataId(id,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiItemClassCustomDataId>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiItemClassCustomDataFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<CustomDataIPagedResultList>(
      {url: `/api/item-class-custom-data/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiItemClassCustomDataFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiItemClassCustomDataFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiItemClassCustomDataFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiItemClassCustomDataFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiItemClassCustomDataFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiItemClassCustomData = (
    saveCustomDataRequest: SaveCustomDataRequest,
 ) => {
      return orvalRequestor<CustomData>(
      {url: `/api/item-class-custom-data`, method: 'post',
      data: saveCustomDataRequest
    },
      );
    }
  


    export const usePostApiItemClassCustomData = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiItemClassCustomData>, TError,{data: SaveCustomDataRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiItemClassCustomData>, {data: SaveCustomDataRequest}> = (props) => {
          const {data} = props || {};

          return  postApiItemClassCustomData(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiItemClassCustomData>, TError, {data: SaveCustomDataRequest}, TContext>(mutationFn, mutationOptions)
    }
    