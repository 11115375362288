import type { SiteConfig } from '@core';
import { ShellService, useApiListener, useDi, useSiteConfig } from '@core';
import styled from '@emotion/styled';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { Dialog, IconButton, Link as MuiLink, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { useMount, useSet } from 'react-use';

import { EnvTag } from './EnvTag';

function useSiteConfigRouting() {
    const match = useRouteMatch<{ siteId: string }>('/:siteId');
    const history = useHistory();
    const shellSvc = useDi(ShellService);

    useEffect(() => {
        if (match?.params) {
            shellSvc.setSiteConfigById(match.params.siteId);
        }
    }, [match?.params, shellSvc]);

    useMount(() => {
        const routeSiteId = match?.params.siteId;
        const routeSite = shellSvc.getSiteConfigById(routeSiteId);
        const defaultSite = routeSite || shellSvc.getDefaultSite();

        if (!routeSite) {
            history.replace(`/${defaultSite.id}`);
        }
        shellSvc.setConfig(defaultSite);
    });
}

interface TreeItem {
    data: SiteConfig;
    children: TreeItem[];
}

function getOptionTree(options: SiteConfig[]) {
    const result: TreeItem[] = [];
    const parentLookup = new Map<string, TreeItem>();
    for (const item of options) {
        const treeItem = { data: item, children: [] };
        if (item.type !== 'game') {
            parentLookup.set(item.id, treeItem);
            result.push(treeItem);
        } else {
            const parent = parentLookup.get(item.orgId);
            if (!parent) {
                result.push(treeItem);
            } else {
                parent.children.push(treeItem);
            }
        }
    }

    return result;
}

function useOptionTree(options: SiteConfig[]) {
    const [treeOptions, setTreeOptions] = useState(() => getOptionTree(options));
    const [, { toggle, has }] = useSet(new Set<TreeItem>());

    useEffect(() => setTreeOptions(getOptionTree(options)), [options]);

    return { treeOptions, toggle, isCollapsed: (item: TreeItem) => has(item) };
}

function PickerItem({
    item,
    toggle,
    isCollapsed,
    onClick,
}: {
    onClick: () => void;
    item: TreeItem;
    toggle: (item: TreeItem) => void;
    isCollapsed: boolean;
}) {
    return (
        <>
            <TableRow hover>
                <TableCell>
                    <IconButton sx={{ padding: 0 }} onClick={() => toggle(item)}>
                        {item.data.type === 'admin' ? (
                            ''
                        ) : item.data.type === 'game' ? (
                            ''
                        ) : isCollapsed ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowDown />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell width="100%">
                    <MuiLink component={Link} onClick={onClick} to={`/${item.data.id}`}>
                        {item.data.name}
                    </MuiLink>
                </TableCell>
            </TableRow>
            {isCollapsed
                ? null
                : item.children.map((o) => <PickerItem key={o.data.id} onClick={onClick} item={o} toggle={toggle} isCollapsed={false} />)}
        </>
    );
}

function PickerContent(props: { options: SiteConfig[]; onClick: () => void }) {
    const { treeOptions, toggle, isCollapsed } = useOptionTree(props.options);
    return (
        <Paper sx={{ width: 400, padding: '1rem' }}>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow></TableRow>
                    </TableHead>
                    <TableBody>
                        {treeOptions.map((o) => (
                            <PickerItem key={o.data.id} onClick={props.onClick} item={o} isCollapsed={isCollapsed(o)} toggle={toggle} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export function SitePicker() {
    const [pickerShowing, setPickerShowing] = useState(false);
    const config = useSiteConfig();
    useSiteConfigRouting();
    const shellSvc = useDi(ShellService);
    const [configOptions, setConfigOptions] = useState(shellSvc.getConfigOptions());
    const multipleOptions = configOptions.length > 1;
    const togglePicker = () => {
        setPickerShowing(!pickerShowing);
    };
    const reloadShellData = async () => {
        await shellSvc.reload();
        setConfigOptions(shellSvc.getConfigOptions());
    };
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    useApiListener('organization', reloadShellData);
    useApiListener('game', reloadShellData);
    useApiListener('gameenvironment', reloadShellData);

    return (
        <Picker>
            <SiteName onClick={togglePicker}>
                {config.name}
            </SiteName>
            <Dialog open={pickerShowing} onClose={() => setPickerShowing(false)}>
                <PickerContent options={configOptions} onClick={togglePicker} />
            </Dialog>
            <EnvTag isSandbox={config.isSandbox} />
        </Picker>
    );
}

const SiteName = styled(MuiLink)`
    font-size: 1.2rem;
    color: inherit;
    display: flex;
    padding: 0 0.5rem;
    cursor: pointer;
`;

const Picker = styled.div`
    display: flex;
    align-items: center;
`;
