import { modal, useNavigator } from '@components';
import { usePermissions } from '@core';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import type { Shop, ShopVersion } from '@services/model';
import { postApiShopVersions } from '@services/shop-versions/shop-versions';
import { PageBody, PageContent, PageHeader } from 'features/shell/layout';
import { useMount } from 'react-use';

import { ShopVersionSettings } from './ShopVersionSettings';

export function ShopEmpty({ shop, onVersionAdded }: { shop: Shop; onVersionAdded: () => void }) {
    const nav = useNavigator();
    const { canCreate } = usePermissions();
    const onSave = async (version: ShopVersion, callback: () => void) => {
        await postApiShopVersions(version);
        callback();
        onVersionAdded();
    };
    const onExit = (close: () => void) => {
        close();
        nav.ascend();
    };
    useMount(async () => {
        let version: ShopVersion;
        await modal('Initial Version', (close) => (
            <>
                <Box width={300}>
                    <Typography variant="body1">No Shop Versions exist yet for {shop.name}. Please add the first shop version below. </Typography>
                </Box>
                <ShopVersionSettings onChange={(v) => (version = v)} shopId={shop.id || ''} />
                <Box textAlign="right">
                    {canCreate && (
                        <Button variant="contained" onClick={() => onSave(version, close)}>
                            Save Shop Version
                        </Button>
                    )}
                    <Button onClick={() => onExit(close)}>Exit</Button>
                </Box>
            </>
        ));
    });
    return (
        <PageContent>
            <PageHeader>Shop: {shop.name}</PageHeader>
            <PageBody></PageBody>
        </PageContent>
    );
}
