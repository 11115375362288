import { forEach } from 'lodash';
import { container, inject, singleton } from 'tsyringe';

import { AppConfigService } from './AppConfigService';
import { AuthenticationServiceToken, IAuthenticationService } from './AuthenticationService';
import { DataChangeService } from './DataChangeService';
import { Event } from './Event';
import { Logger } from './Logger';
import { ShellService } from './ShellService';
import { RandomIdGenerator } from './TraceIdGenerator';

export interface RequestOptions {
    url: string;
    method: 'get' | 'post' | 'put' | 'delete' | 'patch';
    params?: unknown;
    data?: unknown;
    responseType?: string;
}

@singleton()
export class GameServiceApiBase {
    private configSvc: AppConfigService;
    private static instance: GameServiceApiBase;

    public loginNeeded = new Event<() => void>();

    public static getInstance() {
        if (!GameServiceApiBase.instance) {
            GameServiceApiBase.instance = container.resolve(GameServiceApiBase);
        }
        return GameServiceApiBase.instance;
    }

    public constructor(
        @inject(Logger) private readonly logger: Logger,
        @inject(ShellService) private readonly shellSvc: ShellService,
        @inject(AuthenticationServiceToken) private readonly authenticationSvc: IAuthenticationService,
        @inject(DataChangeService) private readonly dataChangeService: DataChangeService,
        @inject(RandomIdGenerator) private readonly traceIdGenerator: RandomIdGenerator,
        @inject(AppConfigService) configSvc: AppConfigService
    ) {
        GameServiceApiBase.instance = this;
        this.configSvc = configSvc;
    }

    public getBaseUrl() {
        return this.configSvc.getConfig().apis.gameServicesUrl;
    }

    public getBaseCdnUrl() {
        return this.configSvc.getConfig().cdnUrl;
    }

    public async getBlobUrl(url: string) {
        const response = await this.tryFetch({ method: 'get', url });
        const blob = await response.blob();
        return URL.createObjectURL(blob);
    }

    public async uploadFile(url: string, file: File) {
        const response = await this.tryFetch({ method: 'post', data: file, url });

        return response;
    }

    public async request<T>(options: RequestOptions) {
        const response = await this.tryFetch(options);
        
        //Temp fix for successfully showing error toast to users in all environments
        if (response.status >= 400 && response.status !== 401 && response.status !== 403) {
            throw "Error, request failed";
        }

        const responseText = await response.text();

        const result = responseText ? (JSON.parse(responseText) as T) : null;
        this.dataChangeService.publish(options);
        return result;
    }

    private async tryFetch(options: RequestOptions, retryCount = 0): Promise<Response> {
        const { url, request } = this.createRequest(options);
        const response = await fetch(url, request);
        const nextStep = await this.evaluateResponse(response);

        if ((nextStep === 'retry' && retryCount > 0) || nextStep === 'relogin') {
            await this.authenticationSvc.tryRelogin();
            return this.tryFetch(options, retryCount + 1);
        } else if (nextStep === 'retry') {
            return this.tryFetch(options, retryCount + 1);
        }
        return response;
    }

    private async evaluateResponse(response: Response): Promise<'relogin' | 'retry' | 'continue'> {
        if (response.status === 401 || response.status === 403) {
            return 'relogin';
        } else if (response.type === 'opaqueredirect') {
            return 'relogin';
        }
        return 'continue';
    }

    private createRequest({ url: path, method, params, data }: RequestOptions) {
        const request: RequestInit = {
            method,
            ...this.createBody(data),
            headers: this.createHeaders(data),
            credentials: 'include',
            redirect: 'manual',
        };
        const url = this.createUrl(path, params);

        return { url, request };
    }

    private createUrl(path: string, params: unknown) {
        const baseUrl = this.getBaseUrl();
        return /^http/.test(path) ? path : `${baseUrl}${path}${this.createParams(params)}`;
    }

    private createParams(params: unknown) {
        const result = new URLSearchParams(params as Record<string, string>).toString();
        return result ? `?${result.toString()}` : '';
    }

    private createBody(data: unknown) {

        if (!data) {
            return {};
        }

        if (data instanceof File) {
            return { body: this.createFileBody(data) };
        }
        else if (data instanceof FormData) {
            return {body: data};
        }
        else {
            return { body: JSON.stringify(data) }
        }
    }

    private createFileBody(file: File) {
        const result = new FormData();
        result.append('file', file);
        return result;
    }

    private createHeaders(data: unknown) {
        const baseHeaders = {
            ['X-GAME-ENVIRONMENT-ID']: this.shellSvc.config.id,
            /*
                Open telemetry headers, remove when open-telemetry-js' issues are fixed
                 - https://github.com/open-telemetry/opentelemetry-js/issues/2464
                Following:
                 - https://opentelemetry.lightstep.com/core-concepts/context-propagation/
                 - https://github.com/openzipkin/b3-propagation#traceid-1
            */
            ['X-B3-TraceId']: this.traceIdGenerator.generateTraceId(),
            ['X-B3-SpanId']: this.traceIdGenerator.generateSpanId(),
        } as HeadersInit;

        if (data instanceof File) {
            return baseHeaders;
        } else if (data instanceof FormData) {
            // return { ...baseHeaders, ['Content-Type']: 'multipart/form-data' }
            return baseHeaders;
        } else {
            return { ...baseHeaders, ['Content-Type']: 'application/json' };
        }
    }

    private createAuthHeaders() {
        return {} as HeadersInit;
    }
}
