import { usePermissions, useSiteReadyState } from '@core';
import styled from '@emotion/styled';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import type { SvgIconProps } from '@mui/material';
import { Collapse, Divider, List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import type { ComponentType, MouseEvent } from 'react';
import { Fragment, useCallback, useState } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';

const ItemContent = styled.div`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    align-items: center;
    width: 100%;
`;

const ExpandLessOverride = styled(ExpandLess)`
    font-size: 1.2rem !important;
    font-weight: bold;
`;

const ExpandMoreOverride = styled(ExpandMore)`
    font-size: 1.2rem !important;
`;

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const SideListItem = muiStyled(ListItem)`
    display: flex;
    justify-content: space-between;
    align-items: center;
` as unknown as typeof ListItem;

export interface SidebarItem {
    name: string;
    label: string;
    path?: string;
    Icon?: ComponentType<SvgIconProps> | string;
    onClick?: (e: MouseEvent, item: SidebarItem) => void;
    exact?: boolean;
    resource?: string;
    items?: SidebarItem[];
}

interface SidebarItemProps {
    depthStep?: number;
    depth?: number;
    expanded?: boolean;
    item: SidebarItem;
    onExpandOpen: () => void;
}

export function SidebarItems({ depthStep = 10, depth = 0, expanded, item, onExpandOpen, ...rest }: SidebarItemProps) {
    const [collapsed, setCollapsed] = useState(true);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { url } = useRouteMatch();
    const { label, items, Icon, onClick: onClickProp } = item;
    const { canRead } = usePermissions(item.resource || '');
    const permitted = item.resource ? canRead : true;
    const siteReady = useSiteReadyState();
    const toggleCollapse = useCallback(() => {
        setCollapsed((prevValue) => !prevValue);
    }, []);

    const onClick = useCallback(
        (e: MouseEvent) => {
            if (Array.isArray(items)) {
                onExpandOpen();
                toggleCollapse();
            }
            if (onClickProp) {
                onClickProp(e, item);
            }
        },
        [item, items, onClickProp, onExpandOpen, toggleCollapse]
    );

    let expandIcon;

    if (Array.isArray(items) && items.length) {
        expandIcon = !collapsed ? <ExpandLessOverride /> : <ExpandMoreOverride />;
    }

    return (
        <>
            {!siteReady ? (
                'Loading...'
            ) : !permitted ? null : (
                <>
                    <List disablePadding>
                        {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                        <SideListItem button component={NavLink} to={`${url}${item.path}`} onClick={onClick} {...rest}>
                            <Tooltip title={expanded ? '' : label} placement="right">
                                <ItemContent style={{ paddingLeft: depth * depthStep }}>
                                    {Icon && (
                                        <ListItemIcon>
                                            {typeof Icon === 'string' ? <i className={`fas fa-${Icon}`} aria-hidden="true"></i> : <Icon />}
                                        </ListItemIcon>
                                    )}
                                    <ListItemText primary={label} />
                                </ItemContent>
                            </Tooltip>
                            {expandIcon}
                        </SideListItem>
                    </List>
                    <Collapse in={!collapsed} timeout="auto" unmountOnExit>
                        {Array.isArray(items) ? (
                            <List disablePadding>
                                {items.map((subItem, index) => (
                                    <Fragment key={`${subItem.name}${index}`}>
                                        {subItem.name === 'divider' ? (
                                            <Divider style={{ margin: '6px 0' }} />
                                        ) : (
                                            <SidebarItems
                                                depth={depth + 1}
                                                depthStep={depthStep}
                                                item={subItem}
                                                expanded={undefined}
                                                onExpandOpen={onExpandOpen}
                                            />
                                        )}
                                    </Fragment>
                                ))}
                            </List>
                        ) : null}
                    </Collapse>
                </>
            )}
        </>
    );
}
