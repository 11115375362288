import { TextInput } from '@components';
import { Box, Button } from '@mui/material';
import type { Role } from '@services/model';
import { Toolbar } from 'features/shell/layout';
import { useCallback, useState } from 'react';

export function RoleDetailsPrompt({ onClose, initialValue }: { onClose: (role?: Role) => void; initialValue: Role }) {
    const [name, setName] = useState<string | undefined>(initialValue?.name || '');
    const [description, setDescription] = useState<string | undefined>(initialValue?.description || '');
    const ok = useCallback(() => {
        onClose({ ...initialValue, name: name || '', description });
    }, [onClose, initialValue, name, description]);
    const cancel = useCallback(() => onClose(undefined), [onClose]);
    return (
        <>
            <Box>
                <TextInput width={'full'} value={name} onChange={setName} label="Role  Name" helperText="Name of the role." />
                <TextInput width={'full'} value={description} onChange={setDescription} label="Description" helperText="Description of the role. " />
            </Box>
            <Toolbar>
                <Button onClick={ok}>OK</Button>
                <Button onClick={cancel}>Cancel</Button>
            </Toolbar>
        </>
    );
}
