import { CustomData, LinkTabs } from '@components';
import { useSiteConfig } from '@core';
import { postApiGameTitleCustomDataFindBy, useDeleteApiGameTitleCustomDataId, usePostApiGameTitleCustomData } from '@services/game-title-custom-data/game-title-custom-data';
import type { CustomData as CustomDataModel, CustomDataIPagedResultList, EntitySearch } from '@services/model';

import { titlePageRegistry } from '../PageRegistry';

export function DataDetails() {
    const config = useSiteConfig();
    const { mutateAsync: remove } = useDeleteApiGameTitleCustomDataId();
    const { mutateAsync: addCustomData } = usePostApiGameTitleCustomData();

    async function handleFindBy(data: EntitySearch) : Promise<CustomDataIPagedResultList> {
        return await postApiGameTitleCustomDataFindBy(data);
    }
    
    async function handleSave(data: { data: CustomDataModel }) : Promise<CustomDataModel> {
        return await addCustomData(data);
    }

    async function handleDelete(data: {id: string}) {
        await remove(data);
    }

    return (
        <LinkTabs
            orientation="vertical"
            tabs={[
                {
                    label: 'Client',
                    render: () => <CustomData key="client" type="GameTitleClient" resource="GameTitleCustomData" entityId={config.id} onFindBy={handleFindBy} onSave={handleSave} onDelete={handleDelete}/>,
                    url: 'client',
                },
                {
                    label: 'Server',
                    render: () => <CustomData key="server" type="GameTitleServer" resource="GameTitleCustomData" entityId={config.id} onFindBy={handleFindBy} onSave={handleSave} onDelete={handleDelete}/>,
                    url: 'server',
                },
            ]}
        />
    );
}

titlePageRegistry.register({ page: DataDetails, icon: '', name: 'Title Data', path: 'data' });
