import { BreadcrumbLink, CustomData, LinkTabs, useUrlParams } from '@components';
import { useApiListener } from '@core';
import { useDeleteApiItemClassCustomDataId, usePostApiItemClassCustomData, postApiItemClassCustomDataFindBy } from '@services/item-class-custom-data/item-class-custom-data';
import { useGetApiItemClassesId } from '@services/item-classes/item-classes';
import type { CustomData as CustomDataModel, CustomDataIPagedResultList, EntitySearch } from '@services/model';
import { PageContent, PageHeader } from 'features/shell/layout';

import { titlePageRegistry } from '../PageRegistry';

export function ItemClassDetails() {
    const { params } = useUrlParams();
    const { mutateAsync: remove } = useDeleteApiItemClassCustomDataId();
    const { mutateAsync: addCustomData } = usePostApiItemClassCustomData();

    async function handleFindBy(data: EntitySearch) : Promise<CustomDataIPagedResultList> {
        return await postApiItemClassCustomDataFindBy(data);
    }
    
    async function handleSave(data: { data: CustomDataModel }) : Promise<CustomDataModel> {
        return await addCustomData(data);
    }

    async function handleDelete(data: {id: string}) {
        await remove(data);
    }

    return (
        <LinkTabs
            orientation="vertical"
            tabs={[
                // {
                //     label: 'Details',
                //     render: () => (
                //         <PageContent>
                //             <PageHeader>Details coming soon</PageHeader>
                //         </PageContent>
                //     ),
                //     url: 'details',
                // },
                {
                    label: 'Item Properties',
                    render: () => <CustomData entityId={params.id} type="ItemClassItem" resource="ItemClassCustomData" canViewSchema onFindBy={handleFindBy} onSave={handleSave} onDelete={handleDelete} />,
                    url: 'props',
                },
            ]}
        />
    );
}

function ItemClassBreadcrumb() {
    const { params } = useUrlParams();
    const { isLoading, data, refetch } = useGetApiItemClassesId(params.id);
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    useApiListener('ItemClass', () => refetch());

    return <BreadcrumbLink>Item Class ({isLoading ? 'Loading...' : data?.itemClass})</BreadcrumbLink>;
}
titlePageRegistry.register({ page: ItemClassDetails, path: 'item-class-details', name: ItemClassBreadcrumb });
