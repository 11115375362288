import { FormControl, FormControlLabel, FormHelperText, Switch } from '@mui/material';

import { makeHookablePermission, styleFromProps } from './HookAdapter';

export const BoolInput = makeHookablePermission<boolean | undefined>(function BoolInput(props) {
    const handleChange = (checked: boolean) => {
        if (props.type === 'standard') {
            props.onChange(checked);
        } else {
            props.hookOnChange({ target: { value: checked } });
        }
    };
    return (
        <FormControl fullWidth={props.width === 'full'} style={{ ...styleFromProps(props) }} variant="standard" size="small">
            <FormControlLabel
                control={<Switch checked={props.value || false} onChange={(e, checked) => handleChange(checked)} />}
                label={props.label}
            />
            <FormHelperText sx={{ margin: 0 }}>{props.errorMessage ?? props.helperText}</FormHelperText>
        </FormControl>
    );
});
