import { UnauthorizedAccess } from '@components';
import { TabContext } from '@mui/lab';
import { Chip, Divider, LinearProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useGetApiBundles } from '@services/bundles/bundles';
import { useGetApiContainers } from '@services/containers/containers';
import { useGetApiItemClasses } from '@services/item-classes/item-classes';
import { useGetApiItems } from '@services/items/items';
import { useGetApiVirtualCurrencyByEnvironment, useGetApiVirtualCurrencyId } from '@services/virtual-currency/virtual-currency';
import { PageBody, PageContent, PageHeader } from 'features/shell/layout';
import { useEffect } from 'react';
import { useMount } from 'react-use';

import { ShopGrid } from './ShopGrid';
import { useShop } from './ShopProvider';
import { ShopTabMenu } from './ShopTabMenu';
import { ShopTabs } from './ShopTabs';
import { ShopVersionMenu } from './ShopVersionMenu';
import { ShopVersionSelector } from './ShopVersionSelector';
import { UserPermissions } from './UserPermissions';

export function ShopConfiguration() {
    const {
        selectedShopVersion,
        selectedShopTab,
        getShopVersions,
        getShopTabs,
        getShopItems,
        getShopBundles,
        getShopContainers,
        getShopVirtualCurrencies,
        getShopTabBundles,
        getShopTabItems,
        getShopTabContainers,
        getShopTabVirtualCurrencies,
        shopTabs,
        shopItems,
        shopBundles,
        shopContainers,
        shopVirtualCurrencies,
        shopTabBundles,
        shopTabItems,
        shopTabContainers,
        shopTabVirtualCurrencies
    } = useShop();

    const { data: itemsResult } = useGetApiItems({ Page: 1, PageSize: 1000, SortBy: 'Id', SortOrder: 'ASC' });
    const { data: bundlesResult } = useGetApiBundles({ Page: 1, PageSize: 1000, SortBy: 'Id', SortOrder: 'ASC' });
    const { data: containersResult } = useGetApiContainers({ Page: 1, PageSize: 1000, SortBy: 'Id', SortOrder: 'ASC' });
    const { data: virtualCurrenciesResult } = useGetApiVirtualCurrencyByEnvironment();

    const { data: itemClasses } = useGetApiItemClasses();

    const items = itemsResult?.items;
    const bundles = bundlesResult?.items;
    const containers = containersResult?.items;
    const virtualCurrencies = virtualCurrenciesResult;

    useMount(() => {
        getShopVersions();
    });

    useEffect(() => {
        if (selectedShopVersion) {
            getShopTabs(selectedShopVersion?.id as string);
            getShopItems(selectedShopVersion?.id as string);
            getShopBundles(selectedShopVersion?.id as string);
            getShopContainers(selectedShopVersion?.id as string);
            getShopVirtualCurrencies(selectedShopVersion?.id as string);
        }
    }, [selectedShopVersion]);

    useEffect(() => {
        if (selectedShopTab) {
            getShopTabItems(selectedShopTab.id as string);
            getShopTabBundles(selectedShopTab.id as string);
            getShopTabContainers(selectedShopTab.id as string);
            getShopTabVirtualCurrencies(selectedShopTab.id as string);
        }
    }, [selectedShopTab]);

    const { format: formatDate } = new Intl.DateTimeFormat('en-US');

    const startDate = new Date(selectedShopVersion?.startDate as string);
    const endDate = new Date(selectedShopVersion?.endDate as string);
    const currentDate = new Date();
    const hasStarted = currentDate >= startDate;
    const hasEnded = currentDate >= endDate;

    return (
        <UserPermissions requires={['read']} resource="Shop" fallback={<UnauthorizedAccess />}>
            <PageContent>
                <PageHeader noPadding>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4, px: 4, py: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <ShopVersionSelector />
                            <UserPermissions requiresOneOf={['create', 'update']} resource="Shop">
                                <ShopVersionMenu />
                            </UserPermissions>
                        </Box>
                        {selectedShopVersion && (
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                <Typography variant="subtitle2" sx={{ mb: '8px' }}>
                                    Start Date <Chip label={formatDate(startDate)} variant={!hasStarted ? 'outlined' : undefined} />
                                </Typography>
                                <Typography variant="subtitle2">
                                    End Date <Chip label={formatDate(endDate)} variant={!hasEnded ? 'outlined' : undefined} />
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    <Divider />
                </PageHeader>
                {selectedShopVersion && (
                    <PageBody noPadding>
                        {items && itemClasses && bundles && containers && virtualCurrencies && shopItems && shopBundles && shopContainers && shopVirtualCurrencies && shopTabs ? (
                            <TabContext value={selectedShopTab?.id as string}>
                                <Box sx={{ display: 'flex' }}>
                                    <UserPermissions requiresOneOf={['create', 'update']} resource="Shop">
                                        <ShopTabMenu />
                                    </UserPermissions>
                                    {shopTabs.length > 0 && <ShopTabs />}
                                </Box>
                                {shopTabs.length > 0 && selectedShopTab && shopTabItems && shopTabBundles && shopTabContainers && shopVirtualCurrencies && (
                                    <ShopGrid tabs={shopTabs} items={items} itemClasses={itemClasses} bundles={bundles} containers={containers} virtualCurrencies={virtualCurrencies}/>
                                )}
                            </TabContext>
                        ) : (
                            <LinearProgress />
                        )}
                    </PageBody>
                )}
            </PageContent>
        </UserPermissions>
    );
}
