import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import type { GridSize } from '@mui/material';
import { Box, Grid, Paper, styled, Typography } from '@mui/material';
import type { ReactNode } from 'react';
import { useState } from 'react';

interface SectionProps {
    children: ReactNode;
    xs?: GridSize;
    sm?: GridSize;
    md?: GridSize;
    lg?: GridSize;
    xl?: GridSize;
    header: string;
    subheader?: string;
}
export function FormSection({ children, xs, sm, md, lg, xl, header, subheader }: SectionProps) {
    const [expanded, setExpanded] = useState(true);
    const handleHeaderClick = () => setExpanded(!expanded);
    return (
        <Grid item {...{ xs: xs || 12, sm, md, lg, xl }}>
            <Box component={Paper} p={1}>
                <FormSectionHeader onClick={handleHeaderClick}>
                    <Typography>{header}</Typography>
                    <Typography>{subheader}</Typography>
                    {!expanded ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                </FormSectionHeader>
                <FormSectionBody display={expanded ? 'block' : 'none'}>{children}</FormSectionBody>
            </Box>
        </Grid>
    );
}
const FormSectionBody = styled('div')<{ display: 'block' | 'none' }>`
    display: ${(p) => p.display};
    padding: 0 0.5rem;
`;
const FormSectionHeader = styled('button')`
    display: block;
    width: 100%;
    border: none;
    color: inherit;
    background: none;
    border-bottom: solid 1px ${(p) => p.theme.palette.grey[500]};
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
`;

export function FormGrid({ children }: { children: ReactNode }) {
    return (
        <Grid container spacing={2} p={2} alignContent="baseline">
            {children}
        </Grid>
    );
}
