import { BreadcrumbLink, LinkTabs, OrgTitles, useUrlParams } from '@components';
import { useApiListener } from '@core';
import { Business } from '@mui/icons-material';
import { Divider, LinearProgress, Typography } from '@mui/material';
import { useGetApiOrganizationsId } from '@services/organizations/organizations';
import { PageBody, PageContent, PageHeader } from 'features/shell/layout';

import { adminPageRegistry } from '../PageRegistry';
import { OrgConfig } from './OrgConfig';

export function OrgDetails() {
    const { params } = useUrlParams();
    const { data: org, isLoading, refetch } = useGetApiOrganizationsId(params.id || '');
    const tabs = [
        {
            label: 'Game Titles',
            url: 'titles',
            render: function Titles() {
                return <OrgTitles orgId={params.id} />;
            },
        },
        // {
        //     label: 'Admins',
        //     url: 'admins',
        //     render: () => (
        //         <PageContent>
        //             <PageHeader>Administration Settings coming soon</PageHeader>
        //         </PageContent>
        //     ),
        // },
        // {
        //     label: 'Authentication',
        //     url: 'auth',
        //     render: () => (
        //         <PageContent>
        //             <PageHeader>Authentication Settings coming soon</PageHeader>
        //         </PageContent>
        //     ),
        // },
        {
            label: 'Org Configuration',
            url: 'config',
            render: () => <OrgConfig org={org!} />,
        },
    ];

    useApiListener('organization', () => refetch());

    return isLoading ? (
        <LinearProgress />
    ) : (
        <PageContent>
            <PageHeader>
                <Typography variant="h6">{org?.name}</Typography>
            </PageHeader>
            <Divider />
            <PageBody noPadding>
                <LinkTabs orientation="vertical" tabs={tabs} />
            </PageBody>
        </PageContent>
    );
}

function OrgDetailBreadcrumb() {
    const { params } = useUrlParams();
    const { data: org, error, refetch } = useGetApiOrganizationsId(params.id || '');

    useApiListener('organization', () => refetch());

    return <BreadcrumbLink>Org Details ({error ? 'Not Found!' : org ? org.name : 'Loading...'})</BreadcrumbLink>;
}

adminPageRegistry.register({ page: OrgDetails, icon: <Business />, name: OrgDetailBreadcrumb, path: 'org-details' });
