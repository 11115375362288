import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export interface RemoveConfirmationDialogProps {
    isOpen: boolean;
    onCancel(): void;
    onSubmit(): void;
    displayName: string;
}
export function RemoveConfirmationDialog(props: RemoveConfirmationDialogProps) {
    const { isOpen, onCancel, onSubmit, displayName } = props;

    return (
        <Dialog open={isOpen}>
            <DialogTitle>Confirm Removal</DialogTitle>
            <DialogContent>
                <DialogContentText>Are you sure you wish to remove {displayName}?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onSubmit} variant="contained">
                    Remove {displayName}
                </Button>
                <Button variant="outlined" onClick={onCancel}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
