import { DateInput, TextInput } from '@components';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/system';
import type { ShopVersion } from '@services/model';
import { addDays } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

const shopVersionSettingsSchema = yup.object().shape({
    name: yup.string().required('Shop Name is required!'),
    startDate: yup.date().required('Start Date is Required'),
    endDate: yup.date().min(yup.ref('startDate'), 'End date must be greater than start date'),
    preloadStartDate: yup.date().nullable().default(null).max(yup.ref('startDate'), 'Preload date must be earlier than start date'),
    previewDate: yup.date().nullable().default(null).max(yup.ref('startDate'), 'Preview date must be earlier than start date'),
});

export function ShopVersionSettings({
    version,
    shopId,
    onChange,
}: {
    version?: ShopVersion;
    shopId: string;
    onChange: (version: ShopVersion) => void;
}) {
    const form = useForm<ShopVersion>({
        defaultValues: version || {
            name: '',
            startDate: new Date().toJSON(),
            endDate: addDays(new Date(), 7).toJSON(),
            preloadStartDate: null,
            previewDate: null,
            shopId,
        },
        resolver: yupResolver(shopVersionSettingsSchema),
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });
    form.watch(onChange);

    return (
        <FormProvider {...form}>
            <Box width={300}>
                <TextInput width="full" name="name" label="Version Name" helperText="Enter a name for the shop version" />
                <DateInput width="full" name="startDate" includeTime label="Start Date" helperText="When is this shop available" />
                <DateInput width="full" name="endDate" includeTime label="End Date" helperText="When is this shop no longer available" />
                <DateInput width="full" name="preloadStartDate" includeTime label="Preload Date" helperText="When content is loaded into a testing area" />
                <DateInput width="full" name="previewDate" includeTime label="Preview Date" helperText="When content is available for viewing before going live" />
            </Box>
        </FormProvider>
    );
}
