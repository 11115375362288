import { Calendar, modal, useNavigator, UnauthorizedAccess } from '@components';
import { buildSearchAll, useApiListener, usePermissions } from '@core';
import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import type { Shop, ShopVersion } from '@services/model';
import { usePostApiShopVersionsFindBy } from '@services/shop-versions/shop-versions';
import { usePostApiShopsFindBy } from '@services/shops/shops';
import { addMonths, endOfMonth, parseJSON, startOfMonth } from 'date-fns';
import { PageBody, PageContent, Toolbar } from 'features/shell/layout';
import { useEffect, useState } from 'react';
import { useMount } from 'react-use';

import { NewShopPrompt } from './NewShopPrompt';

export function ShopCalendar() {
    const { canRead, canDelete, canUpdate, canCreate } = usePermissions("Shop");
    if(!canRead) {
        return (<UnauthorizedAccess />);
    }
    const { data: shops, mutate: getShops } = usePostApiShopsFindBy();
    const { data: shopVersions, mutate: getShopVersions } = usePostApiShopVersionsFindBy();
    const [shopLookup, setShopLookup] = useState(new Map<string, Shop>());
    const loadShops = () => getShops(buildSearchAll());
    const loadShopVersions = (start?: Date | string, end?: Date | string) => {
        getShopVersions(
            buildSearchAll<ShopVersion>({
                gte: { endDate: start || startOfMonth(addMonths(new Date(), -1)) },
                lte: { startDate: end || endOfMonth(addMonths(new Date(), 1)) },
            })
        );
    };
    const nav = useNavigator();
    const onAdd = async () => {
        let id = '';
        await modal('Add Shop', (close) => <NewShopPrompt onClose={close} onCreated={(newId) => (id = newId)} />);
        if (id) {
            nav.descend('shop-details', { id });
        }
    };
    const onRangeChange = (range: Date[] | { start: string | Date; end: string | Date }) => {
        if (range instanceof Array) {
            loadShopVersions(range.shift(), range.pop());
        } else {
            loadShopVersions(range.start, range.end);
        }
    };
    const onSelect = (version: ShopVersion) => {
        nav.descend('shop-details', { id: version.shopId || '', versionId: version.id || '' });
    };
    useEffect(() => {
        setShopLookup(new Map(shops?.items?.map((s) => [s.id!, s!] as [string, Shop]) || []));
    }, [setShopLookup, shops]);
    useMount(() => {
        loadShops();
        loadShopVersions();
    });
    useApiListener('shop', loadShops);
    useApiListener('shopversion', () => loadShopVersions());
    return (
        <>
            {canRead && (
                <PageContent>
                    <PageBody noPadding>
                        <Calendar<ShopVersion>
                            titleAccessor={(e) => `${shopLookup.get(e.shopId || '')?.name}: ${e.name}`}
                            startDateAccessor={(e) => parseJSON(e.startDate!)}
                            endDateAccessor={(e) => parseJSON(e.endDate!)}
                            onSelectEvent={onSelect}
                            events={shopVersions?.items?.filter((v) => shopLookup.has(v.shopId!)) || []}
                            onRangeChange={onRangeChange}
                        />
                    </PageBody>
                    {canCreate && (
                        <Toolbar>
                            <Button variant="contained" onClick={onAdd}>
                                <Add /> Add Shop
                            </Button>
                        </Toolbar>
                    )}
                </PageContent>
            )}
        </>
    );
}
