import type {
    EntitySearch,
    EntitySearchExpr,
    EntitySearchJoin,
    EntitySearchOperation,
    EntitySearchSort,
    JoinTypes,
    ObjectIPagedResultList,
} from '@services/model';
import type * as Types from '@services/Types';

type StringKeys<T> = keyof T & string;
type SearchExprRel<T> = { and: SearchExpr<T>[] } | { or: SearchExpr<T>[] } | { not: SearchExpr<T> };
type ComparisonOps = 'eq' | 'ne' | 'gt' | 'gte' | 'lt' | 'lte' | 'like';
type SearchExprNull<T> = Partial<Record<'isNull' | 'isNotNull', unknown[]>>;
type SearchExprComparison<T> = Partial<Record<ComparisonOps, Partial<Record<StringKeys<T>, string | number | null | undefined | Date>>>>;
export type SearchExpr<T> = SearchExprComparison<T> | SearchExprRel<T> | SearchExprNull<T>;
type SearchExpression = SearchExpr<any> | EntitySearchExpr;

export const Op = {
    Count: (expr: unknown) => ({ operation: 'Count', operands: [expr] } as unknown as number),
    CountDistinct: (expr: unknown) => ({ operation: 'CountDistinct', operands: [expr] } as unknown as number),
    Sum: (expr: unknown) => ({ operation: 'Sum', operands: [expr] } as unknown as number),
    Min: <T>(expr: T) => ({ operation: 'Min', operands: [expr] } as unknown as T),
    Max: <T>(expr: T) => ({ operation: 'Max', operands: [expr] } as unknown as T),
    Iif: (...rest: unknown[]) => ({ operation: 'Iif', operands: rest } as unknown),
};

class ExprAdapter {
    public constructor(
        private readonly fieldExprLookup?: Map<symbol, EntitySearchExpr>,
        private readonly resultAliases?: Map<string, EntitySearchExpr>
    ) {}

    private normalizeNull(operation: 'IsNull' | 'IsNotNull', exprs: unknown[]) {
        return exprs.map((item) => {
            if (typeof item === 'string') {
                return { operation, operands: [{ field: item }] };
            } else {
                return { operation, operands: [this.normalize(item as SearchExpression)] };
            }
        });
    }

    public normalize(expr: SearchExpression): EntitySearchExpr {
        if (typeof expr === 'number' || typeof expr === 'boolean' || typeof expr === 'string') {
            return { value: expr };
        } else if (typeof expr === 'symbol') {
            return this.fieldExprLookup!.get(expr)!;
        } else if ('isNull' in expr) {
            return { operation: 'Or', operands: this.normalizeNull('IsNull', expr.isNull!) };
        } else if ('isNotNull' in expr) {
            return { operation: 'Or', operands: this.normalizeNull('IsNotNull', expr.isNotNull!) };
        } else if ('not' in expr) {
            return { operation: 'Not', operands: [this.normalize(expr.not)] };
        } else if ('and' in expr) {
            return { operation: 'And', operands: expr.and.map((e) => this.normalize(e)) };
        } else if ('or' in expr) {
            return { operation: 'Or', operands: expr.or.map((e) => this.normalize(e)) };
        } else if ('value' in expr) {
            return expr;
        } else if ('operands' in expr) {
            return { operation: expr.operation, operands: expr.operands?.map((o) => this.normalize(o)) };
        } else if ('field' in expr) {
            return this.resultAliases?.get(expr.field || '') || expr;
        } else {
            const result = { operation: 'And', operands: [] as EntitySearchExpr[] } as EntitySearchExpr;
            const compExpr = expr as any;
            for (const operation of Object.keys(compExpr)) {
                const fieldValue = compExpr[operation];
                for (const field of Object.keys(fieldValue)) {
                    const value = fieldValue[field];
                    const fieldExpr = this.resultAliases?.get(field) || { field };
                    result.operands?.push({ operation: operation as EntitySearchOperation, operands: [fieldExpr, { value }] });
                }
                if (this.fieldExprLookup) {
                    for (const sym of Object.getOwnPropertySymbols(fieldValue)) {
                        const leftField = this.fieldExprLookup.get(sym)!;
                        const value = typeof fieldValue[sym] === 'symbol' ? this.fieldExprLookup.get(fieldValue[sym])! : { value: fieldValue[sym] };
                        result.operands?.push({ operation: operation as EntitySearchOperation, operands: [leftField, value] });
                    }
                }
            }
            return result;
        }
    }
}

const exprAdapter = new ExprAdapter();

function normalize(expr: SearchExpr<any>): EntitySearchExpr {
    return exprAdapter.normalize(expr);
}

type ModelNames = keyof typeof Types;
type JoinPredicate<T extends Array<any> | any, J extends ModelNames> = (
    source: T extends Array<any> ? [...T, typeof Types[J]] : [T, typeof Types[J]]
) => SearchExpression;
type JoinDelegate<T extends Array<any> | any> = <J extends ModelNames>(
    m: J,
    predicate: JoinPredicate<T, J>
) => ISearchBuilder<T extends Array<any> ? [...T, typeof Types[J]] : [T, typeof Types[J]]>;
interface ISearchBuilder<T> extends IQueryable<T> {
    leftJoin: JoinDelegate<T>;
    rightJoin: JoinDelegate<T>;
    innerJoin: JoinDelegate<T>;
}
export interface IQueryable<T> {
    select<TResult>(expr: (sources: T) => TResult): IQueryable<Types.WithNameOf<TResult>>;
    execute<TQueryApi extends (search: EntitySearch) => Promise<ObjectIPagedResultList>>(
        queryApi: TQueryApi
    ): Promise<{ items?: T[] | null; totalCount?: number }>;
    execute(): Promise<{ items?: T[] | null; totalCount?: number }>;
    build(): EntitySearch;
    prepare<TQueryApi extends (search: EntitySearch) => Promise<ObjectIPagedResultList>>(queryApi: TQueryApi): IQueryable<T>;
    where(criteria: (source: T) => SearchExpression): IQueryable<T>;
    sortAsc(expr: (source: T) => SearchExpression | unknown): IQueryable<T>;
    sortDesc(expr: (source: T) => SearchExpression | unknown): IQueryable<T>;
    page(pageNumber: number, pageSize: number): IQueryable<T>;
}
interface EntitySource {
    propProvider: Record<string, unknown>;
}

type Sources = Record<string, unknown> | Record<string, unknown>[];
class SearchBuilder {
    private selects: EntitySearchExpr[] = [];
    private selectExprLookup = new Map<string, EntitySearchExpr>();
    private fieldExprLookup = new Map<symbol, EntitySearchExpr>();
    private sources: EntitySource[] = [];
    private joins: EntitySearchJoin[] = [];
    private criteria: EntitySearchExpr[] = [];
    private sortBy: EntitySearchSort[] = [];
    private pageSize = 10000;
    private pageNumber = 1;
    private api?: (search: EntitySearch) => Promise<ObjectIPagedResultList>;
    private singleSource = true;

    public constructor() {
        this.sources.push({ propProvider: this.createPropProvider() });
    }

    public leftJoin(model: string, predicate: (sources: Sources) => SearchExpression) {
        return this.join('Left', model, predicate);
    }
    public rightJoin(model: string, predicate: (sources: Sources) => SearchExpression) {
        return this.join('Right', model, predicate);
    }
    public innerJoin(model: string, predicate: (sources: Sources) => SearchExpression) {
        return this.join('Inner', model, predicate);
    }
    public where(criteria: (sources: Sources) => SearchExpression) {
        return this.extend((b) => {
            b.criteria.push(b.normalizeExpr(criteria(b.getSourcePropProviders())));
        });
    }
    public select(expr: (source: Sources) => Record<string, unknown>) {
        return this.extend((b) => {
            const selected = expr(b.getSourcePropProviders());
            const exprLookup = new Map<string, EntitySearchExpr>();
            b.selects = [];
            for (const key of Object.keys(selected)) {
                const value = selected[key];
                const expr = b.convertToExpr(value);
                b.selects.push({ ...expr, resultAlias: key });
                exprLookup.set(key, expr);
            }
            b.selectExprLookup = exprLookup;
            b.sources = [{ propProvider: b.createPropProvider(undefined, exprLookup) }];
            b.singleSource = true;
        });
    }
    private convertToExpr(value: unknown): EntitySearchExpr {
        if (typeof value === 'symbol') {
            const fieldExpr = this.fieldExprLookup.get(value);
            return { ...fieldExpr };
        } else if (typeof value === 'object') {
            return this.normalizeExpr({ ...value });
        } else {
            return { value };
        }
    }

    public sortAsc(expr: (source: Sources) => SearchExpression) {
        return this.sort(false, expr);
    }
    public sortDesc(expr: (source: Sources) => SearchExpression) {
        return this.sort(true, expr);
    }
    public page(pageNumber = 1, pageSize = 10000) {
        return this.extend((b) => {
            b.pageNumber = pageNumber;
            b.pageSize = pageSize;
        });
    }
    public execute<TQueryApi extends (search: EntitySearch) => Promise<ObjectIPagedResultList>>(api?: TQueryApi) {
        const query = this.build();
        if (api) {
            return api(query);
        } else if (this.api) {
            return this.api(query);
        }
        throw new Error('Cannot execute query, no API provided, you should have called prepare first. ');
    }
    public prepare<TQueryApi extends (search: EntitySearch) => Promise<ObjectIPagedResultList>>(api: TQueryApi) {
        return this.extend((b) => {
            b.api = api;
        });
    }
    private extend(changes: (next: SearchBuilder) => void) {
        const result = this.copy();
        changes(result);
        return result;
    }

    private copy() {
        const result = new SearchBuilder();
        result.api = this.api;
        result.selects = this.selects.slice();
        result.selectExprLookup = this.selectExprLookup;
        result.fieldExprLookup = this.fieldExprLookup;
        result.sources = this.sources.slice();
        result.joins = this.joins.slice();
        result.criteria = this.criteria.slice();
        result.sortBy = this.sortBy.slice();
        result.pageSize = this.pageSize;
        result.pageNumber = this.pageNumber;
        result.singleSource = this.singleSource;
        return result;
    }

    public build() {
        return {
            select: this.selects.length ? this.selects : null,
            joins: this.joins,
            criteria: this.criteria.length ? { operation: 'And', operands: this.criteria } : null,
            sortBy: this.sortBy,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
        } as EntitySearch;
    }

    private normalizeExpr(expr: SearchExpression) {
        return new ExprAdapter(this.fieldExprLookup, this.selectExprLookup).normalize(expr);
    }
    private sort(desc: boolean, sort: (source: Sources) => SearchExpression) {
        return this.extend((b) => {
            const expr = b.normalizeExpr(sort(b.getSourcePropProviders()));
            b.sortBy.push({ desc, expr });
        });
    }
    private getSourcePropProviders() {
        return this.singleSource ? this.sources[0]!.propProvider : this.sources.map((s) => s.propProvider);
    }
    private join(joinType: JoinTypes, model: string, predicate: (sources: Sources) => SearchExpression) {
        return this.extend((b) => {
            const alias = `j${b.sources.length}`;
            b.singleSource = false;
            const propProvider = b.createPropProvider(alias);
            b.sources.push({ propProvider });
            const criteria = b.normalizeExpr(predicate(b.getSourcePropProviders()));
            b.joins.push({ alias, joinType, targetTypeName: model, criteria });
        });
    }

    private createPropProvider(ownerAlias?: string, knownExprs?: Map<string, EntitySearchExpr>) {
        const result: Record<string, unknown> = new Proxy(
            {},
            {
                get: (_target, field) => {
                    if (field === 'nameof') {
                        return result;
                    } else {
                        const fieldExpr =
                            typeof field === 'string' && knownExprs?.has(field) ? knownExprs.get(field)! : { field: field.toString(), ownerAlias };
                        const sym = Symbol();
                        this.fieldExprLookup.set(sym, fieldExpr);
                        return sym;
                    }
                },
            }
        );
        return result;
    }
}

// eslint-disable-next-line unused-imports/no-unused-vars
export function searchBuilder<ModelName extends ModelNames>(modelName: ModelName) {
    return new SearchBuilder() as unknown as ISearchBuilder<typeof Types[ModelName]>;
}

export function buildSearch<T>(pageNumber = 1, pageSize = 100, criteria?: SearchExpr<T>, sortBy?: EntitySearchSort[]) {
    return { data: { criteria: criteria ? normalize(criteria) : undefined, pageNumber, pageSize, sortBy } as EntitySearch };
}
export function buildSearchAll<T>(criteria?: SearchExpr<T>, sortBy?: EntitySearchSort[]) {
    return { data: { criteria: criteria ? normalize(criteria) : undefined, pageNumber: 1, pageSize: 10000, sortBy } as EntitySearch };
}
