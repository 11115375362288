import { modal, useObjectKey } from '@components';
import { useEvent } from '@core';
import { Add, Security } from '@mui/icons-material';
import { Box, Button, Divider } from '@mui/material';
import type { Role } from '@services/model';
import { PageBody, PageContent, Toolbar } from 'features/shell/layout';
import { useCallback, useState } from 'react';

import type { RolesPageService } from './Models';
import { ExpansionState } from './Models';
import { RoleDetailsPrompt } from './RoleDetailsPrompt';
import { RoleListItem } from './RoleListItem';

interface RoleAndPermissionFormProps {
    rolesPageService: RolesPageService;
}
export const RolesAndPermissionForm = ({ rolesPageService }: RoleAndPermissionFormProps) => {
    const key = useObjectKey();
    const [expansion] = useState(new ExpansionState());
    useEvent(rolesPageService.rolesChanged);

    const addRole = useCallback(async () => {
        const newRole: Role = {
            description: '',
            name: '',
            scopeEntityId: rolesPageService.organizationId || '',
            scopeEntityIdType: rolesPageService.organizationId ? 'Organization' : undefined,
        };
        const close = (closer: () => void, role?: Role) => {
            if (role) {
                rolesPageService.addRole(role);
            }
            closer();
        };
        await modal('Add New Role', (closer) => <RoleDetailsPrompt initialValue={newRole} onClose={(r) => close(closer, r)} />);
    }, [rolesPageService]);

    return (
        <PageContent>
            <Toolbar align="space-between">
                <Box display="flex">
                    <Security /> {rolesPageService.organizationId ? 'Organization' : 'Global'} Roles
                </Box>
                <Button onClick={addRole}>
                    <Add /> Add Role
                </Button>
            </Toolbar>
            <Divider />
            <PageBody noPadding>
                {rolesPageService.roleBuilders.map((r) => (
                    <RoleListItem key={key(r)} expansion={expansion} roleBuilder={r} rolesPageService={rolesPageService} />
                ))}
            </PageBody>
        </PageContent>
    );
};
