import { FormControl, FormHelperText, InputLabel, Select } from '@mui/material';
import type { ReactNode } from 'react';

import { makeHookablePermission, styleFromProps } from './HookAdapter';

interface DropdownProps {
    children?: ReactNode;
    label: string;
    disabled?: boolean;
}
export const DropdownInput = makeHookablePermission<undefined | string | number, DropdownProps>(function DropdownInput(props) {
    return (
        <FormControl fullWidth={props.width === 'full'} style={{ ...styleFromProps(props) }} variant="standard" size="small">
            <InputLabel>{props.label}</InputLabel>
            <Select
                name={props.type === 'standard' ? undefined : props.name}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                value={props.value || ''}
                onChange={(e) => (props.type === 'standard' ? props.onChange(e.target.value) : props.hookOnChange(e))}
                error={props.invalid}
                onBlur={props.onBlur}
                disabled={props.disabled}
            >
                {props.children}
            </Select>
            <FormHelperText>{props.errorMessage ?? props.helperText}</FormHelperText>
        </FormControl>
    );
});
