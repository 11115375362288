import { HelpOutline } from '@mui/icons-material';
import { TextField, Tooltip } from '@mui/material';
import { useState } from 'react';

import { makeHookablePermission, styleFromProps } from './HookAdapter';

export const DurationInput = makeHookablePermission<number | undefined>(function DurationInput(props) {
    const [value, setValue] = useState(durationToText(props.value || 0));
    const update = () => {
        const mills = textToDuration(value);
        if (props.type === 'standard') {
            props.onChange(mills);
        } else {
            props.hookOnChange({ target: { value: mills } });
        }
        setValue(durationToText(mills));
        if (props.onBlur) {
            props.onBlur();
        }
    };
    return (
        <TextField
            variant="standard"
            size="small"
            label={props.label}
            value={value}
            style={{
                ...styleFromProps({ width: 130, ...props }),
            }}
            fullWidth={props.width === 'full'}
            error={props.invalid}
            helperText={props.errorMessage ?? props.helperText}
            onBlur={update}
            onChange={(e) => setValue(e.target.value)}
            InputProps={{
                endAdornment: (
                    <Tooltip title="Duration in days, minutes, and hours as #d #h #m, e.g., 2d 12h 0m">
                        <HelpOutline style={{ cursor: 'pointer' }} />
                    </Tooltip>
                ),
            }}
        />
    );
});

const m = 60 * 1000,
    h = m * 60,
    d = h * 24,
    unitScale: Record<string, number> = { d, h, m };
function durationToText(value: number) {
    const days = Math.floor(value / d);
    const hours = Math.floor((value - days * d) / h);
    const minutes = Math.floor((value - (days * d + hours * h)) / m);
    return `${days}d ${hours}h ${minutes}m`;
}
const tokenMatcher = /^([\d]+)([dhm])$/;
function parseToken(token: string) {
    const match = tokenMatcher.exec(token);
    if (match && match.length) {
        const unit = unitScale[match.pop() || ''] || 0;
        const num = parseInt(match.pop() || '', 10) || 0;
        return num * unit;
    }
    return 0;
}
function textToDuration(value: string) {
    let result = 0;
    const tokens = value.split(' ');
    while (tokens.length) {
        const token = tokens.shift();
        const tokenMs = parseToken(token!);
        result += tokenMs;
    }
    return result;
}
