import { ReadonlyInput, TextInput, UnauthorizedAccess } from '@components';
import { usePermissions } from '@core';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import { useGetApiGamesAudiencesId, usePutApiGamesAudiencesId } from '@services/games/games';
import { GameAllowedAudiences } from '@services/model';
import { PageBody, PageContent, PageHeader } from 'features/shell/layout';
import { useEffect } from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';

interface TitleTenantConfigurationProps {
    titleId: string;
}

const tenantConfigSchema = yup.object().shape({
    loadedData: yup.object().shape({
        allowedAudienceServer: yup.string().nullable(),
        allowedAudienceClient: yup.string().nullable(),
    })    
});

export const TitleTenantConfiguration = ({ titleId }: TitleTenantConfigurationProps) => {
    const { canRead, canMutate } = usePermissions("Game");
    if(!canRead) {
        return (<UnauthorizedAccess />);
    }
    const { data, isLoading } = useGetApiGamesAudiencesId(titleId);
    const { mutateAsync: update } = usePutApiGamesAudiencesId();

    const defaultValues: {loadedData: GameAllowedAudiences} = {
        loadedData: {allowedAudienceServer: undefined, allowedAudienceClient: undefined}
    };
    const form = useForm({
        defaultValues,
        mode: 'onChange',
        reValidateMode: 'onBlur',
        resolver: yupResolver(tenantConfigSchema)
    });

    useEffect(()=>{       
        form.reset({loadedData: data})
    }, [data]);

    const { isDirty, isValid } = useFormState(form);
    const undo = () => form.reset();
    const save = async () => {
        const newAudiences = form.getValues();
        await update({ id: titleId, data: { ...newAudiences.loadedData! } });
        form.reset(newAudiences);
    };

    return isLoading ? (
        <LinearProgress />
    ) : (
        <PageContent>
            <PageBody noPadding>
                <FormProvider {...form}>
                    <Box p={2} width={300}>
                        {canMutate ? 
                            (<><TextInput width="full" name="loadedData.allowedAudienceServer" label="Server Allowed Audience" helperText="'aud' claim for server tenant JWT" />                        
                            <TextInput width="full" name="loadedData.allowedAudienceClient" label="Client Allowed Audience" helperText="'aud' claim for client tenant JWT" /></> )
                            :
                            (<><ReadonlyInput width="full" name="loadedData.allowedAudienceServer" label="Server Allowed Audience" helperText="'aud' claim for server tenant JWT" />                        
                            <ReadonlyInput width="full" name="loadedData.allowedAudienceClient" label="Client Allowed Audience" helperText="'aud' claim for client tenant JWT" /> </>)
                        }                   
                    </Box>
                </FormProvider>
            </PageBody>
            {canMutate && 
                (<PageHeader>
                    <Box textAlign="right" width={400}>
                        <Button onClick={form.handleSubmit(save)} disabled={!isDirty || !isValid} variant="contained">
                            Save Changes
                        </Button>
                        <Button onClick={undo} disabled={!isDirty}>
                            Undo Changes
                        </Button>
                    </Box>
                </PageHeader>)
            }
        </PageContent>
    );
};
