import { BoolInput, BreadcrumbLink, FormGrid, FormSection, LinkTabs, monitor, ReadonlyInput, ResourceProvider, SecureImage, TextInput, useUrlParams } from "@components";
import { Person } from "@mui/icons-material";
import { getApiVirtualCurrencyId, putApiVirtualCurrencyId, useGetApiVirtualCurrencyId } from "@services/virtual-currency/virtual-currency";
import { getApiVirtualCurrencyTokensVirtualCurrencyVirtualCurrencyId } from "@services/virtual-currency-tokens/virtual-currency-tokens";
import { titlePageRegistry } from "../PageRegistry";
import { useApiListener, usePermissions } from '@core';
import { VirtualCurrency, VirtualCurrencyToken } from "@services/model";
import { useEffect, useState } from "react";
import { Button, Divider, Grid, LinearProgress, Toolbar } from "@mui/material";
import { FormProvider, useForm, useFormState } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { PageBody, PageContent } from "features/shell/layout";

interface DetailsFormProps {
    virtualCurrency: VirtualCurrency | undefined;
    virtualCurrencyToken: VirtualCurrencyToken | undefined;
}

const formSchema = yup.object().shape({
    virtualCurrency: yup.object().shape({
        name: yup.string().required('Currency name is required'),
        currencyCode: yup
            .string()
            .required('Currency code is required')
            .min(2, 'Currency Code must be at least 2 characters')
            .max(40, 'Currency Code can be no more than 40 characters')
    }),
});

function DetailsForm({ virtualCurrency, virtualCurrencyToken }: DetailsFormProps) { 
    const { canUpdate: canUpdateCurrency, canCreate: canCreateCurrency } = usePermissions('Item');
    const canSave = canUpdateCurrency || canCreateCurrency;

    const form = useForm({
        defaultValues: {
            virtualCurrency,
            virtualCurrencyToken,
        },
        resolver: yupResolver(formSchema),
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });

    const { isDirty } = useFormState(form);

    const save = async () => {
        const { virtualCurrency, virtualCurrencyToken } = form.getValues();
        monitor({
            failureMessage: 'Error Saving Virtual Currency!',
            successMessage: 'Virtual Currency Saved',
            action: async () => {
                const updatedCurrency = await putApiVirtualCurrencyId(virtualCurrency.id!, virtualCurrency);
                form.reset({ virtualCurrency: updatedCurrency, virtualCurrencyToken });
            },
        });
    };

    return (
        <PageContent>
            <PageBody>
                <FormProvider {...form}>
                    <FormGrid>
                        <Grid item container md={4} xs={12} xl={6} spacing={2} alignContent="baseline">
                            <FormSection header="Virtual Currency Details" sm={12} xl={4}>
                                <ResourceProvider value="Item">
                                    <ReadonlyInput label="Virtual Currency ID" name="virtualCurrency.id" />
                                    <TextInput width="full" label="Virtual Currency Name" name="virtualCurrency.name" />
                                    <TextInput width="full" label="Description" name="virtualCurrency.description" multiline />
                                    <TextInput width="full" label="Currency Code" name="virtualCurrency.currencyCode" multiline />
                                    <BoolInput width="full" label="Initial Grant" name="virtualCurrency.initialGrant" />
                                </ResourceProvider>
                            </FormSection>
                            {virtualCurrencyToken && (
                                <FormSection header="Virtual Currency Token Details" sm={12} xl={8}>
                                    <ResourceProvider value="Item">
                                            <>
                                                {/* <ReadonlyInput
                                                    width="full"
                                                    label="State"
                                                    value={token.lastIviState ? `${token.lastIviState || 'None'} - ${token.iviUpdatedAt || 'Never'}` :
                                                    `${token.blockchainState || 'None'} - ${token.blockchainStateModifiedAt || 'Never'}` }
                                                /> */}

                                                <ReadonlyInput width="full" label="Token Name" value={virtualCurrencyToken.tokenName} />
                                                <ReadonlyInput width="full" label="Symbol" value={virtualCurrencyToken.symbol} />
                                                <ReadonlyInput width="full" label="Contract Address" value={virtualCurrencyToken.contractAddress} />
                                                <ReadonlyInput width="full" label="Transaction Id" value={virtualCurrencyToken.transactionId} />
                                                <ReadonlyInput width="full" label="Publisher Address" value={virtualCurrencyToken.publisherAddress} />
                                                <ReadonlyInput width="full" label="Balance In Wei" value={virtualCurrencyToken.balanceInWei} />

                                                {virtualCurrencyToken.imageUrl ? (
                                                    <SecureImage url={virtualCurrencyToken.imageUrl || ''} width="100%" />
                                                ) : null}
                                            </>
                                    </ResourceProvider>
                                </FormSection>
                            )}
                        </Grid>
                    </FormGrid>
                </FormProvider>
            </PageBody>
            <Divider />
            {canSave && (
                <Toolbar>
                    <Button onClick={form.handleSubmit(save)} variant="contained" disabled={!isDirty}>
                        Save Changes
                    </Button>
                </Toolbar>
            )}
        </PageContent>
    );
}


function Details(props: { virtualCurrencyId: string }) {
    const [virtualCurrency, setVirtualCurrency] = useState<VirtualCurrency>();
    const [virtualCurrencyToken, setVirtualCurrencyToken] = useState<VirtualCurrencyToken>();

    const loadData = async () => {
        const [virtualCurrencyResult, virtualCurrencyTokenResult]  = await Promise.all([
            getApiVirtualCurrencyId(props.virtualCurrencyId), 
            getApiVirtualCurrencyTokensVirtualCurrencyVirtualCurrencyId(props.virtualCurrencyId)]);
        setVirtualCurrency(virtualCurrencyResult);
        setVirtualCurrencyToken(virtualCurrencyTokenResult);
    };

    useEffect(() => {
        loadData();
    }, [props.virtualCurrencyId]);

    return !virtualCurrency ? <LinearProgress /> : <DetailsForm virtualCurrency={virtualCurrency} virtualCurrencyToken={virtualCurrencyToken} />;
}

function VirtualCurrencyBreadcrumb() {
    const { params } = useUrlParams();
    const { isLoading, data, refetch } = useGetApiVirtualCurrencyId(params.id);
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    useApiListener('virtual', () => refetch());

    return (
        <BreadcrumbLink>
            <Person /> Virtual Currency ({isLoading ? 'Loading...' : data?.name})
        </BreadcrumbLink>
    );
}

export function VirtualCurrencyDetail() {
    const { params } = useUrlParams();
    return (
        <LinkTabs
            orientation="vertical"
            tabs={[
                { label: 'Details', render: () => <Details virtualCurrencyId={params.id} />, url: 'details' },
            ]}
        />
    );
}

titlePageRegistry.register({ page: VirtualCurrencyDetail, path: 'virtual-currency-detail', name: VirtualCurrencyBreadcrumb });
