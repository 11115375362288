import { useAuthenticationService, useUserPrefs } from '@core';
import { AccountCircle } from '@mui/icons-material';
import { Divider, IconButton, List, ListItem, ListItemButton, Paper, Popover, Switch, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';

export function ProfileMenu() {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const toggleMenu = (el: HTMLElement) => setAnchorEl(el);
    const onClose = () => setAnchorEl(null);
    const { prefs, setPrefs } = useUserPrefs();
    const authSvc = useAuthenticationService();
    const profile = authSvc.getProfile();
    const toggleTheme = () => setPrefs({ darkMode: prefs?.darkMode ? false : true });
    const logout = async () => {
        authSvc.logout();
    };

    return (
        <>
            <IconButton color="inherit" onClick={(e) => toggleMenu(e.currentTarget)}>
                <AccountCircle />
            </IconButton>
            <Popover anchorEl={anchorEl} open={!!anchorEl} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} onClose={onClose}>
                <Paper sx={{ width: 300 }}>
                    <Box p={4}>
                        <Typography variant="h6">
                            {profile.firstName} {profile.lastName}
                        </Typography>
                        <Typography variant="body2">{profile.email}</Typography>
                    </Box>
                    <Divider />
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton dense onClick={toggleTheme}>
                                <Switch checked={prefs.darkMode}></Switch>
                                Switch to {prefs.darkMode ? 'light' : 'dark'} theme
                            </ListItemButton>
                        </ListItem>
                        <Divider />
                        <ListItem disablePadding>
                            <ListItemButton style={{ justifyContent: 'center' }} onClick={logout}>
                                LOG OUT
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Paper>
            </Popover>
        </>
    );
}
