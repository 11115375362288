import { AdminTable, ReadonlyInput, useUrlParams } from '@components';
import { searchBuilder } from '@core';
import { Box, Grid, LinearProgress, Paper, styled } from '@mui/material';
import { GridColDef, GridRowModel } from '@mui/x-data-grid';
import { PlayerItem } from '@services/model';
import { getApiPlayerItemsId, getApiPlayerItemsPlayerItemIdCustomData, postApiPlayerItemsQuery } from '@services/player-items/player-items';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { useMount } from 'react-use';

import { PageBody, PageContent } from '../../shell/layout';
import { AttributeListContents } from '../Items/ItemTokenMetadataContents';
import { titlePageRegistry } from '../PageRegistry';
import { buildMetadataAttributeListColumns } from '../Shared/EntityListService';
import { UpdateType } from '../Shared/EntityModels';

interface InventoryItemDetailProps {
    itemDetail: PlayerItemQueryResult | undefined;
    isLoading: boolean;
    metadataListColumns: GridColDef[];
    handleOnClick: (data: GridRowModel[], updateType: UpdateType) => void;
    playerItem: PlayerItem | undefined;
    attributesInMetadata: GridRowModel[];
    customData: CustomData[];
}

interface PlayerItemQueryResult {
    sellable: boolean;
    transferable: boolean;
    consumable: boolean;
    burnable: boolean;
    tokenName: string;
    itemId: string;
    itemName: string;
    maxSupply: number;
}

export function InventoryItemDetail() {
    const { params } = useUrlParams();
    const [itemDetail, setItemDetail] = useState<PlayerItemQueryResult | undefined>(undefined);
    const [playerItem, setPlayerItem] = useState<PlayerItem>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [attributesInMetadata, setAttributesInMetadata] = useState<GridRowModel[]>([]);
    const [customData, setCustomData] = useState<CustomData[]>([]);

    const metadataListColumns = buildMetadataAttributeListColumns(true);

    const inventoryItemQuery = searchBuilder('PlayerItem')
        .leftJoin('Item', ([pi, i]) => ({ eq: { [pi.itemId]: i.id } }))
        .leftJoin('ItemToken', ([pi, _i, it]) => ({ eq: { [pi.itemId]: it.itemId } }))
        .where(([pi, _i, _it]) => ({ eq: { [pi.nameof.id]: params.id } }))
        .select(([pi, i, it]) => ({
            sellable: it.sellable,
            consumable: i.consumable,
            burnable: it.burnable,
            tokenName: it.tokenName,
            itemId: i.id,
            itemName: i.itemName,
            maxSupply: it.maxSupply
        }))
        .prepare(postApiPlayerItemsQuery);    

    useMount(async () => {
        inventoryItemQuery.execute().then((res) => {
            // Should only ever be one row
            const item = res.items?.[0] as unknown as PlayerItemQueryResult;

            setItemDetail(item);
        });

        var currentPlayerItem = await getApiPlayerItemsId(params.id);
        setPlayerItem(currentPlayerItem);
        setAttributesInMetadata((currentPlayerItem.blockchainMetadata?.attributes?.map((x) => {
            return { ...x, entryId: nanoid() };
        }) ?? []));

        setIsLoading(false);
    });

    useEffect(() => {
        getApiPlayerItemsPlayerItemIdCustomData(params.id)
        .then(response => {
            const result: CustomData[] = [];
            for(let key in response )
            {
                result.push({id: key, value: response[key]});
            }
            setCustomData(result);
        });

    }, []);

    return <InventoryItemDetailPage isLoading={isLoading} itemDetail={itemDetail} metadataListColumns={metadataListColumns}
             handleOnClick={setAttributesInMetadata} playerItem={playerItem} attributesInMetadata={attributesInMetadata} customData={customData} />;
}

const InventoryItemDetailPage = ({ itemDetail, isLoading, metadataListColumns, handleOnClick, playerItem, attributesInMetadata, customData }: InventoryItemDetailProps) => {
    if (itemDetail == undefined) {
        if (isLoading) {
            return <LinearProgress />;
        } else {
            return <h2>Not Found</h2>;
        }
    }
    const hasBlockchainMetadata = !!playerItem?.blockchainMetadata?.name;
    const hasDgoodSerial = playerItem?.dgoodSerial != null && playerItem?.dgoodSerial != undefined; // we should treat 0 as `true` so !! will not work here
    const hasCustomData = customData.length > 0;
    return (
        <PageContent>
            <PageBody>
                <Container>
                    <PageHeader>{itemDetail.itemName}</PageHeader>
                    <Container>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={playerItem?.dgoodSerial ? 6 : 12} alignContent="baseline">
                                <Box component={Paper} p={1}>
                                    <Header>Item Details</Header>
                                    <ReadonlyInput width="full" label="Inventory Item ID" value={playerItem?.id} />
                                    <ReadonlyInput width="full" label="Item ID" value={itemDetail.itemId} />
                                    <ReadonlyInput width="full" label="Instance ID" value={playerItem?.instanceId ?? 'N/A'} />
                                    <ReadonlyInput width="full" label="Item Name" value={itemDetail.itemName} />
                                    <ReadonlyInput width="full" label="Consumable" format="yesno" value={itemDetail.consumable} />
                                    {itemDetail.consumable && <ReadonlyInput width="full" label="Remaining Uses" value={playerItem?.remainingUses} />}
                                    <ReadonlyInput width="full" label="Locked" format="yesno" value={playerItem?.locked} />
                                    <ReadonlyInput width="full" label="Locked By Blockchain" format="yesno" value={playerItem?.lockedByBlockchain} />
                                    <ReadonlyInput width="full" label="Locked Reason" value={playerItem?.lockedReason} />
                                </Box>
                            </Grid>
                            
                            {hasDgoodSerial && (                              
                                <Grid item xs={12} md={6} alignContent="baseline">
                                    <Box component={Paper} p={1}>
                                        <Header>Token Details</Header>
                                        <ReadonlyInput width="full" label="Token Name" value={itemDetail.tokenName} />
                                        <ReadonlyInput width="full" label="Serial Number" value={playerItem.dgoodSerial == 0 ? 'Pending' : playerItem.dgoodSerial} />
                                        <ReadonlyInput width="full" label="Max Supply" value={itemDetail.maxSupply} />
                                        <ReadonlyInput width="full" label="Sellable" format="yesno" value={itemDetail.sellable} />

                                        {/* Disabling because saga does not honor these values
                                        <ReadonlyInput width="full" label="Transferable" format="yesno" value={itemDetail.transferable} /> */}
                                        
                                        <ReadonlyInput width="full" label="Burnable" format="yesno" value={itemDetail.burnable} />
                                        
                                    </Box>
                                </Grid>
                            )}

                            {hasBlockchainMetadata && (
                                
                                    <Grid item xs={8} md={6} alignContent="baseline">
                                        <Box component={Paper} p={1}>
                                            <Header>Metadata</Header>
                                            <ReadonlyInput width="full" label="Name" value={playerItem?.blockchainMetadata?.name} />
                                            <ReadonlyInput width="full" label="Description" value={playerItem?.blockchainMetadata?.description} />
                                            <ReadonlyInput width="full" label="Image Url" value={playerItem?.blockchainMetadata?.image} />
                                            <ReadonlyInput width="full" label="External Url" value={playerItem?.blockchainMetadata?.externalUrl} />
                                            <ReadonlyInput width="full" label="Background Color" value={playerItem?.blockchainMetadata?.backgroundColor} />
                                            <ReadonlyInput width="full" label="Animation Url" value={playerItem?.blockchainMetadata?.animationUrl} />
                                            <ReadonlyInput width="full" label="Youtube Url" value={playerItem?.blockchainMetadata?.youtubeUrl} />
                                        </Box>
                                    </Grid>
                            )}                            

                            {attributesInMetadata.length > 0 && (
                                <Grid item xs={12} md={6} alignContent="baseline">
                                    <Box component={Paper} p={1} >
                                        <Header>Metadata Attributes</Header>
                                        <AttributeListContents columns={metadataListColumns} handleOnClick={handleOnClick} rowsInMetadata={attributesInMetadata} disableEditing={true}/>
                                    </Box>
                            </Grid>
                            )}
                            {
                                <Grid item xs={12} md={12} alignContent="baseline">
                                    <Box component={Paper} p={3} sx={{ height: hasCustomData ? "50vh" : "17vh" }}>
                                        <Header>Custom Data</Header>                                        
                                        {<AdminTable 
                                            disableColumnSelector={true}
                                            resource='Item'
                                            columns={customDataColumns}
                                            data = {customData}
                                        />}
                                    </Box>
                                </Grid>
                            }
                        </Grid>
                    </Container>
                </Container>
            </PageBody>
        </PageContent>
    );
};

type CustomData = {id: string, value: string};
const customDataColumns: GridColDef[] = [
    {
        field: 'id',
        headerName: 'Key',
        type: 'string',
        flex: .25
    },
    {
        field: 'value',
        headerName: 'Value',
        type: 'string',
        flex: 1,
    }
];

const PageHeader = styled('div')`
    font-weight: bold;
    font-size: large;
`;

const Container = styled('div')`
    margin-top: 1rem;
`;

const Header = styled('div')`
    display: block;
    width: 100%;
    border: none;
    color: inherit;
    background: none;
    border-bottom: solid 1px ${(p) => p.theme.palette.grey[500]};
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
`;
titlePageRegistry.register({ page: InventoryItemDetail, path: 'inventory-item-detail', name: 'Inventory Item' });
