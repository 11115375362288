/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.14.2.2422
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ShopTab,
  SaveShopTabRequest,
  ShopTabIPagedResultList,
  EntitySearch,
  ObjectIPagedResultList
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const postApiShopTabs = (
    saveShopTabRequest: SaveShopTabRequest,
 ) => {
      return orvalRequestor<ShopTab>(
      {url: `/api/shop-tabs`, method: 'post',
      data: saveShopTabRequest
    },
      );
    }
  


    export const usePostApiShopTabs = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiShopTabs>, TError,{data: SaveShopTabRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiShopTabs>, {data: SaveShopTabRequest}> = (props) => {
          const {data} = props || {};

          return  postApiShopTabs(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiShopTabs>, TError, {data: SaveShopTabRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const putApiShopTabsId = (
    id: string,
    saveShopTabRequest: SaveShopTabRequest,
 ) => {
      return orvalRequestor<ShopTab>(
      {url: `/api/shop-tabs/${id}`, method: 'put',
      data: saveShopTabRequest
    },
      );
    }
  


    export const usePutApiShopTabsId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiShopTabsId>, TError,{id: string;data: SaveShopTabRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiShopTabsId>, {id: string;data: SaveShopTabRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiShopTabsId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiShopTabsId>, TError, {id: string;data: SaveShopTabRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const deleteApiShopTabsId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/shop-tabs/${id}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiShopTabsId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiShopTabsId>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiShopTabsId>, {id: string}> = (props) => {
          const {id} = props || {};

          return  deleteApiShopTabsId(id,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiShopTabsId>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiShopTabsId = (
    id: string,
 ) => {
      return orvalRequestor<ShopTab>(
      {url: `/api/shop-tabs/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiShopTabsIdQueryKey = (id: string,) => [`/api/shop-tabs/${id}`];

    
export const useGetApiShopTabsId = <TData = AsyncReturnType<typeof getApiShopTabsId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiShopTabsId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiShopTabsIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiShopTabsId>> = () => getApiShopTabsId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiShopTabsId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const postApiShopTabsFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ShopTabIPagedResultList>(
      {url: `/api/shop-tabs/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiShopTabsFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiShopTabsFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiShopTabsFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiShopTabsFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiShopTabsFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiShopTabsQuery = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ObjectIPagedResultList>(
      {url: `/api/shop-tabs/query`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiShopTabsQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiShopTabsQuery>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiShopTabsQuery>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiShopTabsQuery(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiShopTabsQuery>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    