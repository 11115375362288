import { Button, Grid } from '@mui/material';
import type { ObjectFieldTemplateProps } from '@rjsf/core';
import { utils } from '@rjsf/core';

const { canExpand } = utils;
export const ObjectFieldTemplate = ({
    DescriptionField,
    description,
    TitleField,
    title,
    properties,
    required,
    disabled,
    readonly,
    uiSchema,
    idSchema,
    schema,
    formData,
    onAddClick,
}: ObjectFieldTemplateProps) => (
    <>
        {(uiSchema['ui:title'] || title) && <TitleField id={`${idSchema.$id}-title`} title={title} required={required} />}
        {description && <DescriptionField id={`${idSchema.$id}-description`} description={description} />}
        <Grid container={true} spacing={2}>
            {properties.map((element, index) =>
                // Remove the <Grid> if the inner element is hidden as the <Grid>
                // itself would otherwise still take up space.
                element.hidden ? (
                    element.content
                ) : (
                    <Grid item={true} xs={12} key={index} style={{ marginBottom: '10px' }}>
                        {element.content}
                    </Grid>
                )
            )}
            {canExpand(schema, uiSchema, formData) && (
                <Grid container>
                    <Grid item={true}>
                        <Button onClick={onAddClick(schema)} disabled={disabled || readonly} />
                    </Grid>
                </Grid>
            )}
        </Grid>
    </>
);
