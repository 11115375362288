import { ShellService } from '@core';
import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { inject, Lifecycle, scoped } from 'tsyringe';

import type { RouteEndpoint } from './RegistryPageRenderer';
import { RouteEndpointDiToken, RouteSerializer } from './RegistryPageRenderer';

@scoped(Lifecycle.ContainerScoped)
export class RegistryNavigator {
    public static history: { push: (path: string) => void; replace: (path: string) => void };

    private readonly serializer = new RouteSerializer();
    public constructor(
        @inject(RouteEndpointDiToken) private readonly endpoint: (() => RouteEndpoint) | undefined,
        @inject(ShellService) private readonly shellSvc: ShellService
    ) {}

    /**
     * Go up to the parent page, i.e., pop the breadcrumb stack
     */
    public ascend = () => {
        this.go(this.getAscendUrl());
    };
    public getAscendUrl = () => {
        return this.endpoint?.()?.parent?.path || this.shellSvc.getSiteRootPath();
    };

    /**
     * Drill into a deeper page, i.e., push to the breadcrumb stack
     * @param name Page path as registered in the page registry
     * @param params Params for page
     */
    public descend = (name: string, params?: Record<string, string>) => {
        this.go(this.getDescendUrl(name, params));
    };
    public getDescendUrl = (name: string, params?: Record<string, string>) => {
        const path = this.serializer.serialize({ name, params });
        return `${this.endpoint?.()?.path || this.shellSvc.getSiteRootPath()}/${path}`;
    };

    /**
     * Replace the current page with a different page, i.e., pop and push the breadcrumb stack
     * @param name Page path as registered in the page registry
     * @param params Params for page
     */
    public move = (name: string, params?: Record<string, string>) => {
        this.go(this.getMoveUrl(name, params));
    };
    public getMoveUrl = (name: string, params?: Record<string, string>) => {
        const path = this.serializer.serialize({ name, params });
        return `${this.getAscendUrl()}/${path}`;
    };

    /**
     * Clear and replace all params
     * @param params params to set
     */
    public setParams = (params: Record<string, string>) => {
        this.go(this.getSetParamsUrl(params));
    };

    /**
     * Add or update existing params
     * @param params params to set
     */
    public updateParams = (params: Record<string, string>) => {
        this.setParams({ ...this.getParams(), ...params });
    };

    /**
     * Get params from route segment
     * @returns params from route segment
     */
    public getParams = () => {
        return this.endpoint?.()?.params;
    };

    public getSetParamsUrl = (params: Record<string, string>) => {
        return this.getMoveUrl(this.endpoint?.()?.name || this.shellSvc.getSiteRootPath(), params);
    };

    public getEndpoint = () => {
        return this.endpoint?.();
    };

    public serialize(name: string, params?: Record<string, string>) {
        return this.serializer.serialize({ name, params });
    }

    /**
     * Go to a page, replace the entire breadcrumb stack
     * @param path full path to navigate to
     */
    public go = (path: string) => {
        RegistryNavigator.history.push(path);
    };

    public replace = (path: string) => {
        RegistryNavigator.history.replace(path);
    };
}

export function NavInitializer() {
    RegistryNavigator.history = useHistory();
    return <Fragment></Fragment>;
}
