import type { SiteConfig, SiteTheme, SocialLinkUrls } from '@core';
import { Box } from '@mui/material';

import { TextInput } from './Forms';

export function TitleSocialLinksForm(props: { socialLinks: SocialLinkUrls; onChange: (value: SocialLinkUrls) => void }) {
    const onChange = (change: Partial<SocialLinkUrls>) => {
        const newSocialLink = { ...props.socialLinks, ...change };
        props.onChange(newSocialLink);
    };
    return (
        <>
            <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                <TextInput
                    label="Discord URL"
                    value={props.socialLinks?.discord || ''}
                    onChange={(link) => onChange({ discord: link || null })}
                />
                <TextInput
                    label="Facebook URL"
                    value={props.socialLinks?.facebook || ''}
                    onChange={(link) => onChange({ facebook: link || null })}
                />
                <TextInput
                    label="Instagram URL"
                    value={props.socialLinks?.instagram || ''}
                    onChange={(link) => onChange({ instagram: link || null })}
                />
                <TextInput
                    label="Twitter URL"
                    value={props.socialLinks?.twitter || ''}
                    onChange={(link) => onChange({ twitter: link || null })}
                />
                <TextInput
                    label="YouTube URL"
                    value={props.socialLinks?.youTube || ''}
                    onChange={(link) => onChange({ youTube: link || null })}
                />
            </Box>
        </>
    );
}
