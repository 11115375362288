/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.14.2.2422
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ShopTabVirtualCurrency,
  SaveShopTabVirtualCurrencyRequest,
  ShopTabVirtualCurrencyIPagedResultList,
  EntitySearch,
  ObjectIPagedResultList
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const postApiShopTabVirtualCurrency = (
    saveShopTabVirtualCurrencyRequest: SaveShopTabVirtualCurrencyRequest,
 ) => {
      return orvalRequestor<ShopTabVirtualCurrency>(
      {url: `/api/shop-tab-virtual-currency`, method: 'post',
      data: saveShopTabVirtualCurrencyRequest
    },
      );
    }
  


    export const usePostApiShopTabVirtualCurrency = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiShopTabVirtualCurrency>, TError,{data: SaveShopTabVirtualCurrencyRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiShopTabVirtualCurrency>, {data: SaveShopTabVirtualCurrencyRequest}> = (props) => {
          const {data} = props || {};

          return  postApiShopTabVirtualCurrency(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiShopTabVirtualCurrency>, TError, {data: SaveShopTabVirtualCurrencyRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const putApiShopTabVirtualCurrencyId = (
    id: string,
    saveShopTabVirtualCurrencyRequest: SaveShopTabVirtualCurrencyRequest,
 ) => {
      return orvalRequestor<ShopTabVirtualCurrency>(
      {url: `/api/shop-tab-virtual-currency/${id}`, method: 'put',
      data: saveShopTabVirtualCurrencyRequest
    },
      );
    }
  


    export const usePutApiShopTabVirtualCurrencyId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiShopTabVirtualCurrencyId>, TError,{id: string;data: SaveShopTabVirtualCurrencyRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiShopTabVirtualCurrencyId>, {id: string;data: SaveShopTabVirtualCurrencyRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiShopTabVirtualCurrencyId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiShopTabVirtualCurrencyId>, TError, {id: string;data: SaveShopTabVirtualCurrencyRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const deleteApiShopTabVirtualCurrencyId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/shop-tab-virtual-currency/${id}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiShopTabVirtualCurrencyId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiShopTabVirtualCurrencyId>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiShopTabVirtualCurrencyId>, {id: string}> = (props) => {
          const {id} = props || {};

          return  deleteApiShopTabVirtualCurrencyId(id,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiShopTabVirtualCurrencyId>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiShopTabVirtualCurrencyId = (
    id: string,
 ) => {
      return orvalRequestor<ShopTabVirtualCurrency>(
      {url: `/api/shop-tab-virtual-currency/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiShopTabVirtualCurrencyIdQueryKey = (id: string,) => [`/api/shop-tab-virtual-currency/${id}`];

    
export const useGetApiShopTabVirtualCurrencyId = <TData = AsyncReturnType<typeof getApiShopTabVirtualCurrencyId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiShopTabVirtualCurrencyId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiShopTabVirtualCurrencyIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiShopTabVirtualCurrencyId>> = () => getApiShopTabVirtualCurrencyId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiShopTabVirtualCurrencyId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const postApiShopTabVirtualCurrencyFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ShopTabVirtualCurrencyIPagedResultList>(
      {url: `/api/shop-tab-virtual-currency/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiShopTabVirtualCurrencyFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiShopTabVirtualCurrencyFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiShopTabVirtualCurrencyFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiShopTabVirtualCurrencyFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiShopTabVirtualCurrencyFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiShopTabVirtualCurrencyQuery = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ObjectIPagedResultList>(
      {url: `/api/shop-tab-virtual-currency/query`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiShopTabVirtualCurrencyQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiShopTabVirtualCurrencyQuery>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiShopTabVirtualCurrencyQuery>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiShopTabVirtualCurrencyQuery(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiShopTabVirtualCurrencyQuery>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    