/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.14.2.2422
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  DropTableAggregateViewModel,
  SaveDropTableRequest,
  DropTableAggregateViewModelIPagedResultList,
  GetApiDropTablesAggregateParams,
  InventoryContainerIPagedResultList,
  GetApiDropTablesParams,
  InventoryContainer,
  SaveInventoryContainerRequest,
  EntitySearch,
  ObjectIPagedResultList
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const getApiDropTablesAggregateId = (
    id: string,
 ) => {
      return orvalRequestor<DropTableAggregateViewModel>(
      {url: `/api/drop-tables/aggregate/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiDropTablesAggregateIdQueryKey = (id: string,) => [`/api/drop-tables/aggregate/${id}`];

    
export const useGetApiDropTablesAggregateId = <TData = AsyncReturnType<typeof getApiDropTablesAggregateId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiDropTablesAggregateId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiDropTablesAggregateIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiDropTablesAggregateId>> = () => getApiDropTablesAggregateId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiDropTablesAggregateId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const putApiDropTablesAggregateId = (
    id: string,
    saveDropTableRequest: SaveDropTableRequest,
 ) => {
      return orvalRequestor<DropTableAggregateViewModel>(
      {url: `/api/drop-tables/aggregate/${id}`, method: 'put',
      data: saveDropTableRequest
    },
      );
    }
  


    export const usePutApiDropTablesAggregateId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiDropTablesAggregateId>, TError,{id: string;data: SaveDropTableRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiDropTablesAggregateId>, {id: string;data: SaveDropTableRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiDropTablesAggregateId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiDropTablesAggregateId>, TError, {id: string;data: SaveDropTableRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiDropTablesAggregate = (
    params?: GetApiDropTablesAggregateParams,
 ) => {
      return orvalRequestor<DropTableAggregateViewModelIPagedResultList>(
      {url: `/api/drop-tables/aggregate`, method: 'get',
        params,
    },
      );
    }
  

export const getGetApiDropTablesAggregateQueryKey = (params?: GetApiDropTablesAggregateParams,) => [`/api/drop-tables/aggregate`, ...(params ? [params]: [])];

    
export const useGetApiDropTablesAggregate = <TData = AsyncReturnType<typeof getApiDropTablesAggregate>, TError = unknown>(
 params?: GetApiDropTablesAggregateParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiDropTablesAggregate>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiDropTablesAggregateQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiDropTablesAggregate>> = () => getApiDropTablesAggregate(params, );

  const query = useQuery<AsyncReturnType<typeof getApiDropTablesAggregate>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const postApiDropTablesAggregate = (
    saveDropTableRequest: SaveDropTableRequest,
 ) => {
      return orvalRequestor<DropTableAggregateViewModel>(
      {url: `/api/drop-tables/aggregate`, method: 'post',
      data: saveDropTableRequest
    },
      );
    }
  


    export const usePostApiDropTablesAggregate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiDropTablesAggregate>, TError,{data: SaveDropTableRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiDropTablesAggregate>, {data: SaveDropTableRequest}> = (props) => {
          const {data} = props || {};

          return  postApiDropTablesAggregate(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiDropTablesAggregate>, TError, {data: SaveDropTableRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiDropTables = (
    params?: GetApiDropTablesParams,
 ) => {
      return orvalRequestor<InventoryContainerIPagedResultList>(
      {url: `/api/drop-tables`, method: 'get',
        params,
    },
      );
    }
  

export const getGetApiDropTablesQueryKey = (params?: GetApiDropTablesParams,) => [`/api/drop-tables`, ...(params ? [params]: [])];

    
export const useGetApiDropTables = <TData = AsyncReturnType<typeof getApiDropTables>, TError = unknown>(
 params?: GetApiDropTablesParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiDropTables>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiDropTablesQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiDropTables>> = () => getApiDropTables(params, );

  const query = useQuery<AsyncReturnType<typeof getApiDropTables>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const postApiDropTables = (
    saveInventoryContainerRequest: SaveInventoryContainerRequest,
 ) => {
      return orvalRequestor<InventoryContainer>(
      {url: `/api/drop-tables`, method: 'post',
      data: saveInventoryContainerRequest
    },
      );
    }
  


    export const usePostApiDropTables = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiDropTables>, TError,{data: SaveInventoryContainerRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiDropTables>, {data: SaveInventoryContainerRequest}> = (props) => {
          const {data} = props || {};

          return  postApiDropTables(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiDropTables>, TError, {data: SaveInventoryContainerRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiDropTablesId = (
    id: string,
 ) => {
      return orvalRequestor<InventoryContainer>(
      {url: `/api/drop-tables/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiDropTablesIdQueryKey = (id: string,) => [`/api/drop-tables/${id}`];

    
export const useGetApiDropTablesId = <TData = AsyncReturnType<typeof getApiDropTablesId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiDropTablesId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiDropTablesIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiDropTablesId>> = () => getApiDropTablesId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiDropTablesId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const putApiDropTablesId = (
    id: string,
    saveInventoryContainerRequest: SaveInventoryContainerRequest,
 ) => {
      return orvalRequestor<InventoryContainer>(
      {url: `/api/drop-tables/${id}`, method: 'put',
      data: saveInventoryContainerRequest
    },
      );
    }
  


    export const usePutApiDropTablesId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiDropTablesId>, TError,{id: string;data: SaveInventoryContainerRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiDropTablesId>, {id: string;data: SaveInventoryContainerRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiDropTablesId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiDropTablesId>, TError, {id: string;data: SaveInventoryContainerRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const deleteApiDropTablesId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/drop-tables/${id}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiDropTablesId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiDropTablesId>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiDropTablesId>, {id: string}> = (props) => {
          const {id} = props || {};

          return  deleteApiDropTablesId(id,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiDropTablesId>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiDropTablesFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<InventoryContainerIPagedResultList>(
      {url: `/api/drop-tables/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiDropTablesFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiDropTablesFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiDropTablesFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiDropTablesFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiDropTablesFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiDropTablesQuery = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ObjectIPagedResultList>(
      {url: `/api/drop-tables/query`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiDropTablesQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiDropTablesQuery>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiDropTablesQuery>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiDropTablesQuery(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiDropTablesQuery>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    