import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

export function buildEntityListColumns(entityName: string): GridColDef[] {
    return [
        {
            field: 'id',
            headerName: 'Id',
            type: 'uuid',
            hide: true,
        },
        {
            field: 'displayName',
            headerName: `${entityName} Name`,
            type: 'string',
            flex: 1,
        }
    ]
}

export function buildEntityListContentsColumns(entityName: string): GridColDef[] {
    return [
        {
            field: 'id',
            headerName: 'Id',
            type: 'uuid',
            hide: true,
        },
        {
            field: 'displayName',
            headerName: `${entityName} Name`,
            type: 'string',
            flex: 1,
        },
        {
            field: 'count',
            headerName: 'Count',
            type: 'string',
            flex: 1,
        }
    ]
}

function displayNameRenderer (cellValues: GridRenderCellParams) {
    const content: string = cellValues?.value?.toString() ?? '';
    return <span title={content}>{content}</span>;
}

function percentageRenderer (cellValues: GridRenderCellParams) {
    let percentageNumber = cellValues?.value as number;
    percentageNumber = Math.round(percentageNumber * 10)/10;
    const content = percentageNumber.toString();
    return <span title={`${content}% chance to be picked`}>{content}%</span>;
}

export function buildDropTableEntityListContentsColumns(entityName: string): GridColDef[] {
    var result = [
        {
            field: 'id',
            headerName: 'Id',
            type: 'uuid',
            hide: true,
        },
        {
            field: 'displayName',
            headerName: `${entityName} Name`,
            type: 'string',
            flex: 3,
            renderCell: displayNameRenderer,
        },
        {
            field: 'weight',
            headerName: 'Weight',
            type: 'string',
            flex: 1,
        },
        {
            field: 'percentage',
            headerName: 'Pick,%',
            flex: 0.5,
            renderCell: percentageRenderer,
        }
    ]

    if (entityName == 'Item') {
        result.push({
            field: 'maxCount',
            headerName: 'Max Count',
            type: 'string',
            flex: 1,
        },
        {
            field: 'countAvailable',
            headerName: 'Count Available',
            type: 'string',
            flex: 1
        });
    }

    return result;
}

export function buildMetadataAttributeListColumns(small: boolean) : GridColDef[] {
    return [
        {
            field: 'entryId',
            headerName: 'Id',
            type: 'uuid',
            hide: true,
        },
        {
            field: 'value',
            headerName: 'Value',
            type: 'string',
            flex: small ? 1 : 2
        },
        {
            field: 'traitType',
            headerName: 'Trait Type',
            type: 'string',
            flex: small ? 1 : 2
        },
        {
            field: 'displayType',
            headerName: 'Display Type',
            type: 'string',
            flex: 1,
        },
        {
            field: 'maxValue',
            headerName: 'Max Value',
            type: 'string',
            flex: 1,
        }
    ]
}
