/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.14.2.2422
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  PlayerVirtualCurrency,
  AdjustSingleCurrencyRequest
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const postApiPlayerVirtualCurrencyPlayerCurrencyAdjustment = (
    adjustSingleCurrencyRequest: AdjustSingleCurrencyRequest,
 ) => {
      return orvalRequestor<PlayerVirtualCurrency>(
      {url: `/api/player-virtual-currency/player-currency-adjustment`, method: 'post',
      data: adjustSingleCurrencyRequest
    },
      );
    }
  


    export const usePostApiPlayerVirtualCurrencyPlayerCurrencyAdjustment = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiPlayerVirtualCurrencyPlayerCurrencyAdjustment>, TError,{data: AdjustSingleCurrencyRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiPlayerVirtualCurrencyPlayerCurrencyAdjustment>, {data: AdjustSingleCurrencyRequest}> = (props) => {
          const {data} = props || {};

          return  postApiPlayerVirtualCurrencyPlayerCurrencyAdjustment(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiPlayerVirtualCurrencyPlayerCurrencyAdjustment>, TError, {data: AdjustSingleCurrencyRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiPlayerVirtualCurrencyId = (
    id: string,
 ) => {
      return orvalRequestor<PlayerVirtualCurrency>(
      {url: `/api/player-virtual-currency/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiPlayerVirtualCurrencyIdQueryKey = (id: string,) => [`/api/player-virtual-currency/${id}`];

    
export const useGetApiPlayerVirtualCurrencyId = <TData = AsyncReturnType<typeof getApiPlayerVirtualCurrencyId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiPlayerVirtualCurrencyId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiPlayerVirtualCurrencyIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiPlayerVirtualCurrencyId>> = () => getApiPlayerVirtualCurrencyId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiPlayerVirtualCurrencyId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

