import { CustomData } from '@components';
import { LinearProgress } from '@mui/material';
import { postApiItemsCustomDataFindBy, useDeleteApiItemsCustomDataId, usePostApiItemsCustomData } from '@services/items-custom-data/items-custom-data';
import { useGetApiItemsId } from '@services/items/items';
import type { CustomData as CustomDataModel, CustomDataIPagedResultList, EntitySearch } from '@services/model';
import { PageBody, PageContent } from 'features/shell/layout';

export const ItemData = ({ id }: { id: string }) => {
    const { data: item } = useGetApiItemsId(id);
    const { mutateAsync: remove } = useDeleteApiItemsCustomDataId();
    const { mutateAsync: addCustomData } = usePostApiItemsCustomData();

    async function handleFindBy(data: EntitySearch) : Promise<CustomDataIPagedResultList> {
        return await postApiItemsCustomDataFindBy(data);
    }
    
    async function handleSave(data: { data: CustomDataModel }) : Promise<CustomDataModel> {
        return await addCustomData(data);
    }

    async function handleDelete(data: {id: string}) {
        await remove(data);
    }
    
    return !item ? (
        <LinearProgress />
    ) : (
        <PageContent>
            <PageBody noPadding>
                <CustomData
                    type="Item"
                    entityId={id}
                    resource="Item"
                    onFindBy={handleFindBy}
                    onSave={handleSave}
                    onDelete={handleDelete}
                />
            </PageBody>
        </PageContent>
    );
};
