import { FormSection } from '@components';
import { Box, CircularProgress, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import type { BulkPlayerOperationStatus } from '@services/model';

interface BulkResultsProps {
    statuses: BulkPlayerOperationStatus[];
    header: string;
    subheader: string;
}

export function BulkResults({ header, statuses, subheader }: BulkResultsProps) {
    return (
        <Grid item container xs={12} spacing={2}>
            <FormSection header={header} subheader={subheader}>
                <Box height={500} overflow={'scroll'}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Success</TableCell>
                                    <TableCell>Error Message</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {statuses.map((status, index) => {
                                    return (
                                        <TableRow hover key={`${status.email}-${index}`}>
                                            <TableCell>{status.email}</TableCell>
                                            {/* <TableCell>{status.success === undefined ? '-' : status.success.toString()}</TableCell> */}
                                            {status.success === undefined && (
                                                <TableCell>
                                                    <CircularProgress size={15} />
                                                </TableCell>
                                            )}
                                            {status.success !== undefined && <TableCell>{status.success.toString()}</TableCell>}
                                            <TableCell>{status.errorMessage}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </FormSection>
        </Grid>
    );
}
