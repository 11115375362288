/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.14.2.2422
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  Role,
  SaveRoleRequest,
  RoleIPagedResultList,
  EntitySearch,
  ObjectIPagedResultList
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const getApiRoleId = (
    id: string,
 ) => {
      return orvalRequestor<Role>(
      {url: `/api/role/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiRoleIdQueryKey = (id: string,) => [`/api/role/${id}`];

    
export const useGetApiRoleId = <TData = AsyncReturnType<typeof getApiRoleId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiRoleId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiRoleIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiRoleId>> = () => getApiRoleId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiRoleId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const putApiRoleId = (
    id: string,
    saveRoleRequest: SaveRoleRequest,
 ) => {
      return orvalRequestor<Role>(
      {url: `/api/role/${id}`, method: 'put',
      data: saveRoleRequest
    },
      );
    }
  


    export const usePutApiRoleId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiRoleId>, TError,{id: string;data: SaveRoleRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiRoleId>, {id: string;data: SaveRoleRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiRoleId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiRoleId>, TError, {id: string;data: SaveRoleRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const deleteApiRoleId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/role/${id}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiRoleId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiRoleId>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiRoleId>, {id: string}> = (props) => {
          const {id} = props || {};

          return  deleteApiRoleId(id,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiRoleId>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiRoleFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<RoleIPagedResultList>(
      {url: `/api/role/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiRoleFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiRoleFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiRoleFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiRoleFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiRoleFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiRoleQuery = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ObjectIPagedResultList>(
      {url: `/api/role/query`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiRoleQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiRoleQuery>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiRoleQuery>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiRoleQuery(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiRoleQuery>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiRole = (
    saveRoleRequest: SaveRoleRequest,
 ) => {
      return orvalRequestor<Role>(
      {url: `/api/role`, method: 'post',
      data: saveRoleRequest
    },
      );
    }
  


    export const usePostApiRole = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiRole>, TError,{data: SaveRoleRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiRole>, {data: SaveRoleRequest}> = (props) => {
          const {data} = props || {};

          return  postApiRole(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiRole>, TError, {data: SaveRoleRequest}, TContext>(mutationFn, mutationOptions)
    }
    