import 'react-toastify/dist/ReactToastify.css';

import type { Theme } from '@mui/material';
import { css, GlobalStyles, ThemeContext } from '@mui/styled-engine';
import type { ReactNode } from 'react';
import { Flip, toast, ToastContainer } from 'react-toastify';

interface NotificationOptions {
    content: ReactNode;
    expireMs?: number;
    type?: 'info' | 'warning' | 'error' | 'success';
}
export const notify = (options: NotificationOptions) => {
    toast(options.content, { autoClose: options.expireMs, type: options.type || 'default', style: { minHeight: 'auto' } });
};

export function NotificationProvider() {
    return (
        <ThemeContext.Consumer>
            {(theme) => (
                <>
                    <GlobalStyles styles={overrideToastStyles(theme as Theme)} />
                    <ToastContainer
                        position="bottom-right"
                        progressStyle={{ background: '#8888' }}
                        closeOnClick={false}
                        transition={Flip}
                        theme="colored"
                    />
                </>
            )}
        </ThemeContext.Consumer>
    );
}
const overrideToastStyles = (theme: Theme) => css`
    :root {
        --toastify-color-success: ${theme.palette.success.main};
        --toastify-color-info: ${theme.palette.info.main};
        --toastify-color-warning: ${theme.palette.warning.main};
        --toastify-color-error: ${theme.palette.error.main};
    }
`;
