/* eslint-disable prettier/prettier */
import { usePermissions } from '@core';
import { Box, Divider, ListItemIcon, MenuItem } from '@mui/material';
import type { GridRowModel } from '@mui/x-data-grid';

import type { RowMenuAction, RowMenuActionButton } from '.';

interface RowMenuItemProps<T> extends GridRowModel {
    item: T;
    action: RowMenuAction<T>;
    onClick: (item: T, action: RowMenuActionButton<T>) => void;
}

export function RowMenuItem<T>({ item, action, onClick }: RowMenuItemProps<T>) {
    const { canDelete, canUpdate } = usePermissions();
    switch (action.type) {
        case 'button':
            if (action.label === 'Delete') {
                return (!canDelete) ? null : (
                    <MenuItem onClick={() => onClick(item, action)} disabled={action.disabled && action.disabled(item)}>
                        <ListItemIcon>
                            <action.icon fontSize="small" />
                        </ListItemIcon>
                        {action.label}
                    </MenuItem>);
            }
            if (action.label === 'Edit') {
                return (!canUpdate) ? null : (
                    <MenuItem onClick={() => onClick(item, action)} disabled={action.disabled && action.disabled(item)}>
                        <ListItemIcon>
                            <action.icon fontSize="small" />
                        </ListItemIcon>
                        {action.label}
                    </MenuItem>);
            }
            return (
                <MenuItem onClick={() => onClick(item, action)} disabled={action.disabled && action.disabled(item)}>
                    <ListItemIcon>
                        <action.icon fontSize="small" />
                    </ListItemIcon>
                    {action.label}
                </MenuItem>
            );
        case 'divider':
            return (
                <Box my={1}>
                    <Divider />
                </Box>
            );
        case 'link':
            return (
                <MenuItem component="a" href={action.href}>
                    <ListItemIcon>
                        <action.icon fontSize="small" />
                    </ListItemIcon>
                    {action.label}
                </MenuItem>
            );
        default:
            return null;
    }
}
