import { modal } from '@components';
import type { FusionAuthAuthenticationService } from '@core';
import { useAppConfig, useAuthenticationService } from '@core';
import styled from '@emotion/styled';
import { Alert, Button, DialogTitle, Typography } from '@mui/material';
import { Toolbar } from 'features/shell/layout';
import { useState } from 'react';
import { useMount } from 'react-use';
import tinycolor from 'tinycolor2';

function Login(props: { close: () => void; onCancel: () => void }) {
    const appConfig = useAppConfig();
    const onSubmit = async () => {
        const query = new URLSearchParams();
        query.append('route', `${window.location.origin}/close`);
        const url = `${appConfig.apis.mythicalFusionAuth.loginProxyUrl}?${query.toString()}`;
        window.open(url, '_blank');
    };
    const handleKeypress = (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === 'Enter') {
            onSubmit();
        }
    };
    const handleCancel = () => {
        props.onCancel();
        props.close();
    };

    return (
        <>
            <LoginBody onKeyPress={handleKeypress}>
                <Alert severity="error">Either your session has expired or you are not authorized.</Alert>
                <Typography my={2} variant="body1">
                    Try signing in again. A new window will open. Once you have signed back in, your request will be retried.
                </Typography>
                <Toolbar>
                    <Button variant="contained" onClick={onSubmit}>
                        Sign In
                    </Button>
                    <Button onClick={handleCancel}>Cancel</Button>
                </Toolbar>
            </LoginBody>
        </>
    );
}

const LoginBody = styled.div`
    margin-top: 2rem;
    margin-bottom: -1rem;
    width: 300px;
`;

export function FusionAuthLogin() {
    const authSvc = useAuthenticationService() as FusionAuthAuthenticationService;

    const [showBg, setShowBg] = useState(false);
    useMount(() => {
        authSvc.onCredentialsNeeded.listen(({ cancel, wait }) => {
            setShowBg(true);
            modal(<LoginHeader>Not Authorized</LoginHeader>, (closer) => {
                const loginCloser = () => {
                    closer();
                    setShowBg(false);
                };
                wait().then(loginCloser);
                return <Login close={loginCloser} onCancel={cancel} />;
            });
        });
    });
    return <></>;
}

const LoginHeader = styled(DialogTitle)`
    background: ${(p) => p.theme.darkBg};
    color: ${(p) => tinycolor.mostReadable(p.theme.darkBg, ['#000', '#fff']).toHexString()};
`;
