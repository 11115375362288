import { DiContext } from '@core';
import styled from '@emotion/styled';
import type { DependencyContainer } from 'tsyringe';
import { container, singleton } from 'tsyringe';

import { useFullRoute } from './hooks';
import type { IPageRegistry } from './PageRegistry';

export const RouteEndpointDiToken = Symbol();
interface ParsedRouteEndpoint {
    name: string;
    params: Record<string, string>;
    path: string;
    pathWithoutParams: string;
    fullPath: string;
    depth: number;
    parent: RouteEndpoint;
}
export interface RouteEndpoint extends ParsedRouteEndpoint {
    registry: IPageRegistry;
    container: DependencyContainer;
}
container.register(RouteEndpointDiToken, { useValue: () => ({}) });

@singleton()
export class RouteManager {
    private endpoints: Readonly<RouteEndpoint[]> = [];
    private serializer = new RouteSerializer();
    private containers = new Map<string, DependencyContainer>();
    private containerEndpoints = new WeakMap<DependencyContainer, RouteEndpoint>();

    public getEndpoints() {
        return this.endpoints;
    }

    public reload(path: string, root: string, registry: IPageRegistry, container: DependencyContainer) {
        const endpoints = this.serializer.deserialize(path, root);
        const prevContainers = this.containers;
        this.containers = new Map<string, DependencyContainer>();
        let parent = container;
        this.endpoints = endpoints.map((e) => {
            container = prevContainers.get(e.pathWithoutParams) ?? this.createChildContainer(parent);
            const endpoint = {
                ...e,
                container,
                registry,
            };
            this.containers.set(endpoint.pathWithoutParams, endpoint.container);
            this.containerEndpoints.set(container, endpoint);
            parent = container;
            return endpoint;
        });
    }

    private createChildContainer(parent: DependencyContainer) {
        const result = parent.createChildContainer();
        result.register(RouteEndpointDiToken, { useValue: () => this.containerEndpoints.get(result)! });
        return result;
    }
}

export class RouteSerializer {
    public serialize(endpoint: { name: string; params?: Record<string, string> }) {
        return `${endpoint.name}${this.writeParams(endpoint.params)}`;
    }

    private writeParams(params?: Record<string, string>) {
        const query = new URLSearchParams();

        if (params) {
            for (const key of Object.keys(params)) {
                query.set(key, params[key]);
            }
            return `@${query.toString()}`;
        }
        return '';
    }

    public deserialize(path: string, root: string) {
        return this.parsePath(path, root);
    }

    private parsePath(path: string, root: string) {
        const result: ParsedRouteEndpoint[] = [];
        const unrootedPath = path.substring(root.length);
        const segments = unrootedPath.split('/').slice(1);

        let depth = 0;
        let pathSoFar = root;
        let pathWithoutParams = '';
        for (const segment of segments) {
            const { name, params } = this.parseSegment(segment);
            pathSoFar += `/${segment}`;
            pathWithoutParams += `/${name}`;
            result.push({
                name,
                params,
                parent: result[depth - 1] as RouteEndpoint,
                depth,
                path: pathSoFar,
                fullPath: path,
                pathWithoutParams,
            });
            depth++;
        }

        return result;
    }

    private parseSegment(segment: string) {
        const [name, rawParams] = segment.split('@');
        return { name, params: this.parseParams(rawParams) };
    }

    private parseParams(rawParams: string | undefined) {
        const result: Record<string, string> = {};
        if (rawParams) {
            const parsed = new URLSearchParams(rawParams);
            parsed.sort();
            parsed.forEach((value, key) => {
                result[key] = value;
            });
        }
        return result;
    }
}

function RegistryRouteEntry(props: { endpoint: RouteEndpoint }) {
    const Page = props.endpoint.registry.getPage(props.endpoint.name)?.page;
    return Page ? (
        <DiContext.Provider value={props.endpoint.container}>
            <PageWrap>
                <Page />
            </PageWrap>
        </DiContext.Provider>
    ) : null;
}

export function RegistryPageRenderer(props: { registry: IPageRegistry }) {
    const routeEndpoints = useFullRoute(props.registry);

    return (
        <>
            {routeEndpoints.map((e) => {
                return <RegistryRouteEntry key={e.pathWithoutParams} endpoint={e} />;
            })}
        </>
    );
}

const PageWrap = styled.div`
    height: 100%;
    display: none;
    flex: 1 1 auto;
    overflow: auto;
    &:last-child {
        display: block;
    }
`;
