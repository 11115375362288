import { Event, useDi } from '@core';
import { useEffect } from 'react';
import { singleton } from 'tsyringe';
import { v4 as uuidv4 } from 'uuid';

import type { RequestOptions } from './GameServiceApiBase';

@singleton()
export class DataChangeService {
    private changed = new Event<RequestOptions>();
    public publish(request: RequestOptions) {
        setTimeout(() => this.changed.raise(request), 0);
    }

    public listen(listener: (request: RequestOptions) => void) {
        return this.changed.listen(listener);
    }
}

/**
 * Listen to API post, put, and delete events for a given resource
 * @param resource name of type to listen for changes
 * @param handler callback to be called when changes occur
 */
export function useApiListener(resource: string, handler: (request: RequestInit) => void) {
    const changeSvc = useDi(DataChangeService);
    useEffect(
        () =>
            changeSvc.listen((request) => {
                if (request.method === 'post' || request.method === 'put' || request.method === 'delete') {
                    if (
                        request.url.replace(/-/g, '').toLowerCase().startsWith(`/api/${resource.toLowerCase()}`) &&
                        request.url.toLowerCase().indexOf('/find') < 0 &&
                        request.url.toLowerCase().indexOf('/query') < 0
                    ) {
                        handler(request);
                    }
                }
            }).dispose,
        [changeSvc, resource, handler]
    );
}

export class EntityChanges<T extends { id?: string | null }> {
    private toSave = new Set<T>();
    private toDelete = new Set<T>();
    public constructor(private changeListener: () => void, private createId: () => string) {}

    public delete(item: T) {
        this.toDelete.add(item);
        this.toSave.delete(item);
        this.changeListener();
    }
    public save(item: T) {
        this.toSave.add(item);
        if (!item.id) {
            item.id = this.createId();
        }
        this.changeListener();
    }
    public isEmpty() {
        return this.toDelete.size === 0 && this.toSave.size === 0;
    }
    public clear() {
        this.toDelete.clear();
        this.toSave.clear();
    }
    public getSaves() {
        return [...this.toSave];
    }
    public getDeletes() {
        return [...this.toDelete];
    }
}

export class ChangeTrackerService {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private allEntityChangeTrackers: EntityChanges<any>[] = [];
    protected handleChange = () => {
        this.onChange.raise();
    };
    protected createId = () => {
        const result = uuidv4();
        this.refKeys.add(result);
        return result;
    };
    protected entityChangeTracker = <T>() => {
        const result = new EntityChanges<T>(this.handleChange, this.createId);
        this.allEntityChangeTrackers.push(result);
        return result;
    };
    public refKeys = new Set<string>();
    public onChange = new Event<void>();

    public hasChanges() {
        return this.allEntityChangeTrackers.some((c) => !c.isEmpty());
    }

    public clear() {
        this.refKeys.clear();
        for (const item of this.allEntityChangeTrackers) {
            item.clear();
        }
        this.onChange.raise();
    }
}
