﻿import {GridRenderCellParams} from "@mui/x-data-grid";
import {ReactNode} from "react";
import {Box, Chip} from "@mui/material";
import {amber, green, lightBlue, red} from "@mui/material/colors";

function AuditAction(props: { label: string, url: string }) {
    return <>
        <Box component="span" mr={1}>
            <Chip label={props.label} size="small" color="primary"
                  style={{backgroundColor: verbColors[props.label], fontWeight: "bold"}}/>
        </Box>
        {props.url}
    </>;
}

export const renderAction = (params: GridRenderCellParams): ReactNode => {
    const verb = params.getValue(params.id, 'method') as string;
    const url = params.getValue(params.id, 'url') as string;
    return (
        <AuditAction label={verb} url={url}/>
    );
};

const verbColors: { [key: string]: string; } = {
    'POST': green['700'],
    'PUT': amber['700'],
    'PATCH': amber['300'],
    'DELETE': red['700'],
    'GRPC': lightBlue['700']
};