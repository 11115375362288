import { TextInput } from '@components';
import { useSiteConfig } from '@core';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Box, Button } from '@mui/material';
import type { Shop } from '@services/model';
import { usePostApiShops } from '@services/shops/shops';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';

const newShopFormSchema = yup.object().shape({
    gameEnvironmentId: yup.string(),
    name: yup.string().required('Name is required'),
});

export function NewShopPrompt(props: { onClose: () => void; onCreated: (shopId: string) => void }) {
    const { id: gameEnvironmentId } = useSiteConfig();
    const { mutateAsync: add } = usePostApiShops();
    const form = useForm<Shop>({
        defaultValues: {
            gameEnvironmentId,
            name: '',
        },
        resolver: yupResolver(newShopFormSchema),
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });
    const { isDirty } = useFormState(form);
    const onSave = async () => {
        const shop = await add({ data: { ...form.getValues() } });
        props.onCreated(shop?.id || '');
        props.onClose();
    };
    return (
        <>
            <FormProvider {...form}>
                <TextInput name="name" width="full" label="Store Name" helperText="Enter name for store." />
            </FormProvider>
            <Box textAlign="right" width={300}>
                <Button variant="contained" onClick={form.handleSubmit(onSave)} disabled={!isDirty}>
                    Save Store
                </Button>
                <Button onClick={props.onClose}>Cancel</Button>
            </Box>
        </>
    );
}
