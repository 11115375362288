import { LinkTabs } from '@components';
import { PeopleAlt } from '@mui/icons-material';
import { Players, titlePageRegistry } from '..';
import { BulkAdd } from './PlayersBulkAdd';
import { BulkGrant } from './PlayersBulkGrant';
import { PlayersBulkGrantItems } from './PlayersBulkGrantItems';
import { TrackPlayerBulkGrantItems } from './TrackPlayerBulkGrantItems';
import { SyncItemOwnership } from "./ItemOwnershipBlockchainSync";

export function PlayersDetails() {
    return (
        <LinkTabs
            orientation="vertical"
            tabs={[
                { label: 'Players', render: () => <Players />, url: 'players' },
                { label: 'Bulk Add', render: () => <BulkAdd />, url: 'bulk-add' },
                { label: 'Bulk Grant to Players', render: () => <BulkGrant />, url: 'bulk-grant' },
                { label: 'Sync Item Ownership', render: () => <SyncItemOwnership />, url: 'sync-ownership' },
                { label: 'Bulk Grant Items', render: () => <PlayersBulkGrantItems />, url: 'bulk-grant-items' },
                { label: 'Track Bulk Grant', render: () => <TrackPlayerBulkGrantItems />, url: 'track-bulk-grant-items' },
            ]}
        />
    );
}
titlePageRegistry.register({ page: PlayersDetails, path: 'players', name: 'Players', icon: <PeopleAlt /> });
