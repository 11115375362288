import { BreadcrumbLink, LinkTabs, useUrlParams } from '@components';
import { useApiListener } from '@core';
import { Person } from '@mui/icons-material';
import { useGetApiPlayersId } from '@services/players/players';

import { titlePageRegistry } from '../PageRegistry';
import { PlayerData } from './PlayerData';
import { PlayerInfo } from './PlayerInfo';
import { PlayerInventory } from './PlayerInventory';
import { PlayerOrders } from './PlayerOrders';

export function PlayerDetails() {
    const { params } = useUrlParams();
    return (
        <LinkTabs
            orientation="vertical"
            tabs={[
                { label: 'Info', render: () => <PlayerInfo playerId={params.id || ''} />, url: 'info' },
                { label: 'Data', render: () => <PlayerData id={params.id || ''} />, url: 'data' },
                // eslint-disable-next-line react/display-name
                { label: 'Inventory', render: () => <PlayerInventory playerId={params.id || ''} />, url: 'inv' },
                { label: 'Orders', render: () => <PlayerOrders playerId={params.id || ''} />, url: 'orders' },
            ]}
        />
    );
}

function PlayerBreadcrumb() {
    const { params } = useUrlParams();
    const { isLoading, data, refetch } = useGetApiPlayersId(params.id);
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    useApiListener('player', () => refetch());

    return (
        <BreadcrumbLink>
            <Person /> Player ({isLoading ? 'Loading...' : data?.displayName})
        </BreadcrumbLink>
    );
}
titlePageRegistry.register({ page: PlayerDetails, icon: '', name: PlayerBreadcrumb, path: 'player-details' });
