import {FormGrid, FormSection, notify, TextInput } from '@components';
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import { PageBody, PageContent } from 'features/shell/layout';
import { useCallback } from 'react';
import { useSiteConfig } from '../../../shared/core/ShellService';
import {FormProvider, useForm, useFormState} from "react-hook-form";
import { Button, Divider, Grid, Toolbar } from "@mui/material";
import * as yup from "yup";
import type {BaseBackofficeResult, SyncPlayerItemOwnershipRequest} from '@services/model';
import { orvalRequestor } from 'shared/core/GameServiceApiOrvalFacade';
import {usePermissions} from "@core";



const syncOwnershipFormSchema = yup.object().shape({
    playerItemId: yup.string(),
    instanceId: yup.string(),
});

function OwnershipSyncForm(props: { gameEnvironmentId?: string }) {
    const { canUpdate: canUpdatePlayers } = usePermissions('Player');
    const canSyncPlayerItems = canUpdatePlayers;
    const gameEnvironment = props.gameEnvironmentId;
    const form = useForm({
        defaultValues: {
            playerItemId: '',
            instanceId: '',
        },
        resolver: yupResolver(syncOwnershipFormSchema),
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });
    
    const { playerItemId, instanceId } = form.getValues();
    const { isDirty } = useFormState(form);

    const postApiSyncItemOwnership = useCallback((request: SyncPlayerItemOwnershipRequest) => {
        request.playerItemId = request.playerItemId == '' ? null : request.playerItemId;
        request.instanceId = request.instanceId == '' ? null : request.instanceId;
        return orvalRequestor<BaseBackofficeResult>({
            url: '/api/player-items/sync-with-blockchain',
            method: 'post',
            data: request,
        });
    }, []);
    
    const syncOwnership = useCallback(async () => {
        
        try {
            const request: SyncPlayerItemOwnershipRequest = {
                playerItemId,
                instanceId
            };
            const result = await postApiSyncItemOwnership(request);
            if(result.errorMessage){
                notify({ type: 'error', content: result.errorMessage });  
            }
            else{
                notify({ type: 'success', content: result.successMessage });
            }
        } finally {
            form.reset({
                playerItemId: '',
                instanceId: ''});
        }
    }, [playerItemId, instanceId, gameEnvironment]);
    
    return (
        <PageContent>
            <PageBody>
                <FormProvider {...form}>
                    <FormGrid>
                        <Grid item container xs={12} spacing={2}>
                            <FormSection header="Sync Item Ownership">
                                <TextInput
                                    width="full"
                                    label="MGS Player Item ID"
                                    name="playerItemId"
                                    helperText="Enter game services player item id"
                                    rows={1}
                                />
                                <TextInput
                                    width="full"
                                    label="Saga Inventory Id"
                                    name="instanceId"
                                    helperText="Enter saga inventory id"
                                    rows={1}
                                />
                            </FormSection>
                        </Grid>
                    </FormGrid>
                </FormProvider>
            </PageBody>
            <Divider />
            {canSyncPlayerItems && (
                <Toolbar>
                        <Button onClick={syncOwnership} variant="contained" disabled={!isDirty}>
                            Sync Ownership
                        </Button>
                </Toolbar>
            )}
        </PageContent>
    );
}

export const SyncItemOwnership = () => {
    const config = useSiteConfig();
    return (
        <OwnershipSyncForm gameEnvironmentId={config.id} />
    );
};
