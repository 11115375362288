import { Chip } from "@mui/material"

export function OrderStateDisplay(props: { state: string, size?: 'small' | 'medium' | undefined }) {
    switch(props.state) {
        case 'CREATED':
            return <Chip size={props.size} label="Created" color="primary" />
        case 'PENDING_RESERVATION':
            return <Chip size={props.size} label="Pending Reservation" color="warning" />
        case 'RESERVED':
            return <Chip size={props.size} label="Reserved" color="primary" />
        case 'PAYMENT_PENDING':
            return <Chip size={props.size} label="Payment Pending" color="warning" />
        case 'PENDING_COMPLETION':
            return <Chip size={props.size} label="Pending Completion" color="warning" />
        case 'COMPLETED':
            return <Chip size={props.size} label="Completed" color="success" />
        case 'PENDING_CANCELLATION':
            return <Chip size={props.size} label="Pending Cancellation" color="warning" />
        case 'CANCELLED':
            return <Chip size={props.size} label="Cancelled" color="primary" />
        case 'FAILED':
            return <Chip size={props.size} label="Failed" color="error" />
        default:
            // How did you do that...
            return <>{props.state}</>;
    }
}

export function BeautifyStateText(state: string) : string {
    let result = state.replaceAll("_", " ");
    result = result.replace(/\w\S*/g,
        function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });

    return result;
}