import { ReadonlyInput, useUrlParams } from '@components';
import { Box, Grid, LinearProgress, Paper, styled } from '@mui/material';
import { useGetApiContainersId } from '@services/containers/containers';
import { InventoryContainer, PlayerContainer } from '@services/model';
import { useGetApiPlayerContainersId } from '@services/player-containers/player-containers';

import { PageBody, PageContent } from '../../shell/layout';
import { titlePageRegistry } from '../PageRegistry';

interface InventoryContainerDetailProps {
    playerContainer: PlayerContainer | undefined;
    container: InventoryContainer | undefined;
    isLoading: boolean;
}

export function InventoryContainerDetail() {
    const { params } = useUrlParams();
    const { isLoading: isPlayerContainerLoading, data: playerContainer } = useGetApiPlayerContainersId(params.id);
    const { isLoading: isContainerLoading, data: container } = useGetApiContainersId(playerContainer?.inventoryContainerId!);

    return <InventoryContainerDetailPage isLoading={isPlayerContainerLoading && isContainerLoading} playerContainer={playerContainer} container={container} />;
}

const InventoryContainerDetailPage = ({ playerContainer, container, isLoading }: InventoryContainerDetailProps) => {
    if (playerContainer == undefined || container == undefined) {
        if (isLoading) {
            return <LinearProgress />;
        } else {
            return <h2>Not Found</h2>;
        }
    }

    return (
        <PageContent>
            <PageBody>
                <Container>
                    <PageHeader>{container.displayName}</PageHeader>
                    <Container>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} alignContent="baseline">
                                <Box component={Paper} p={1}>
                                    <Header>Container Details</Header>
                                    <ReadonlyInput width="full" label="Inventory Container ID" value={playerContainer?.id} />
                                    <ReadonlyInput width="full" label="Instance ID" value={playerContainer?.instanceId} />
                                    <ReadonlyInput width="full" label="Container ID" value={playerContainer.inventoryContainerId} />
                                    <ReadonlyInput width="full" label="Container Name" value={container.displayName} />
        
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Container>
            </PageBody>
        </PageContent>
    );
};

const PageHeader = styled('div')`
    font-weight: bold;
    font-size: large;
`;

const Container = styled('div')`
    margin-top: 1rem;
`;

const Header = styled('div')`
    display: block;
    width: 100%;
    border: none;
    color: inherit;
    background: none;
    border-bottom: solid 1px ${(p) => p.theme.palette.grey[500]};
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
`;
titlePageRegistry.register({ page: InventoryContainerDetail, path: 'inventory-container-detail', name: 'Inventory Container' });
