import { AdminTable, CellNavigation, confirm, modal } from '@components';
import { Event, useApiListener } from '@core';
import { Delete } from '@mui/icons-material';
import { Alert, Box, Button, CircularProgress, MenuItem } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid';
import { BasePack, BaseBackofficeResult, PostApiPacksUploadBody } from '@services/model';
import { PageContent } from 'features/shell/layout';
import { ChangeEvent, useEffect, useState } from 'react';
import { postApiPacksUpload, useDeleteApiPacksName, useGetApiPacksByEnvironment } from '@services/packs/packs';
import { nanoid } from 'nanoid';
import { toast } from 'react-toastify';

export const PacksGrid = () => {
    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'Id',
            type: 'uuid',
            flex: 0.5,
            hide: true,
        },
        {
            field: 'packName',
            headerName: 'Pack Name',
            flex: 0.5,
            type: 'string',
            renderCell: (f) => <CellNavigation to="pack-details" id={String(f.row.packName)} value={String(f.value)} />,
        },
        {
            field: 'totalPacks',
            headerName: 'Total',
            type: 'string',
            flex: 1,
        },
        {
            field: 'totalRedeemed',
            headerName: 'Total Redeemed',
            type: 'string',
            flex: 1,
        },
    ];

    interface PackTableGrid {
        id?: string | null;
        packName?: string | null | undefined;
        totalPacks?: number | undefined;
        redeemedPacks?: number | undefined;
    }

    const [invalidateData] = useState(() => new Event<void>());
    const { data: packs, refetch } = useGetApiPacksByEnvironment();
    const { mutateAsync: remove } = useDeleteApiPacksName();
    const [packGridRowData, setPackGridRowData] = useState<PackTableGrid[]>([]);

    useEffect(() => {
        if (packs != null) {
            setPackGridRowData(buildRowData(packs));
        }
    }, [packs]);

    function buildRowData(entities: BasePack[] | undefined): PackTableGrid[] {
        var data =
            entities?.map((x) => {
                return {
                    ...x,
                    id: nanoid(),
                };
            }) ?? [];

        return data;
    }

    const onDelete = async (e: Partial<BasePack>) => {
        const didConfirm = await confirm(`Delete ${e.packName ?? ''}?`, `Are you sure you want to delete this Pack?`);
        if (didConfirm) {
            await remove({ name: e.packName ?? '' });
            refetch();
        }
    };

    const onAdd = async () => {
        await modal('Add Pack', (close) => <NewPackPrompt onClose={close} />);
        refetch();
    };

    useApiListener('pack', () => invalidateData.raise());

    return (
        <AdminTable<PackTableGrid>
            title="Packs"
            resource="Item"
            columns={columns}
            data={packGridRowData ? packGridRowData : []}
            dataInvalidationEvent={invalidateData}
            menuActions={[{ type: 'button', icon: Delete, label: 'Delete', onClick: onDelete }]}
            onAdd={onAdd}
        />
    );
};

function NewPackPrompt(props: { onClose: () => void }) {
    const [isLoading, setIsLoading] = useState<boolean>();
    const [uploadResponse, setUploadResponse] = useState<BaseBackofficeResult | null>(null);

    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);

        if (e.target.files) {
            var uploadedFile = e.target.files[0];

            if (uploadedFile.size < 5e6) {
                setIsLoading(true);
                var request: PostApiPacksUploadBody = { csvFile: uploadedFile };
                var uploadResult = await Promise.resolve(postApiPacksUpload(request));
                setIsLoading(false);
                setUploadResponse(uploadResult);
            } else {
                var response : BaseBackofficeResult = {
                    hasError: true,
                    errorMessage: 'File is greater than 5MB'
                };
                
                setUploadResponse(response);
                setIsLoading(false);
            }
        }

        setIsLoading(false);
    };

    const handleOnClose = () => {
        setUploadResponse(null);
        setIsLoading(false);
        props.onClose();
    };

    return (
        <div>
            <div>Please select a csv file to upload</div>

            {uploadResponse && uploadResponse.hasError && <Alert severity="error">{uploadResponse.errorMessage}</Alert>}
            {uploadResponse && !uploadResponse.hasError && <Alert severity="success">{uploadResponse.successMessage}</Alert>} 
                       
            <Box p={3} justifyContent="flex-end" display="flex">
                {isLoading && <CircularProgress size={20} />}
                <input accept=".csv" style={{ display: 'none' }} id="pack-upload" type="file" onChange={handleFileChange} />

                <label htmlFor="pack-upload">
                    <Button component="span" disabled={isLoading}>
                        Upload
                    </Button>
                </label>

                <Button onClick={handleOnClose}>Cancel</Button>
            </Box>
        </div>
    );
}

export function Packs() {
    return (
        <PageContent>
            <PacksGrid />
        </PageContent>
    );
}
