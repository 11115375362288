import { useFileUploader, usePermissions } from '@core';
import styled from '@emotion/styled';
import { Delete, Edit, Upload } from '@mui/icons-material';
import { Grid, IconButton, Tooltip } from '@mui/material';
import type {
    ShopBundle,
    ShopContainer,
    ShopItem,
    ShopTabBundle,
    ShopTabContainer,
    ShopTabItem,
    ShopTabVirtualCurrency,
    ShopVirtualCurrency,
    VirtualCurrency,
} from '@services/model';
import { useRef, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';

import { EditGridItemModal } from './EditGridItemModal';
import { RemoveConfirmationDialog } from './RemoveConfirmationDialog';

export interface ShopGridItemProps<T extends GridItem> {
    gridItem: T;
    displayName: string;
    imageUrl: string;
    onRemove(gridItem: T): void;
    onEdit: SubmitHandler<T>;
    onEditTab(tabItem: T): void;
    virtualCurrencies: VirtualCurrency[];
}

export type GridItem =
    | (ShopVirtualCurrency & ShopTabVirtualCurrency)
    | (ShopContainer & ShopTabContainer)
    | (ShopBundle & ShopTabBundle)
    | (ShopItem & ShopTabItem);
export type GridItemNoVirtualCurrency = (ShopContainer & ShopTabContainer) | (ShopBundle & ShopTabBundle) | (ShopItem & ShopTabItem);

export function ShopGridItem<T extends GridItem>(props: ShopGridItemProps<T>) {
    const { displayName, imageUrl, gridItem, onRemove, onEdit, onEditTab, virtualCurrencies } = props;

    const { prepare } = useFileUploader();
    const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const { canUpdate, canDelete, canCreate } = usePermissions();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const onFileChange = async (el: HTMLInputElement) => {
        const prepared = prepare(el);
        const uploadResult = await prepared?.upload('/api/Organizations/Asset');
        if (uploadResult) {
            onEditTab({ ...gridItem, imageUrl: uploadResult.url });
        }
    };

    const handleToggleRemoveDialog = () => {
        setIsRemoveDialogOpen((status) => !status);
    };

    const handleToggleEditDialog = () => {
        setIsEditDialogOpen((status) => !status);
    };

    function handleRemoveGridItem() {
        onRemove(gridItem);
        handleToggleRemoveDialog();
    }

    const handleEditGridItem = (gridItem: Parameters<SubmitHandler<T>>['0']) => {
        onEdit({ ...gridItem });
        onEditTab({ ...props.gridItem, description: gridItem.description });
        handleToggleEditDialog();
    };

    return (
        <Grid item sm={4} xl={2} sx={{ height: '200px' }}>
            <ItemImageBackground background={imageUrl}>
                <Header>{displayName}</Header>
                <Body imageUrl={imageUrl}>
                    <FloatingButtonContainer>
                        <FileInput ref={fileInputRef} type="file" onChange={(e) => onFileChange(e.target as HTMLInputElement)} />
                        {(canCreate || canUpdate) && (
                            <Tooltip title="Upload Image">
                                <IconButton onClick={() => fileInputRef?.current?.click()}>
                                    <Upload />
                                </IconButton>
                            </Tooltip>
                        )}
                        {canUpdate && (
                            <Tooltip title="Edit Item Details">
                                <IconButton onClick={handleToggleEditDialog}>
                                    <Edit />
                                </IconButton>
                            </Tooltip>
                        )}
                        {canDelete && (
                            <Tooltip title="Remove this Item">
                                <IconButton onClick={handleToggleRemoveDialog}>
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        )}
                    </FloatingButtonContainer>
                </Body>
            </ItemImageBackground>
            <RemoveConfirmationDialog
                isOpen={isRemoveDialogOpen}
                onSubmit={handleRemoveGridItem}
                onCancel={handleToggleRemoveDialog}
                displayName={displayName}
            />
            <EditGridItemModal
                isOpen={isEditDialogOpen}
                onSubmit={handleEditGridItem}
                onCancel={handleToggleEditDialog}
                gridItem={gridItem}
                virtualCurrencies={virtualCurrencies}
            />
        </Grid>
    );
}

const FileInput = styled.input`
    display: none;
`;
const Header = styled.div`
    background: #8888;
    padding: 0.25rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const FloatingButtonContainer = styled.div`
    border-radius: 2rem;
    padding: 0.5rem;
    background: #888888;
    &:hover {
        opacity: 1;
    }
`;
const Body = styled.div<{ imageUrl: string }>`
    padding: 10px;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: ${(p) => (p.imageUrl ? 'flex-end' : 'center')};
    overflow: auto;
`;
const ItemImageBackground = styled.div<{ background: string }>`
    height: 100%;
    background: url('${(p) => p.background}');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    display: flex;
    flex-direction: column;
    border: 1px solid #8885;
`;
