import { TabPanel } from '@mui/lab';
import { Button, ButtonGroup, Grid } from '@mui/material';
import { Box } from '@mui/system';
import type { InventoryContainer, Item, ItemClassModel, ShopBundle, ShopContainer, ShopItem, ShopTab, ShopTabBundle, ShopTabContainer, ShopTabItem, ShopTabVirtualCurrency, ShopVirtualCurrency, VirtualCurrency } from '@services/model';
import type { ReactNode } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { BundleSelectModal, InventoryContainerShopGrid } from './BundleSelectModal';
import { ContainerSelectModal } from './ContainerSelectModal';
import { ItemSelectModal, ItemShopGrid } from './ItemSelectModal';
import { ShopGridItem } from './ShopGridItem';
import { useShop } from './ShopProvider';
import { VirtualCurrencySelectModal, VirtualCurrencyShopGrid } from './VirtualCurrencySelectModal';

export interface ShopGridPanelProps {
    shopTab: ShopTab | null;
    items: Item[];
    itemClasses: ItemClassModel[];
    bundles: InventoryContainer[];
    containers: InventoryContainer[];
    virtualCurrencies: VirtualCurrency[];
    children: ReactNode;
}

export const ShopGridPanel = (props: ShopGridPanelProps) => {
    const { shopTab, items, itemClasses, bundles, containers, virtualCurrencies } = props;

    const {
        selectedShopVersion,
        selectedShopTab,
        getShopTabs,
        getShopItems,
        getShopBundles,
        getShopContainers,
        getShopVirtualCurrencies,
        addShopBundle,
        addShopItem,
        addShopContainer,
        addShopVirtualCurrency,
        addShopTabItem,
        addShopTabBundle,
        addShopTabContainer,
        addShopTabVirtualCurrency,
        getShopTabBundles,
        getShopTabItems,
        getShopTabContainers,
        getShopTabVirtualCurrencies,
        updateShopItem,
        updateShopBundle,
        updateShopContainer,
        updateShopVirtualCurrency,
        updateShopTabBundle,
        updateShopTabItem,
        updateShopTabContainer,
        updateShopTabVirtualCurrency,
        removeShopBundle,
        removeShopItem,
        removeShopContainer,
        removeShopVirtualCurrency,
        removeShopTabBundle,
        removeShopTabItem,
        removeShopTabContainer,
        removeShopTabVirtualCurrency,
        shopItems,
        shopBundles,
        shopTabBundles,
        shopTabItems,
        shopContainers,
        shopTabContainers,
        shopVirtualCurrencies,
        shopTabVirtualCurrencies
    } = useShop();

    const [isItemSelectionOpen, setIsItemSelectionOpen] = useState(false);
    const [isBundleSelectionOpen, setIsBundleSelectionOpen] = useState(false);
    const [isContainerSelectionOpen, setIsContainerSelectionOpen] = useState(false);
    const [isVirtualCurrencySelectionOpen, setIsVirtualCurrencySelectionOpen] = useState(false); 

    const handleEditShopItem = (item: ShopItem) => {
        updateShopItem(
            {
                data: {
                    ...item,
                    cartLimit: item.cartLimit ?? undefined,
                    virtualCurrencyId: item.virtualCurrencyId ? item.virtualCurrencyId : null,
                    shopVersionId: selectedShopVersion?.id as string,
                    eligibleQuantity: item.eligibleQuantity!
                },
                id: item.id as string,
            },
            {
                onSuccess() {
                    getShopItems(selectedShopVersion?.id as string);
                    toast.success('Shop item updated successfully.');
                },
                onError() {
                    toast.error('Something went wrong trying to update the shop item, please try again later.');
                },
            }
        );
    };

    const handleEditShopTabItem = (item: ShopTabItem) => {
        if (selectedShopTab?.id) {
            updateShopTabItem(
                { data: { ...item, shopTabId: selectedShopTab.id as string }, id: item.id as string },
                {
                    onSuccess() {
                        getShopTabItems(selectedShopTab.id as string);
                    },
                }
            );
        }
    };

    const handleRemoveShopTabItem = (item: ShopTabItem) => {
        if (selectedShopTab) {
            removeShopTabItem(
                {
                    id: item.id as string,
                },
                {
                    onSuccess() {
                        removeShopItem(
                            { id: item.shopItemId as string },
                            {
                                onSuccess() {
                                    getShopTabs(selectedShopVersion?.id as string);
                                    getShopItems(selectedShopVersion?.id as string);
                                    getShopTabItems(selectedShopTab.id as string);
                                },
                            }
                        );
                    },
                }
            );
        }
    };

    const handleAddShopTabItems = async (items: ItemShopGrid[]) => {
        if (selectedShopTab) {
            const shopItems = await Promise.all(
                items.map((item) => addShopItem({ data: { itemId: item.id as string, shopVersionId: selectedShopVersion?.id as string, eligibleQuantity: item.eligibleQuantity } }))
            );

            await Promise.all([
                shopItems.map((shopItem) =>
                    addShopTabItem({
                        data: {
                            shopItemId: shopItem.id as string,
                            shopTabId: selectedShopTab.id as string,
                        },
                    })
                ),
            ]);

            await Promise.all([
                getShopTabs(selectedShopVersion?.id as string),
                getShopItems(selectedShopVersion?.id as string),
                getShopTabItems(selectedShopTab.id as string),
            ]);
        }
    };
    const handleEditShopBundle = (bundle: ShopBundle) => {
        updateShopBundle(
            {
                data: {
                    ...bundle,
                    virtualCurrencyId: bundle.virtualCurrencyId ? bundle.virtualCurrencyId : null,
                    shopVersionId: selectedShopVersion?.id as string,
                    eligibleQuantity: bundle.eligibleQuantity!
                },
                id: bundle.id as string,
            },
            {
                onSuccess() {
                    getShopBundles(selectedShopVersion?.id as string);
                    toast.success('Shop bundle updated successfully.');
                },
                onError() {
                    toast.error('Something went wrong trying to update the shop bundle, please try again later.');
                },
            }
        );
    };
    const handleEditShopTabBundle = (bundle: ShopTabBundle) => {
        if (selectedShopTab?.id) {
            updateShopTabBundle(
                { data: { ...bundle, shopTabId: selectedShopTab?.id as string }, id: bundle.id as string },
                {
                    onSuccess() {
                        getShopTabBundles(selectedShopTab.id as string);
                    },
                }
            );
        }
    };

    const handleRemoveShopTabBundle = (bundle: ShopTabBundle) => {
        if (selectedShopTab) {
            removeShopTabBundle(
                {
                    id: bundle.id as string,
                },
                {
                    onSuccess() {
                        removeShopBundle(
                            { id: bundle.shopBundleId as string },
                            {
                                onSuccess() {
                                    getShopTabs(selectedShopVersion?.id as string);
                                    getShopBundles(selectedShopVersion?.id as string);
                                    getShopTabBundles(selectedShopTab.id as string);
                                },
                            }
                        );
                    },
                }
            );
        }
    };

    const handleAddShopTabBundles = async (bundles: InventoryContainerShopGrid[]) => {
        if (selectedShopTab) {
            const shopBundles = await Promise.all(
                bundles.map((bundle) => addShopBundle({ data: { bundleId: bundle.id as string, shopVersionId: selectedShopVersion?.id as string, eligibleQuantity: bundle.eligibleQuantity } }))
            );

            await Promise.all([
                shopBundles.map((shopBundle) =>
                    addShopTabBundle({
                        data: {
                            shopBundleId: shopBundle.id as string,
                            shopTabId: selectedShopTab.id as string,
                        },
                    })
                ),
            ]);

            await Promise.all([
                getShopTabs(selectedShopVersion?.id as string),
                getShopBundles(selectedShopVersion?.id as string),
                getShopTabBundles(selectedShopTab.id as string),
            ]);
        }
    };

    const handleEditShopContainer = (container: ShopContainer) => {
        updateShopContainer(
            {
                data: {
                    ...container,
                    virtualCurrencyId: container.virtualCurrencyId ? container.virtualCurrencyId : null,
                    shopVersionId: selectedShopVersion?.id as string,
                    eligibleQuantity: container.eligibleQuantity!
                },
                id: container.id as string,
            },
            {
                onSuccess() {
                    getShopContainers(selectedShopVersion?.id as string);
                    toast.success('Shop container updated successfully.');
                },
                onError() {
                    toast.error('Something went wrong trying to update the shop container, please try again later.');
                },
            }
        );
    };
    const handleEditShopTabContainer = (container: ShopTabContainer) => {
        if (selectedShopTab?.id) {
            updateShopTabContainer(
                { data: { ...container, shopTabId: selectedShopTab?.id as string }, id: container.id as string },
                {
                    onSuccess() {
                        getShopTabContainers(selectedShopTab.id as string);
                    },
                }
            );
        }
    };

    const handleRemoveShopTabContainer = (container: ShopTabContainer) => {
        if (selectedShopTab) {
            removeShopTabContainer(
                {
                    id: container.id as string,
                },
                {
                    onSuccess() {
                        removeShopContainer(
                            { id: container.shopContainerId as string },
                            {
                                onSuccess() {
                                    getShopTabs(selectedShopVersion?.id as string);
                                    getShopContainers(selectedShopVersion?.id as string);
                                    getShopTabContainers(selectedShopTab.id as string);
                                },
                            }
                        );
                    },
                }
            );
        }
    };

    const handleAddShopTabContainers = async (containers: InventoryContainerShopGrid[]) => {
        if (selectedShopTab) {
            const shopContainers = await Promise.all(
                containers.map((container) => addShopContainer({ data: { containerId: container.id as string, shopVersionId: selectedShopVersion?.id as string, eligibleQuantity: container.eligibleQuantity } }))
            );

            await Promise.all([
                shopContainers.map((shopContainer) =>
                    addShopTabContainer({
                        data: {
                            shopContainerId: shopContainer.id as string,
                            shopTabId: selectedShopTab.id as string,
                        },
                    })
                ),
            ]);

            await Promise.all([
                getShopTabs(selectedShopVersion?.id as string),
                getShopContainers(selectedShopVersion?.id as string),
                getShopTabContainers(selectedShopTab.id as string),
            ]);
        }
    };

    const handleEditShopVirtualCurrency = (currency: ShopVirtualCurrency) => {
        updateShopVirtualCurrency(
            {
                data: {
                    ...currency,
                    //virtualCurrencyId: container.virtualCurrencyId ? container.virtualCurrencyId : null,
                    shopVersionId: selectedShopVersion?.id as string,
                    eligibleQuantity: currency.eligibleQuantity!
                },
                id: currency.id as string,
            },
            {
                onSuccess() {
                    getShopVirtualCurrencies(selectedShopVersion?.id as string);
                    toast.success('Shop virtual currency updated successfully.');
                },
                onError() {
                    toast.error('Something went wrong trying to update the shop virtual currency, please try again later.');
                },
            }
        );
    };
    const handleEditShopTabVirtualCurrency = (currency: ShopTabVirtualCurrency) => {
        if (selectedShopTab?.id) {
            updateShopTabVirtualCurrency(
                { data: { ...currency, shopTabId: selectedShopTab?.id as string }, id: currency.id as string },
                {
                    onSuccess() {
                        getShopTabVirtualCurrencies(selectedShopTab.id as string);
                    },
                }
            );
        }
    };

    const handleRemoveShopTabVirtualCurrency = (currency: ShopTabVirtualCurrency) => {
        if (selectedShopTab) {
            removeShopTabVirtualCurrency(
                {
                    id: currency.id as string,
                },
                {
                    onSuccess() {
                        removeShopVirtualCurrency(
                            { id: currency.shopVirtualCurrencyId as string },
                            {
                                onSuccess() {
                                    getShopTabs(selectedShopVersion?.id as string);
                                    getShopVirtualCurrencies(selectedShopVersion?.id as string);
                                    getShopTabVirtualCurrencies(selectedShopTab.id as string);
                                },
                            }
                        );
                    },
                }
            );
        }
    };

    const handleAddShopTabVirtualCurrencies = async (currencies: VirtualCurrencyShopGrid[]) => {
        if (selectedShopTab) {
            const shopVirtualCurrencies = await Promise.all(
                currencies.map((currency) => addShopVirtualCurrency({ data: { virtualCurrencyPurchasableId: currency.id as string, shopVersionId: selectedShopVersion?.id as string, eligibleQuantity: currency.eligibleQuantity } }))
            );

            await Promise.all([
                shopVirtualCurrencies.map((shopVirtualCurrency) =>
                    addShopTabVirtualCurrency({
                        data: {
                            shopVirtualCurrencyId: shopVirtualCurrency.id as string,
                            shopTabId: selectedShopTab.id as string,
                        },
                    })
                ),
            ]);

            await Promise.all([
                getShopTabs(selectedShopVersion?.id as string),
                getShopVirtualCurrencies(selectedShopVersion?.id as string),
                getShopTabVirtualCurrencies(selectedShopTab.id as string),
            ]);
        }
    };

    const handleAddVirtualCurrencies = (currencies: VirtualCurrencyShopGrid[]) => {
        handleAddShopTabVirtualCurrencies(currencies);
        handleToggleVirtualCurrencySelection();
    };
    const handleToggleVirtualCurrencySelection = () => {
        setIsVirtualCurrencySelectionOpen((status) => !status);
    };
    const handleAddContainers = (containers: InventoryContainerShopGrid[]) => {
        handleAddShopTabContainers(containers);
        handleToggleContainerSelection();
    };
    const handleToggleContainerSelection = () => {
        setIsContainerSelectionOpen((status) => !status);
    };
    const handleAddBundles = (bundles: InventoryContainerShopGrid[]) => {
        handleAddShopTabBundles(bundles);
        handleToggleBundleSelection();
    };
    const handleToggleBundleSelection = () => {
        setIsBundleSelectionOpen((status) => !status);
    };
    const handleAddItems = (items: ItemShopGrid[]) => {
        handleAddShopTabItems(items);
        handleToggleItemSelection();
    };
    const handleToggleItemSelection = () => {
        setIsItemSelectionOpen((status) => !status);
    };

    return (
        <TabPanel value={shopTab?.id as string} key={shopTab?.id}>
            <ItemSelectModal
                isOpen={isItemSelectionOpen}
                items={items}
                itemClasses={itemClasses}
                onSubmit={handleAddItems}
                onCancel={handleToggleItemSelection}
            />
            <BundleSelectModal 
                isOpen={isBundleSelectionOpen} 
                bundles={bundles} 
                onSubmit={handleAddBundles} 
                onCancel={handleToggleBundleSelection} 
            />
            <ContainerSelectModal 
                isOpen={isContainerSelectionOpen} 
                containers={containers} 
                onSubmit={handleAddContainers} 
                onCancel={handleToggleContainerSelection} 
            />
            <VirtualCurrencySelectModal 
                isOpen={isVirtualCurrencySelectionOpen} 
                virtualCurrencies={virtualCurrencies} 
                onSubmit={handleAddVirtualCurrencies} 
                onCancel={handleToggleVirtualCurrencySelection} 
            />
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
                <ButtonGroup variant="contained">
                    <Button onClick={handleToggleItemSelection}>Add Items</Button>
                    <Button onClick={handleToggleBundleSelection}>Add Bundles</Button>
                    <Button onClick={handleToggleContainerSelection}>Add Containers</Button>
                    <Button onClick={handleToggleVirtualCurrencySelection}>Add Currencies</Button>
                </ButtonGroup>
            </Box>
            <Grid container spacing={2} columns={12}>
                {virtualCurrencies &&
                    shopTabItems?.map((shopTabItem) => {
                        const shopItem = shopItems?.find((shopItem) => shopItem.id === shopTabItem.shopItemId);
                        const item = items.find((item) => shopItem?.itemId === item.id);
                        return item && shopItem ? (
                            <ShopGridItem<ShopItem & ShopTabItem>
                                key={shopTabItem.id}
                                gridItem={{ ...shopItem, ...shopTabItem }}
                                displayName={item.displayName}
                                imageUrl={shopTabItem.imageUrl as string}
                                onRemove={handleRemoveShopTabItem}
                                onEdit={handleEditShopItem}
                                onEditTab={handleEditShopTabItem}
                                virtualCurrencies={virtualCurrencies}
                            />
                        ) : null;
                    })}
                {virtualCurrencies &&
                    shopTabBundles?.map((shopTabBundle) => {
                        const shopBundle = shopBundles?.find((shopBundle) => shopBundle.id === shopTabBundle.shopBundleId);
                        const bundle = bundles.find((bundle) => shopBundle?.bundleId === bundle.id);
                        return bundle && shopBundle ? (
                            <ShopGridItem<ShopBundle & ShopTabBundle>
                                key={shopTabBundle.id}
                                gridItem={{ ...shopBundle, ...shopTabBundle }}
                                onRemove={handleRemoveShopTabBundle}
                                onEdit={handleEditShopBundle}
                                onEditTab={handleEditShopTabBundle}
                                displayName={bundle.displayName}
                                imageUrl={shopTabBundle.imageUrl as string}
                                virtualCurrencies={virtualCurrencies}
                            />
                        ) : null;
                    })}
                {virtualCurrencies &&
                    shopTabContainers?.map((shopTabContainer) => {
                        const shopContainer = shopContainers?.find((shopContainer) => shopContainer.id === shopTabContainer.shopContainerId);
                        const container = containers.find((container) => shopContainer?.containerId === container.id);
                        return container && shopContainer ? (
                            <ShopGridItem<ShopContainer & ShopTabContainer>
                                key={shopTabContainer.id}
                                gridItem={{ ...shopContainer, ...shopTabContainer }}
                                onRemove={handleRemoveShopTabContainer}
                                onEdit={handleEditShopContainer}
                                onEditTab={handleEditShopTabContainer}
                                displayName={container.displayName}
                                imageUrl={shopTabContainer.imageUrl as string}
                                virtualCurrencies={virtualCurrencies}
                            />
                        ) : null;
                    })}
                {virtualCurrencies &&
                    shopTabVirtualCurrencies?.map((shopTabVirtualCurrency) => {
                        const shopVirtualCurrency = shopVirtualCurrencies?.find((shopVirtualCurrency) => shopVirtualCurrency.id === shopTabVirtualCurrency.shopVirtualCurrencyId);
                        const virtualCurrency = virtualCurrencies.find((virtualCurrency) => shopVirtualCurrency?.virtualCurrencyPurchasableId === virtualCurrency.id);
                        return virtualCurrency && shopVirtualCurrency ? (
                            <ShopGridItem<ShopVirtualCurrency & ShopTabVirtualCurrency>
                                key={shopVirtualCurrency.id}
                                gridItem={{ ...shopVirtualCurrency, ...shopTabVirtualCurrency }}
                                onRemove={handleRemoveShopTabVirtualCurrency}
                                onEdit={handleEditShopVirtualCurrency}
                                onEditTab={handleEditShopTabVirtualCurrency}
                                displayName={virtualCurrency.name}
                                imageUrl={shopTabVirtualCurrency.imageUrl as string}
                                virtualCurrencies={virtualCurrencies}
                            />
                        ) : null;
                    })}
            </Grid>
        </TabPanel>
    );
};
