import { monitor, TextInput, ThemeAndLogoFields } from '@components';
import type { SiteConfig } from '@core';
import { Box, Button, Divider } from '@mui/material';
import type { Organization } from '@services/model';
import { putApiOrganizationsId } from '@services/organizations/organizations';
import { PageBody, PageContent, PageHeader, Toolbar } from 'features/shell/layout';
import { useState } from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';

export function OrgConfig(props: { org: Organization }) {
    const [siteConfig] = useState(() => JSON.parse(props.org.siteConfigJson || '{}') as SiteConfig);
    const form = useForm({
        defaultValues: {
            config: siteConfig,
            name: props.org.name,
        },
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });
    const { isDirty } = useFormState(form);
    const save = async () => {
        const data = form.getValues();
        monitor({
            failureMessage: 'Error saving organization configuration!',
            successMessage: 'Organization saved!',
            action: async () => {
                await putApiOrganizationsId(props.org.id!, {
                    ...props.org,
                    name: data.name,
                    siteConfigJson: JSON.stringify(data.config),
                });
                form.reset(data);
            },
        });
    };
    const undo = () => form.reset();
    return (
        <PageContent>
            <PageBody noPadding>
                <FormProvider {...form}>
                    <Box px={2} py={3}>
                        <TextInput label="Organization Name" name="name" helperText="What's the actual name of the organization?" />
                    </Box>
                    <ThemeAndLogoFields />
                </FormProvider>
            </PageBody>
            <PageHeader>
                <Toolbar>
                    <Button onClick={form.handleSubmit(save)} disabled={!isDirty} variant="contained">
                        Save Changes
                    </Button>
                    <Button onClick={undo} disabled={!isDirty}>
                        Undo Changes
                    </Button>
                </Toolbar>
            </PageHeader>
        </PageContent>
    );
}
