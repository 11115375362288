import { AdminTable, confirm, DateInput, modal, TextInput, UnauthorizedAccess } from '@components';
import { buildSearch, useApiListener, usePermissions, useSiteConfig } from '@core';
import { yupResolver } from '@hookform/resolvers/yup';
import { CallSplit, Delete } from '@mui/icons-material';
import { Box, Button, LinearProgress } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid';
import { useDeleteApiGameVersionsId, usePostApiGameVersions, usePostApiGameVersionsFindBy } from '@services/game-versions/game-versions';
import type { GameVersion } from '@services/model';
import { format, parseISO } from 'date-fns';
import { PageContent } from 'features/shell/layout';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { useMount } from 'react-use';
import * as yup from 'yup';

import type { RowMenuAction } from '../../../shared/components/AdminTable/RowMenu';
import { titlePageRegistry } from '../PageRegistry';

interface VersionsGridProps {
    gameEnvironmentId: string;
}

export const VersionsGrid = ({ gameEnvironmentId }: VersionsGridProps) => {
    const { canRead, canCreate, canDelete } = usePermissions('GameVersion');
    if (!canRead) {
        return <UnauthorizedAccess />;
    }
    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Id', flex: 0.75 },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
        },
        {
            field: 'version',
            headerName: 'Version',
            flex: 1,
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 1,
        },
        { field: 'createdAt', headerName: 'Created', flex: 0.5, valueFormatter: (params) => format(parseISO(String(params.value)), 'Ppp') },
    ];
    const { mutate, data, isLoading } = usePostApiGameVersionsFindBy();
    const search = () => {
        mutate(buildSearch(1, 1000, { eq: { gameEnvironmentId } }));
    };
    const { mutateAsync: remove } = useDeleteApiGameVersionsId();
    const { mutateAsync: add } = usePostApiGameVersions();

    useMount(search);
    const onDelete = async (e: Partial<GameVersion>) => {
        const didConfirm = await confirm(`Delete ${e.name ?? ''}?`, `Are you sure you want to delete this Version?`);
        if (didConfirm) {
            await remove({ id: e.id ?? '' });
        }
    };
    const onAdd = async () => {
        let version = undefined;
        await modal('Add Version', (close) => <NewGameVersionPrompt onClose={close} onChange={(e: GameVersion) => (version = e)} />);
        if (version) {
            await add({ data: { ...(version as GameVersion) } });
        }
    };
    useApiListener('gameversion', search);
    const actions: RowMenuAction<GameVersion>[] | undefined = canDelete
        ? [{ type: 'button', icon: Delete, label: 'Delete', onClick: onDelete }]
        : undefined;
    return isLoading ? (
        <LinearProgress />
    ) : (
        <>
            <AdminTable<GameVersion>
                title="Game Versions"
                resource="GameVersion"
                columns={columns}
                data={canRead && data && data.items ? data.items : []}
                menuActions={actions}
                onAdd={canCreate ? onAdd : undefined}
            />
        </>
    );
};
const VersionSchema = yup.object().shape({
    name: yup.string().required('Name is required!'),
    version: yup.string().required('Version is required!'),
    description: yup.string(),
    releasedAt: yup.date().required('Release Date is required!'),
});
function NewGameVersionPrompt(props: { onChange: (item: GameVersion) => void; onClose: () => void }) {
    const defaultValues: GameVersion = {
        name: '',
        version: '',
        description: '',
        releasedAt: '',
    };
    const form = useForm({ resolver: yupResolver(VersionSchema), defaultValues, mode: 'onBlur', reValidateMode: 'onBlur' });
    const { isDirty } = useFormState({ control: form.control });
    const save = () => {
        const values = form.getValues();
        props.onChange(values);
        props.onClose();
    };
    return (
        <>
            <FormProvider {...form}>
                <Box px={3} width={300}>
                    <TextInput label="Name" width="full" name="name" helperText="What's version name?" />
                    <TextInput label="Version" width="full" name="version" helperText="What's version?" />
                    <TextInput label="Description" width="full" name="description" helperText="Description of the version" />
                    <DateInput label="Release Date" width="full" name="releasedAt" helperText="What's the release date?" />
                </Box>
            </FormProvider>
            <Box p={2} justifyContent="flex-end" display="flex">
                <Button variant="contained" color="primary" disabled={!isDirty} onClick={form.handleSubmit(save)}>
                    Create Version
                </Button>
                <Button onClick={props.onClose}>Cancel</Button>
            </Box>
        </>
    );
}

export function Versions() {
    const config = useSiteConfig();
    return (
        <PageContent>
            <VersionsGrid gameEnvironmentId={config.id} />
        </PageContent>
    );
}

titlePageRegistry.register({ page: Versions, path: 'versions', name: 'Versions', icon: <CallSplit /> });
