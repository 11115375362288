import { BoolInput, monitor } from '@components';
import { Event, searchBuilder, useEvent, useEventValue } from '@core';
import { Save, Security } from '@mui/icons-material';
import { Box, Button, Divider, LinearProgress, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { deleteApiAdminUserRoleId, postApiAdminUserRole } from '@services/admin-user-role/admin-user-role';
import { postApiRoleQuery } from '@services/role/role';
import { PageBody, PageContent, PageHeader, Toolbar } from 'features/shell/layout';
import { useCallback, useEffect, useState } from 'react';
import { isNullOrUndefined } from 'util';

export function RolePicker({ name, userId, organizationId, onClose }: { name: string; userId: string; organizationId: string; onClose: () => void }) {
    const [rolePickerSvc, setRolePickerSvc] = useState<RolePickerService>();
    const loadRolePicker = useCallback(async () => {
        const svc = new RolePickerService(userId, organizationId);
        setRolePickerSvc(svc);
        await svc.init();
    }, [userId, organizationId, setRolePickerSvc]);
    useEffect(() => {
        loadRolePicker();
    }, [loadRolePicker]);
    const save = useCallback(async () => {
        if (await rolePickerSvc?.save()) {
            onClose();
        }
    }, [rolePickerSvc, onClose]);

    useEvent(rolePickerSvc?.selectionChanged);
    const loading = useEventValue(rolePickerSvc?.loading, true);
    const saving = useEventValue(rolePickerSvc?.saving, false);
    return (
        <PageContent>
            <PageHeader>
                <Box display="flex">
                    <Security />
                    Roles: {name}
                </Box>
            </PageHeader>
            <Divider />
            <PageBody>
                {loading ? (
                    <LinearProgress />
                ) : (
                    <List>
                        {rolePickerSvc!.roles.map((r) => (
                            <ListItem key={r.roleId} dense disablePadding>
                                <ListItemButton onClick={() => rolePickerSvc?.toggle(r)}>
                                    <ListItemText primary={r.name} secondary={r.description} />
                                    <BoolInput label="" value={rolePickerSvc?.isSelected(r)} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                )}
            </PageBody>
            <Divider />
            <Toolbar>
                <Button onClick={save} variant="contained" disabled={!rolePickerSvc || !rolePickerSvc.hasChanges || loading || saving}>
                    <Save /> Save Changes
                </Button>
                <Button onClick={onClose}>Close</Button>
            </Toolbar>
        </PageContent>
    );
}

interface PickableRole {
    name: string;
    description?: string | null;
    roleId: string;
    id?: string | null;
}
class RolePickerService {
    private readonly selectedRoles = new Set<PickableRole>();
    public roles: PickableRole[] = [];
    public readonly loading = new Event<boolean>();
    public readonly saving = new Event<boolean>();
    public readonly selectionChanged = new Event<void>();
    public hasChanges = false;

    public constructor(private readonly userId: string, private readonly orgId: string) {}

    public async init() {
        this.loading.raise(true);
        await monitor({
            action: () => this.load(),
            failureMessage: 'Failed to Load Roles!',
            successMessage: false,
            finally: () => this.loading.raise(false),
        });
        this.hasChanges = false;
    }

    private async load() {
        const { items } = await searchBuilder('Role')
            .leftJoin('AdminUserRole', ([r, ur]) => ({ and: [{ eq: { [ur.roleId]: r.id } }, { eq: { [ur.adminUserId]: this.userId } }, { isNull: [ur.deletedAt]}] }))
            .where(([r, _ur]) => ({ eq: { [r.scopeEntityId]: this.orgId } }))
            .sortAsc(([r, _ur]) => r.name)
            .select(([r, ur]) => ({ roleId: r.id!, name: r.name, description: r.description, id: ur.id }))
            .execute(postApiRoleQuery);

        if (items) {
            for (const role of items) {
                if (role.id) {
                    this.selectedRoles.add(role);
                }
                this.roles.push(role);
            }
        }
        this.loading.raise(false);
    }

    public async save() {
        this.saving.raise(true);
        let success = false;
        await monitor({
            action: async () => {
                await this.saveRoles();
                success = true;
            },
            failureMessage: 'Failed to Update Roles!',
            successMessage: 'Roles Updated',
            finally: () => this.saving.raise(false),
        });
        this.hasChanges = false;
        return success;
    }
    private async saveRoles() {
        for (const role of this.roles) {
            const isSelected = this.isSelected(role);
            if (!isSelected && role.id) {
                deleteApiAdminUserRoleId(role.id);
            } else if (isSelected && !role.id ) {
                postApiAdminUserRole({ adminUserId: this.userId, roleId: role.roleId });
            }
        }
    }

    public toggle(role: PickableRole) {
        if (this.selectedRoles.has(role)) {
            this.selectedRoles.delete(role);
        } else {
            this.selectedRoles.add(role);
        }
        this.hasChanges = true;
        this.selectionChanged.raise();
    }

    public isSelected(role: PickableRole) {
        return this.selectedRoles.has(role);
    }
}
