import { TextField } from '@mui/material';
import { forwardRef } from 'react';
import type { NumberFormatProps } from 'react-number-format';
import NumberFormat from 'react-number-format';

import type { InternalInputProps } from './HookAdapter';
import { makeHookablePermission, styleFromProps } from './HookAdapter';

function inRange(min?: number, max?: number, value?: number) {
    min = min === undefined ? -Infinity : min;
    max = max === undefined ? Infinity : max;
    return value === undefined || (value >= min && value <= max);
}
const NumberFormatCustom = forwardRef(function NumberFormatCustom(props: NumberFormatProps, ref: React.Ref<HTMLInputElement>) {
    return <NumberFormat getInputRef={ref} {...props} />;
});
interface NumericMuiProps {
    currency?: string;
    min?: number;
    max?: number;
    decimals: number;
}
type NumericMuiInputProps = InternalInputProps<number | undefined, NumericMuiProps>;
/** Adapt Mui to NumberFormat, taken from Mui examples */
function NumericMui(props: NumericMuiInputProps) {
    const specificProps = props.type === 'standard' ? { value: props.value } : { name: props.name };
    return (
        <TextField
            variant="standard"
            error={props.invalid}
            size="small"
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            value={props.value}
            label={props.label}
            style={{ ...styleFromProps(props) }}
            fullWidth={props.width === 'full'}
            helperText={props.errorMessage ?? props.helperText}
            InputProps={{
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                inputComponent: NumberFormatCustom as any,
                inputProps: {
                    ...specificProps,
                    onValueChange: (v) => {
                        if (props.type === 'standard') {
                            props.onChange(v.floatValue);
                        } else {
                            props.hookOnChange({ target: { value: v.floatValue } });
                        }
                    },
                    thousandSeparator: true,
                    isAllowed: (v) => inRange(props.min, props.max, v.floatValue),
                    onBlur: props.onBlur,
                    prefix: props.currency,
                    decimalScale: props.decimals,
                } as NumberFormatProps,
            }}
        />
    );
}

interface NumericProps {
    min?: number;
    max?: number;
    label: string;
}
export const IntegerInput = makeHookablePermission<number | undefined, NumericProps>(function IntegerInput(props) {
    return <NumericMui decimals={0} currency="" {...props} />;
});

type DecimalProps = NumericProps & { decimals?: number };
export const DecimalInput = makeHookablePermission<number | undefined, DecimalProps>(function IntegerInput(props) {
    return <NumericMui decimals={props.decimals === undefined ? 4 : props.decimals} currency="" {...props} />;
});

type CurrencyProps = NumericProps & { currency?: string };
export const CurrencyInput = makeHookablePermission<number | undefined, CurrencyProps>(function IntegerInput(props) {
    return <NumericMui decimals={2} currency="$" {...props} />;
});
