import type { IQueryable, SearchExpr } from '@core';
import { Event } from '@core';
import type { GridColDef, GridFilterItem, GridSortItem, GridState } from '@mui/x-data-grid';
import type { EntitySearchExpr, EntitySearchOperation } from '@services/model';

export class GridQueryAdapter {
    private pageNumber?: number;
    public pageSize?: number;
    private sorts?: { col: string; desc: boolean }[];
    private filters?: GridDataStateFilter[];
    private globalSearch = '';
    private columns: Record<string, GridColDef> = {};
    public onChange = new Event<void>();

    public constructor(columns: GridColDef[]) {
        this.updateColumns(columns);
    }

    public load(state: GridState) {
        this.columns = state.columns.lookup;
        if (!this.equalsState(state)) {
            this.loadState(state);
            this.onChange.raise();
        }
    }
    public updateColumns(columns: GridColDef[]) {
        this.columns = columns.reduce((result, item) => {
            result[item.field] = item;
            return result;
        }, {} as Record<string, GridColDef>);
    }
    public updateGlobalSearch(search: string) {
        search = search.trim();
        if (search !== this.globalSearch) {
            this.globalSearch = search;
            this.onChange.raise();
        }
    }
    public applyToQuery<T>(query: IQueryable<T>) {
        let result = query;
        result = this.applyFilter(result);
        result = this.applyGlobalSearch(result);
        result = this.applySort(result);
        result = this.applyPaging(result);
        return result;
    }
    private equalsState(state: GridState) {
        return (
            state.filter.filterModel.items.length === this.filters?.length &&
            state.filter.filterModel.items.reduce((match, filter, i) => match && this.filtersMatch(filter, i), true) &&
            state.sorting.sortModel.length === this.sorts?.length &&
            state.sorting.sortModel.reduce((match, sort, i) => match && this.sortMatch(sort, i), true) &&
            state.pagination.page === this.pageNumber &&
            state.pagination.pageSize === this.pageSize
        );
    }
    private filtersMatch(gridFilter: GridFilterItem, index: number) {
        const filter = this.filters?.[index];
        return gridFilter.columnField === filter?.col && gridFilter.operatorValue === filter?.operator && gridFilter.value === filter?.value;
    }
    private sortMatch(gridSort: GridSortItem, index: number) {
        const sort = this.sorts?.[index];
        const desc = gridSort.sort === 'desc';
        return gridSort.field === sort?.col && desc === sort?.desc;
    }
    private loadState(state: GridState) {
        this.filters = state.filter.filterModel.items.map((f) => ({ col: f.columnField, operator: f.operatorValue, value: f.value }));
        this.sorts = state.sorting.sortModel.map((s) => ({ col: s.field, desc: s.sort === 'desc' }));
        this.pageNumber = state.pagination.page;
        this.pageSize = state.pagination.pageSize;
    }
    private applyFilter<T>(query: IQueryable<T>) {
        if (this.filters) {
            for (const filter of this.filters) {
                const criteria = this.convertFilter(filter);
                if (criteria) {
                    query = query.where(() => criteria as SearchExpr<T>);
                }
            }
        }
        return query;
    }
    private applyGlobalSearch<T>(query: IQueryable<T>) {
        if (this.globalSearch) {
            const criteria = [...this.createGlobalSearchCrit()];
            if (criteria.length) {
                query = query.where(() => ({ or: criteria }));
            }
        }
        return query;
    }
    private *createGlobalSearchCrit() {
        const text = `%${this.globalSearch}%`;
        const number = parseFloat(this.globalSearch);
        const isUuid = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i.test(this.globalSearch);
        for (const col of Object.values(this.columns)) {
            if (col.hide) continue;
            if (!isNaN(number) && col.type === 'number') {
                yield { eq: { [col.field]: number } };
            } else if (col.type === 'string') {
                yield { like: { [col.field]: text } };
            } else if (col.type === 'uuid' && isUuid) {
                yield { eq: { [col.field]: this.globalSearch } };
            }
        }
    }
    private applySort<T>(query: IQueryable<T>) {
        if (this.sorts) {
            for (const sort of this.sorts) {
                const expr = { field: sort.col };
                query = sort.desc ? query.sortDesc(() => expr) : query.sortAsc(() => expr);
            }
        }
        return query;
    }
    private applyPaging<T>(query: IQueryable<T>) {
        return query.page(this.pageNumber ? this.pageNumber + 1 : 1, this.pageSize || 100);
    }
    private convertFilter(filter: GridDataStateFilter) {
        if (!filter.col) {
            return undefined;
        } else if (!filter.operator) {
            return undefined;
        } else {
            return this.convertCriteria(filter.col, filter.operator, filter.value);
        }
    }
    private convertCriteria(field: string, gridOperator: string, value: unknown) {
        switch (gridOperator) {
            case 'isEmpty':
                return this.convertEmptyCheck(field, 'isNull');
            case 'isNotEmpty':
                return this.convertEmptyCheck(field, 'isNotNull');
            default:
                return this.convertBinaryOp(field, gridOperator, value);
        }
    }
    private convertEmptyCheck(field: string, op: 'isNull' | 'isNotNull') {
        const col = this.columns[field];
        if (col) {
            if (col.type === 'string') {
                const result = {
                    or: [{ isNull: [{ field }] }, { eq: { [field]: '' } }],
                };
                return op === 'isNotNull' ? { not: result } : result;
            } else {
                return { [op]: [{ field }] };
            }
        }
        return undefined;
    }
    private convertBinaryOp(field: string, gridOperator: string, value: unknown) {
        const operation = this.convertOperator(gridOperator);
        const operands: EntitySearchExpr[] = [{ field }];
        const valueOp = this.convertValue(gridOperator, value);
        if (valueOp) {
            operands.push(valueOp);
        }
        return { operation, operands };
    }
    private convertValue(gridOp: string, value: unknown) {
        if (value === undefined) value = '';
        switch (gridOp) {
            case 'contains':
                return { value: `%${value}%` };
            case 'startsWith':
                return { value: `${value}%` };
            case 'endsWith':
                return { value: `%${value}` };
            default:
                return { value };
        }
    }
    private convertOperator(gridOp: string): EntitySearchOperation {
        switch (gridOp) {
            case 'contains':
            case 'endsWith':
            case 'startsWith':
                return 'Like';
            case 'is':
            case '=':
            case 'equals':
                return 'Eq';
            case 'not':
            case '!=':
                return 'Ne';
            case 'after':
            case '>':
                return 'Gt';
            case 'onOrAfter':
            case '>=':
                return 'Gte';
            case 'before':
            case '<':
                return 'Lt';
            case 'onOrBefore':
            case '<=':
                return 'Lte';
            case 'isEmpty':
                return 'IsNull';
            case 'isNotEmpty':
                return 'IsNotNull';
            default:
                return 'Eq';
        }
    }
}

interface GridDataStateFilter {
    col?: string;
    value?: unknown;
    operator?: string;
}
