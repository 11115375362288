import { Copyable, useUrlParams } from "@components";
import { format, parseISO } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { Alert, Box, CircularProgress, Divider, Grid, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { OrderDetailResult, SagaStateHistoryRecord } from "@services/model";
import { useGetApiOrderOrderIdDetail, useGetApiOrderOrderIdHistory } from "@services/order/order";
import { OrderStateDisplay, BeautifyStateText } from "shared/components/Order/OrderStateDisplay";
import { titlePageRegistry } from "../PageRegistry";
import { StateContainer, CardHeader, StateItem, StateHeader, StateDate, CardItem, CardValue, BoxHeader, PaymentContainer, PaymentItem } from "shared/components/Order/OrderDetailStyles";

export function OrderDetail() {
    const { params } = useUrlParams();
    const { data: order, isLoading } = useGetApiOrderOrderIdDetail(params.id);
    const { data: stateData, isLoading: isStateDataLoding } = useGetApiOrderOrderIdHistory(params.id);

    return isLoading ? (
        <LinearProgress />
    ) : (
        <>
            {order?.orderFailureDetail != null && (
                <Box>
                    <Alert severity="error">The order has failed. Error details: {order?.orderFailureDetail?.message}</Alert>
                </Box>
            )}
            <Grid container direction="row" justifyContent="space-between" spacing={2} p={2}>
                <OrderHeader order={order!} />
            </Grid>
            <Grid container direction="row" justifyContent="space-between" spacing={2} p={2}>
                <Grid item md={2} xs={12} sx={{display: 'flex', flexDirection: 'column'}}> 
                    <BoxHeader>Order State History</BoxHeader>
                    <Paper>
                        {isStateDataLoding ? (
                            <Box p={8}>
                                <CircularProgress/>
                            </Box>
                        ):(
                            <StateContainer>
                                {stateData!.map((row) => {
                                    return(
                                        <StateItem>
                                            <StateHeader>{BeautifyStateText(row.state!)}</StateHeader>
                                            <StateDate>{format(parseISO(String(row.created_at)), 'Ppp')}</StateDate>
                                        </StateItem>
                                    )
                                })}
                            </StateContainer>
                        )}
                    </Paper>
                </Grid>
                <Grid item md={order?.paidAt == null ? 10 : 7} sx={{display: 'flex', flexDirection: 'column'}}>
                    <BoxHeader>Order Items</BoxHeader>
                    <OrderItemsTable order={order!} />
                </Grid>
                {order?.total != null && (
                    <Grid item md={3} xs={12} sx={{display: 'flex', flexDirection: 'column'}}>
                        <BoxHeader>Payment Details</BoxHeader>
                        <Paper>
                            <PaymentContainer>
                                <PaymentItem>
                                    <span>Subtotal</span>
                                    <span>${order?.subTotal!.toFixed(2)}</span>
                                </PaymentItem>
                                <PaymentItem>
                                    <span>Tax</span>
                                    <span>${order?.tax!.toFixed(2)}</span>
                                </PaymentItem>
                                <PaymentItem>
                                    <span>Fiat Total</span>
                                    <span>${(order?.tax! + order?.subTotal!).toFixed(2)}</span>
                                </PaymentItem>
                                <Divider sx={{marginTop: '5px', marginBottom: '5px'}}/>
                                <PaymentItem>
                                    <span>Charged Total</span>
                                    <span>{order.total} {order.totalCurrencyType}</span>
                                </PaymentItem>
                            </PaymentContainer>
                        </Paper>
                    </Grid>
                )}
            </Grid>
        </>
    );
}
function OrderItemsTable(props: { order: OrderDetailResult }) {
    let items = adaptOrderItems(props.order);

    // This is messier than the admin table but the admin table sucks
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Fiat Price</TableCell>
                        <TableCell>Virtual Currency Price</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((i) => (
                        <TableRow key={i.id}>
                            <TableCell>{i.name}</TableCell>
                            <TableCell>{i.quantity}</TableCell>
                            <TableCell>{i.type}</TableCell>
                            <TableCell>${i.price.toFixed(2)}</TableCell>
                            <TableCell>
                                {i.vcCode != null && (
                                    <span>{i.vcPrice} {i.vcCode}</span>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

function OrderHeader(props: { order: OrderDetailResult}) {
    return (
        <>
            <Grid item md={3} xs={12}>
                <Paper>
                    <CardItem>
                        <CardHeader>Order ID</CardHeader>
                        <CardValue><Copyable>{props.order.id}</Copyable></CardValue>
                    </CardItem>
                </Paper>
            </Grid>
            <Grid item md={3} xs={12}>
                <Paper>
                    <CardItem>
                        <CardHeader>State</CardHeader>
                        <CardValue><OrderStateDisplay state={props.order.orderState!} size="small" /></CardValue>
                    </CardItem>
                </Paper>
            </Grid>
            <Grid item md={3} xs={12}>
                <Paper>
                    <CardItem>
                        <CardHeader>Total</CardHeader>
                        <CardValue>
                            {props.order.total != null ? (
                                <span>{props.order.total} {props.order.totalCurrencyType}</span>
                            ):(
                                <span>Unpaid</span>
                            )}
                        </CardValue>
                    </CardItem>
                </Paper>
            </Grid>
            <Grid item md={3} xs={12}>
                <Paper>
                    <CardItem>
                        <CardHeader>Paid At</CardHeader>
                        <CardValue>
                            { props.order.paidAt != null ? (
                                format(parseISO(String(props.order.paidAt)), 'Ppp') 
                            ) : (
                                <span> Unpaid</span>
                            )
                            }
                        </CardValue>
                    </CardItem>
                </Paper>
            </Grid>
        </>
    );
}

function adaptOrderItems(order: OrderDetailResult) : OrderItem[] {
    let items: OrderItem[] = [];

    order.items?.forEach(i => {
        let item: OrderItem = {
            id: uuidv4(),
            name: i.displayName!,
            type: "Item",
            price: i.price!,
            vcCode: i.virtualCurrencyCode,
            vcPrice: i.virtualCurrencyPrice,
            quantity: i.quantity!
        }

        items.push(item);
    });

    order.bundles?.forEach(i => {
        let item: OrderItem = {
            id: uuidv4(),
            name: i.displayName!,
            type: "Bundle",
            price: i.price!,
            vcCode: i.virtualCurrencyCode,
            vcPrice: i.virtualCurrencyPrice,
            quantity: i.quantity!
        }

        items.push(item);
    });

    order.containers?.forEach(i => {
        let item: OrderItem = {
            id: uuidv4(),
            name: i.displayName!,
            type: "Container",
            price: i.price!,
            vcCode: i.virtualCurrencyCode,
            vcPrice: i.virtualCurrencyPrice,
            quantity: i.quantity!
        }

        items.push(item);
    });

    order.virtualCurrency?.forEach(i => {
        let item: OrderItem = {
            id: uuidv4(),
            name: i.name!,
            type: "Virtual Currency",
            price: i.price!,
            vcCode: i.virtualCurrencyCode,
            vcPrice: i.virtualCurrencyPrice,
            quantity: i.amount!
        }

        items.push(item);
    });

    return items;
}

function adaptOrderVcTotals(items: OrderItem[]) : Map<string, number> {
    let totals = new Map<string, number>();

    items.forEach(i => {
        if (i.vcCode != null && i.vcPrice != null) {
            if (totals.get(i.vcCode) != null) {
                const currentAmount = totals.get(i.vcCode);
                totals.set(i.vcCode, currentAmount! + i.vcPrice!);
            } else {
                totals.set(i.vcCode, i.vcPrice!)
            }
        }
    });

    return totals;
}

interface OrderItem {
    id: string;
    name: string;
    type: string;
    price: number;
    vcCode?: string | undefined | null,
    vcPrice?: number | undefined | null;
    quantity: number;
}

titlePageRegistry.register({ page: OrderDetail, icon: '', name: <>Order Detail</>, path: 'order-detail' });