import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    DialogActions,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    MenuItem,
    Modal,
    OutlinedInput,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { styled as muiStyled } from '@mui/styles';
import { Box } from '@mui/system';
import type {
    ShopBundle,
    ShopContainer,
    ShopItem,
    ShopTabBundle,
    ShopTabContainer,
    ShopTabItem,
    ShopTabVirtualCurrency,
    ShopVirtualCurrency,
    VirtualCurrency,
} from '@services/model';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import type { GridItem } from './ShopGridItem';

export interface EditShopItemModalProps<T extends GridItem> {
    gridItem: T;
    isOpen: boolean;
    onCancel(): void;
    onSubmit: SubmitHandler<T>;
    virtualCurrencies: VirtualCurrency[];
}

export function EditGridItemModal<T extends GridItem>(props: EditShopItemModalProps<T>) {
    const { isOpen, onCancel, onSubmit, gridItem, virtualCurrencies } = props;
    const shopId =
        (gridItem as ShopTabItem).shopItemId ??
        (gridItem as ShopTabBundle).shopBundleId ??
        (gridItem as ShopTabContainer).shopContainerId ??
        (gridItem as ShopTabVirtualCurrency).shopVirtualCurrencyId ??
        gridItem.id;

    const shopItemSchema = yup.object({
        description: yup.string().nullable(true),
        virtualCurrencyPurchasableAmount: yup
            .number()
            .min(0)
            .transform((_, val) => (val ? Number(val) : null))
            .typeError('Must be a positive number.'),
        cartLimit: yup
            .number()
            .min(0)
            .nullable(true)
            .transform((_, val) => (val ? Number(val) : null))
            .typeError('Must be a positive number.'),
        rmPrice: yup
            .number()
            .min(0)
            .nullable(true)
            .transform((_, val) => (val ? Number(val) : null))
            .typeError('Must be a positive number.'),
        virtualCurrencyId: yup
            .string()
            .nullable()
            .when('virtualCurrencyPrice', {
                is: (vCurrencyPrice: string) => vCurrencyPrice && parseInt(vCurrencyPrice, 10) > 0,
                then(schema) {
                    return schema.required('Please select a virtual currency to add a price.');
                },
            }),
        virtualCurrencyPrice: yup
            .number()
            .min(0)
            .nullable(true)
            .transform((_, val) => (val ? Number(val) : null))
            .typeError('Must be a positive number.'),
        eligibleQuantity: yup
            .number()
            .min(0)
            .nullable(true)
            .transform((_, val) => (val ? Number(val) : null))
            .typeError('Must be a positive number.'),
        virtualCurrencyCostId: yup
            .string()
            .nullable()
            .when('virtualCurrencyCostAmount', {
                is: (vCurrencyPrice: string) => vCurrencyPrice && parseInt(vCurrencyPrice, 10) > 0,
                then(schema) {
                    return schema.required('Please select a virtual currency cost to add a cost amount.');
                },
            })
            .transform((_, val) => (val === '' ? null : val)),
        virtualCurrencyCostAmount: yup
            .number()
            .min(0)
            .nullable(true)
            .transform((_, val) => (val ? Number(val) : null))
            .typeError('Must be a positive number.'),
        id: yup.string().default(shopId),
        itemId: yup.string().default((gridItem as ShopItem).itemId),
        bundleId: yup.string().default((gridItem as ShopBundle).bundleId),
        containerId: yup.string().default((gridItem as ShopContainer).containerId),
        virtualCurrencyPurchasableId: yup.string().default((gridItem as ShopVirtualCurrency).virtualCurrencyPurchasableId),
    });

    const { register, handleSubmit, formState } = useForm({
        resolver: yupResolver(shopItemSchema),
    });

    return (
        <Modal open={isOpen}>
            <Box
                component="form"
                sx={{
                    position: 'absolute',
                    top: '33%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                }}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Edit Shop Item
                    </Typography>
                    <FieldControl>
                        <TextField
                            error={Boolean(formState.errors?.description)}
                            {...register('description')}
                            label="Description"
                            variant="outlined"
                            defaultValue={gridItem.description}
                        />
                    </FieldControl>
                    {(gridItem as ShopVirtualCurrency).virtualCurrencyPurchasableId && (
                        <FieldControl>
                            <TextField
                                error={Boolean(formState.errors?.virtualCurrencyPurchasableAmount)}
                                helperText={
                                    formState.errors?.virtualCurrencyPurchasableAmount?.message ??
                                    'Amount of virtual currency granted when order is finalized.'
                                }
                                {...register('virtualCurrencyPurchasableAmount')}
                                label="Purchaseable Amount"
                                variant="outlined"
                                defaultValue={(gridItem as ShopVirtualCurrency).virtualCurrencyPurchasableAmount}
                            />
                        </FieldControl>
                    )}
                    <FieldControl>
                        <TextField
                            error={Boolean(formState.errors?.cartLimit)}
                            helperText={formState.errors?.cartLimit?.message ?? 'The max amount allowed for a single order.'}
                            {...register('cartLimit')}
                            label="Cart Limit"
                            variant="outlined"
                            defaultValue={gridItem.cartLimit}
                        />
                    </FieldControl>
                    <FieldControl>
                        <TextField
                            error={Boolean(formState.errors?.eligibleQuantity)}
                            helperText={formState.errors?.eligibleQuantity?.message ?? 'The max quantity available in this store.'}
                            {...register('eligibleQuantity')}
                            label="Eligible Quantity"
                            variant="outlined"
                            defaultValue={gridItem.eligibleQuantity}
                        />
                    </FieldControl>
                    <FieldControl>
                        <InputLabel htmlFor="fiat-price-input">Fiat Price</InputLabel>
                        <OutlinedInput
                            error={Boolean(formState.errors?.rmPrice)}
                            {...register('rmPrice')}
                            id="fiat-price-input"
                            label="Fiat Price"
                            defaultValue={gridItem.rmPrice}
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        />
                        <FormHelperText error={Boolean(formState.errors?.rmPrice)}>{formState.errors?.rmPrice?.message}</FormHelperText>
                    </FieldControl>
                    {(gridItem as ShopBundle | ShopItem | ShopContainer).virtualCurrencyId !== undefined && (
                        <FieldControl>
                            <InputLabel id="virtual-currency-selection">Virtual Currency</InputLabel>
                            <Select
                                error={Boolean(formState.errors?.virtualCurrencyId)}
                                {...register('virtualCurrencyId')}
                                labelId="virtual-currency-selection"
                                label="Virtual Currency"
                                defaultValue={(gridItem as ShopBundle | ShopItem | ShopContainer).virtualCurrencyId}
                                variant="outlined"
                            >
                                <MenuItem value="">None</MenuItem>
                                {virtualCurrencies &&
                                    virtualCurrencies.map((vCurrency) => (
                                        <MenuItem key={vCurrency.id} value={vCurrency.id as string}>
                                            {vCurrency.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                            <FormHelperText error={Boolean(formState.errors?.virtualCurrencyId)}>
                                {formState.errors?.virtualCurrencyId?.message}
                            </FormHelperText>
                        </FieldControl>
                    )}
                    {(gridItem as ShopVirtualCurrency).virtualCurrencyPurchasableId && (
                        <FieldControl>
                            <InputLabel id="virtual-currency-cost-selection">Virtual Currency</InputLabel>
                            <Select
                                error={Boolean(formState.errors?.virtualCurrencyCostId)}
                                {...register('virtualCurrencyCostId')}
                                labelId="virtual-currency-cost-selection"
                                label="Virtual Currency"
                                defaultValue={(gridItem as ShopVirtualCurrency).virtualCurrencyCostId}
                                variant="outlined"
                            >
                                <MenuItem value="">None</MenuItem>
                                {virtualCurrencies &&
                                    virtualCurrencies.map((vCurrency) => (
                                        <MenuItem key={vCurrency.id} value={vCurrency.id as string}>
                                            {vCurrency.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                            <FormHelperText error={Boolean(formState.errors?.virtualCurrencyCostId)}>
                                {formState.errors?.virtualCurrencyCostId?.message}
                            </FormHelperText>
                        </FieldControl>
                    )}
                    {(gridItem as ShopBundle | ShopItem | ShopContainer).virtualCurrencyPrice !== undefined && (
                        <FieldControl>
                            <InputLabel htmlFor="fiat-price-input">Virtual Currency Price</InputLabel>
                            <OutlinedInput
                                error={Boolean(formState.errors?.virtualCurrencyPrice)}
                                {...register('virtualCurrencyPrice')}
                                label="Virtual Currency Price"
                                defaultValue={(gridItem as ShopBundle | ShopItem | ShopContainer).virtualCurrencyPrice}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            />
                            <FormHelperText error={Boolean(formState.errors?.virtualCurrencyPrice)}>
                                {formState.errors?.virtualCurrencyPrice?.message}
                            </FormHelperText>
                        </FieldControl>
                    )}
                    {(gridItem as ShopVirtualCurrency).virtualCurrencyPurchasableId && (
                        <TextField
                            error={Boolean(formState.errors?.virtualCurrencyCostAmount)}
                            helperText={formState.errors?.virtualCurrencyCostAmount?.message ?? 'If this item does cost virtual currency, how much?'}
                            {...register('virtualCurrencyCostAmount')}
                            label="Virtual Currency Price"
                            variant="outlined"
                            defaultValue={(gridItem as ShopVirtualCurrency).virtualCurrencyCostAmount}
                        />
                    )}
                    <DialogActions>
                        <Button type="submit" variant="contained">
                            Update
                        </Button>
                        <Button variant="outlined" onClick={onCancel}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Box>
            </Box>
        </Modal>
    );
}

const FieldControl = muiStyled(FormControl)({
    margin: '12px 0px',
});
