import { TextField } from '@mui/material';

import { makeHookablePermission, styleFromProps } from './HookAdapter';

interface TextProps {
    maxLength?: number;
    label: string;
    password?: boolean;
    number?: boolean;
    placeholder?: string;
    multiline?: boolean;
    rows?: number;
    disabled?: boolean;
    readonly?: boolean;
}
export const TextInput = makeHookablePermission<string | undefined, TextProps>(function TextInput(props) {
    return (
        <TextField
            variant="standard"
            size="small"
            type={props.password ? 'password' : props.number ? 'number' : 'text'}
            label={props.label}
            placeholder={props.placeholder ?? ''}
            multiline={props.multiline}
            rows={props.rows}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            value={props.value}
            style={{ ...styleFromProps(props) }}
            fullWidth={props.width === 'full'}
            error={props.invalid}
            helperText={props.errorMessage ?? props.helperText}
            onBlur={props.onBlur}
            onChange={(e) => (props.type === 'standard' ? props.onChange(e.target.value) : props.hookOnChange(e))}
            inputProps={{ maxLength: props.maxLength, readOnly: props.readonly }}
            disabled={props.disabled}
        />
    );
});
