/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.14.2.2422
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  Resource,
  SaveResourceRequest,
  ResourceIPagedResultList,
  EntitySearch,
  ObjectIPagedResultList
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const getApiResources = (
    
 ) => {
      return orvalRequestor<Resource[]>(
      {url: `/api/resources`, method: 'get'
    },
      );
    }
  

export const getGetApiResourcesQueryKey = () => [`/api/resources`];

    
export const useGetApiResources = <TData = AsyncReturnType<typeof getApiResources>, TError = unknown>(
  options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiResources>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiResourcesQueryKey();

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiResources>> = () => getApiResources();

  const query = useQuery<AsyncReturnType<typeof getApiResources>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const postApiResources = (
    saveResourceRequest: SaveResourceRequest,
 ) => {
      return orvalRequestor<Resource>(
      {url: `/api/resources`, method: 'post',
      data: saveResourceRequest
    },
      );
    }
  


    export const usePostApiResources = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiResources>, TError,{data: SaveResourceRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiResources>, {data: SaveResourceRequest}> = (props) => {
          const {data} = props || {};

          return  postApiResources(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiResources>, TError, {data: SaveResourceRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiResourcesId = (
    id: string,
 ) => {
      return orvalRequestor<Resource>(
      {url: `/api/resources/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiResourcesIdQueryKey = (id: string,) => [`/api/resources/${id}`];

    
export const useGetApiResourcesId = <TData = AsyncReturnType<typeof getApiResourcesId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiResourcesId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiResourcesIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiResourcesId>> = () => getApiResourcesId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiResourcesId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const putApiResourcesId = (
    id: string,
    saveResourceRequest: SaveResourceRequest,
 ) => {
      return orvalRequestor<Resource>(
      {url: `/api/resources/${id}`, method: 'put',
      data: saveResourceRequest
    },
      );
    }
  


    export const usePutApiResourcesId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiResourcesId>, TError,{id: string;data: SaveResourceRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiResourcesId>, {id: string;data: SaveResourceRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiResourcesId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiResourcesId>, TError, {id: string;data: SaveResourceRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const deleteApiResourcesId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/resources/${id}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiResourcesId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiResourcesId>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiResourcesId>, {id: string}> = (props) => {
          const {id} = props || {};

          return  deleteApiResourcesId(id,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiResourcesId>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiResourcesFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ResourceIPagedResultList>(
      {url: `/api/resources/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiResourcesFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiResourcesFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiResourcesFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiResourcesFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiResourcesFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiResourcesQuery = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ObjectIPagedResultList>(
      {url: `/api/resources/query`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiResourcesQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiResourcesQuery>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiResourcesQuery>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiResourcesQuery(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiResourcesQuery>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    