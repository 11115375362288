import { AdminTable } from '@components';
import { Button, Checkbox, DialogActions, Modal } from '@mui/material';
import { Box } from '@mui/system';
import type { GridCellValue, GridColDef } from '@mui/x-data-grid';
import type { VirtualCurrency } from '@services/model';
import { useEffect, useState } from 'react';
import { useSet } from 'react-use';

export interface VirtualCurrencySelectModalProps {
    virtualCurrencies: VirtualCurrency[];
    onSubmit(currencies: VirtualCurrencyShopGrid[]): void;
    onCancel(): void;
    isOpen: boolean;
}

interface IVirtualCurrencyShopGrid extends VirtualCurrency {
    eligibleQuantity: number;
}

export class VirtualCurrencyShopGrid implements IVirtualCurrencyShopGrid {
    
    constructor() {
        this.eligibleQuantity = 1;
        this.name = "";
        this.gameEnvironmentId = "";
        this.currencyCode = "";
    }

    eligibleQuantity: number;
    id?: string | null | undefined;
    createdAt?: string | null | undefined;
    modifiedAt?: string | null | undefined;
    deletedAt?: string | null | undefined;
    name: string;
    gameEnvironmentId: string;
    description?: string | null | undefined;
    currencyCode: string;
    initialGrant?: number | null | undefined;

}

export function VirtualCurrencySelectModal(props: VirtualCurrencySelectModalProps) {
    const { virtualCurrencies, onSubmit, onCancel, isOpen } = props;
    const [ selections, { toggle, has } ] = useSet<VirtualCurrencyShopGrid>();
    const [ shopVirtualCurrencies, setShopVirtualCurrencies ] = useState<VirtualCurrencyShopGrid[]>([]);

    useEffect(() => {
        setShopVirtualCurrencies(
            virtualCurrencies?.map((virtualCurrency) => ({
                ...virtualCurrency,
                eligibleQuantity: 1
            })) as VirtualCurrencyShopGrid[]
        );
    }, [virtualCurrencies]);

    const columns: GridColDef[] = [
        {
            field: 'check',
            headerName: '',
            flex: 0,
            align: 'center',
            valueGetter: ({ row }) => has(row as VirtualCurrencyShopGrid),
            renderCell: ({ row }) => <Checkbox onClick={() => { toggle(row as VirtualCurrencyShopGrid); }}/>
        },
        {
            field: 'name',
            flex: 1,
            headerName: 'Name',
        },
        {
            field: 'eligibleQuantity',
            headerName: 'Eligible Quantity',
            flex: 1,
            editable: true,
            valueParser: (value: GridCellValue) => {
                return value as number >= 0 ? value : 1;
            },
        }
    ];

    return (
        <Modal open={isOpen}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '33%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box width={500}>
                    <Box width={500} height={500}>
                        <AdminTable<VirtualCurrencyShopGrid>
                            resource="Item"
                            title=""
                            menuActions={[]}
                            actions={[]}
                            data={shopVirtualCurrencies}
                            columns={columns}
                        />
                    </Box>
                </Box>
                <DialogActions>
                    <Button variant="contained" onClick={() => onSubmit(Array.from(selections))}>
                        Done (selections: {selections.size})
                    </Button>
                    <Button variant="outlined" onClick={onCancel}>
                        Cancel
                    </Button>
                </DialogActions>
            </Box>
        </Modal>
    );
}
