import { AdminTable, CellNavigation, confirm, LogoImageInput, modal, TextInput, ThemeEditor, UnauthorizedAccess } from '@components';
import { buildSearch, defaultSiteConfig, useApiListener, usePermissions } from '@core';
import { yupResolver } from '@hookform/resolvers/yup';
import { Delete } from '@mui/icons-material';
import { Box, Button, Divider, LinearProgress } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid';
import { useDeleteApiGamesId, usePostApiGames, usePostApiGamesFindBy } from '@services/games/games';
import type { Game } from '@services/model';
import { format, parseISO } from 'date-fns';
import { Controller, FormProvider, useForm, useFormState } from 'react-hook-form';
import { useMount } from 'react-use';
import * as yup from 'yup';

const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', flex: 0.5, hide: true },
    {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        renderCell: (cellValues) => <CellNavigation id={String(cellValues?.id)} value={String(cellValues?.value)} to="title-details" tab="envs" />,
    },
    { field: 'createdAt', headerName: 'Created', flex: 0.5, valueFormatter: (params) => format(parseISO(String(params.value)), 'Ppp') },
];
export function OrgTitles(props: { orgId?: string }) {
    const { canRead } = usePermissions("Game");
    if(!canRead) {
        return (<UnauthorizedAccess />);
    }
    const { isLoading, data, mutate } = usePostApiGamesFindBy();
    const { mutateAsync: remove } = useDeleteApiGamesId();
    const { mutateAsync: add } = usePostApiGames();
    const search = () => {
        mutate(buildSearch<Game>(1, 1000, { eq: { organizationId: props.orgId } }));
    };
    useMount(search);
    const onDelete = async (e: Partial<Game>) => {
        const didConfirm = await confirm(`Delete ${e.name ?? ''}?`, `Are you sure you want to delete this Title?`);
        if (didConfirm) {
            await remove({ id: e.id ?? '' });
        }
    };
    const onAdd = async () => {
        let title: Game = { name: '', organizationId: '', siteConfigJson: '' };
        let existingTitles = data?.items != undefined ? data.items.map(x => x.name) : [];
        await modal('Create Game Title', (close) => <NewTitlePrompt existingTitles={existingTitles} onClose={close} orgId={props.orgId || ''} onChange={(t) => (title = t)} />);
        if (title) {
            await add({ data: { ...title } });
        }
    };

    useApiListener('game', search);

    return isLoading ? (
        <LinearProgress />
    ) : (
        <>
            <AdminTable<Game>
                resource="Game"
                title="Game Titles"
                columns={columns}
                data={data && data?.items ? data?.items : []}
                menuActions={[
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    // { type: 'button', icon: Create, label: 'Edit', onClick: onEdit },
                    { type: 'button', icon: Delete, label: 'Delete', onClick: onDelete },
                ]}
                onAdd={onAdd}
            />
        </>
    );
}
function buildTitleSetupSchema(existingTitles: string[]) {
    return yup.object().shape({
        name: yup.string().required('Title name is required!').test("Unique", "Title name taken. Please choose a different title name.", x => {return x != undefined ? !existingTitles.includes(x) : true}),
    });
};

interface NewTitlePromptProps {
    onChange: (game: Game) => void;
    onClose: () => void;
    orgId: string;
    defaultValues?: Game;
    existingTitles: string[];
}
function NewTitlePrompt({ onChange, onClose, orgId, defaultValues, existingTitles }: NewTitlePromptProps) {
    const theme = {
        primary: '#00b0ff',
        secondary: '#4caf50',
        darkBg: '#102f10',
        lightBg: '#efffef',
        logo: defaultSiteConfig.theme.logo,
    };
    const form = useForm({
        resolver: yupResolver(buildTitleSetupSchema(existingTitles)),
        defaultValues: {
            name: defaultValues?.name ?? '',
            organizationId: orgId,
            siteConfig: defaultValues?.siteConfigJson ? JSON.parse(String(defaultValues?.siteConfigJson)) : { theme },
        },
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });
    const { isDirty } = useFormState({ control: form.control });
    const save = () => {
        const values = form.getValues();
        const siteConfigJson = JSON.stringify(values.siteConfig);
        onChange({ ...values, siteConfigJson });
        onClose();
    };

    return (
        <>
            <Box px={2} width={340}>
                <FormProvider {...form}>
                    <TextInput label="Title name" name={'name'} />
                    <Controller
                        name="siteConfig.theme"
                        render={({ field }) => <ThemeEditor theme={field.value} onChange={(value) => field.onChange({ target: { value } })} />}
                    />
                    <Divider />
                    <LogoImageInput name="siteConfig.theme.logo" />
                </FormProvider>
            </Box>
            <Box p={2} justifyContent="flex-end" display="flex">
                <Button variant="contained" color="primary" disabled={!isDirty} onClick={form.handleSubmit(save)}>
                    Create Game Title
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </Box>
        </>
    );
}
