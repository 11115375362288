import { Event, useDi } from '@core';
import { container, inject, injectable } from 'tsyringe';

import { AppConfigService } from './AppConfigService';
import { Logger } from './Logger';

export interface IAuthenticationService {
    logout(): void;
    start(): Promise<void>;
    getProfile(): { firstName: string; lastName: string; email: string };
    tryRelogin(): Promise<void>;
}

export interface IUser {
    firstName: string;
    lastName: string;
    email: string;
    id: string;
}
@injectable()
export class FusionAuthAuthenticationService implements IAuthenticationService {
    private reloginKey = 'relogin';
    private pollFrequency = 500;
    private credAwaiter?: Promise<void>;
    private user?: IUser;
    public onCredentialsNeeded = new Event<{ cancel: () => void; wait: () => Promise<void> }>();
    public onLoggedIn = new Event<Record<string, never>>();

    public constructor(@inject(AppConfigService) private readonly configSvc: AppConfigService, @inject(Logger) private readonly logger: Logger) {}

    public logout() {
        window.location.href = this.getLogoutUrl();
    }
    public async start() {
        if (!(await this.tryLoadLogin())) {
            this.navigateToFusionAuthLogin();
        } else {
            this.clearReloginToken();
        }
    }
    public getProfile() {
        return { ...{ firstName: '', lastName: '', email: '', id: '' }, ...this.user };
    }
    public async tryRelogin() {
        if (!this.credAwaiter) {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            let resolver = () => {};
            this.credAwaiter = new Promise<void>((r) => (resolver = r));
            const pollDisposer = this.pollForRelogin(() => {
                resolver();
                this.credAwaiter = undefined;
                this.clearReloginToken();
            });
            this.onCredentialsNeeded.raise({
                cancel: () => {
                    this.credAwaiter = undefined;
                    pollDisposer();
                },
                wait: () => this.credAwaiter!,
            });
        }
        return this.credAwaiter;
    }
    public clearReloginToken() {
        localStorage.removeItem(this.reloginKey);
    }
    public async reloadLogin() {
        await this.tryLoadLogin();
    }

    private async tryLoadLogin() {
        try {
            const response = await fetch(this.getProfileUrl(), {
                credentials: 'include',
                headers: { ['Content-Type']: 'application/json' },
                method: 'get',
            });
            if (response.ok) {
                this.user = await response.json();
                this.onLoggedIn.raise({});
                return true;
            }
        } catch (err) {
            this.logger.error(`Error loading user profile`, err);
        }
        return false;
    }

    private pollForRelogin(done: () => void) {
        let continuePolling = true;
        const poller = () => {
            if (continuePolling) {
                if (this.isReloginTokenClear()) {
                    done();
                } else {
                    setTimeout(poller, this.pollFrequency);
                }
            }
        };
        this.setReloginToken();
        poller();
        return () => (continuePolling = false);
    }

    private navigateToFusionAuthLogin() {
        location.href = this.getLoginUrl();
    }

    private getLoginUrl() {
        const query = new URLSearchParams();
        //query.append('tenant-id', 'this should be based on a config setting');
        query.append('route', window.location.href);
        return `${this.getLoginProxyUrl()}?${query.toString()}`;
    }

    private setReloginToken() {
        localStorage.setItem(this.reloginKey, '1');
    }
    private isReloginTokenClear() {
        return !localStorage.getItem(this.reloginKey);
    }

    private getLogoutUrl() {
        return this.configSvc.getConfig().apis.mythicalFusionAuth.signoutUrl;
    }
    private getLoginProxyUrl() {
        return this.configSvc.getConfig().apis.mythicalFusionAuth.loginProxyUrl;
    }
    private getProfileUrl() {
        return `${this.configSvc.getConfig().apis.gameServicesUrl}/api/user-accounts/my-profile`;
    }
}

export const AuthenticationServiceToken = Symbol();
container.registerSingleton(AuthenticationServiceToken, FusionAuthAuthenticationService);

export function useAuthenticationService() {
    return useDi(AuthenticationServiceToken) as IAuthenticationService;
}
