/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.14.2.2422
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ShopContainer,
  SaveShopContainerRequest,
  ShopContainerIPagedResultList,
  EntitySearch,
  ObjectIPagedResultList
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const postApiShopContainer = (
    saveShopContainerRequest: SaveShopContainerRequest,
 ) => {
      return orvalRequestor<ShopContainer>(
      {url: `/api/shop-container`, method: 'post',
      data: saveShopContainerRequest
    },
      );
    }
  


    export const usePostApiShopContainer = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiShopContainer>, TError,{data: SaveShopContainerRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiShopContainer>, {data: SaveShopContainerRequest}> = (props) => {
          const {data} = props || {};

          return  postApiShopContainer(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiShopContainer>, TError, {data: SaveShopContainerRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiShopContainerId = (
    id: string,
 ) => {
      return orvalRequestor<ShopContainer>(
      {url: `/api/shop-container/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiShopContainerIdQueryKey = (id: string,) => [`/api/shop-container/${id}`];

    
export const useGetApiShopContainerId = <TData = AsyncReturnType<typeof getApiShopContainerId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiShopContainerId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiShopContainerIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiShopContainerId>> = () => getApiShopContainerId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiShopContainerId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const putApiShopContainerId = (
    id: string,
    saveShopContainerRequest: SaveShopContainerRequest,
 ) => {
      return orvalRequestor<ShopContainer>(
      {url: `/api/shop-container/${id}`, method: 'put',
      data: saveShopContainerRequest
    },
      );
    }
  


    export const usePutApiShopContainerId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiShopContainerId>, TError,{id: string;data: SaveShopContainerRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiShopContainerId>, {id: string;data: SaveShopContainerRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiShopContainerId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiShopContainerId>, TError, {id: string;data: SaveShopContainerRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const deleteApiShopContainerId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/shop-container/${id}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiShopContainerId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiShopContainerId>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiShopContainerId>, {id: string}> = (props) => {
          const {id} = props || {};

          return  deleteApiShopContainerId(id,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiShopContainerId>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiShopContainerFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ShopContainerIPagedResultList>(
      {url: `/api/shop-container/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiShopContainerFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiShopContainerFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiShopContainerFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiShopContainerFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiShopContainerFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiShopContainerQuery = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ObjectIPagedResultList>(
      {url: `/api/shop-container/query`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiShopContainerQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiShopContainerQuery>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiShopContainerQuery>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiShopContainerQuery(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiShopContainerQuery>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    