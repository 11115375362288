import { useAppConfig } from '@core';
import { Warning } from '@mui/icons-material';
import { Chip, Tooltip } from '@mui/material';
import { amber, blueGrey, deepOrange, green, red, yellow } from '@mui/material/colors';
import { ReactNode } from 'react';

const production = 'Production';

const envTags: { [key: string]: { name: string | ReactNode; color: string } } = {
    local: { name: 'Local', color: blueGrey['600'] },
    dev: { name: 'Dev', color: green['700'] },
    qa: { name: 'QA', color: amber['900'] },
    loadtest: { name: 'Load Testing', color: blueGrey['600'] },
    staging: { name: 'Staging', color: deepOrange['600'] },
    prod: { name: production, color: red['700'] },
    unknown: { name: 'Unknown', color: blueGrey['500'] },
};

export function EnvTag({ isSandbox }: EnvTagProps) {
    const appConfig = useAppConfig();
    let envTag = appConfig.env in envTags ? envTags[appConfig.env] : envTags['unknown'];
    let isLive = isSandbox === false;
    if (isLive) {
        envTag = { name: <span style={{ display: "flex", alignItems: 'center', flexWrap: 'wrap', gap: '.2em' }}>{envTag.name} - LIVE<Warning color='warning' /></span>, color: yellow[400] }
    }

    return (
        <Tooltip title="Current environment">
            <Chip label={envTag.name} color="primary" style={{ backgroundColor: envTag.color }} ></Chip>
        </Tooltip>
    );
}

export interface EnvTagProps {
    isSandbox?: boolean;
}
