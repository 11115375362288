import { Add, Delete, Edit } from '@mui/icons-material';
import { Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper } from '@mui/material';
import { GridMenuIcon } from '@mui/x-data-grid';
import type { ShopTab } from '@services/model';
import { useState } from 'react';

import { AddShopTabModal } from './AddShopTabModal';
import { EditShopTabModal } from './EditShopTabModal';
import { RemoveConfirmationDialog } from './RemoveConfirmationDialog';
import { useShop } from './ShopProvider';
import { UserPermissions } from './UserPermissions';

export function ShopTabMenu() {
    const { selectedShopTab, selectShopTab, selectedShopVersion, updateShopTab, getShopTabs, addShopTab, removeShopTab } = useShop();

    const [isEditModalOpen, setIsModalEditOpen] = useState<boolean>(false);
    const [isAddModalOpen, setIsModalAddOpen] = useState<boolean>(false);
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();

    const handleCloseShopTabMenu = () => {
        setAnchorEl(void 0);
    };

    const handleOpenEditTab = () => {
        handleCloseShopTabMenu();
        setIsModalEditOpen(true);
    };
    const handleOpenAddTab = () => {
        handleCloseShopTabMenu();
        setIsModalAddOpen(true);
    };
    const handleOpenRemoveShopTabModal = () => {
        handleCloseShopTabMenu();
        setIsRemoveModalOpen(true);
    };

    const handleAddShopTab = (tab: ShopTab) => {
        if (selectedShopVersion?.id) {
            addShopTab(
                { data: { ...tab, shopVersionId: selectedShopVersion?.id } },
                {
                    onSuccess(data) {
                        getShopTabs(selectedShopVersion?.id as string);
                        selectShopTab(data);
                    },
                }
            );
        }
        handleCloseAddShopModal();
    };

    const handleEditShopTab = (tab: ShopTab) => {
        if (selectedShopVersion?.id) {
            updateShopTab(
                { data: { ...tab, shopVersionId: selectedShopVersion?.id }, id: tab.id as string },
                {
                    onSuccess() {
                        getShopTabs(selectedShopVersion?.id as string);
                    },
                }
            );
        }
        handleCloseEditShopModal();
    };

    const handleRemoveShopTab = (tab: ShopTab) => {
        if (selectedShopVersion?.id) {
            removeShopTab(
                { id: tab.id as string },
                {
                    onSuccess() {
                        getShopTabs(selectedShopVersion?.id as string);
                    },
                }
            );
        }
        handleCloseRemoveShopTabModal();
    };

    const handleCloseAddShopModal = () => {
        setIsModalAddOpen(false);
    };
    const handleCloseEditShopModal = () => {
        setIsModalEditOpen(false);
    };

    const handleCloseRemoveShopTabModal = () => {
        setIsRemoveModalOpen(false);
    };
    return (
        <>
            <IconButton sx={{ '&:hover': { background: 'none' } }} onClick={(event) => setAnchorEl(event.currentTarget)}>
                <GridMenuIcon color="primary" />
            </IconButton>
            <Paper>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseShopTabMenu}>
                    <UserPermissions requires={['update']} resource="Shop">
                        {selectedShopTab && (
                            <div>
                                <MenuItem onClick={handleOpenEditTab}>
                                    <ListItemIcon>
                                        <Edit />
                                    </ListItemIcon>
                                    <ListItemText>Edit Current Tab</ListItemText>
                                </MenuItem>
                                <Divider />
                            </div>
                        )}
                    </UserPermissions>
                    <UserPermissions requires={['delete']} resource="Shop">
                        {selectedShopTab && (
                            <div>
                                <MenuItem onClick={handleOpenRemoveShopTabModal}>
                                    <ListItemIcon>
                                        <Delete />
                                    </ListItemIcon>
                                    <ListItemText>Delete Current Tab</ListItemText>
                                </MenuItem>
                                <Divider />
                            </div>
                        )}
                    </UserPermissions>
                    <UserPermissions requires={['create']} resource="Shop">
                        <MenuItem onClick={handleOpenAddTab}>
                            <ListItemIcon>
                                <Add />
                            </ListItemIcon>
                            <ListItemText>Add New Tab</ListItemText>
                        </MenuItem>
                    </UserPermissions>
                </Menu>
            </Paper>
            <AddShopTabModal isOpen={isAddModalOpen} onClose={handleCloseAddShopModal} onAddShopTab={handleAddShopTab} />
            {selectedShopTab && (
                <>
                    <RemoveConfirmationDialog
                        displayName={selectedShopTab.name}
                        isOpen={isRemoveModalOpen}
                        onSubmit={() => handleRemoveShopTab(selectedShopTab)}
                        onCancel={handleCloseRemoveShopTabModal}
                    />
                    <EditShopTabModal
                        isOpen={isEditModalOpen}
                        onClose={handleCloseEditShopModal}
                        onEditShopTab={handleEditShopTab}
                        shopTab={selectedShopTab}
                    />
                </>
            )}
        </>
    );
}
