/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import styled from '@emotion/styled';
import { Popover, TextField } from '@mui/material';
import { useState } from 'react';
import { ChromePicker } from 'react-color';

import { makeHookablePermission, styleFromProps } from './HookAdapter';

export const ColorInput = makeHookablePermission<string | undefined, unknown>(function ColorInput(props) {
    const [pickerAnchor, setPickerAnchor] = useState<HTMLElement>();
    const onChange = (color: string) => {
        if (props.type === 'standard') {
            props.onChange(color);
        } else {
            props.hookOnChange({ target: { value: color } });
        }
    };

    return (
        <>
            <TextField
                variant="standard"
                size="small"
                label={props.label}
                value={props.value}
                style={{
                    ...styleFromProps({ width: 130, ...props }),
                }}
                fullWidth={props.width === 'full'}
                error={props.invalid}
                helperText={props.errorMessage ?? props.helperText}
                onBlur={props.onBlur}
                onChange={(e) => onChange(e.target.value)}
                InputProps={{
                    startAdornment: <Swatch onClick={(e) => setPickerAnchor(e.target as HTMLElement)} color={props.value} />,
                }}
            />
            <Popover
                open={!!pickerAnchor}
                anchorEl={pickerAnchor}
                onClose={() => setPickerAnchor(undefined)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <ChromePicker color={props.value} onChangeComplete={(c) => onChange(c.hex)} />
            </Popover>
        </>
    );
});

const Swatch = styled.button<{ color: string }>`
    min-width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.25rem;
    margin-top: -0.25rem;
    border: solid 1px #8888;
    border-radius: 1rem;
    padding: 0.125rem;
    cursor: pointer;
    background: ${(p) => p.color};
`;
