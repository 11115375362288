import { singleton } from 'tsyringe';

import { useDi } from './DiService';

declare global {
    interface Window {
        _env_: string;
    }
}

@singleton()
export class AppConfigService {
    private appConfig = localConfig;
    public start() {
        const env = window._env_;
        if (env && env !== 'local') {
            if (!(env in configs)) {
                throw new Error(`Attempted to use an unconfigured env, ${env}`);
            }
            this.appConfig = this.mergeConfig(localConfig, configs[env]);
        }
    }

    private mergeConfig(base: AppConfig, override: DeepPartial<AppConfig>) {
        return {
            ...base,
            ...override,
            apis: {
                ...base.apis,
                ...override?.apis,
                mythicalFusionAuth: {
                    ...base.apis.mythicalFusionAuth,
                    ...override?.apis?.mythicalFusionAuth,
                },
            },
        } as AppConfig;
    }

    public getConfig() {
        return this.appConfig;
    }

    public isLocal() {
        return this.appConfig.env === 'local';
    }
}

export function useAppConfig() {
    const appConfigSvc = useDi(AppConfigService);
    return appConfigSvc.getConfig();
}

type AppConfig = typeof localConfig;
const localConfig = {
    env: 'local',
    name: 'Local',
    apis: {
        gameServicesUrl: 'https://localhost:5003',
        mythicalFusionAuth: {
            clientId: '00000000-0001-0000-0000-000000000000',
            url: 'http://localhost:9011',
            loginProxyUrl: 'https://localhost:5003/api/authenticate',
            signoutUrl: 'https://localhost:5003/api/signout',
        },
        telemetryUrl: 'http://localhost:9411',
    },
    consoleLogging: true,
    gtmId: 'GTM-5H62HP8',
    cdnUrl: 'https://gs-assets.dev.mythical.dev',
    ldClientId: '62671ebf42bc5b150147900e',
};
type DeepPartial<T> = {
    [P in keyof T]?: DeepPartial<T[P]>;
};

const configs: { [key: string]: DeepPartial<AppConfig> } = {
    dev: {
        env: 'dev',
        name: 'Dev',
        apis: {
            gameServicesUrl: 'https://gs-backoffice.dev.mythical.dev',
            mythicalFusionAuth: {
                clientId: '8d70638c-7b21-424e-a810-5a1f2ccc70b2',
                url: 'https://auth-internal.mythicalgames.com',
                loginProxyUrl: 'https://gs-backoffice.dev.mythical.dev/api/authenticate',
                signoutUrl: 'https://gs-backoffice.dev.mythical.dev/api/signout',
            },
        },
        gtmId: 'GTM-5H62HP8',
        cdnUrl: 'https://gs-assets.dev.mythical.dev',
        ldClientId: '62671ebf42bc5b150147900e',
    },
    qa: {
        env: 'qa',
        name: 'QA',
        apis: {
            gameServicesUrl: 'https://gs-backoffice.qa.mythical.dev',
            mythicalFusionAuth: {
                clientId: 'a227a7b4-5cae-4784-a880-b7085b4563dd',
                url: 'https://auth-internal.mythicalgames.com',
                loginProxyUrl: 'https://gs-backoffice.qa.mythical.dev/api/authenticate',
                signoutUrl: 'https://gs-backoffice.qa.mythical.dev/api/signout',
            },
        },
        gtmId: 'GTM-5H62HP8',
        cdnUrl: 'https://gs-assets.qa.mythical.dev',
        ldClientId: '62671ee9fb51aa15a7bee3e9',
    },
    'ivi-internal': {
        env: 'ivi-internal',
        name: 'Ivi Internal',
        apis: {
            mythicalFusionAuth: {
                url: 'https://accounts.mythical.games',
            },
        },
    },
    loadtest: {
        env: 'loadtest',
        name: 'Load Testing',
        apis: {
            gameServicesUrl: 'https://gs-backoffice.lt.mythical.dev',
            mythicalFusionAuth: {
                clientId: 'a227a7b4-5cae-4784-a880-b7085b4563dd',
                url: 'https://auth.mythicalgames.com',
                loginProxyUrl: 'https://gs-backoffice.lt.mythical.dev/api/authenticate',
                signoutUrl: 'https://gs-backoffice.lt.mythical.dev/api/signout',
            },
        },
        gtmId: 'GTM-5H62HP8',
        cdnUrl: 'https://gs-assets.lt.mythical.dev',
        ldClientId: '64ca6b168bbdfb1409bcd459',
    },
    staging: {
        env: 'staging',
        name: 'Staging',
        apis: {
            gameServicesUrl: 'https://gs-backoffice.staging.mythical.dev',
            mythicalFusionAuth: {
                clientId: 'a227a7b4-5cae-4784-a880-b7085b4563dd',
                url: 'https://auth.mythicalgames.com',
                loginProxyUrl: 'https://gs-backoffice.staging.mythical.dev/api/authenticate',
                signoutUrl: 'https://gs-backoffice.staging.mythical.dev/api/signout',
            }
        },
        gtmId: 'GTM-5H62HP8',
        cdnUrl: 'https://gs-assets.staging.mythical.dev',
        ldClientId: '63939e46c9efec1113a62868',
    },
    prod: {
        env: 'prod',
        name: 'Production (Beta)',
        apis: {
            gameServicesUrl: 'https://gs-backoffice.mythical.dev',
            mythicalFusionAuth: {
                clientId: 'a227a7b4-5cae-4784-a880-b7085b4563dd',
                url: 'https://auth.mythicalgames.com',
                loginProxyUrl: 'https://gs-backoffice.mythical.dev/api/authenticate',
                signoutUrl: 'https://gs-backoffice.mythical.dev/api/signout',
            },
            telemetryUrl: '',
        },
        consoleLogging: false,
        gtmId: 'GTM-5H62HP8',
        cdnUrl: 'https://gs-assets.mythical.dev',
        ldClientId: '62671ebf42bc5b150147900f',
    },
};
