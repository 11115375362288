import type { Theme } from '@mui/material';
import { ThemeContext } from '@mui/styled-engine';
import type { Context } from 'react';
import { useCallback, useContext, useState } from 'react';
import type { EditorValue, ToolbarConfig } from 'react-rte';
import RichTextEditor from 'react-rte';

import { makeHookablePermission } from './HookAdapter';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Rte = 'default' in RichTextEditor ? (RichTextEditor as any)['default'] : RichTextEditor;

export const MarkdownEditor = makeHookablePermission<string | undefined>(function MarkdownEditor(props) {
    const [editorState, setEditorState] = useState(props.value ? Rte.createValueFromString(props.value, 'markdown') : Rte.createEmptyValue());
    const [currentText, setCurrentText] = useState('');
    const { type } = props;
    const muiTheme = useContext<Theme>(ThemeContext as Context<Theme>);
    const handleChange = useCallback(
        (value: EditorValue) => {
            setEditorState(value);
            const markdown = value.getEditorState().getCurrentContent().hasText() ? value.toString('markdown').trim() : '';
            if (currentText !== markdown) {
                setCurrentText(markdown);
                if (type === 'standard') {
                    props.onChange(markdown);
                } else {
                    props.hookOnChange({ target: { value: markdown } });
                }
            }
        },
        [currentText, props, type]
    );
    const toolbarConfig: ToolbarConfig = {
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
            { label: 'Bold', style: 'BOLD' },
            { label: 'Italic', style: 'ITALIC' },
            { label: 'Strikethrough', style: 'STRIKETHROUGH' },
            { label: 'Monospace', style: 'CODE' },
        ],
        BLOCK_TYPE_DROPDOWN: [
            { label: 'Normal', style: 'unstyled' },
            { label: 'Heading Large', style: 'header-one' },
            { label: 'Heading Medium', style: 'header-two' },
            { label: 'Heading Small', style: 'header-three' },
            { label: 'Code Block', style: 'code-block' },
        ],
        BLOCK_TYPE_BUTTONS: [
            { label: 'UL', style: 'unordered-list-item' },
            { label: 'OL', style: 'ordered-list-item' },
        ],
    };
    return (
        <Rte
            rootStyle={{ height: '180px', fontFamily: `inherit`, background: muiTheme.palette.background.paper }}
            toolbarConfig={toolbarConfig}
            value={editorState}
            onChange={handleChange}
        />
    );
});
