import { AltRoute, Business, Home, Inventory, ListAlt, PeopleAlt, Storage, Store, Visibility, VpnKey, Money } from '@mui/icons-material';
import { getApiAdminUserAuthorize } from '@services/admin-user/admin-user';
import { inject, singleton } from 'tsyringe';

import type { SidebarItem } from '../../features/shell/SidebarItems';
import { useDi } from './DiService';
import { Event, useEventValue } from './Event';
import { UserPreferenceService } from './UserPreferenceService';


export interface SiteTheme {
    primary: string;
    secondary: string;
    logo: string;
    lightBg: string;
    darkBg: string;
}
export interface SocialLinkUrls {
    discord: string | null;
    facebook: string | null;
    instagram: string | null;
    twitter: string | null;
    youTube: string | null,

};
export interface PolicyInformation {
    privacyPolicy: string | null;
    termsOfService: string | null;
};
export type SiteConfig = {
    theme: SiteTheme;
    name: string;
    id: string;
    sidebarNav?: SidebarItem[];
    socialLinks?: SocialLinkUrls | null;
    policyInformation?: PolicyInformation | null;
    orgId: string;
    isSandbox?: boolean;
} & ({ type: 'admin' } | { type: 'org' } | { type: 'game'; orgId: string });
export const defaultSiteConfig: SiteConfig = {
    type: 'admin',
    name: 'Admin',
    id: 'admin',
    theme: {
        primary: '#ff395a',
        secondary: '#9afffd',
        darkBg: '#1b2137',
        lightBg: '#fafafa',
        logo: '/assets/mythical-platform-logo.png',
    },
    sidebarNav: [
        { name: 'home', label: 'Home', Icon: Home, path: '/home' },
        { name: 'orgs', label: 'Orgs', Icon: Business, path: '/cards', resource: 'Organization' },
    ],
    socialLinks: null,
    policyInformation: null,
    orgId: 'admin'
};


@singleton()
export class ShellService {
    private _config?: SiteConfig;
    private configOptions: SiteConfig[] = [];
    public get config() {
        return this._config || defaultSiteConfig;
    }
    public configChanged = new Event<SiteConfig>();

    public constructor(@inject(UserPreferenceService) private readonly prefsService: UserPreferenceService) {}

    public setConfig(config: SiteConfig) {
        if (config !== this.config) {
            this._config = config;
            this.configChanged.raise(config);
            this.setBrowserInfo(config);
            this.prefsService.updatePrefs({ lastSiteConfigId: config.id });
        }
    }

    public getConfigOptions() {
        return this.configOptions.slice();
    }

    public async start() {
        await this.reload();
    }

    public async reload() {
        const siteNavigation = (await this.getSiteNav()) || [];
        this.configOptions = [defaultSiteConfig, ...siteNavigation];
    }

    public getSiteConfigById(siteId: string | undefined) {
        return this.configOptions.find((c) => c.id === siteId);
    }
    public setSiteConfigById(siteId: string | undefined) {
        const config = this.getSiteConfigById(siteId);
        if (config) {
            this.setConfig(config);
        }
    }

    public getSiteRootPath() {
        return `/${this.config.id}`;
    }

    public getDefaultSite() {
        const lastSelectedId = this.prefsService.getPrefs().lastSiteConfigId;
        const lastSelected = this.getSiteConfigById(lastSelectedId);
        const result = lastSelected || this.configOptions[0];
        return result;
    }

    private setBrowserInfo(config: SiteConfig) {
        document.title = config.name;
    }

    private async getSiteNav() {
        const authorization = await getApiAdminUserAuthorize();
        var siteConfigs =  authorization?.siteNavigation?.organizations?.map((org) => {
            const theme: { theme: SiteTheme } = JSON.parse(org.siteConfigJson || 'null') || { theme: defaultSiteConfig.theme };
            return {
                name: org.name || 'No Name',
                id: org.id || '',
                type: 'org',
                sidebarNav: [
                    { name: 'accounts', label: 'Accounts', Icon: PeopleAlt, path: '/accounts', resource: 'AdminUser' },
                    { name: 'title', label: 'Game Titles', Icon: ListAlt, path: '/titles', resource: 'Game' },
                    { name: 'auth', label: 'Auth', Icon: VpnKey, path: '/auth', resource: 'Role' },
                ],
                orgId: org.id || '',
                ...theme,
            } as SiteConfig;
        });

        authorization?.siteNavigation?.organizations?.forEach(org => {
            org.games?.forEach(game => {
                const config: { theme: SiteTheme; sidebarNav: SidebarItem[] } = {
                    theme: defaultSiteConfig.theme,
                    sidebarNav: [],
                    ...JSON.parse(game?.siteConfigJson || 'null'),
                };
                game.gameEnvironments?.forEach(environment => {
                    siteConfigs?.push( {
                        name: `${game?.name || ''} - ${environment?.name || ''}`,
                        id: environment.id || '',
                        orgId: game?.organizationId,
                        type: 'game',
                        sidebarNav: [
                            { name: 'data', label: 'Game Title Data', Icon: Storage, path: '/data@tab=server' },
                            { name: 'players', label: 'Players', Icon: PeopleAlt, path: '/players', resource: 'Player' },
                            { name: 'virtualCurrency', label: 'Virtual Currencies', Icon: Money, path: '/virtualcurrencies', resource: 'VirtualCurrency' },
                            { name: 'items', label: 'Items', Icon: Inventory, path: '/items@tab=items', resource: 'Item' },
                            { name: 'shops', label: 'Shops', Icon: Store, path: '/shops', resource: 'Shop' },
                            { name: 'version', label: 'Game Versions', Icon: AltRoute, path: '/versions', resource: 'GameVersion' },
                            { name: 'logs', label: 'Logs', Icon: Visibility, path: '/logs', resource: 'AdminRequest' },
                            ...config.sidebarNav,
                        ],
                        theme: {
                            ...config.theme,
                        },
                        isSandbox: environment.isSandbox,
                    } as SiteConfig)
                });
            });
        });
        return siteConfigs;    
    }
}

export function useSiteConfig() {
    const shellSvc = useDi(ShellService);
    const config = useEventValue(shellSvc.configChanged, shellSvc.config);
    return config;
}

export function useSiteRootPath() {
    const shellSvc = useDi(ShellService);
    return shellSvc.getSiteRootPath();
}