import { yupResolver } from '@hookform/resolvers/yup';
import { DateTimePicker } from '@mui/lab';
import { Button, DialogActions, Modal, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import type { ShopVersion } from '@services/model';
import { addDays } from 'date-fns';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export interface AddShopVersionModalProps {
    isOpen: boolean;
    onClose: () => void;
    onAddShopVersion: (shopVersion: ShopVersion) => void;
}

export function AddShopVersionModal(props: AddShopVersionModalProps) {
    const { isOpen, onClose, onAddShopVersion } = props;

    const versionSchema = yup
        .object({
            name: yup.string().required('Shop Name is required!'),
            startDate: yup.date().required('Start Date is Required'),
            endDate: yup.date().min(yup.ref('startDate'), 'End date must be grater than start date'),
            preloadStartDate: yup.date().nullable().default(null).max(yup.ref('startDate'), 'Preload date must be earlier than start date'),
            previewDate: yup.date().nullable().default(null).max(yup.ref('startDate'), 'Preview date must be earlier than start date'),
        })
        .required();

    const { register, handleSubmit, formState, setValue, watch } = useForm({
        resolver: yupResolver(versionSchema),
        defaultValues: {
            name: null,
            startDate: new Date().toJSON(),
            endDate: addDays(new Date(), 7).toJSON(),
            preloadStartDate: new Date().toJSON(),
            previewDate: new Date().toJSON(),
        },
    });

    return (
        <Modal open={isOpen}>
            <Box
                component="form"
                sx={{
                    position: 'absolute',
                    top: '33%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                }}
                onSubmit={handleSubmit(onAddShopVersion)}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        '& > *': {
                            mb: 4,
                        },
                    }}
                >
                    <Typography variant="h6">Add Shop Version</Typography>
                    <TextField
                        {...register('name')}
                        error={!!formState.errors?.name?.message}
                        helperText={formState.errors?.name?.message}
                        label="Version Name"
                        variant="outlined"
                    />
                    <DateTimePicker
                        label="Start Date"
                        value={watch('startDate')}
                        onChange={(startDate: string | null) => setValue('startDate', startDate as string)}
                        renderInput={(props) => {
                            return (
                                <TextField
                                    {...props}
                                    name="startDate"
                                    variant="outlined"
                                    error={!!formState.errors?.startDate}
                                    helperText={formState.errors?.startDate?.message}
                                />
                            );
                        }}
                    />
                    <DateTimePicker
                        label="End Date"
                        value={watch('endDate')}
                        onChange={(endDate: string | null) => setValue('endDate', endDate as string)}
                        renderInput={(props) => {
                            return (
                                <TextField
                                    {...props}
                                    name="endDate"
                                    variant="outlined"
                                    error={!!formState.errors?.endDate}
                                    helperText={formState.errors?.endDate?.message}
                                />
                            );
                        }}
                    />
                    <DateTimePicker
                        label="Preload Date"
                        value={watch('preloadStartDate')}
                        onChange={(preloadStartDate: string | null) => setValue('preloadStartDate', preloadStartDate as string)}
                        renderInput={(props) => {
                            return (
                                <TextField
                                    {...props}
                                    name="preloadStartDate"
                                    variant="outlined"
                                    error={!!formState.errors?.preloadStartDate}
                                    helperText={formState.errors?.preloadStartDate?.message}
                                />
                            );
                        }}
                    />
                    <DateTimePicker
                        label="Preview Date"
                        value={watch('previewDate')}
                        onChange={(previewDate: string | null) => setValue('previewDate', previewDate as string)}
                        renderInput={(props) => {
                            return (
                                <TextField
                                    {...props}
                                    name="previewDate"
                                    variant="outlined"
                                    error={!!formState.errors?.previewDate}
                                    helperText={formState.errors?.previewDate?.message}
                                />
                            );
                        }}
                    />
                </Box>
                <DialogActions>
                    <Button type="submit" variant="contained">
                        Create Shop Version
                    </Button>
                    <Button variant="outlined" onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Box>
        </Modal>
    );
}
