import { LogoImageInput, monitor, TextInput, ThemeEditor } from '@components';
import type { SiteTheme } from '@core';
import { defaultSiteConfig } from '@core';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography } from '@mui/material';
import { usePostApiOrganizations } from '@services/organizations/organizations';
import { Controller, FormProvider, useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';

const orgSetupSchema = yup.object().shape({
    name: yup.string().required('Name is required!'),
});

export function OrgSetup(props: { onClose: (orgId: string) => void }) {
    const { mutateAsync } = usePostApiOrganizations();

    const form = useForm({
        resolver: yupResolver(orgSetupSchema),
        defaultValues: {
            name: '',
            siteConfig: {
                theme: {
                    darkBg: '#000',
                    lightBg: '#fff',
                    logo: defaultSiteConfig.theme.logo,
                    primary: '#3f50b5',
                    secondary: '#f44336',
                } as SiteTheme,
            },
        },
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });
    const { isDirty } = useFormState({ control: form.control });
    const save = async () => {
        const values = form.getValues();
        monitor({
            failureMessage: 'Error saving new organization!',
            successMessage: 'Organization created successfully!',
            action: async () => {
                const result = await mutateAsync({
                    data: {
                        name: values.name,
                        siteConfigJson: JSON.stringify(values.siteConfig),
                    },
                });

                props.onClose(result.id || '');
            },
        });

    };
    return (
        <Box width={400}>
            <FormProvider {...form}>
                <Box px={4} py={2}>
                    <TextInput label="Organization Name" placeholder="No Name" name="name" helperText="What's the actual name of the organization?" />
                </Box>
                <Typography variant="body1" px={4} my={1}>
                    Color Scheme
                </Typography>
                <Box px={4}>
                    <Controller
                        name="siteConfig.theme"
                        render={({ field }) => (
                            <ThemeEditor theme={field.value} onChange={(value) => field.onChange({ target: { value } })} />
                        )}
                    />
                </Box>
                <Typography variant="body1" px={4} my={1}>
                    Logo
                </Typography>
                <Box px={4}>
                    <LogoImageInput name="siteConfig.theme.logo" />
                </Box>
            </FormProvider>
            <Box p={2} justifyContent="flex-end" display="flex">
                <Button variant="contained" color="primary" disabled={!isDirty} onClick={form.handleSubmit(save)}>
                    Create Org
                </Button>
                <Button onClick={() => props.onClose('')}>Cancel</Button>
            </Box>
        </Box>
    );
}
