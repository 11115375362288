/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.14.2.2422
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  SaveShopVersionContentRequest,
  ShopVersion,
  SaveShopVersionRequest,
  ShopVersionIPagedResultList,
  EntitySearch,
  ObjectIPagedResultList
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const postApiShopVersionsContent = (
    saveShopVersionContentRequest: SaveShopVersionContentRequest,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/shop-versions/Content`, method: 'post',
      data: saveShopVersionContentRequest
    },
      );
    }
  


    export const usePostApiShopVersionsContent = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiShopVersionsContent>, TError,{data: SaveShopVersionContentRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiShopVersionsContent>, {data: SaveShopVersionContentRequest}> = (props) => {
          const {data} = props || {};

          return  postApiShopVersionsContent(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiShopVersionsContent>, TError, {data: SaveShopVersionContentRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiShopVersions = (
    saveShopVersionRequest: SaveShopVersionRequest,
 ) => {
      return orvalRequestor<ShopVersion>(
      {url: `/api/shop-versions`, method: 'post',
      data: saveShopVersionRequest
    },
      );
    }
  


    export const usePostApiShopVersions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiShopVersions>, TError,{data: SaveShopVersionRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiShopVersions>, {data: SaveShopVersionRequest}> = (props) => {
          const {data} = props || {};

          return  postApiShopVersions(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiShopVersions>, TError, {data: SaveShopVersionRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const putApiShopVersionsId = (
    id: string,
    saveShopVersionRequest: SaveShopVersionRequest,
 ) => {
      return orvalRequestor<ShopVersion>(
      {url: `/api/shop-versions/${id}`, method: 'put',
      data: saveShopVersionRequest
    },
      );
    }
  


    export const usePutApiShopVersionsId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiShopVersionsId>, TError,{id: string;data: SaveShopVersionRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiShopVersionsId>, {id: string;data: SaveShopVersionRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiShopVersionsId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiShopVersionsId>, TError, {id: string;data: SaveShopVersionRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const deleteApiShopVersionsId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/shop-versions/${id}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiShopVersionsId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiShopVersionsId>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiShopVersionsId>, {id: string}> = (props) => {
          const {id} = props || {};

          return  deleteApiShopVersionsId(id,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiShopVersionsId>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiShopVersionsId = (
    id: string,
 ) => {
      return orvalRequestor<ShopVersion>(
      {url: `/api/shop-versions/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiShopVersionsIdQueryKey = (id: string,) => [`/api/shop-versions/${id}`];

    
export const useGetApiShopVersionsId = <TData = AsyncReturnType<typeof getApiShopVersionsId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiShopVersionsId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiShopVersionsIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiShopVersionsId>> = () => getApiShopVersionsId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiShopVersionsId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const postApiShopVersionsFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ShopVersionIPagedResultList>(
      {url: `/api/shop-versions/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiShopVersionsFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiShopVersionsFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiShopVersionsFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiShopVersionsFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiShopVersionsFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiShopVersionsQuery = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ObjectIPagedResultList>(
      {url: `/api/shop-versions/query`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiShopVersionsQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiShopVersionsQuery>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiShopVersionsQuery>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiShopVersionsQuery(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiShopVersionsQuery>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    