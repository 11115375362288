import { ImageInput } from '@components';

export function LogoImageInput(props: { name: string }) {
    return (
        <ImageInput
            uploadUrl="/api/Organizations/Asset"
            name={props.name}
            width={300}
            maxHeight={150}
            label="Logo Image"
            helperText="3:1 ratio, wide logo image, with white foreground"
        />
    );
}
