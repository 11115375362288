import { ChangeTrackerService, Event, searchBuilder } from '@core';
import { postApiGameEnvironmentsQuery } from '@services/game-environments/game-environments';
import type { CanHave, EntitySearch, Permission, Resource, Role } from '@services/model';
import { postApiPermission, postApiPermissionFindBy, putApiPermissionId } from '@services/permission/permission';
import { postApiResourcesFindBy } from '@services/resources/resources';
import { deleteApiRoleId, postApiRole, postApiRoleFindBy } from '@services/role/role';

class RoleChangeTracker extends ChangeTrackerService {
    public roleChanges = this.entityChangeTracker<Role>();
    public permissionChanges = this.entityChangeTracker<Permission>();

    public getPermissionSavesByRole() {
        return this.permissionChanges.getSaves().reduce((result, item) => {
            const perms = result.get(item.roleId);
            if (!perms) {
                result.set(item.roleId, [item]);
            } else {
                perms.push(item);
            }
            return result;
        }, new Map<string, Permission[]>());
    }
}
export class PermissionBuilder {
    public permissionChanged = new Event<Permission>();
    public constructor(
        private readonly changeTracker: RoleChangeTracker,
        public readonly resource: Resource,
        public readonly permission: Permission
    ) {}

    public update(changes: Partial<CanHave>) {
        Object.assign(this.permission, changes);
        this.permissionChanged.raise(this.permission);
        this.changeTracker.permissionChanges.save(this.permission);
    }
}
export class PermissionSetBuilder {
    private permissionLookup = new Map<string, PermissionBuilder>();
    private scopes: PermissionScope[] = [];
    private resourcesByScopeType = new Map<string, Resource[]>();
    private resourceById = new Map<string, Resource>();
    private scopeById = new Map<string, PermissionScope>();

    public constructor(private readonly changeTracker: RoleChangeTracker, private readonly organizationId?: string) {}

    public async init() {
        const [permissions, resources, scopes] = await Promise.all([this.loadPermissions(), this.loadResources(), this.loadScopes()]);
        this.scopes = scopes;
        this.resourceById = new Map<string, Resource>(resources.map((r) => [r.id!, r]));
        this.permissionLookup = new Map<string, PermissionBuilder>(
            permissions.map((p) => [this.permissionKey(p), new PermissionBuilder(this.changeTracker, this.resourceById.get(p.resourceId)!, p)])
        );
        this.scopeById = new Map<string, PermissionScope>(scopes.map((s) => [s.id, s]));
        this.resourcesByScopeType.clear();
        for (const resource of resources) {
            const type = resource.scopeEntityIdType || 'GameEnvironment';
            if (!this.resourcesByScopeType.has(type)) {
                this.resourcesByScopeType.set(type, [resource]);
            } else {
                this.resourcesByScopeType.get(type)?.push(resource);
            }
        }
    }

    public getScopes() {
        return this.scopes;
    }

    public bulkUpdate(scopeEntityId: string, roleId: string, can: keyof CanHave) {
        const permissions = this.getPermissionSet(scopeEntityId, roleId);
        const nextValue = permissions.length ? !permissions[0].permission[can] : true;
        for (const permission of permissions) {
            permission.update({ [can]: nextValue });
        }
    }

    public getPermissionSet(scopeEntityId: string, roleId: string) {
        const scope = this.scopeById.get(scopeEntityId);
        const result: PermissionBuilder[] = [];
        if (scope) {
            const resources = this.resourcesByScopeType.get(scope.type) || [];
            for (const resource of resources) {
                const resourceId = resource.id!;
                const key = this.permissionKey({ scopeEntityId, roleId, resourceId });
                let perm = this.permissionLookup.get(key);
                if (!perm) {
                    perm = new PermissionBuilder(this.changeTracker, resource, { scopeEntityId, roleId, resourceId } as Permission);
                    this.permissionLookup.set(key, perm);
                }
                result.push(perm);
            }
        }
        return result;
    }

    private permissionKey({ scopeEntityId, roleId, resourceId }: { scopeEntityId: string; roleId: string; resourceId: string }) {
        return `${scopeEntityId}/${roleId}/${resourceId}`;
    }

    private async loadPermissions() {
        let search: EntitySearch = {};
        if (!this.organizationId) {
            search = searchBuilder('Permission')
                .leftJoin('Role', ([p, r]) => ({ eq: { [p.roleId]: r.id } }))
                .where(([_p, r]) => ({ isNull: [r.scopeEntityId] }))
                .build();
        } else {
            search = searchBuilder('Permission')
                .leftJoin('Role', ([p, r]) => ({ eq: { [p.roleId]: r.id } }))
                .leftJoin('GameEnvironment', ([_p, r, ge]) => ({ eq: { [r.scopeEntityId]: ge.id } }))
                .leftJoin('Game', ([_p, _r, ge, g]) => ({ eq: { [ge.gameId]: g.id } }))
                .where(([_p, r, _ge, g]) => ({
                    or: [{ eq: { [r.scopeEntityId]: this.organizationId } }, { eq: { [g.organizationId]: this.organizationId } }],
                }))
                .build();
        }
        const results = await postApiPermissionFindBy(search);
        return results.items || [];
    }

    private async loadResources() {
        let search: EntitySearch = {};
        if (!this.organizationId) {
            search = searchBuilder('Resource')
                .where((r) => ({ isNull: [r.scopeEntityId] }))
                .sortAsc((r) => r.name)
                .build();
        } else {
            search = searchBuilder('Resource')
                .leftJoin('Organization', ([r, o]) => ({ eq: { [r.scopeEntityId]: o.id } }))
                .leftJoin('GameEnvironment', ([r, _o, ge]) => ({ eq: { [r.scopeEntityId]: ge.id } }))
                .leftJoin('Game', ([_r, _o, ge, g]) => ({ eq: { [ge.gameId]: g.id } }))
                .where(([r, o, _ge, g]) => ({
                    or: [
                        { eq: { [o.nameof.id]: this.organizationId } },
                        { eq: { [g.organizationId]: this.organizationId } },
                        { isNull: [r.scopeEntityId] },
                    ],
                }))
                .sortAsc(([r]) => r.name)
                .build();
        }
        const results = await postApiResourcesFindBy(search);
        return results.items || [];
    }

    private async loadScopes() {
        if (this.organizationId) {
            const results = await searchBuilder('GameEnvironment')
                .leftJoin('Game', ([ge, g]) => ({ eq: { [ge.gameId]: g.id } }))
                .where(([_ge, g]) => ({ eq: { [g.organizationId]: this.organizationId } }))
                .sortAsc(([_ge, g]) => g.name)
                .sortAsc(([ge, _g]) => ge.name)
                .select(([ge, g]) => ({ id: ge.id!, envName: ge.name, gameName: g.name }))
                .execute(postApiGameEnvironmentsQuery);

            const orgScope = { id: this.organizationId, name: 'Organization', type: 'Organization' };
            const gameEnvScopes = (results.items || []).map((s) => ({ id: s.id, name: `${s.gameName} - ${s.envName}`, type: 'GameEnvironment' }));
            return [orgScope, ...gameEnvScopes];
        }
        return [];
    }
}
export class RoleBuilder {
    public roleChanged = new Event<Role>();

    public constructor(public role: Role, private readonly changeTracker: RoleChangeTracker) {}

    public updateRole(name: string, description: string) {
        this.role.name = name;
        this.role.description = description;
        this.roleChanged.raise(this.role);
        this.changeTracker.roleChanges.save(this.role);
    }
}
export interface PermissionScope {
    id: string;
    type: string;
    name: string;
}

export class ExpansionState {
    public expansionChanged = new Event<void>();
    private readonly lookup = new Set<unknown>();
    public isExpanded = (o: unknown) => {
        return this.lookup.has(o);
    };
    public toggle = (o: unknown) => {
        if (this.lookup.has(o)) {
            this.lookup.delete(o);
        } else {
            this.lookup.add(o);
        }
        this.expansionChanged.raise();
    };
}

export class RolesPageService {
    public readonly changeTracker = new RoleChangeTracker();
    public readonly permissionSetBuilder: PermissionSetBuilder;
    public readonly rolesChanged = new Event<RoleBuilder[]>();
    public readonly loadingChanged = new Event<boolean>();
    public readonly saving = new Event<boolean>();
    public roleBuilders: RoleBuilder[] = [];

    public constructor(public readonly organizationId?: string) {
        this.permissionSetBuilder = new PermissionSetBuilder(this.changeTracker, this.organizationId);
    }

    public async init() {
        this.loadingChanged.raise(true);
        const [roles] = await Promise.all([this.loadRoles(), this.permissionSetBuilder.init()]);
        this.roleBuilders = roles.map((r) => new RoleBuilder(r, this.changeTracker));
        this.loadingChanged.raise(false);
    }

    public async reload() {
        this.changeTracker.clear();
        await this.init();
    }

    public addRole(role: Role) {
        this.changeTracker.roleChanges.save(role);
        this.roleBuilders.push(new RoleBuilder(role, this.changeTracker));
        this.roleBuilders.sort((a, b) => (a.role.name > b.role.name ? 1 : a.role.name < b.role.name ? -1 : 0));
        this.rolesChanged.raise(this.roleBuilders);
    }

    public removeRole(role: Role) {
        this.roleBuilders = this.roleBuilders.filter((r) => r.role !== role);
        this.changeTracker.roleChanges.delete(role);
        this.rolesChanged.raise(this.roleBuilders);
    }

    public async save() {
        this.saving.raise(true);
        const roleSaves = this.changeTracker.roleChanges.getSaves();
        const roleDeletes = this.changeTracker.roleChanges.getDeletes();
        const permissionSaves = this.changeTracker.getPermissionSavesByRole();
        for (const role of roleDeletes) {
            await this.deleteRole(role);
        }
        for (const role of roleSaves) {
            const roleId = role.id!;
            await this.saveRole(role, permissionSaves.get(roleId));
            permissionSaves.delete(roleId);
        }
        for (const saveSet of permissionSaves.values()) {
            await this.savePermissions(saveSet);
        }
        this.changeTracker.clear();
        this.saving.raise(false);
    }

    private async deleteRole(role: Role) {
        if (!this.changeTracker.refKeys.has(role.id!)) {
            await deleteApiRoleId(role.id!);
        }
    }

    private async saveRole(role: Role, permissions?: Permission[]) {
        if (this.changeTracker.refKeys.has(role.id!)) {
            role.id = undefined;
        }
        const savedRole = await postApiRole(role);
        if (permissions) {
            await this.savePermissions(permissions, savedRole.id!);
        }
    }

    private async savePermissions(permissions: Permission[], roleId?: string) {
        if (permissions) {
            for (const permission of permissions) {
                if (roleId) {
                    permission.roleId = roleId;
                }
                // Fix this for real - unblocking load testing
                await postApiPermission(permission);

                // This fix breaks new environments
                // if (permission.id) {
                //     await putApiPermissionId(permission.id, permission);
                // } else {
                //     await postApiPermission(permission);
                // }
            }
        }
    }

    private async loadRoles() {
        let search = searchBuilder('Role')
            .where((r) => ({ isNull: [r.scopeEntityId] }))
            .build();

        if (this.organizationId) {
            search = searchBuilder('Role')
                .leftJoin('Organization', ([r, o]) => ({ eq: { [r.scopeEntityId]: o.id } }))
                .leftJoin('GameEnvironment', ([r, _o, ge]) => ({ eq: { [r.scopeEntityId]: ge.id } }))
                .leftJoin('Game', ([_r, _o, ge, g]) => ({ eq: { [ge.gameId]: g.id } }))
                .where(([_r, o, _ge, g]) => ({
                    or: [{ eq: { [o.nameof.id]: this.organizationId } }, { eq: { [g.organizationId]: this.organizationId } }],
                }))
                .sortAsc(([r]) => r.name)
                .build();
        }

        const results = await postApiRoleFindBy(search);
        return results.items || [];
    }
}
