import styled from '@emotion/styled';
import { FormControl, FormHelperText, InputLabel, Typography } from '@mui/material';
import { format as formatDate, parseISO, toDate } from 'date-fns';

import { Copyable } from './Copyable';
import { makeHookable, styleFromProps } from './HookAdapter';

type DisplayValue = string | undefined | null | number | Date | string[] | boolean;
type DisplayFormat = 'text' | 'currency' | 'int' | 'decimal' | 'date' | 'datetime' | 'time' | 'yesno';
export function formatValue(value: DisplayValue, format?: DisplayFormat) {
    if (value instanceof Array) {
        return value.join(', ');
    } else if (format === 'date' || format === 'datetime') {
        const date = value instanceof Date ? value : typeof value === 'number' ? toDate(value) : typeof value === 'string' ? parseISO(value) : null;
        return !date ? '' : formatDate(date, format === 'date' ? 'P' : 'Pp');
    } else if (format === 'time') {
        throw new Error('time format not yet supported. add support if you need it');
    } else if (format === 'yesno') {
        return value ? 'Yes' : 'No';
    } else {
        return String(value);
    }
}
interface ReadonlyInputProps {
    format?: DisplayFormat;
    emptyText?: string;
}
export const ReadonlyInput = makeHookable<DisplayValue, Omit<ReadonlyInputProps, 'onChange'>>(function ReadonlyInput(props) {
    const text = formatValue(props.value, props.format);
    const isEmpty = !text;

    return (
        <FormControl fullWidth={props.width === 'full'} style={{ ...styleFromProps(props) }} size="small" variant="standard" margin="dense">
            <InputLabel shrink>{props.label}</InputLabel>
            <ReadonlyText component="span" mode={isEmpty ? 'empty' : 'normal'}>
                <Copyable>{text ? text : props.emptyText || 'Nothing'}</Copyable>
            </ReadonlyText>
            {props.helperText ? <FormHelperText style={{ marginTop: '-0.5rem' }}>{props.helperText}</FormHelperText> : null}
        </FormControl>
    );
});

const ReadonlyText = styled(Typography)<{ mode: 'empty' | 'normal'; component: string }>`
    opacity: ${(p) => (p.mode === 'empty' ? 0.75 : 1)};
    font-style: ${(p) => (p.mode === 'empty' ? 'italic' : 'normal')};
    margin-top: 1rem;
`;
