import { BoolInput, ColorInput, DateInput, DecimalInput, DropdownInput, IntegerInput, TextInput } from '@components';
import { MenuItem } from '@mui/material';
import type { Field, FieldProps, FieldTemplateProps, Widget, WidgetProps } from '@rjsf/core';
import type { ExternalInputProps } from 'shared/components/Forms/HookAdapter';

export function CustomFieldTemplate({ id, classNames, label, help, required, description, errors, children }: FieldTemplateProps) {
    return (
        <div className={classNames}>
            {children}
            {/* {errors.props.errors !== undefined ? errors : description} */}
            {/* {help} */}
        </div>
    );
}

// eslint-disable-next-line @typescript-eslint/ban-types
function SchemaToUncontrolledComponentProps<T = FieldProps | WidgetProps>(props: FieldProps | WidgetProps): ExternalInputProps<T, any> {
    const mappedProps: ExternalInputProps<T, any> = {
        value: props.formData,
        onChange: props.onChange,
        onBlur: props.onBlur,
        label: props.schema.title || props.name,
        type: 'standard',
        invalid: !!props.rawErrors,
        helperText: props.rawErrors ?? props.schema.description,
    };
    return mappedProps;
}

export const customFields = {
    StringField: (props: FieldProps) => {
        const mappedProps = SchemaToUncontrolledComponentProps<FieldProps>(props);
        if (props.schema.enum) {
            return (
                <DropdownInput width={'full'} {...mappedProps}>
                    {props.schema.enum.map((o, i) => (
                        <MenuItem key={i} value={o?.valueOf().toString()}>
                            {o?.valueOf().toString()}
                        </MenuItem>
                    ))}
                </DropdownInput>
            );
        } else {
            return <TextInput width={'full'} {...mappedProps} />;
        }
    },
    BooleanField: (props: FieldProps) => {
        const mappedProps = SchemaToUncontrolledComponentProps<FieldProps>(props);
        return <BoolInput width={'full'} {...mappedProps} />;
    },
    NumberField: (props: FieldProps) => {
        const mappedProps = SchemaToUncontrolledComponentProps<FieldProps>(props);
        return props.schema.type === 'number' ? <DecimalInput width={'full'} {...mappedProps} /> : <IntegerInput width={'full'} {...mappedProps} />;
    },
} as unknown as { [name: string]: Field };
export const customWidgets = {
    ColorWidget: (props: WidgetProps) => {
        const mappedProps = SchemaToUncontrolledComponentProps<WidgetProps>(props);
        return <ColorInput width={'full'} {...mappedProps} />;
    },
    DateWidget: (props: WidgetProps) => {
        const mappedProps = SchemaToUncontrolledComponentProps<WidgetProps>(props);
        return <DateInput width={'full'} {...mappedProps} />;
    },
    DateTimeWidget: (props: WidgetProps) => {
        const mappedProps = SchemaToUncontrolledComponentProps<WidgetProps>(props);
        return <DateInput width={'full'} includeTime {...mappedProps} />;
    },
} as unknown as { [name: string]: Widget };
