import { DatePicker, DateTimePicker } from '@mui/lab';
import { TextField } from '@mui/material';

import { makeHookablePermission, styleFromProps } from './HookAdapter';

interface DateProps {
    max?: Date;
    min?: Date;
    includeTime?: boolean;
    label: string;
}
export const DateInput = makeHookablePermission<Date | undefined, DateProps>(function DateInput(props) {
    const Picker = props.includeTime ? DateTimePicker : DatePicker;
    const specificInputProps = props.type === 'standard' ? undefined : { name: props.name };
    return (
        <Picker
            label={props.label}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            value={props.value}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            onChange={(d) => (props.type === 'standard' ? props.onChange(d || undefined) : props.hookOnChange({ target: { value: d } }))}
            maxDate={props.max}
            minDate={props.min}
            renderInput={(p) => (
                <TextField
                    {...specificInputProps}
                    {...p}
                    fullWidth={props.width === 'full'}
                    style={{ ...styleFromProps(props) }}
                    onBlur={props.onBlur}
                    error={props.invalid}
                    helperText={props.errorMessage ?? props.helperText}
                    size="small"
                    variant="standard"
                />
            )}
        />
    );
});
