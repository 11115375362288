import { confirm, modal, useObjectKey } from '@components';
import { useEvent } from '@core';
import { Delete, Edit, MoreVert, Security } from '@mui/icons-material';
import { Divider, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import type { Role } from '@services/model';
import { useCallback, useState } from 'react';

import type { ExpansionState, RoleBuilder, RolesPageService } from './Models';
import { RoleDetailsPrompt } from './RoleDetailsPrompt';
import { ScopeListItem } from './ScopeListItem';

export function RoleListItem({ roleBuilder, expansion, rolesPageService }: RoleListItemProps) {
    const key = useObjectKey();
    const toggle = useCallback(() => {
        expansion.toggle(roleBuilder);
    }, [expansion, roleBuilder]);
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();

    const open = expansion.isExpanded(roleBuilder);
    const scopes = rolesPageService.permissionSetBuilder.getScopes();
    const closeMenu = useCallback(() => setMenuAnchorEl(undefined), [setMenuAnchorEl]);
    const openMenu = useCallback((e: { currentTarget: HTMLElement }) => setMenuAnchorEl(e.currentTarget), [setMenuAnchorEl]);
    const promptUpdate = useCallback(async () => {
        closeMenu();
        const close = (closer: () => void, role?: Role) => {
            if (role) {
                roleBuilder.updateRole(role.name, role.description!);
            }
            closer();
        };
        await modal('Update Role Name', (closer) => <RoleDetailsPrompt initialValue={roleBuilder.role} onClose={(r) => close(closer, r)} />);
    }, [roleBuilder, closeMenu]);
    const confirmDelete = useCallback(async () => {
        closeMenu();
        const confirmed = await confirm(
            'Delete Role',
            <>
                Are you sure you want to <strong>delete role {roleBuilder.role.name}</strong>?<br />
                Change takes affect after Save.
            </>,
            'Yes, Delete It'
        );
        if (confirmed) {
            rolesPageService.removeRole(roleBuilder.role);
        }
    }, [rolesPageService, roleBuilder, closeMenu]);

    useEvent(roleBuilder.roleChanged);
    useEvent(expansion.expansionChanged);

    return (
        <>
            <ListItem disablePadding dense>
                <IconButton onClick={openMenu}>
                    <MoreVert />
                </IconButton>
                <ListItemButton onClick={toggle}>
                    <ListItemIcon sx={{ minWidth: '2rem' }}>
                        <Security />
                    </ListItemIcon>
                    <ListItemText primary={roleBuilder.role.name} secondary={roleBuilder.role.description} />
                </ListItemButton>
                <Menu onClose={closeMenu} open={Boolean(menuAnchorEl)} anchorEl={menuAnchorEl}>
                    <MenuItem onClick={promptUpdate}>
                        <Edit /> Edit
                    </MenuItem>
                    <MenuItem onClick={confirmDelete}>
                        <Delete /> Delete
                    </MenuItem>
                </Menu>
            </ListItem>
            <Divider />
            {!open
                ? null
                : scopes.map((s) => (
                      // eslint-disable-next-line react/jsx-indent
                      <ScopeListItem
                          key={key(s)}
                          scope={s}
                          roleId={roleBuilder.role.id!}
                          expansion={expansion}
                          permissionSetBuilder={rolesPageService.permissionSetBuilder}
                      />
                  ))}
        </>
    );
}
interface RoleListItemProps {
    expansion: ExpansionState;
    roleBuilder: RoleBuilder;
    rolesPageService: RolesPageService;
}
