import { BreadcrumbLink, LinkTabs, useUrlParams } from '@components';
import { useApiListener } from '@core';
import { SportsEsports } from '@mui/icons-material';
import { useGetApiGamesId } from '@services/games/games';
import { adminPageRegistry } from 'features/admin';
import { orgPageRegistry } from 'features/org';

import { APIDocumentation } from '../APIDocumentation';
import { TitleEnvironments } from './TitleEnvs';
import { Title } from './Title';
import { TitleTenantConfiguration } from './TitleTenantConfiguration';
import { TitleTheme } from './TitleTheme';

export function TitleDetails() {
    const { params } = useUrlParams();
    return (
        <LinkTabs
            orientation="vertical"
            tabs={[
                { label: 'Info', render: () => <Title titleId={params.id || ''} />, url: 'info' },
                { label: 'Environments', render: () => <TitleEnvironments titleId={params.id || ''} />, url: 'envs' },
                { label: 'Title Configuration', render: () => <TitleTheme titleId={params.id || ''} />, url: 'theme' },
                { label: 'API Configuration', render: () => <TitleTenantConfiguration titleId={params.id || ''} />, url: 'tenantConfig' },
                { label: 'API Documentation', url: 'api-documentation', render: () => <APIDocumentation titleId={params.id || ''} /> },
            ]}
        />
    );
}

function TitleBreadcrumb() {
    const { params } = useUrlParams();
    const { isLoading, data, refetch } = useGetApiGamesId(params.id);
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    useApiListener('game', () => refetch());

    return (
        <BreadcrumbLink>
            <SportsEsports /> Title ({isLoading ? 'Loading...' : data?.name})
        </BreadcrumbLink>
    );
}

adminPageRegistry.register({ page: TitleDetails, icon: '', name: TitleBreadcrumb, path: 'title-details' });
orgPageRegistry.register({ page: TitleDetails, icon: '', name: TitleBreadcrumb, path: 'title-details' });
