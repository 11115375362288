import {
    BreadcrumbLink,
    FormGrid,
    FormSection,
    LinkTabs,
    monitor,
    Notes,
    ReadonlyInput,
    ResourceProvider,
    TextInput,
    useUrlParams,
} from '@components';
import { useApiListener, usePermissions } from '@core';
import { yupResolver } from '@hookform/resolvers/yup';
import { Person } from '@mui/icons-material';
import { Button, Divider, Grid, LinearProgress, Toolbar } from '@mui/material';
import type { GridRowData } from '@mui/x-data-grid';
import { getApiDropTablesAggregateId, putApiDropTablesAggregateId, useGetApiDropTablesId } from '@services/drop-tables/drop-tables';
import type {
    DropTableAggregateViewModel,
    DropTableChildListViewModel,
    DropTableChildVirtualCurrencyListViewModel,
    SaveDropTableRequest,
} from '@services/model';
import { PageBody, PageContent } from 'features/shell/layout';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { titlePageRegistry } from '../PageRegistry';
import { UpdateType } from '../Shared/EntityModels';
import { DropTableContents } from './DropTableContents';
import { DropTableData } from './DropTableData';

const dropTableFormSchema = yup.object().shape({
    dropTableAggregateViewModel: yup.object().shape({
        dropTable: yup.object().shape({
            displayName: yup.string().required('Display Name is Required'),
            minGrantableItems: yup.number().nullable().moreThan(-1, "Minimum must be larger than -1").max(2147483647, 'Cannot be larger than max integer size.').nullable(true).transform((_, val) => (val ? Number(val) : null))
            .when("dropTableAggregateViewModel.dropTable.maxGrantableItems", {
                is: (value: number) => value !== null,
                then: yup.number().required("Value is required when Maximum Grantable Items is specified.").nullable(true).transform((_, val) => (val ? Number(val) : null))
            })
            .test("minTest", "Value must be less than or equal to Maximum Grantable Items", function (value) {
                if (this.parent.maxGrantableItems !== null && value !== null) {
                    return value as number <= this.parent.maxGrantableItems;
                }

                return true;
            }),
            maxGrantableItems: yup.number().nullable().positive('Must be a positive number.').max(2147483647, 'Cannot be larger than max integer size.').nullable(true).transform((_, val) => (val ? Number(val) : null))
            .when("dropTableAggregateViewModel.dropTable.minGrantableItems", {
                is: (value: number) => value !== null,
                then: yup.number().required("Value is required when Minimum Grantable Items is specified.").nullable(true).transform((_, val) => (val ? Number(val) : null))
            })
            .test("maxTest", "Value must be greater than or equal to Minimum Grantable Items", function (value) {
                if (this.parent.minGrantableItems !== null && value !== null) {
                    return value as number >= this.parent.minGrantableItems;
                }

                return true;
            })
        })
    }),

    consumable: yup.bool(),
});

interface DetailsFormProps {
    dropTableAggregateViewModel: DropTableAggregateViewModel;
}

function DetailsForm({ dropTableAggregateViewModel }: DetailsFormProps) {
    const { canUpdate: canUpdateDropTable, canCreate } = usePermissions('Item');
    const canSave = canUpdateDropTable;
    const [itemsInDropTable, setItemsInDropTable] = useState<GridRowData[]>([]);
    const [bundlesInDropTable, setBundlesInDropTable] = useState<GridRowData[]>([]);
    const [currenciesInDropTable, setCurrenciesInDropTable] = useState<GridRowData[]>([]);
    const [dropTableContentsChanged, setDropTableContentsChanged] = useState(false);
    const [containersInDropTable, setContainersInDropTable] = useState<GridRowData[]>([]);
    const [dropTablesinDropTable, setDropTablesinDropTable] = useState<GridRowData[]>([]);

    useEffect(() => {
        setEntityRows();
        setDropTableContentsChanged(false);
    }, [dropTableAggregateViewModel]);

    const setEntityRows = () => {
        setBundlesInDropTable(buildEntityRows(dropTableAggregateViewModel?.bundles));
        setItemsInDropTable(buildItemRows(dropTableAggregateViewModel?.items));
        setCurrenciesInDropTable(buildVirtualCurrenyRows(dropTableAggregateViewModel?.virtualCurrencyItems));
        setContainersInDropTable(buildEntityRows(dropTableAggregateViewModel?.containers));
        setDropTablesinDropTable(buildEntityRows(dropTableAggregateViewModel?.dropTables));
    };

    function buildItemRows(entities: DropTableChildListViewModel[] | null | undefined): GridRowData[] {
        return (
            entities?.map((x) => {
                return { 
                    ...x, 
                    entryId: nanoid(), 
                    countAvailable: x.maxCount 
                    ? x.maxCount - (x.grantedCount ?? 0) : null 
                };
            }) ?? []
        );
    }

    function buildEntityRows(entities: DropTableChildListViewModel[] | null | undefined): GridRowData[] {
        return (
            entities?.map((x) => {
                return { ...x, entryId: nanoid() };
            }) ?? []
        );
    }

    function buildVirtualCurrenyRows(entities: DropTableChildVirtualCurrencyListViewModel[] | null | undefined): GridRowData[] {
        return (
            entities?.map((x) => {
                return { ...x, entryId: nanoid(), name: x.displayName };
            }) ?? []
        );
    }

    function handleSetItemsInDropTable(items: GridRowData[], updateType: UpdateType) {
        setDropTableContentsChanged(true);

        if (updateType == UpdateType.Add) {
            const addedId = items?.[0].id;
            if (itemsInDropTable.some(cur => cur.id === addedId)) {
                toast.error('Please remove then re-add the existing item entry to change its properties.');
            }
            else {
                let newItems: GridRowData[] = [];

                let itemsInState = [...itemsInDropTable];
    
                items.forEach((i) => {
                    let itemIndex = itemsInState.findIndex((x) => x.id === i.id);

                    if (itemIndex !== -1) {
                        let itemToUpdate = itemsInState[itemIndex];

                        if (i.maxSupply != 0 && itemToUpdate.count + 1 > i.maxSupply) {
                            toast.error('The count of the item available in the drop table can not be higher than the max supply');
                            return;
                        }

                        itemToUpdate.count++
                    } else {
                        if (i.maxSupply != 0 && i.maxCount > i.maxSupply) {
                            toast.error('The count of the item available in the drop table can not be higher than the max supply');
                            return;
                        }

                        newItems.push({ entryId: nanoid(), count: 1, ...i });
                    }
                });
                setItemsInDropTable(itemsInState.concat(newItems));
            }
            
        } else {
            setItemsInDropTable(items);
        }
    }

    function handleSetCurrenciesInDropTable(currencies: GridRowData[], updateType: UpdateType) {
        setDropTableContentsChanged(true);

        if (updateType == UpdateType.Add) {
            const addedCurrency = currencies?.[0];
            if (currenciesInDropTable.some(cur => cur.id === addedCurrency.id && cur.amount === addedCurrency.amount)){
                toast.error('Cannot add a duplicate currency with the same amount.');
            } else {
                setCurrenciesInDropTable((current) => [...current, ...currencies.map((curr) => ({ ...curr, entryId: nanoid() }))]);
            }
        } else {
            const currencyIds = currencies.map((curr) => curr.entryId);
            setCurrenciesInDropTable((current) => current.filter((cur) => currencyIds.includes(cur.entryId)));
        }
    }

    function handleSetBundlesInDropTable(bundles: GridRowData[], updateType: UpdateType) {
        setDropTableContentsChanged(true);

        if (updateType == UpdateType.Add) {
            const addedId = bundles?.[0].id;
            if (bundlesInDropTable.some(cur => cur.id === addedId)) {
                toast.error('Please remove then re-add the existing bundle entry to change its properties.');
            }
            else {
                let newBundles: GridRowData[] = [];

                let bundlesInState = [...bundlesInDropTable];
    
                bundles.forEach((b) => {
                    let bundleIndex = bundlesInState.findIndex((x) => x.id === b.id);
    
                    if (bundleIndex !== -1) {
                        let bundleToUpdate = bundlesInDropTable[bundleIndex];
                        bundleToUpdate.count++;
                    } else {
                        newBundles.push({ entryId: nanoid(), count: 1, ...b });
                    }
                });
                setBundlesInDropTable(bundlesInState.concat(newBundles));
            }
            
        } else {
            setBundlesInDropTable(bundles);
        }
    }

    function handleSetContainersInDropTable(containers: GridRowData[], updateType: UpdateType) {
        setDropTableContentsChanged(true);

        if (updateType == UpdateType.Add) {
            const addedId = containers?.[0].id;
            if (containersInDropTable.some(cur => cur.id === addedId)) {
                toast.error('Please remove then re-add the existing container entry to change its properties.');
            }
            else {
                let newContainers: GridRowData[] = [];

                let containersInState = [...containersInDropTable];
    
                containers.forEach((b) => {
                    let containerIndex = containersInState.findIndex((x) => x.id === b.id);
    
                    if (containerIndex !== -1) {
                        let containerToUpdate = containersInDropTable[containerIndex];
                        containerToUpdate.count++;
                    } else {
                        newContainers.push({ entryId: nanoid(), count: 1, ...b });
                    }
                });
                setContainersInDropTable(containersInState.concat(newContainers));
            }
        } else {
            setContainersInDropTable(containers);
        }
    }

    function handleSetDropTablesInDropTable(dropTables: GridRowData[], updateType: UpdateType) {
        setDropTableContentsChanged(true);

        if (updateType == UpdateType.Add) {
            const addedId = dropTables?.[0].id;
            if (dropTablesinDropTable.some(cur => cur.id === addedId)) {
                toast.error('Please remove then re-add the existing drop table entry to change its properties.');
            }
            else {
                let newDropTables: GridRowData[] = [];

                let dropTablesInState = [...dropTablesinDropTable];
    
                dropTables.forEach((b) => {
                    let dropTableIndex = dropTablesInState.findIndex((x) => x.id === b.id);

                    if (dropTableIndex !== -1) {
                        let dropTableToUpdate = dropTablesinDropTable[dropTableIndex];
                        dropTableToUpdate.count++;
                    } else {
                        newDropTables.push({ entryId: nanoid(), count: 1, ...b });
                    }
                });
    
                setDropTablesinDropTable(dropTablesInState.concat(newDropTables));
            }
        } else {
            setDropTablesinDropTable(dropTables);
        }
    }

    const form = useForm({
        defaultValues: {
            dropTableAggregateViewModel,
        },
        resolver: yupResolver(dropTableFormSchema),
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });

    const { isDirty } = useFormState(form);
    const save = async () => {
        const { dropTableAggregateViewModel } = form.getValues();
        monitor({
            failureMessage: 'Error Saving Drop Table!',
            successMessage: 'Drop Table Saved',
            action: async () => {
                const request: SaveDropTableRequest = {
                    displayName: dropTableAggregateViewModel.dropTable?.displayName ?? '',
                    minGameVersion: dropTableAggregateViewModel.dropTable?.minGameVersion ?? '',
                    externalId: dropTableAggregateViewModel.dropTable?.externalId ?? null,
                    items: itemsInDropTable.map((x) => ({ id: x.id, count: 1, weight: x.weight, maxCount: x.maxCount })) ?? [],
                    bundles: bundlesInDropTable.map((x) => ({ id: x.id, count: 1, weight: x.weight })) ?? [],
                    virtualCurrencyItems: currenciesInDropTable?.map((x) => ({ id: x.id, amount: x.amount, weight: x.weight })) ?? [],
                    containers: containersInDropTable.map((x) => ({ id: x.id, count: 1, weight: x.weight })) ?? [],
                    dropTables: dropTablesinDropTable.map((x) => ({ id: x.id, count: 1, weight: x.weight })) ?? [],
                    minGrantableItems: dropTableAggregateViewModel.dropTable?.minGrantableItems ?? 1,
                    maxGrantableItems: dropTableAggregateViewModel.dropTable?.maxGrantableItems ?? 1
                };

                let dropTableId = dropTableAggregateViewModel.dropTable?.id ?? '';
                const updatedDropTable = await putApiDropTablesAggregateId(dropTableId, request);
                form.reset({ dropTableAggregateViewModel: updatedDropTable });
                setDropTableContentsChanged(false);
            },
        });
    };

    return (
        <PageContent>
            <PageBody>
                <FormProvider {...form}>
                    <FormGrid>
                        <Grid item container md={4} xs={12} xl={6} spacing={2} alignContent="baseline">
                            <FormSection header="Drop Table Details" sm={12} xl={6}>
                                <ResourceProvider value="Item">
                                    <ReadonlyInput label="Drop Table ID" name="dropTableAggregateViewModel.dropTable.id" />
                                    <TextInput width="full" label="Display Name" name="dropTableAggregateViewModel.dropTable.displayName" multiline />
                                    <TextInput width="full" label="External Id" name="dropTableAggregateViewModel.dropTable.externalId" multiline />
                                    <TextInput label="Minimum Grantable Items" width="full" name="dropTableAggregateViewModel.dropTable.minGrantableItems" placeholder="1" />
                                    <TextInput label="Maximum Grantable Items" width="full" name="dropTableAggregateViewModel.dropTable.maxGrantableItems" placeholder="1" />
                                </ResourceProvider>
                            </FormSection>
                        </Grid>
                        <Grid item container xs={12} md={8} xl={6} spacing={2}>
                            <FormSection header="Notes">
                                <Notes type="DropTable" typeId={dropTableAggregateViewModel.dropTable?.id!} hideTitle />
                            </FormSection>
                        </Grid>
                        <Grid item container xs={12} spacing={2}>
                            <FormSection header="Manage Content">
                                <DropTableContents
                                    dropTable={dropTableAggregateViewModel}
                                    setBundlesInDropTable={handleSetBundlesInDropTable}
                                    setItemsInDropTable={handleSetItemsInDropTable}
                                    setContainersInDropTable={handleSetContainersInDropTable}
                                    setDropTablesInDropTable={handleSetDropTablesInDropTable}
                                    setCurrenciesInDropTable={handleSetCurrenciesInDropTable}
                                    itemsInDropTable={itemsInDropTable}
                                    bundlesInDropTable={bundlesInDropTable}
                                    currenciesInDropTable={currenciesInDropTable}
                                    containersInDropTable={containersInDropTable}
                                    dropTablesInDropTable={dropTablesinDropTable}
                                />
                            </FormSection>
                        </Grid>
                    </FormGrid>
                </FormProvider>
            </PageBody>
            <Divider />
            {canSave && (
                <Toolbar>
                    <Button onClick={save} variant="contained" disabled={(!form.formState.isDirty || !form.formState.isValid) && !dropTableContentsChanged}>
                        Save Changes
                    </Button>
                </Toolbar>
            )}
        </PageContent>
    );
}

function Details(props: { dropTableId: string }) {
    const [dropTable, setDropTable] = useState<DropTableAggregateViewModel>();

    const loadDropTable = async () => {
        const dropTableResult = await Promise.resolve(getApiDropTablesAggregateId(props.dropTableId));
        setDropTable(dropTableResult);
    };

    useEffect(() => {
        loadDropTable();
    }, [props.dropTableId]);

    return !dropTable ? <LinearProgress /> : <DetailsForm dropTableAggregateViewModel={dropTable} />;
}

function DropTableBreadcrumb() {
    const { params } = useUrlParams();
    const { isLoading, data, refetch } = useGetApiDropTablesId(params.id);
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    useApiListener('droptables', () => refetch());

    return (
        <BreadcrumbLink>
            <Person /> Drop Table ({isLoading ? 'Loading...' : data?.displayName})
        </BreadcrumbLink>
    );
}

export function DropTableDetails() {
    const { params } = useUrlParams();
    return (
        <LinkTabs
            orientation="vertical"
            tabs={[
                { label: 'Details', render: () => <Details dropTableId={params.id} />, url: 'details' },
                { label: 'Data', render: () => <DropTableData id={params.id || ''} />, url: 'data' },
            ]}
        />
    );
}

titlePageRegistry.register({ page: DropTableDetails, path: 'drop-table-details', name: DropTableBreadcrumb });
