import { FormSection } from '@components';
import { FormControl, FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';
import { useGetApiItemTokenSupportedBlockchainGetByItemTokenIdItemTokenId } from '@services/item-token-supported-blockchain/item-token-supported-blockchain';
import { usePutApiItemTokensItemTokenIdSupportedBlockchain } from '@services/item-tokens/item-tokens';
import type { ItemTokenSupportedBlockchainResult } from '@services/model/itemTokenSupportedBlockchainResult';
import type { MakeItemTokenBridgeableToBlockchainRequest } from '@services/model';
import type { SupportedBlockchainResult } from '@services/model/supportedBlockchainResult';
import { useGetApiSupportedBlockchain } from '@services/supported-blockchain/supported-blockchain';
import { useEffect, useState } from 'react';

import { BridgeableBlockchainConfirmationDialog } from './BridgeableBlockchainConfirmationDialog';
import { BridgeableBlockchainError } from './BridgeableBlockchainError';

class Blockchain {
    constructor(init?: Partial<Blockchain>) {
        if (init) {
            Object.assign(this, init);
        }
    }
    chainId = 0;
    name = '';
    isAssigned = false;
    isPending = false;
}

const coalesceBlockchainData = (
    supportedBlockchains: SupportedBlockchainResult[],
    itemTokenBlockchains: ItemTokenSupportedBlockchainResult[]
): Blockchain[] => {
    const blockchains: Blockchain[] = [];

    supportedBlockchains.forEach((blockchain) => {
        const itemTokenBlockchain = itemTokenBlockchains.find((assigned) => assigned.chainId === blockchain.chainId);
        const isAssigned = itemTokenBlockchain != null;
        const isPending = isAssigned && itemTokenBlockchain.isPending;

        blockchains.push(
            new Blockchain({
                chainId: blockchain.chainId,
                name: blockchain.name ?? '',
                isAssigned: isAssigned,
                isPending: isPending,
            })
        );
    });

    return blockchains;
};

const getBlockchainLabel = (blockchain: Blockchain): string => {
    if (blockchain.isPending) {
        return `${blockchain.name} (pending)`;
    }
    return blockchain.name;
};

export function BridgeableBlockchains(props: { itemTokenId: string }) {
    const { isLoading: loadingSupportedBlockchains, data: supportedBlockchains } = useGetApiSupportedBlockchain();

    const {
        isLoading: loadingItemTokenSupportedBlockchains,
        data: itemTokenSupportedBlockchains,
        refetch,
        isRefetching,
    } = useGetApiItemTokenSupportedBlockchainGetByItemTokenIdItemTokenId(props.itemTokenId);

    const { mutateAsync: addBlockchain } = usePutApiItemTokensItemTokenIdSupportedBlockchain();

    const [blockchains, setBlockchains] = useState<Blockchain[]>([]);
    const [errorIsOpen, setErrorIsOpen] = useState<boolean>(false);
    const [confirmationIsOpen, setConfirmationIsOpen] = useState<boolean>(false);
    const [confirmBlockchain, setConfirmBlockchain] = useState<Blockchain>();

    useEffect(() => {
        const data = coalesceBlockchainData(supportedBlockchains ?? [], itemTokenSupportedBlockchains ?? []);
        setBlockchains(data);
    }, [loadingSupportedBlockchains, loadingItemTokenSupportedBlockchains, isRefetching]);

    const onBridge = (id: number) => {
        setConfirmBlockchain(blockchains.find((o) => o.chainId === id));
        setConfirmationIsOpen(true);
    };

    const closeConfirmationDialog = () => {
        setConfirmationIsOpen(false);
    };

    const onCancel = () => {
        closeConfirmationDialog();
    };

    const onConfirm = async () => {
        let saved = false;

        const requestBody: MakeItemTokenBridgeableToBlockchainRequest = { chainId: confirmBlockchain?.chainId };

        try {
            await addBlockchain({
                itemTokenId: props.itemTokenId,
                data: requestBody,
            });
            saved = true;
        } catch {
            setErrorIsOpen(true);
        } finally {
            if (saved) {
                await refetch();
            }
            closeConfirmationDialog();
        }
    };

    return (
        <>
            <BridgeableBlockchainConfirmationDialog
                isOpen={confirmationIsOpen}
                onCancel={onCancel}
                onConfirm={onConfirm}
                blockchainName={confirmBlockchain?.name ?? ''}
            />

            <BridgeableBlockchainError isOpen={errorIsOpen} onClose={() => setErrorIsOpen(false)} />

            <FormSection header="Item Token Bridging">
                <Typography sx={{ fontStyle: 'italic' }}>
                    Select which blockchains that tokens of this item can be transferred. Once saved, these changes cannot be undone.
                </Typography>
                <FormControl component="fieldset" variant="standard" fullWidth>
                    <FormGroup>
                        {blockchains.length > 0 ? (
                            blockchains.map((chain, index) => (
                                <FormControlLabel
                                    control={
                                        <Switch checked={chain.isAssigned} onClick={() => onBridge(chain.chainId)} disabled={chain.isAssigned} />
                                    }
                                    label={getBlockchainLabel(chain)}
                                    key={index}
                                />
                            ))
                        ) : (
                            <p>- No blockchains found -</p>
                        )}
                    </FormGroup>
                </FormControl>
            </FormSection>
        </>
    );
}
