import { DiContext } from '@core';
import { Breadcrumbs, Link as MuiLink, styled } from '@mui/material';
import type { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { useFullRoute, useNavigator } from './hooks';
import type { IPageRegistry } from './PageRegistry';
import type { RouteEndpoint } from './RegistryPageRenderer';

export function BreadcrumbLink(props: { path?: string; children: ReactNode }) {
    const { getEndpoint } = useNavigator();
    const path = props.path || getEndpoint()?.path || '';
    return (
        <MuiLink component={StyledLink} to={path} style={{ display: 'flex' }}>
            {props.children}
        </MuiLink>
    );
}

const StyledLink = styled(Link)`
    svg {
        margin-right: 8px;
    }
`;

function Breadcrumb(props: { endpoint: RouteEndpoint }) {
    const pageRegistration = props.endpoint.registry.getPage(props.endpoint.name);
    const Type = typeof pageRegistration?.name === 'function' ? pageRegistration.name : null;
    const renderContent = () => {
        if (Type) {
            return <Type />;
        } else if (typeof pageRegistration?.name === 'string') {
            return (
                <BreadcrumbLink path={props.endpoint.path}>
                    {pageRegistration.icon}
                    {pageRegistration.name}
                </BreadcrumbLink>
            );
        } else {
            return pageRegistration?.name;
        }
    };

    return <>{renderContent()}</>;
}

export function RegistryBreadcrumb({ registry }: { registry: IPageRegistry }) {
    const fullRoute = useFullRoute(registry);

    return (
        <StyledBreadcrumbs style={{ display: 'flex', height: 40, padding: '0 1rem' }}>
            {fullRoute.map((item) => (
                <DiContext.Provider key={item.path} value={item.container}>
                    <Breadcrumb endpoint={item} />
                </DiContext.Provider>
            ))}
        </StyledBreadcrumbs>
    );
}

const StyledBreadcrumbs = styled(Breadcrumbs)`
    display: flex;
    padding: 0.5rem 1rem;
    background: #8882;
`;
