import {
    BreadcrumbLink,
    FormGrid,
    FormSection,
    LinkTabs,
    monitor,
    Notes,
    ReadonlyInput,
    ResourceProvider,
    TextInput,
    useUrlParams,
} from '@components';
import { useApiListener, usePermissions } from '@core';
import { yupResolver } from '@hookform/resolvers/yup';
import { Person } from '@mui/icons-material';
import { Button, Divider, Grid, LinearProgress, Toolbar } from '@mui/material';
import type { GridRowData } from '@mui/x-data-grid';
import { getApiContainersAggregateId, putApiContainersAggregateId, useGetApiContainersId } from '@services/containers/containers';
import { ContainerAggregateViewModel, InventoryContainerChildListViewModel, InventoryContainerChildVirtualCurrencyListViewModel, SaveContainerCommand } from '@services/model';
import { PageBody, PageContent } from 'features/shell/layout';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { titlePageRegistry } from '../PageRegistry';
import { UpdateType } from '../Shared/EntityModels';
import { ContainerContents } from './ContainerContents';
import { ContainerData } from './ContainerData';

const containerFormSchema = yup.object().shape({
    container: yup.object().shape({
        itemName: yup.string().required('Item Name is Required'),
        displayName: yup.string().required('Display Name is Required'),
    }),
    consumable: yup.bool(),
});

interface DetailsFormProps {
    container: ContainerAggregateViewModel;
}

function DetailsForm({ container }: DetailsFormProps) {
    const { canUpdate: canUpdateContainer, canCreate: canCreateContainer } = usePermissions('Item');
    const canSave = canUpdateContainer;
    const [itemsInContainer, setItemsInContainer] = useState<GridRowData[]>([]);
    const [bundlesInContainer, setBundlesInContainer] = useState<GridRowData[]>([]);
    const [currenciesInContainer, setCurrenciesInContainer] = useState<GridRowData[]>([]);
    const [containersInContainer, setContainersInContainer] = useState<GridRowData[]>([]);
    const [dropTablesInContainer, setDropTablesInContainer] = useState<GridRowData[]>([]);
    const [containerContentsChanged, setContainerContentsChanged] = useState(false);

    useEffect(() => {
        setEntityRows();
        setContainerContentsChanged(false);
    }, [container]);

    const setEntityRows = () => {
        setBundlesInContainer(buildEntityRows(container?.bundles));
        setItemsInContainer(buildEntityRows(container?.items));
        setCurrenciesInContainer(buildVirtualCurrenyRows(container?.virtualCurrencyItems));
        setContainersInContainer(buildEntityRows(container?.containers));
        setDropTablesInContainer(buildEntityRows(container?.dropTables));
    };

    function buildEntityRows(entities: InventoryContainerChildListViewModel[] | null | undefined): GridRowData[] {
        return (
            entities?.map((x) => {
                return { ...x, entryId: nanoid() };
            }) ?? []
        );
    }

    function buildVirtualCurrenyRows(entities: InventoryContainerChildVirtualCurrencyListViewModel[] | null | undefined): GridRowData[] {
        return (
            entities?.map((x) => {
                return { ...x, entryId: nanoid(), name: x.displayName };
            }) ?? []
        );
    }

    function handleSetItemsInContainer(items: GridRowData[], updateType: UpdateType) {
        setContainerContentsChanged(true);        

        if (updateType == UpdateType.Add) {
            let newItems: GridRowData[] = [];

            let itemsInState = [...itemsInContainer];

            items.forEach((i) => {
                let itemIndex = itemsInState.findIndex((x) => x.id === i.id);

                if (itemIndex !== -1) {
                    let itemToUpdate = itemsInState[itemIndex];
                    itemToUpdate.count++;
                }
                else {
                    newItems.push({entryId: nanoid(), count: 1, ...i})
                }
            });

            setItemsInContainer(itemsInState.concat(newItems));
        } else {
            setItemsInContainer(items);
        }
    }

    function handleSetCurrenciesInContainer(currencies: GridRowData[]) {
        setContainerContentsChanged(true);
        setCurrenciesInContainer(currencies);
    }

    function addCurrenciesToContainer(currencies: GridRowData[]) {
        setContainerContentsChanged(true);
        var duplicateCurrencies = currenciesInContainer.filter(x => x.id == currencies?.[0].id);
        if (duplicateCurrencies && duplicateCurrencies.map((cur) => cur.amount).includes(currencies?.[0].amount)) {
            toast.error('Cannot add a duplicate currency with the same amount.');
        } else {
            setCurrenciesInContainer((current) => [...current, ...currencies.map((curr) => ({ ...curr, entryId: nanoid() }))]);
        }
    }

    function removeCurrenciesFromContainer(currencies: GridRowData[]) {
        setContainerContentsChanged(true);
        const currencyIds = currencies.map((curr) => curr.entryId);
        setCurrenciesInContainer((current) => current.filter((cur) => currencyIds.includes(cur.entryId)));
    }

    function handleSetBundlesInContainer(bundles: GridRowData[], updateType: UpdateType) {
        setContainerContentsChanged(true);

        if (updateType == UpdateType.Add) {
            let newBundles: GridRowData[] = [];

            let bundlesInState = [...bundlesInContainer];

            bundles.forEach((b) => {
                let bundleIndex = bundlesInState.findIndex((x) => x.id === b.id);

                if (bundleIndex !== -1) {
                    let bundleToUpdate = bundlesInContainer[bundleIndex];
                    bundleToUpdate.count++;
                }
                else {
                    newBundles.push({entryId: nanoid(), count: 1, ...b})
                }
            });

            setBundlesInContainer(bundlesInState.concat(newBundles));
        } else {
            setBundlesInContainer(bundles);
        }
    }

    function handleSetContainersInContainer(containers: GridRowData[], updateType: UpdateType) {
        setContainerContentsChanged(true);

        if (updateType == UpdateType.Add) {
            let newContainers: GridRowData[] = [];

            let containersInState = [...containersInContainer];

            containers.forEach((b) => {
                let containerIndex = containersInState.findIndex((x) => x.id === b.id);

                if (containerIndex !== -1) {
                    let containerToUpdate = containersInContainer[containerIndex];
                    containerToUpdate.count++;
                }
                else {
                    newContainers.push({entryId: nanoid(), count: 1, ...b})
                }
            });

            setContainersInContainer(containersInState.concat(newContainers));
        } else {
            setContainersInContainer(containers);
        }
    }

    function handleSetDropTablesInContainer(dropTables: GridRowData[], updateType: UpdateType) {
        setContainerContentsChanged(true);

        if (updateType == UpdateType.Add) {
            let newDropTables: GridRowData[] = [];

            let dropTablessInState = [...dropTablesInContainer];

            dropTables.forEach((b) => {
                let dropTableIndex = dropTablessInState.findIndex((x) => x.id === b.id);

                if (dropTableIndex !== -1) {
                    let dropTableToUpdate = dropTablesInContainer[dropTableIndex];
                    dropTableToUpdate.count++;
                }
                else {
                    newDropTables.push({entryId: nanoid(), count: 1, ...b})
                }
            });

            setDropTablesInContainer(dropTablessInState.concat(newDropTables));
        } else {
            setDropTablesInContainer(dropTables);
        }
    }

    const form = useForm({
        defaultValues: {
            container,
        },
        resolver: yupResolver(containerFormSchema),
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });

    const { isDirty } = useFormState(form);
    const save = async () => {
        const { container } = form.getValues();
        monitor({
            failureMessage: 'Error Saving Container!',
            successMessage: 'Container Saved',
            action: async () => {
                
                const request : SaveContainerCommand = {
                    id: container.container?.id ?? "",
                    displayName: container.container?.displayName ?? "",
                    minGameVersion: container.container?.minGameVersion ?? "",
                    externalId: container.container?.externalId ?? null,
                    items: itemsInContainer.map((x) => ({id: x.id, count: x.count })) ?? [],
                    bundles: bundlesInContainer.map((x) => ({id: x.id, count: x.count })) ?? [],
                    virtualCurrencyItems: currenciesInContainer?.map((x) => ({ id: x.id, amount: x.amount })) ?? [],
                    containers: containersInContainer.map((x) => ({id: x.id, count: x.count })) ?? [],
                    dropTables: dropTablesInContainer.map((x) => ({id: x.id, count: x.count })) ?? []
                };

                let containerId = container.container?.id ?? '';
                const updatedContainer = await putApiContainersAggregateId(containerId, request);
                form.reset({ container: updatedContainer });
                setContainerContentsChanged(false);
            },
        });
    };

    return (
        <PageContent>
            <PageBody>
                <FormProvider {...form}>
                    <FormGrid>
                        <Grid item container md={4} xs={12} xl={6} spacing={2} alignContent="baseline">
                            <FormSection header="Container Details" sm={12} xl={6}>
                                <ResourceProvider value="Item">
                                    <ReadonlyInput label="Container ID" name="container.container.id" />
                                    <TextInput width="full" label="Display Name" name="container.container.displayName" multiline />
                                    <TextInput width="full" label="External Id" name="container.container.externalId" multiline />
                                </ResourceProvider>
                            </FormSection>
                        </Grid>
                        <Grid item container xs={12} md={8} xl={6} spacing={2}>
                            <FormSection header="Notes">
                                <Notes type="Container" typeId={container.container?.id!} hideTitle />
                            </FormSection>
                        </Grid>
                        <Grid item container xs={12} spacing={2}>
                            <FormSection header="Manage Content">
                                <ContainerContents
                                    container={container}
                                    addCurrenciesToContainer={addCurrenciesToContainer}
                                    removeCurrenciesFromContainer={removeCurrenciesFromContainer}
                                    setCurrenciesInContainer={handleSetCurrenciesInContainer}
                                    setBundlesInContainer={handleSetBundlesInContainer}
                                    setItemsInContainer={handleSetItemsInContainer}
                                    setContainersInContainer={handleSetContainersInContainer}
                                    setDropTablesInContainer={handleSetDropTablesInContainer}
                                    itemsInContainer={itemsInContainer}
                                    bundlesInContainer={bundlesInContainer}
                                    currenciesInContainer={currenciesInContainer}
                                    containersInContainer={containersInContainer}
                                    dropTablesInContainer={dropTablesInContainer}
                                />
                            </FormSection>
                        </Grid>
                    </FormGrid>
                </FormProvider>
            </PageBody>
            <Divider />
            {canSave && (
                <Toolbar>
                    <Button onClick={save} variant="contained" disabled={!isDirty && !containerContentsChanged}>
                        Save Changes
                    </Button>
                </Toolbar>
            )}
        </PageContent>
    );
}

function Details(props: { containerId: string }) {
    const [container, setContainer] = useState<ContainerAggregateViewModel>();

    const loadContainer = async () => {
        const containerResult = await Promise.resolve(getApiContainersAggregateId(props.containerId));
        setContainer(containerResult);
    };

    useEffect(() => {
        loadContainer();
    }, [props.containerId]);

    return !container ? <LinearProgress /> : <DetailsForm container={container} />;
}

function ContainerBreadcrumb() {
    const { params } = useUrlParams();
    const { isLoading, data, refetch } = useGetApiContainersId(params.id);
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    useApiListener('container', () => refetch());

    return (
        <BreadcrumbLink>
            <Person /> Container ({isLoading ? 'Loading...' : data?.displayName})
        </BreadcrumbLink>
    );
}

export function ContainerDetails() {
    const { params } = useUrlParams();
    return (
        <LinkTabs
            orientation="vertical"
            tabs={[
                { label: 'Details', render: () => <Details containerId={params.id} />, url: 'details' },
                { label: 'Data', render: () => <ContainerData id={params.id || ''} />, url: 'data' },
            ]}
        />
    );
}

titlePageRegistry.register({ page: ContainerDetails, path: 'container-details', name: ContainerBreadcrumb });
