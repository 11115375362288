import { DataGrid, GridColDef, GridRowData, GridSelectionModel } from '@mui/x-data-grid';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, FormControl, Grid, Tab, Tabs, TextField } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';

interface EnvironmentsettingsProps {
    environmentSettings: GridRowData[];
    setSettings: (items: GridRowData[], updateType: UpdateType) => void;
}

export enum UpdateType {
    Add,
    Remove,
}

export function EnvSettingsContents(props: EnvironmentsettingsProps) {
    const { environmentSettings, setSettings } = props;

    const columns = [
        {
            field: 'entryId',
            headerName: 'Id',
            type: 'uuid',
            hide: true,
        },
        {
            field: 'itemUpdateNotificationUrl',
            headerName: 'Url',
            type: 'string',
            flex: 2,
        },
        {
            field: 'itemUpdateNotificationHeaderKey',
            headerName: 'Header Key',
            type: 'string',
            flex: 2,
        },
        {
            field: 'itemUpdateNotificationApiKey',
            headerName: 'Api Key',
            type: 'string',
            flex: 2,
        },
    ];

    const settingForm = useForm({
        mode: 'onChange',
        resolver: yupResolver(
            yup.object({
                url: yup.string(),
                headerKey: yup.string(),
                apiKey: yup.string(),
            })
        ),
    });

    const handleAddSetting = useCallback(
        ({
            itemUpdateNotificationUrl,
            itemUpdateNotificationHeaderKey,
            itemUpdateNotificationApiKey,
        }: {
            itemUpdateNotificationUrl: string;
            itemUpdateNotificationHeaderKey: string;
            itemUpdateNotificationApiKey: string;
        }) => {
            const setting: GridRowData = {
                itemUpdateNotificationUrl: itemUpdateNotificationUrl,
                itemUpdateNotificationHeaderKey: itemUpdateNotificationHeaderKey,
                itemUpdateNotificationApiKey: itemUpdateNotificationApiKey,
            };

            setSettings([setting], UpdateType.Add);
            settingForm.reset();
        },
        [environmentSettings]
    );

    return (
        <Grid columns={2} container={true}>
            <Box sx={{ width: '50%' }}>
                <TabContext value={'0'}>
                    <Box>
                        <Tabs variant="scrollable" scrollButtons="auto" value={'0'}>
                            <Tab value="0" label="Settings" />
                        </Tabs>
                    </Box>

                    <TabPanel sx={{ height: '50vh' }} value="0">
                        <form onSubmit={settingForm.handleSubmit(handleAddSetting)}>
                            <FormControl fullWidth sx={{ marginTop: '0.85rem' }}>
                                <TextField
                                    error={Boolean(settingForm.formState.errors?.value)}
                                    {...settingForm.register('itemUpdateNotificationUrl')}
                                    label="Url"
                                    id="setting-url"
                                    variant="outlined"
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{ marginTop: '0.85rem' }}>
                                <TextField
                                    error={Boolean(settingForm.formState.errors?.traitType)}
                                    {...settingForm.register('itemUpdateNotificationHeaderKey')}
                                    label="Header Key"
                                    id="attribute-headerKey"
                                    variant="outlined"
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{ marginTop: '0.85rem' }}>
                                <TextField
                                    error={Boolean(settingForm.formState.errors?.displayType)}
                                    {...settingForm.register('itemUpdateNotificationApiKey')}
                                    label="Api Key"
                                    id="attribute-apiKey"
                                    variant="outlined"
                                />
                                <Button
                                    sx={{ marginTop: '0.85rem' }}
                                    type="submit"
                                    variant="contained"
                                    disabled={!settingForm.formState.isDirty || !settingForm.formState.isValid}
                                >
                                    Add Setting
                                </Button>
                            </FormControl>
                        </form>
                    </TabPanel>
                </TabContext>
            </Box>
            <Box sx={{ width: '50%' }}>
                <Box sx={{ paddingBottom: '5px', paddingTop: '43px' }}>Environment Settings</Box>
                <SettingListContents columns={columns} handleOnClick={setSettings} rows={environmentSettings} />
            </Box>
        </Grid>
    );
}

interface SettingListContents {
    columns: GridColDef[];
    handleOnClick: (data: GridRowData[], updateType: UpdateType) => void;
    rows: GridRowData[];
}

export function SettingListContents({ columns, handleOnClick, rows }: SettingListContents) {
    const [selectedRowsToDelete, setSelectedRowsToDelete] = useState<GridRowData[]>([]);
    const [deleteSelection, setDeleteSelection] = useState(false);

    function onEntitiesToRemove(selections: GridSelectionModel) {
        if (selections.length > 0) {
            setDeleteSelection(true);
        } else {
            setDeleteSelection(false);
        }

        const selectedIDs = new Set(selections);
        const rowsToDelete = rows.filter((row) => selectedIDs.has(row?.entryId.toString()));
        setSelectedRowsToDelete(rows.filter((row) => !rowsToDelete.find((f) => f.entryId === row.entryId)));
    }

    function onDeleteSelectionFromMetadata() {
        handleOnClick(selectedRowsToDelete, UpdateType.Remove);
        setDeleteSelection(false);
    }

    return (
        <div>
            <Box sx={{ height: '43vh' }}>
                <DataGrid
                    columns={columns}
                    rows={rows}
                    rowCount={rows.length}
                    pageSize={10}
                    checkboxSelection
                    onSelectionModelChange={onEntitiesToRemove}
                    getRowId={(row) => row.entryId}
                />
                <Button sx={{ marginTop: '10px' }} disabled={!deleteSelection} onClick={onDeleteSelectionFromMetadata} variant="contained">
                    Remove from Settings
                </Button>
            </Box>
        </div>
    );
}
