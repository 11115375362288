import type { ComponentType, ReactNode } from 'react';

interface RegisteredPage {
    /**
     * The path of the page, a simple string, no slashes, must be unique per PageRegistry
     */
    path: string;
    /**
     * The name of the page for display in breadcrumbs, can be a simple string, component instance or type
     */
    name: string | ComponentType | ReactNode;
    /**
     * The page's icon to display in breadcrumbs and menus
     */
    icon?: ReactNode;
    /**
     * React component type which represents the page
     */
    page: ComponentType;
}

export interface IPageRegistry {
    getPages(): Readonly<RegisteredPage[]>;
    getPage(name: string): Readonly<RegisteredPage> | undefined;
    register(page: RegisteredPage): void;
}

class PageRegistry implements IPageRegistry {
    private pageLookup = new Map<string, RegisteredPage>();
    private pages: Readonly<RegisteredPage[]> = [];

    public register = (page: RegisteredPage) => {
        this.pageLookup.set(page.path, page);
        this.pages = [...this.pages, page];
    };

    public getPages = () => {
        return this.pages;
    };

    public getPage = (path: string) => {
        return this.pageLookup.get(path);
    };
}

export function createPageRegistry() {
    return new PageRegistry();
}
