import { useUrlParams } from '@components';
import { DragHandle, Save } from '@mui/icons-material';
import { TabList } from '@mui/lab';
import { IconButton, Tab, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import type { ShopTab } from '@services/model';
import { nanoid } from 'nanoid';
import type { SyntheticEvent } from 'react';
import { useState } from 'react';
import type { DropResult } from 'react-beautiful-dnd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import { useMount } from 'react-use';

import { useShop } from './ShopProvider';
import { UserPermissions } from './UserPermissions';

const sortTabsByPosition = (a: ShopTab, z: ShopTab) => (a.position as number) - (z.position as number);

export function ShopTabs() {
    const { selectShopTab, selectedShopTab, selectedShopVersion, getShopTabs, updateShopTab, shopTabs } = useShop();
    const { params, updateParams, setParams } = useUrlParams();

    const [sortableTabs, setSortableTabs] = useState<ShopTab[]>(shopTabs.sort(sortTabsByPosition));
    const [hasReorderedTabs, setHasReorderedTabs] = useState(false);
    const handleTabChange = (_: SyntheticEvent<Element, Event>, tabId: string) => {
        const nextTab = shopTabs?.find((tab) => tab.id === tabId);
        if (nextTab) {
            selectShopTab(nextTab);
            setParams({ ...params, tabId });
        }
    };

    const handleEditShopTab = (tab: ShopTab) => {
        if (selectedShopVersion?.id) {
            updateShopTab(
                { data: { ...tab, shopVersionId: selectedShopVersion?.id }, id: tab.id as string },
                {
                    onSuccess() {
                        getShopTabs(selectedShopVersion?.id as string);
                    },
                }
            );
        }
    };
    useMount(() => {
        const _selectedShopTab = shopTabs.find((tab) => tab.id === params.tabId) ?? shopTabs[0];
        if (_selectedShopTab) {
            handleTabChange({} as SyntheticEvent, _selectedShopTab.id as string);
        }
    });

    const handleDragEnd = (result: DropResult) => {
        const oldPosition = result.source.index;
        const newPosition = result.destination?.index ?? -1;
        const isPositionLowered = newPosition < oldPosition;
        setHasReorderedTabs(true);

        if (isPositionLowered) {
            setSortableTabs((sortableTabs) => {
                return sortableTabs
                    .map((tab, index) => {
                        if (index === oldPosition) {
                            return { ...tab, position: newPosition };
                        } else if (index < oldPosition) {
                            return { ...tab, position: (tab.position as number) + 1 };
                        }
                        return tab;
                    })
                    .sort(sortTabsByPosition);
            });
        } else {
            setSortableTabs((sortableTabs) => {
                return sortableTabs
                    .map((tab, index) => {
                        if (index === oldPosition) {
                            return { ...tab, position: newPosition };
                        } else if (index <= newPosition) {
                            return { ...tab, position: index === 0 ? 0 : (tab.position as number) - 1 };
                        }
                        return tab;
                    })
                    .sort(sortTabsByPosition);
            });
        }
    };

    const handleSaveShopTabPositions = () => {
        sortableTabs.forEach((tab) => handleEditShopTab(tab));
        toast.success('Tab positions successfully saved.');
    };

    return (
        selectedShopTab && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId={nanoid()} isDropDisabled={true}>
                        {(provided) => (
                            <div>
                                <TabList onChange={handleTabChange} sx={{ mr: '12px' }}>
                                    {sortableTabs.map((tab) => (
                                        <Tab
                                            sx={{ border: '1px solid #8885', borderTop: 'none', mx: '4px' }}
                                            label={<TabLabel tab={tab} />}
                                            value={tab.id as string}
                                            key={tab.id}
                                        />
                                    ))}
                                </TabList>
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Box>
        )
    );
}

interface TabLabelProps {
    tab: ShopTab;
}

function TabLabel(props: TabLabelProps) {
    const { tab } = props;
    return (
        <Draggable key={tab.id} draggableId={tab.id as string} index={tab.position as number}>
            {(provided, snapshot) => (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        '&:hover': { cursor: 'pointer' },
                        background: snapshot.isDragging ? '#E0E0E0' : 'inherit',
                    }}
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                >
                    <Box sx={{ mr: 1 }}>{tab.name}</Box>
                    <UserPermissions requires={['update']} resource="Shop">
                        <span {...provided.dragHandleProps}></span>
                    </UserPermissions>
                </Box>
            )}
        </Draggable>
    );
}
