import { DropdownInput, FormGrid, FormSection, modal, notify, silentMonitor, TextInput } from '@components';
import { usePermissions } from '@core';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, CircularProgress, Divider, FormLabel, Grid, MenuItem, Toolbar } from '@mui/material';
import { useGetApiBundles } from '@services/bundles/bundles';
import { useGetApiContainers } from '@services/containers/containers';
import { useGetApiItems } from '@services/items/items';
import type {
    BulkGrantCurrencyRequest,
    BulkGrantCurrencyRequestCurrencies,
    BulkGrantItemRequest,
    BulkPlayerOperationResponse,
    BulkPlayerOperationStatus,
    GrantItemsToPlayerRequest,
} from '@services/model';
import { useGetApiVirtualCurrencyByEnvironment } from '@services/virtual-currency/virtual-currency';
import { PageBody, PageContent } from 'features/shell/layout';
import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useFieldArray, useForm, useFormContext, useFormState } from 'react-hook-form';
import { orvalRequestor } from 'shared/core/GameServiceApiOrvalFacade';
import type { InferType } from 'yup';
import { array, number, object, string } from 'yup';

import { BulkResults } from './BulkResults';
import { getEmailsFromInput, getInvalidEmails } from './BulkUtils';
import { GrantEntityModal } from './PlayerInventory';

const NUM_PLAYERS_PER_BATCH = 1;

enum BulkGrantType {
    VirtualCurrency = 1,
    InventoryItem = 2,
}

enum GrantItemType {
    Item = 1,
    Bundle = 2,
    Container = 3,
}

interface CurrencyItem {
    currencyCode: string;
    amount: number;
}

const yupSchema = object()
    .shape({
        emails: string().required('Emails are Required'),
        bulkGrantType: number(),
        currencyItems: array(
            object({
                currencyCode: string(),
                amount: number().required().min(0, 'The amount cannot be negative').typeError('Amount must be a number'),
            })
        ),
        itemId: string().when('bulkGrantType', {
            is: BulkGrantType.InventoryItem,
            then: string().required('Item ID is Required.'),
        }),
    })
    .required();

export type IFormInput = InferType<typeof yupSchema>;

export interface FormValues {
    emails: string;
    bulkGrantType: BulkGrantType;
    itemId: string;
    currencyItems: { currencyCode: string; amount: number }[];
}

function useFormFields() {
    const { control } = useFormContext<FormValues>();

    const { fields } = useFieldArray<FormValues>({
        control,
        name: 'currencyItems',
    });

    return { fields };
}

interface FormFieldProps {
    bulkGrantType: BulkGrantType;
    isGranting: boolean;
    onPickItem: () => void;
}

function FormFields({ bulkGrantType, isGranting, onPickItem }: FormFieldProps) {
    const { fields } = useFormFields();

    return (
        <form>
            <TextInput
                width="full"
                label="Email Addresses"
                name="emails"
                multiline
                helperText="Enter one email per line"
                disabled={isGranting}
                rows={10}
            />
            <DropdownInput width="full" label="Grant Type" name="bulkGrantType" disabled={isGranting}>
                <MenuItem value={BulkGrantType.VirtualCurrency}>Virtual Currency</MenuItem>
                <MenuItem value={BulkGrantType.InventoryItem}>Inventory Item</MenuItem>
            </DropdownInput>
            {bulkGrantType === BulkGrantType.VirtualCurrency && (
                <>
                    <Box mb={1}>
                        <FormLabel sx={{ width: '100%' }}>Amounts to Grant</FormLabel>
                    </Box>
                    {fields.map((field, index) => (
                        <Box key={field.id}>
                            <TextInput
                                label={field.currencyCode}
                                name={`currencyItems.${index}.amount`}
                                width="full"
                                number={true}
                                disabled={isGranting}
                            />
                        </Box>
                    ))}
                </>
            )}
            {bulkGrantType === BulkGrantType.InventoryItem && (
                <>
                    <FormLabel sx={{ width: '100%' }}>Item to Grant</FormLabel>
                    <Box mt={1}>
                        <Grid item container xs={12} spacing={2}>
                            <Grid item xs={9}>
                                <TextInput width="full" label="Item ID" name="itemId" />
                            </Grid>
                            <Grid item xs={3}>
                                <Box mt={1}>
                                    <Button onClick={onPickItem} variant="contained" fullWidth disabled={isGranting}>
                                        Pick Item
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            )}
        </form>
    );
}

/**
 * Given the currencies, returns a list of currency items with valid amounts.
 */
export const getValidCurrencyItems = (items: CurrencyItem[]): CurrencyItem[] => {
    const validItems = items.filter((ci) => ci.amount > 0);
    return validItems;
};

function GrantForm() {
    const { canUpdate: canGrant } = usePermissions('Player');
    const [isGranting, setIsGranting] = useState(false);
    const wasCancelledRef = useRef(false);
    const [progress, setProgress] = useState(0);
    const [statuses, setStatuses] = useState<BulkPlayerOperationStatus[]>([]);
    const [newItemId, setNewItemId] = useState<string | null>(null);
    const [itemType, setItemType] = useState<GrantItemType>(GrantItemType.Item);
    const { isLoading: vcLoading, data: vcData } = useGetApiVirtualCurrencyByEnvironment();
    const { isLoading: itemsLoading, data: items } = useGetApiItems({ SortBy: 'Id', SortOrder: 'ASC' as 'ASC' | 'DESC', Page: 1, PageSize: 100000 });
    const { isLoading: bundlesLoading, data: bundles } = useGetApiBundles({
        SortBy: 'Id',
        SortOrder: 'ASC' as 'ASC' | 'DESC',
        Page: 1,
        PageSize: 100000,
    });
    const { isLoading: containersLoading, data: containers } = useGetApiContainers({
        SortBy: 'Id',
        SortOrder: 'ASC' as 'ASC' | 'DESC',
        Page: 1,
        PageSize: 100000,
    });

    const form = useForm<IFormInput>({
        mode: 'onChange',
        resolver: yupResolver(yupSchema),
        defaultValues: {
            emails: '',
            bulkGrantType: BulkGrantType.VirtualCurrency,
            currencyItems: [
                {
                    currencyCode: '',
                    amount: 0,
                },
            ],
            itemId: '',
        },
    });

    const { isDirty } = useFormState(form);

    useEffect(() => {
        if (!vcData) return;

        const currencyItems: CurrencyItem[] = [];
        vcData.forEach((vc) => {
            currencyItems.push({ currencyCode: vc.currencyCode, amount: 0 });
        });

        form.setValue('currencyItems', currencyItems, {
            shouldValidate: true,
            shouldDirty: true,
        });
    }, [form, vcData]);

    useEffect(() => {
        if (newItemId === null) return;

        form.setValue('itemId', newItemId, {
            shouldValidate: true,
            shouldDirty: true,
        });
        setNewItemId(null);
    }, [form, newItemId]);

    const handlePickItem = async () => {
        form.setValue('itemId', '');
        let grantItemsRequest: GrantItemsToPlayerRequest = { playerId: '', items: [], bundles: [], containers: [], dropTables: [] };
        await modal('Pick Item to Grant', (close) => (
            <GrantEntityModal
                onClose={close}
                items={items?.items}
                bundles={bundles?.items}
                containers={containers?.items}
                dropTables={[]}
                currencies={[]}
                playerId={'ITEM WAS PICKED'}
                onInventoryGrant={(e) => (grantItemsRequest = e)}
                onCurrencyGrant={() => {
                    /* do nothing */
                }}
                wantsSingleSelection={true}
                excludeTabs={['3', '4']}
            />
        ));
        if (grantItemsRequest.playerId.length > 0) {
            let itemId = '';
            if (grantItemsRequest.items && grantItemsRequest.items.length > 0) {
                itemId = grantItemsRequest.items[0];
                setItemType(GrantItemType.Item);
            } else if (grantItemsRequest.bundles && grantItemsRequest.bundles.length > 0) {
                itemId = grantItemsRequest.bundles[0];
                setItemType(GrantItemType.Bundle);
            } else if (grantItemsRequest.containers && grantItemsRequest.containers.length > 0) {
                itemId = grantItemsRequest.containers[0];
                setItemType(GrantItemType.Container);
            }

            setNewItemId(itemId);
        }
    };

    const postApiBulkGrantCurrency = useCallback((request: BulkGrantCurrencyRequest) => {
        return orvalRequestor<BulkGrantCurrencyRequest>({
            url: '/api/players/bulk-grant-currency',
            method: 'post',
            data: request,
        });
    }, []);

    const postApiBulkGrantItem = useCallback((request: BulkGrantItemRequest) => {
        return orvalRequestor<BulkGrantItemRequest>({
            url: '/api/players/bulk-grant-item',
            method: 'post',
            data: request,
        });
    }, []);

    const sendCurrencyBatch = useCallback(
        async (emails: string[], currencies: BulkGrantCurrencyRequestCurrencies): Promise<BulkPlayerOperationResponse> => {
            let response = {};
            let error = null;

            await silentMonitor({
                failureMessage: '',
                successMessage: '',
                action: async () => {
                    const request: BulkGrantCurrencyRequest = {
                        emails,
                        currencies,
                    };
                    response = await postApiBulkGrantCurrency(request);
                },
                onError: (e) => {
                    error = e;
                },
            });

            if (error) {
                throw new Error(error);
            }

            return response;
        },
        [postApiBulkGrantCurrency]
    );

    const sendItemBatch = useCallback(
        async (emails: string[], itemId: string): Promise<BulkPlayerOperationResponse> => {
            let response = {};
            let error = null;

            let grantItemId: string | null = null;
            let grantBundleId: string | null = null;
            let grantContainerId: string | null = null;

            switch (itemType) {
                case GrantItemType.Bundle:
                    grantBundleId = itemId;
                    break;
                case GrantItemType.Container:
                    grantContainerId = itemId;
                    break;
                default:
                    grantItemId = itemId;
                    break;
            }

            await silentMonitor({
                failureMessage: '',
                successMessage: '',
                action: async () => {
                    const request: BulkGrantItemRequest = {
                        emails,
                        itemId: grantItemId,
                        bundleId: grantBundleId,
                        containerId: grantContainerId,
                    };
                    response = await postApiBulkGrantItem(request);
                },
                onError: (e) => {
                    error = e;
                },
            });

            if (error) {
                throw new Error(error);
            }

            return response;
        },
        [itemType, postApiBulkGrantItem]
    );

    const grant = useCallback(
        async (emails: string[], grantType: BulkGrantType, currencyItems: CurrencyItem[], itemId: string, batchSize: number) => {
            setProgress(0);
            setStatuses([]);

            let batch: string[] = [];
            const playerStatusMap: { [key: string]: BulkPlayerOperationStatus } = {};
            const allPlayers: BulkPlayerOperationStatus[] = [];
            emails.forEach((email) => {
                const playersModel = { email };
                allPlayers.push(playersModel);
                playerStatusMap[email] = playersModel;
            });

            // set the initial state of all emails in results view
            const newBulkStatus = _.cloneDeep(allPlayers);
            setStatuses(newBulkStatus);

            // convert currency items to BulkGrantCurrencyRequestCurrencies
            const currencies = currencyItems.reduce<BulkGrantCurrencyRequestCurrencies>((newCurrencies, currencyItem) => {
                newCurrencies[currencyItem.currencyCode] = currencyItem.amount;
                return newCurrencies;
            }, {});

            for (let index = 0; index < emails.length; index++) {
                // if we haven't been cancelled, process a batch
                if (!wasCancelledRef.current) {
                    batch.push(emails[index]);
                    if (batch.length === batchSize || index === emails.length - 1) {
                        try {
                            const result =
                                grantType === BulkGrantType.VirtualCurrency
                                    ? await sendCurrencyBatch(batch, currencies)
                                    : await sendItemBatch(batch, itemId);
                            result.statuses?.forEach((status) => {
                                const playerModel = playerStatusMap[status.email!];
                                playerModel.success = status.success;
                                playerModel.errorMessage = status.errorMessage;
                            });

                            setProgress(index + 1);
                        } catch (e) {
                            // fail the remaining email statuses
                            const emailsRemaining = [...batch, ...emails.slice(index)];
                            emailsRemaining.forEach((email) => {
                                const playerModel = playerStatusMap[email];
                                playerModel.success = false;
                                playerModel.errorMessage = `API error: ${e}`;
                            });

                            index = emails.length;
                            setProgress(emails.length);
                        }
                        batch = [];
                    }
                } else {
                    // we've been cancelled, so update remaining email statuses
                    const emailsRemaining = [...batch, ...emails.slice(index)];
                    emailsRemaining.forEach((email) => {
                        const playerModel = playerStatusMap[email];
                        playerModel.success = false;
                        playerModel.errorMessage = 'Cancelled';
                    });

                    index = emails.length;
                    setProgress(emails.length);
                }

                // update the states of all emails in results view
                const newBulkStatus = _.cloneDeep(allPlayers);
                setStatuses(newBulkStatus);
            }
        },
        [sendCurrencyBatch, sendItemBatch]
    );

    const grantItem = useCallback(async () => {
        const data = form.getValues();

        const emails = getEmailsFromInput(data.emails);

        // make sure all emails are valid
        const invalidEmails = getInvalidEmails(emails);
        if (invalidEmails.length) {
            notify({ type: 'error', content: `Some emails are invalid, like '${invalidEmails[0]}'.` });
            return;
        }

        let validCurrencyItems: CurrencyItem[] = [];
        // depending on our grant type, validate the currencies or items
        if (data.bulkGrantType === BulkGrantType.VirtualCurrency) {
            validCurrencyItems = getValidCurrencyItems(data.currencyItems);
            if (validCurrencyItems.length === 0) {
                notify({ type: 'error', content: 'At least one currency amount must be greater than zero' });
                return;
            }
        } else if (data.bulkGrantType === BulkGrantType.InventoryItem) {
            if (data.itemId.length === 0) {
                notify({ type: 'error', content: 'Please select an item to grant' });
                return;
            }
        }

        setIsGranting(true);
        wasCancelledRef.current = false;
        setProgress(0);
        setStatuses([]);
        try {
            await grant(emails, data.bulkGrantType, validCurrencyItems, data.itemId, NUM_PLAYERS_PER_BATCH);
        } finally {
            setIsGranting(false);
        }
    }, [form, grant]);

    const subheader = isGranting ? `${progress} of ${statuses.length}` : '';

    if (vcLoading || itemsLoading || bundlesLoading || containersLoading) {
        return (
            <Box textAlign="center">
                <CircularProgress />
            </Box>
        );
    }

    const formValues = form.getValues();
    const bulkGrantType = formValues.bulkGrantType;

    return (
        <PageContent>
            <PageBody>
                <FormProvider {...form}>
                    <FormGrid>
                        <Grid item container xs={12} spacing={2}>
                            <FormSection header="Grant to Multiple Players">
                                <FormFields bulkGrantType={bulkGrantType} isGranting={isGranting} onPickItem={handlePickItem} />
                            </FormSection>
                        </Grid>
                        {(isGranting || statuses.length > 0) && <BulkResults header={'Results'} statuses={statuses} subheader={subheader} />}
                    </FormGrid>
                </FormProvider>
            </PageBody>
            <Divider />
            {canGrant && (
                <Toolbar>
                    {!isGranting && (
                        <Button onClick={grantItem} variant="contained" disabled={!form.formState.isValid || !isDirty}>
                            Grant
                        </Button>
                    )}
                    {isGranting && (
                        <Button
                            onClick={() => {
                                wasCancelledRef.current = true;
                            }}
                            variant="contained"
                            disabled={wasCancelledRef.current}
                        >
                            Cancel
                        </Button>
                    )}
                </Toolbar>
            )}
        </PageContent>
    );
}

export function BulkGrant() {
    return <GrantForm />;
}
