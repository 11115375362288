/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.14.2.2422
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ItemToken,
  SaveItemTokenRequest,
  ObjectIPagedResultList,
  EntitySearch,
  ItemTokenIPagedResultList,
  MakeItemTokenBridgeableToBlockchainRequest
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const getApiItemTokensId = (
    id: string,
 ) => {
      return orvalRequestor<ItemToken>(
      {url: `/api/item-tokens/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiItemTokensIdQueryKey = (id: string,) => [`/api/item-tokens/${id}`];

    
export const useGetApiItemTokensId = <TData = AsyncReturnType<typeof getApiItemTokensId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiItemTokensId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiItemTokensIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiItemTokensId>> = () => getApiItemTokensId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiItemTokensId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const putApiItemTokensId = (
    id: string,
    saveItemTokenRequest: SaveItemTokenRequest,
 ) => {
      return orvalRequestor<ItemToken>(
      {url: `/api/item-tokens/${id}`, method: 'put',
      data: saveItemTokenRequest
    },
      );
    }
  


    export const usePutApiItemTokensId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiItemTokensId>, TError,{id: string;data: SaveItemTokenRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiItemTokensId>, {id: string;data: SaveItemTokenRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiItemTokensId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiItemTokensId>, TError, {id: string;data: SaveItemTokenRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const deleteApiItemTokensId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/item-tokens/${id}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiItemTokensId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiItemTokensId>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiItemTokensId>, {id: string}> = (props) => {
          const {id} = props || {};

          return  deleteApiItemTokensId(id,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiItemTokensId>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiItemTokens = (
    saveItemTokenRequest: SaveItemTokenRequest,
 ) => {
      return orvalRequestor<ItemToken>(
      {url: `/api/item-tokens`, method: 'post',
      data: saveItemTokenRequest
    },
      );
    }
  


    export const usePostApiItemTokens = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiItemTokens>, TError,{data: SaveItemTokenRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiItemTokens>, {data: SaveItemTokenRequest}> = (props) => {
          const {data} = props || {};

          return  postApiItemTokens(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiItemTokens>, TError, {data: SaveItemTokenRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiItemTokensQuery = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ObjectIPagedResultList>(
      {url: `/api/item-tokens/query`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiItemTokensQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiItemTokensQuery>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiItemTokensQuery>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiItemTokensQuery(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiItemTokensQuery>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiItemTokensFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ItemTokenIPagedResultList>(
      {url: `/api/item-tokens/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiItemTokensFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiItemTokensFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiItemTokensFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiItemTokensFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiItemTokensFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const putApiItemTokensItemTokenIdSupportedBlockchain = (
    itemTokenId: string,
    makeItemTokenBridgeableToBlockchainRequest: MakeItemTokenBridgeableToBlockchainRequest,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/item-tokens/${itemTokenId}/supported_blockchain`, method: 'put',
      data: makeItemTokenBridgeableToBlockchainRequest
    },
      );
    }
  


    export const usePutApiItemTokensItemTokenIdSupportedBlockchain = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiItemTokensItemTokenIdSupportedBlockchain>, TError,{itemTokenId: string;data: MakeItemTokenBridgeableToBlockchainRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiItemTokensItemTokenIdSupportedBlockchain>, {itemTokenId: string;data: MakeItemTokenBridgeableToBlockchainRequest}> = (props) => {
          const {itemTokenId,data} = props || {};

          return  putApiItemTokensItemTokenIdSupportedBlockchain(itemTokenId,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiItemTokensItemTokenIdSupportedBlockchain>, TError, {itemTokenId: string;data: MakeItemTokenBridgeableToBlockchainRequest}, TContext>(mutationFn, mutationOptions)
    }
    