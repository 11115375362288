import { useDi } from '@core';
import { useEffect, useState } from 'react';
import { inject, singleton } from 'tsyringe';

import { GameServiceApiBase } from './GameServiceApiBase';

@singleton()
export class MediaRequestAuthService {
    public constructor(@inject(GameServiceApiBase) private readonly gameService: GameServiceApiBase) {}

    public getMediaUrl = async (path: string) => {
        if (path.toLowerCase().indexOf(this.gameService.getBaseUrl()) !== 0) {
            return path;
        } else {
            return await this.gameService.getBlobUrl(path);
        }
    };
}

interface PreparedUpload {
    tempUrl: string;
    name: string;
    size: number;
    type: string;
    upload(uploadUrl: string): Promise<{ url: string }>;
}
@singleton()
export class FileUploadService {
    public constructor(@inject(GameServiceApiBase) private readonly gameService: GameServiceApiBase) {}

    public prepare = (fileInput: HTMLInputElement): PreparedUpload | undefined => {
        const file = 'files' in fileInput && fileInput.files?.length === 1 ? fileInput.files.item(0) : null;

        return file ? this.createPreparedDownload(file) : undefined;
    };

    private createPreparedDownload(file: File) {
        const tempUrl = file ? URL.createObjectURL(file) : null;

        return {
            tempUrl,
            name: file.name,
            size: file.size,
            type: file.type,
            upload: (uploadUrl: string) => this.upload(uploadUrl, file),
        } as PreparedUpload;
    }

    private upload = async (url: string, file: File) => {
        const response = await this.gameService.uploadFile(url, file);
        const name = await response.text();
        return { url: `${this.gameService.getBaseCdnUrl()}/${encodeURIComponent(name)}` };
    };
}

export function useFileUploader() {
    const { prepare } = useDi(FileUploadService);
    return { prepare };
}

export function useSecureMedia(mediaUrl: string) {
    const { getMediaUrl } = useDi(MediaRequestAuthService);
    const [url, setUrl] = useState('');
    useEffect(() => {
        if (mediaUrl) {
            void getMediaUrl(mediaUrl).then(setUrl);
        } else {
            setUrl('');
        }
    }, [mediaUrl, getMediaUrl]);
    return { url };
}
