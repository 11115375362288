import { confirm, MarkdownEditor } from '@components';
import { buildSearch, useApiListener, useLogger } from '@core';
import { yupResolver } from '@hookform/resolvers/yup';
import { Delete } from '@mui/icons-material';
import { Avatar, Box, Button, Divider, IconButton, LinearProgress, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import type { Notes as NotesModel, NoteThread, NoteType, UserAccount } from '@services/model';
import { usePostApiNoteThreads, usePostApiNoteThreadsFindBy } from '@services/note-threads/note-threads';
import { useDeleteApiNotesId, usePostApiNotes, usePostApiNotesFindBy } from '@services/notes/notes';
import { usePostApiUserAccountsFindBy } from '@services/user-accounts/user-accounts';
import { Marked } from '@ts-stack/markdown';
import { format, parseISO } from 'date-fns';
import faker from 'faker';
import { Fragment, useCallback, useState } from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { useMount } from 'react-use';
import * as yup from 'yup';

interface NotesProps {
    /**
     * typeId: Player Id, Item Id or Shop Id
     */
    typeId: string;
    type: NoteType;
    hideTitle?: boolean;
}
const NotesSchema = yup.object().shape({
    threadId: yup.string(),
    note: yup.string(),
});
export const Notes = ({ typeId, type, hideTitle }: NotesProps) => {
    const { mutateAsync } = usePostApiNoteThreadsFindBy();
    const { mutateAsync: findNotesByThreadId, data: notes, isLoading: noteLoading } = usePostApiNotesFindBy();
    // TODO: Replace UserAccount with AdminUsers
    const [userLookup, setUserLookup] = useState(new Map<string, UserAccount>());
    const [threadId, setThreadId] = useState<string | null>();
    const logger = useLogger();
    const [defaultValues, setDefaultValues] = useState<NotesModel>({ note: '', threadId: String(threadId) });
    const { mutateAsync: addThread } = usePostApiNoteThreads();
    const { mutateAsync: addNote } = usePostApiNotes();
    const { mutateAsync: removeNote } = useDeleteApiNotesId();
    // TODO: Replace UserAccount with AdminUsers
    const { mutateAsync: findUsers } = usePostApiUserAccountsFindBy();
    const search = useCallback(async () => {
        await mutateAsync(buildSearch<NoteThread>(1, 1000, { eq: { typeId: typeId } }))
            .then((n) => {
                const threadId = n?.items?.[0]?.id;
                setDefaultValues({ threadId: String(threadId), note: '' });
                setThreadId(threadId);

                if (threadId) {
                    findNotesByThreadId(buildSearch<NotesModel>(1, 1000, { eq: { threadId: threadId } }, [{ field: 'createdAt', desc: true }]));
                }
            })
            .catch((e) => logger.error(e));
    }, [findNotesByThreadId, logger, mutateAsync, typeId]);

    useMount(async () => {
        await search();
        findUsers(buildSearch()).then(({ items }) => {
            setUserLookup(new Map((items || []).map((o) => [o.id || '', { email: String(o.email) || '' }] as [string, UserAccount])));
        });
    });
    const form = useForm({ resolver: yupResolver(NotesSchema), defaultValues, mode: 'onBlur', reValidateMode: 'onBlur' });
    const { isDirty } = useFormState({ control: form.control });
    const onSave = useCallback(async () => {
        const values = form.getValues();
        if (threadId) {
            await addNote({ data: { threadId: threadId, note: values.note } });
        } else {
            await addThread({ data: { type: type, typeId: typeId } }).then((nt) => {
                setThreadId(nt.id);
                setDefaultValues({ threadId: String(nt?.id), note: '' });
                addNote({ data: { threadId: String(nt?.id), note: values.note } });
            });
        }
        form.reset(defaultValues);
    }, [addNote, addThread, defaultValues, form, threadId, type, typeId]);
    const onDelete = useCallback(
        async (id: string) => {
            const didConfirm = await confirm(`Delete Note`, `Are you sure you want to delete this note?`);
            if (didConfirm) {
                await removeNote({ id: String(id) ?? '' });
            }
        },
        [removeNote]
    );
    useApiListener('notethread', search);
    useApiListener('note', search);
    return noteLoading ? (
        <LinearProgress />
    ) : (
        <>
            {hideTitle ? null : <Typography variant="h5">Notes</Typography>}
            <Box mt={1}>
                <Box mt={1}>
                    <FormProvider {...form}>
                        <MarkdownEditor name="note" />
                    </FormProvider>
                </Box>
                <Box p={2} justifyContent="flex-end" display="flex">
                    <Button variant="contained" color="primary" disabled={!isDirty} onClick={onSave}>
                        Add Note
                    </Button>
                </Box>
            </Box>
            <Box mt={3}>
                <List>
                    {notes && notes.items ? (
                        notes.items.map((note) => {
                            return (
                                <Fragment key={note.id}>
                                    <>
                                        <ListItem key={note.id} alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar alt="avatar" src={faker.image.avatar()} />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <>
                                                        <Typography component="span">
                                                            <Typography component="span" variant="caption" color="textPrimary">
                                                                {` - ${format(parseISO(String(note.createdAt)), 'Ppp')}`}
                                                            </Typography>
                                                            <IconButton onClick={() => onDelete(String(note.id))} aria-label="delete" size="small">
                                                                <Delete fontSize="inherit" />
                                                            </IconButton>
                                                        </Typography>
                                                    </>
                                                }
                                                secondary={
                                                    <>
                                                        <Typography component="span" variant="body2" color="textPrimary">
                                                            {userLookup.get(String(note.userId))?.email}
                                                        </Typography>
                                                        <div dangerouslySetInnerHTML={{ __html: Marked.parse(String(note.note)) }} />
                                                    </>
                                                }
                                            />
                                        </ListItem>
                                        <Divider />
                                    </>
                                </Fragment>
                            );
                        })
                    ) : (
                        <></>
                    )}
                </List>
            </Box>
        </>
    );
};
