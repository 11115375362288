import { BoolInput } from '@components';
import { useEvent } from '@core';
import { ListItem, ListItemText } from '@mui/material';
import type { CanHave } from '@services/model';
import { useCallback } from 'react';

import { CanHaves } from './Design';
import type { PermissionBuilder } from './Models';

export function PermissionListItem({ permission }: PermissionListItemProps) {
    const {
        permission: { canRead, canCreate, canUpdate, canDelete, canExecute },
    } = permission;
    const update = useCallback(
        (can: keyof CanHave) => {
            const nextValue = !permission.permission[can];
            permission.update({ [can]: nextValue });
        },
        [permission]
    );
    useEvent(permission.permissionChanged);
    return (
        <ListItem disablePadding dense sx={{ paddingLeft: '7.5rem' }}>
            <ListItemText primary={permission.resource.name} />
            <CanHaves>
                <BoolInput label="" margin={0} value={canRead} onChange={() => update('canRead')} />
                <BoolInput label="" margin={0} value={canCreate} onChange={() => update('canCreate')} />
                <BoolInput label="" margin={0} value={canUpdate} onChange={() => update('canUpdate')} />
                <BoolInput label="" margin={0} value={canExecute} onChange={() => update('canExecute')} />
                <BoolInput label="" margin={0} value={canDelete} onChange={() => update('canDelete')} />
            </CanHaves>
        </ListItem>
    );
}
interface PermissionListItemProps {
    permission: PermissionBuilder;
}
