import { useNavigator } from '@components';
import { Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';

interface CellNavigationProps {
    id: string;
    value: string;
    to: string;
    tab?: string;
}

export const CellNavigation = ({ id, value, to, tab }: CellNavigationProps) => {
    const { getDescendUrl } = useNavigator();
    return (
        <MuiLink component={Link} to={getDescendUrl(to, tab ? { id: id || '', tab: tab } : { id: id || '' })}>
            {value}
        </MuiLink>
    );
};
