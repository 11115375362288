import { AdminTable, CellNavigation, confirm, DropdownInput, modal, monitor, TextInput } from '@components';
import { searchBuilder, useApiListener } from '@core';
import { yupResolver } from '@hookform/resolvers/yup';
import { Delete } from '@mui/icons-material';
import { Box, Button, LinearProgress, MenuItem } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid';
import { usePostApiGameEnvironmentsFindBy } from '@services/game-environments/game-environments';
import type {GameEnvironment, GameEnvironmentResult, Resource} from '@services/model';
import { useDeleteApiResourcesId, usePostApiResources, usePostApiResourcesFindBy } from '@services/resources/resources';
import { format, parseISO } from 'date-fns';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { useMount } from 'react-use';
import * as yup from 'yup';

const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', flex: 0.5, hide: true },
    {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        renderCell: (cellValues) => <CellNavigation id={String(cellValues?.id)} value={String(cellValues?.value)} to="account-details" />,
    },
    { field: 'createdAt', headerName: 'Created', flex: 0.5, valueFormatter: (params) => format(parseISO(String(params.value)), 'Ppp') },
];

export function Resources(props: { organizationId: string }) {
    const { mutateAsync: findResources, data, isLoading } = usePostApiResourcesFindBy();
    const { mutateAsync: findGameEnvironments, data: gameEnvironments } = usePostApiGameEnvironmentsFindBy();
    const { mutateAsync: remove } = useDeleteApiResourcesId();
    const searchResources = searchBuilder('Resource')
        .leftJoin('Organization', ([r, o]) => ({ eq: { [r.scopeEntityId]: o.id } }))
        .leftJoin('GameEnvironment', ([r, _o, ge]) => ({ eq: { [r.scopeEntityId]: ge.id } }))
        .leftJoin('Game', ([r, _o, ge, g]) => ({ eq: { [ge.gameId]: g.id } }))
        .where(([r, o, _ge, g]) => ({
            or: [{ eq: { [o.nameof.id]: props.organizationId } }, { eq: { [g.organizationId]: props.organizationId } }],
        }))
        .build();
    const searchGameEnvironments = searchBuilder('GameEnvironment')
        .innerJoin('Game', ([ge, g]) => ({ eq: { [ge.gameId]: g.id } }))
        .where(([_ge, g]) => ({
            eq: { [g.organizationId]: props.organizationId },
        }))
        .build();
    useMount(() => {
        findResources({ data: searchResources });
        findGameEnvironments({ data: searchGameEnvironments });
    });
    const onDelete = async (e: Partial<Resource>) => {
        const didConfirm = await confirm(`Delete ${e.name ?? ''}?`, `Are you sure you want to delete this Resource?`);
        if (didConfirm) {
            await remove({ id: e.id ?? '' });
        }
    };
    const onAdd = async () => {
        await modal('Add Resource', (close) => (
            <NewResourcePrompt gameEnvironment={gameEnvironments && gameEnvironments.items ? gameEnvironments.items : []} onClose={close} />
        ));
    };
    useApiListener('resources', () => findResources({ data: searchResources }));
    return isLoading ? (
        <LinearProgress />
    ) : (
        <>
            <AdminTable<Resource>
                title="Resources"
                resource="Resource"
                columns={columns}
                data={data && data?.items ? data?.items : []}
                menuActions={[{ type: 'button', icon: Delete, label: 'Delete', onClick: onDelete }]}
                onAdd={onAdd}
            />
        </>
    );
}

const ResourceSchema = yup.object().shape({
    name: yup.string().required('Name is required!'),
    scopeEntityId: yup.string().required('Scope is required!'),
});
function NewResourcePrompt(props: { gameEnvironment: GameEnvironmentResult[]; onClose: () => void }) {
    const { mutateAsync: add } = usePostApiResources();
    const defaultValues: Resource = {
        name: '',
        scopeEntityId: '',
        scopeEntityIdType: 'GameEnvironment',
    };
    const form = useForm({ resolver: yupResolver(ResourceSchema), defaultValues, mode: 'onBlur', reValidateMode: 'onBlur' });
    const { isDirty } = useFormState({ control: form.control });
    const save = async () => {
        const values = form.getValues();

        await monitor({
            action: async () => {
                await add({ data: { ...(values as Resource) } });
                props.onClose();
            },
            failureMessage: 'Failed to add resource!',
            successMessage: 'Resource Added',
        });
    };
    return (
        <>
            <FormProvider {...form}>
                <Box px={3} width={300}>
                    <TextInput label="Name" width="full" name="name" helperText="What's version name?" />
                    <DropdownInput width="full" label="Game Title Env" name="scopeEntityId">
                        <MenuItem value="">None</MenuItem>
                        {props.gameEnvironment &&
                            props.gameEnvironment.map((c) => (
                                <MenuItem key={c.id} value={c.id || ''}>
                                    {c.name}
                                </MenuItem>
                            ))}
                    </DropdownInput>
                </Box>
            </FormProvider>
            <Box p={2} justifyContent="flex-end" display="flex">
                <Button variant="contained" color="primary" disabled={!isDirty} onClick={form.handleSubmit(save)}>
                    Create Resource
                </Button>
                <Button onClick={props.onClose}>Cancel</Button>
            </Box>
        </>
    );
}
