import { inject, singleton } from 'tsyringe';

import { AppConfigService } from './AppConfigService';
import { AuthenticationServiceToken, IAuthenticationService } from './AuthenticationService';
import { AuthorizationService } from './AuthorizationService';
import { useDi } from './DiService';
import { Event, useEventValue } from './Event';
import { GameServiceApiBase } from './GameServiceApiBase';
import { setOrvalRequestor } from './GameServiceApiOrvalFacade';
import { enableConsoleLogging, Logger } from './Logger';
import { ShellService } from './ShellService';
//import { TelemetryService } from './TelemetryService';
import { UserPreferenceService } from './UserPreferenceService';

@singleton()
export class System {
    public siteReadyChanged = new Event<boolean>();
    public siteReady = false;

    public constructor(
        @inject(AuthenticationServiceToken) private readonly authenticationSvc: IAuthenticationService,
        @inject(AuthorizationService) private readonly authorizationSvc: AuthorizationService,
        @inject(UserPreferenceService) private readonly userPrefsSvc: UserPreferenceService,
        @inject(ShellService) private readonly shellSvc: ShellService,
        @inject(AppConfigService) private readonly appConfigSvc: AppConfigService,
        //@inject(TelemetryService) private readonly telemetrySvc: TelemetryService,
        @inject(Logger) private readonly logger: Logger,
        @inject(GameServiceApiBase) private readonly gameServiceApiBase: GameServiceApiBase
    ) {}

    public async start() {
        /*
         * Configure dependencies, do initialization
         */

        this.appConfigSvc.start();
        if (this.appConfigSvc.getConfig().consoleLogging) {
            enableConsoleLogging(this.logger);
        }
        await this.authenticationSvc.start();
        setOrvalRequestor((r) => this.gameServiceApiBase.request(r));

        //this.telemetrySvc.start();
        await this.authorizationSvc.start();
        await Promise.all([this.userPrefsSvc.start(), this.shellSvc.start()]);

        this.siteReady = true;
        this.siteReadyChanged.raise(true);
    }
}

export function useSiteReadyState() {
    const system = useDi(System);
    const siteReady = useEventValue(system.siteReadyChanged, system.siteReady);
    return siteReady;
}
