/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.14.2.2422
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  Organization,
  SaveOrganizationRequest,
  OrganizationIPagedResultList,
  GetApiOrganizationsParams,
  EntitySearch,
  PostApiOrganizationsAssetBody
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const postApiOrganizations = (
    saveOrganizationRequest: SaveOrganizationRequest,
 ) => {
      return orvalRequestor<Organization>(
      {url: `/api/organizations`, method: 'post',
      data: saveOrganizationRequest
    },
      );
    }
  


    export const usePostApiOrganizations = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiOrganizations>, TError,{data: SaveOrganizationRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiOrganizations>, {data: SaveOrganizationRequest}> = (props) => {
          const {data} = props || {};

          return  postApiOrganizations(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiOrganizations>, TError, {data: SaveOrganizationRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiOrganizations = (
    params?: GetApiOrganizationsParams,
 ) => {
      return orvalRequestor<OrganizationIPagedResultList>(
      {url: `/api/organizations`, method: 'get',
        params,
    },
      );
    }
  

export const getGetApiOrganizationsQueryKey = (params?: GetApiOrganizationsParams,) => [`/api/organizations`, ...(params ? [params]: [])];

    
export const useGetApiOrganizations = <TData = AsyncReturnType<typeof getApiOrganizations>, TError = unknown>(
 params?: GetApiOrganizationsParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiOrganizations>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiOrganizationsQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiOrganizations>> = () => getApiOrganizations(params, );

  const query = useQuery<AsyncReturnType<typeof getApiOrganizations>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const putApiOrganizationsId = (
    id: string,
    saveOrganizationRequest: SaveOrganizationRequest,
 ) => {
      return orvalRequestor<Organization>(
      {url: `/api/organizations/${id}`, method: 'put',
      data: saveOrganizationRequest
    },
      );
    }
  


    export const usePutApiOrganizationsId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiOrganizationsId>, TError,{id: string;data: SaveOrganizationRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiOrganizationsId>, {id: string;data: SaveOrganizationRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiOrganizationsId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiOrganizationsId>, TError, {id: string;data: SaveOrganizationRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiOrganizationsId = (
    id: string,
 ) => {
      return orvalRequestor<Organization>(
      {url: `/api/organizations/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiOrganizationsIdQueryKey = (id: string,) => [`/api/organizations/${id}`];

    
export const useGetApiOrganizationsId = <TData = AsyncReturnType<typeof getApiOrganizationsId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiOrganizationsId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiOrganizationsIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiOrganizationsId>> = () => getApiOrganizationsId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiOrganizationsId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const deleteApiOrganizationsId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/organizations/${id}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiOrganizationsId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiOrganizationsId>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiOrganizationsId>, {id: string}> = (props) => {
          const {id} = props || {};

          return  deleteApiOrganizationsId(id,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiOrganizationsId>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiOrganizationsFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<OrganizationIPagedResultList>(
      {url: `/api/organizations/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiOrganizationsFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiOrganizationsFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiOrganizationsFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiOrganizationsFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiOrganizationsFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiOrganizationsAsset = (
    postApiOrganizationsAssetBody: PostApiOrganizationsAssetBody,
 ) => {const formData = new FormData();
if(postApiOrganizationsAssetBody.file !== undefined) {
 formData.append('file', postApiOrganizationsAssetBody.file)
 }

      return orvalRequestor<string>(
      {url: `/api/organizations/asset`, method: 'post',
       data: formData
    },
      );
    }
  


    export const usePostApiOrganizationsAsset = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiOrganizationsAsset>, TError,{data: PostApiOrganizationsAssetBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiOrganizationsAsset>, {data: PostApiOrganizationsAssetBody}> = (props) => {
          const {data} = props || {};

          return  postApiOrganizationsAsset(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiOrganizationsAsset>, TError, {data: PostApiOrganizationsAssetBody}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiOrganizationsAssetId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/organizations/asset/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiOrganizationsAssetIdQueryKey = (id: string,) => [`/api/organizations/asset/${id}`];

    
export const useGetApiOrganizationsAssetId = <TData = AsyncReturnType<typeof getApiOrganizationsAssetId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiOrganizationsAssetId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiOrganizationsAssetIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiOrganizationsAssetId>> = () => getApiOrganizationsAssetId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiOrganizationsAssetId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

