import { useSiteConfig, useSiteReadyState, useUserPrefs } from '@core';
import { Box, CssBaseline, LinearProgress, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useAnalytics } from 'shared/hooks/useAnalytics';

import { AppBar } from './AppBar';
import { Drawer } from './Drawer';

export function Shell() {
    const theme = useTheme();
    const { prefs, setPrefs } = useUserPrefs();
    const siteReady = useSiteReadyState();
    const config = useSiteConfig();
    const { sidebarNav: items } = config;
    const handleDrawerOpen = () => {
        setPrefs({ navBarCollapsed: false });
    };
    const analytics = useAnalytics();

    const handleDrawerClose = () => {
        setPrefs({ navBarCollapsed: true });
    };

    useEffect(() => {
        analytics.page();
    }, []);

    return siteReady ? (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar prefs={prefs} handleDrawerOpen={handleDrawerOpen} />
            <Drawer prefs={prefs} handleDrawerClose={handleDrawerClose} theme={theme} items={items} handleDrawerOpen={handleDrawerOpen} />
        </Box>
    ) : (
        <LinearProgress />
    );
}
