import { AdminTable } from '@components';
import { Button, Checkbox, DialogActions, Modal } from '@mui/material';
import { Box } from '@mui/system';
import type { GridCellParams, GridCellValue, GridColDef } from '@mui/x-data-grid';
import type { Item, ItemClassModel, ItemKeyedCustomData } from '@services/model';
import { useEffect, useState } from 'react';
import { useSet } from 'react-use';

export interface ItemSelectModalProps {
    items: Item[];
    itemClasses: ItemClassModel[];
    onSubmit(items: ItemShopGrid[]): void;
    onCancel(): void;
    isOpen: boolean;
}

interface IItemShopGrid extends Item {
    eligibleQuantity: number;
}

export class ItemShopGrid implements IItemShopGrid {
    
    constructor() {
        this.eligibleQuantity = 1;
        this.displayName = "";
        this.minGameVersion = "",
        this.itemName = "";
        this.itemClassId = "";
    }

    eligibleQuantity: number;
    id?: string | null | undefined;
    createdAt?: string | null | undefined;
    modifiedAt?: string | null | undefined;
    deletedAt?: string | null | undefined;
    gameEnvironmentId?: string | undefined;
    itemName: string = "";
    displayName: string;
    minGameVersion: string;
    itemClassId: string;
    customData?: string | null | undefined;
    tags?: string[] | null | undefined;
    consumable?: boolean | undefined;
    consumableUsageCount?: number | undefined;
    consumableTimespan?: number | undefined;
    imageUrl?: string | null | undefined;
    keyedCustomData?: ItemKeyedCustomData | undefined;
}

export function ItemSelectModal(props: ItemSelectModalProps) {
    const { items, itemClasses, onSubmit, onCancel, isOpen } = props;
    const [ selections, { toggle, has } ] = useSet<ItemShopGrid>();
    const [ shopItems, setShopItems ] = useState<ItemShopGrid[]>([]);

    useEffect(() => {
        setShopItems(
            items?.map((item) => ({
                ...item,
                eligibleQuantity: 1
            })) as ItemShopGrid[]
        );
    }, [items]);

    const columns: GridColDef[] = [
        {
            field: 'check',
            headerName: '',
            flex: 0,
            align: 'center',
            valueGetter: ({ row }) => has(row as ItemShopGrid),
            renderCell: ({ row }) => <Checkbox onClick={() => { toggle(row as ItemShopGrid); }}/>
        },
        {
            field: 'id',
            hide: true
        },
        {
            field: 'itemName',
            flex: 1,
            headerName: 'Name',
        },
        {
            field: 'displayName',
            flex: 1,
            headerName: 'Display Name',
        },
        {
            field: 'class',
            headerName: 'Item Class',
            flex: 1,
            renderCell: ({ row }) => itemClasses.find((itemClass) => itemClass.id === row['itemClassId'])?.itemClass,
        },
        {
            field: 'eligibleQuantity',
            headerName: 'Eligible Quantity',
            flex: 1,
            editable: true,
            valueParser: (value: GridCellValue) => {
                return value as number >= 0 ? value : 1;
            },
        }
    ];

    return (
        <Modal open={isOpen}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '33%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box width={700}>
                    <Box width={700} height={500}>
                        <AdminTable<ItemShopGrid>
                            resource="Item"
                            title=""
                            menuActions={[]}
                            actions={[]}
                            data={shopItems}
                            columns={columns}
                        />
                    </Box>
                </Box>
                <DialogActions>
                    <Button variant="contained" onClick={() => onSubmit(Array.from(selections))}>
                        Done (selections: {selections.size})
                    </Button>
                    <Button variant="outlined" onClick={onCancel}>
                        Cancel
                    </Button>
                </DialogActions>
            </Box>
        </Modal>
    );
}
