import { AdminTable, BreadcrumbLink, CellNavigation, useUrlParams } from '@components';
import { Event, useApiListener } from '@core';
import type { GridColDef } from '@mui/x-data-grid';
import { PackDetail } from '@services/model';
import { PageContent } from 'features/shell/layout';
import { useEffect, useState } from 'react';
import { getApiPacksByNameName, useGetApiPacksByNameName } from '@services/packs/packs';
import { nanoid } from 'nanoid';
import { titlePageRegistry } from '../PageRegistry';
import { Person } from '@mui/icons-material';
import { LinearProgress } from '@mui/material';

function PackDetailsGrid(props: { packs: PackDetail[] }) {
    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'Id',
            type: 'uuid',
            flex: 0.5,
            hide: true,
        },
        {
            field: 'packNumber',
            headerName: 'Pack Number',
            flex: 1,
            type: 'string',
            renderCell: (f) => <CellNavigation to="pack-contents" id={String(f.row.packName + "&" + f.row.packNumber)} value={String(f.value)} />,
        },
        {
            field: 'packName',
            headerName: 'Pack Name',
            flex: 1,
            type: 'string',
        },
        {
            field: 'redeemed',
            headerName: 'Redeemed',
            type: 'string',
            flex: 1,
        },
    ];

    interface PackDetailsTableGrid {
        id?: string | null;
        packName?: string | null;
        packNumber?: number;
        redeemed?: boolean;
    }

    const [invalidateData] = useState(() => new Event<void>());
    const [ packDetailsGridRowData, setPackDetailsGridRowData] = useState<PackDetailsTableGrid[]>([]);

    useEffect(() => {
        if (props.packs != null) {
            setPackDetailsGridRowData(buildRowData(props.packs));
        }
    }, [props.packs]);

    function buildRowData(entities: PackDetail[] | undefined): PackDetailsTableGrid[] {
        return (
            entities?.map((x) => {
                return { 
                    ...x, id: nanoid()
                };
            }) ?? []
        );
    }

    useApiListener('pack', () => invalidateData.raise());

    return (
        <AdminTable<PackDetailsTableGrid>
            title="Pack Details"
            resource="Item"
            columns={columns}
            data={packDetailsGridRowData ? packDetailsGridRowData : []}
            dataInvalidationEvent={invalidateData}
            menuActions={[]}
        />
    );
};

function Details(props: { packName: string }) {
    const [packs, setPacks] = useState<PackDetail[]>();

    const loadPacks = async () => {
        const packsResult = await Promise.resolve(getApiPacksByNameName(props.packName));
        setPacks(packsResult);
    };

    useEffect(() => {
        loadPacks();
    }, [props.packName]);

    return !packs ? <LinearProgress /> : <PackDetailsGrid packs={packs} />;
}

function PackDetailsBreadcrumb() {
    const { params } = useUrlParams();
    const { isLoading, data, refetch } = useGetApiPacksByNameName(params.id);
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    useApiListener('pack', () => refetch());

    return (
        <BreadcrumbLink>
            <Person /> Pack ({isLoading ? 'Loading...' : data?.[0].packName ?? ''})
        </BreadcrumbLink>
    );
}
export function PackDetails() {
    const { params } = useUrlParams();

    return (
        <PageContent>
            <Details packName={params.id || ''} />
        </PageContent>
    );
}

titlePageRegistry.register({ page: PackDetails, path: 'pack-details', name: PackDetailsBreadcrumb });
