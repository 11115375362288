import { singleton } from 'tsyringe';

import { useDi } from './DiService';
import { Event } from './Event';

type LogTypes<T> = T[] | undefined | null | T;
type LogPrimitives = LogTypes<number> | LogTypes<string> | LogTypes<boolean>;
interface LogData {
    [key: string]: LogPrimitives;
}
enum LogLevels {
    fatal = 0,
    error = 1,
    warn = 2,
    info = 3,
    debug = 4,
    trace = 5,
}
export interface LogMessage {
    message: string;
    error?: Error;
    data?: LogData;
    level: keyof typeof LogLevels;
}
export type ILogger = Omit<Logger, 'addTarget'>;

@singleton()
export class Logger {
    private onLog = new Event<LogMessage>();

    public addTarget(messageHandler: (message: LogMessage) => void) {
        const { dispose } = this.onLog.listen(messageHandler);
        return { remove: dispose };
    }

    public trace(message: string, data?: LogData) {
        this.write(message, 'trace', data);
    }

    public debug(message: string, data?: LogData) {
        this.write(message, 'debug', data);
    }

    public info(message: string, data?: LogData) {
        this.write(message, 'info', data);
    }

    public warn(message: string, data?: LogData) {
        this.write(message, 'warn', data);
    }

    public error(message: string, error?: Error | unknown, data?: LogData) {
        this.write(message, 'error', data, error instanceof Error ? error : undefined);
    }

    public fatal(message: string, error?: Error | unknown, data?: LogData) {
        this.write(message, 'fatal', data, error instanceof Error ? error : undefined);
    }

    private write(message: string, level: keyof typeof LogLevels, data?: LogData, error?: Error) {
        this.onLog.raise({ message, level, data, error });
    }
}

export function useLogger() {
    const logger = useDi(Logger);
    return logger as ILogger;
}

class ConsoleLogTarget {
    public constructor(private readonly logger: Logger) {}

    public start() {
        this.logger.addTarget((message) => this.receiveMessage(message));
    }

    private receiveMessage(logEntry: LogMessage) {
        // eslint-disable-next-line no-console
        console.log(logEntry.level, logEntry.message, logEntry.error, logEntry.data);
    }
}

export function enableConsoleLogging(logger: Logger) {
    const target = new ConsoleLogTarget(logger);
    target.start();
}
