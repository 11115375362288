import { defaultSiteConfig, useSiteConfig, useUserPrefs } from '@core';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Alert, Box, Button, Divider, LinearProgress, ToggleButton, ToggleButtonGroup } from '@mui/material';
import type { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/core';
import { usePostApiCustomData, usePutApiCustomDataId } from '@services/custom-data/custom-data';
import { getApiCustomDataSchemaId } from '@services/custom-data-schema/custom-data-schema';
import type { CustomData as CustomDataModel, CustomDataSchema } from '@services/model';
import { PageBody, PageContent, PageHeader, Toolbar } from 'features/shell/layout';
import type { JSONSchema7 } from 'json-schema';
import { useCallback, useEffect, useState } from 'react';

import { JsonInput, ReadonlyInput } from '../Forms';
import { monitor } from '../Monitor';
import { ArrayFieldTemplate, customFields, CustomFieldTemplate, customWidgets, ObjectFieldTemplate } from './Templates';

const dynamicStyle = (props: { background: string }) =>
    css`
        background: ${props.background};
    `;

const StyledForm = styled(Form)`
    fieldset {
        margin: 12px;
        padding: 8px 10px 10px;
        border: 1px solid #dedede;
        border-radius: 2px;

        div:first-child {
            margin: 10px 0 0 8px;
        }
    }
    legend {
        padding: 2px 4px;
    }
    fieldset > legend {
        float: left;
        margin-top: -20px;
    }
    fieldset > legend + * {
        clear: both;
    }
`;
const Container = styled.div`
    legend {
        ${dynamicStyle};
    }
    form {
        padding: 1rem;
        display: flex;
        flex-direction: column;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

interface SchemaInfo {
    json: string;
    schema: JSONSchema7;
    error: boolean;
    data?: CustomDataSchema;
}

async function loadSchema(id: string | null | undefined, callback: (value: SchemaInfo | undefined) => void) {
    if(!id){
            const json = '{}';
            const schema = {} as JSONSchema7;
            callback({ json, schema, error: false, data: undefined });
        return;
    }
    try {
        const result = await getApiCustomDataSchemaId(id!);
        if (result) {
            const json = result.jsonSchema || '{}';
            const schema = JSON.parse(json) as JSONSchema7;
            callback({ json, schema, error: false, data: result });
        }
    } catch (err) {
        callback({ json: '{}', schema: {}, error: true, data: { schemaName: 'None', version: 0 } });
    }
}

export interface CustomDataSchemaInfo {
    jsonSchema?: string | null;
    schemaName: string;
    version: number;
    type?: string | null;
}

function SchemaView({ schema }: { schema: CustomDataSchemaInfo | undefined }) {
    const schemaInfo: CustomDataSchemaInfo = schema ?? {jsonSchema: '{}', schemaName: '', version: 0, type: ''};
    return (
        <Box display="flex" sx={{ height: '100%' }}>
            <JsonInput width={'full'} height={'100%'} value={schemaInfo.jsonSchema!} readonly />
            <Divider orientation="vertical" />
            <Box sx={{ minWidth: 200 }} padding={2}>
                <ReadonlyInput label="Schema Name" value={schemaInfo.schemaName} width="full" />
                <ReadonlyInput label="Schema Version" value={schemaInfo.version} width="full" />
                <ReadonlyInput label="Type" value={schemaInfo.type} width="full" />
            </Box>
        </Box>
    );
}

interface CustomDataProps {
    data: CustomDataModel;
    canViewSchema?: boolean;
    canUpdate: boolean | undefined;
}
export const FormAndJsonEditor = ({ data, canViewSchema, canUpdate }: CustomDataProps) => {
    const { prefs } = useUserPrefs();
    const [inputType, setInputType] = useState('form');
    const { mutateAsync: updateCustomData } = usePutApiCustomDataId();
    const { mutateAsync: addCustomData } = usePostApiCustomData();
    const config = useSiteConfig();
    const theme = config.theme || defaultSiteConfig.theme;
    const bg = !theme ? '#fefefe' : prefs.darkMode ? theme.darkBg : theme.lightBg;
    const [localData, setLocalData] = useState<CustomDataModel | undefined>();
    const handleInputTypeChange = useCallback(
        (_event: React.MouseEvent<HTMLElement>, inputType: string) => {
            setInputType(inputType);
            try {
                inputType === 'json' && setJsonData(JSON.stringify(JSON.parse(String(localData?.dataField)), null, 2));
                // eslint-disable-next-line no-empty
            } catch {}
        },
        [localData?.dataField]
    );
    const [schema, setSchema] = useState<SchemaInfo>();
    const [jsonData, setJsonData] = useState<string | undefined>();
    useEffect(() => {
        setLocalData(data.dataField ? data : undefined);
        setJsonData(data.dataField ? JSON.stringify(JSON.parse(String(data.dataField)), null, 2) : '');
        setSchema(undefined);
        loadSchema(data.customDataSchemaId, setSchema);
    }, [data, setSchema]);

    const handleChange = useCallback(
        (e: IChangeEvent<any> | string | undefined) => {
            if (typeof e === 'string') {
                try {
                    setJsonData(e);
                    const testData = { ...data, dataField: JSON.stringify(JSON.parse(e), null, 2) };
                    setLocalData(testData);
                    // eslint-disable-next-line no-empty
                } catch {}
            } else if (e?.formData) {
                setLocalData({ ...data, dataField: JSON.stringify(e.formData) });
            }
        },
        [data]
    );

    const onSubmit = useCallback(async () => {
        if (localData) {
            monitor({
                action: async () => {
                    if (localData.id) {
                        await updateCustomData({ id: String(localData?.id), data: { ...localData } });
                    } else {
                        await addCustomData({ data: { ...localData } });
                    }
                },
                failureMessage: 'Error Saving Custom Data!',
                successMessage: 'Custom Data Saved',
            });
        }
    }, [localData, updateCustomData, addCustomData]);
    return (
        <>
            {!schema ? (
                <LinearProgress />
            ) : (
                localData && (
                    <PageContent style={{ flex: '1 1 auto' }}>
                        <PageHeader noPadding>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '.5rem 1rem 0 0' }}>
                                <ToggleButtonGroup color="primary" value={inputType} exclusive onChange={handleInputTypeChange}>
                                    {!canViewSchema ? null : (
                                        <ToggleButton sx={{ borderRadius: '2px 2px 0 0', borderBottom: 'none' }} value="schema">
                                            Schema
                                        </ToggleButton>
                                    )}
                                    <ToggleButton sx={{ borderRadius: '2px 2px 0 0', borderBottom: 'none' }} value="form">
                                        Form
                                    </ToggleButton>
                                    <ToggleButton sx={{ borderRadius: '2px 2px 0 0', borderBottom: 'none' }} value="json">
                                        Json
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                            <Divider />
                        </PageHeader>
                        <PageBody noPadding style={{ overflow: inputType === 'form' ? 'auto' : 'hidden' }}>
                            {inputType === 'schema' ? (
                                <SchemaView schema={schema.data} />
                            ) : inputType === 'json' ? (
                                <JsonInput width={'full'} height={'100%'} value={jsonData} schema={schema.json} onChange={(e) => handleChange(e)} />
                            ) : !schema.data ? (
                                <Alert color="info">No schema defined. JSON editor available. </Alert>
                            ) : schema.error ? (
                                <Alert color="error">Form is unavailable, because the schema appears to be invalid. JSON editor available. </Alert>
                            ) : (
                                <Container background={bg}>
                                    <StyledForm
                                        schema={schema.schema}
                                        formData={JSON.parse(localData.dataField!)}
                                        fields={customFields}
                                        widgets={customWidgets}
                                        FieldTemplate={CustomFieldTemplate}
                                        ObjectFieldTemplate={ObjectFieldTemplate}
                                        ArrayFieldTemplate={ArrayFieldTemplate}
                                        onChange={(e) => handleChange(e)}
                                        onError={(e) => console.log('errors:', e)}
                                    >
                                        <ButtonContainer></ButtonContainer>
                                    </StyledForm>
                                </Container>
                            )}
                        </PageBody>
                        <Divider orientation="horizontal" />
                        <Toolbar>
                            <Button variant="contained" size={'small'} onClick={onSubmit} type="submit" disabled={!canUpdate}>
                                Save
                            </Button>
                        </Toolbar>
                    </PageContent>
                )
            )}
        </>
    );
};