import { Box, LinearProgress } from '@mui/material';
import type { InventoryContainer, Item, ItemClassModel, ShopTab, VirtualCurrency } from '@services/model';

import { ShopGridPanel } from './ShopGridPanel';
import { useShop } from './ShopProvider';

export interface ShopGridProps {
    tabs: ShopTab[];
    items: Item[];
    itemClasses: ItemClassModel[];
    bundles: InventoryContainer[];
    containers: InventoryContainer[];
    virtualCurrencies: VirtualCurrency[];
}

export function ShopGrid(props: ShopGridProps) {
    const { tabs, items, itemClasses, bundles, containers, virtualCurrencies } = props;
    const { shopTabItems, shopTabBundles } = useShop();

    return (
        <Box sx={{ mt: 2 }}>
            {shopTabItems && shopTabBundles ? (
                tabs.map((tab) => (
                    <ShopGridPanel items={items} itemClasses={itemClasses} bundles={bundles} containers={containers} virtualCurrencies={virtualCurrencies} shopTab={tab} key={tab.id}>
                        {tab.name}
                    </ShopGridPanel>
                ))
            ) : (
                <LinearProgress />
            )}
        </Box>
    );
}
