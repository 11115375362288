import { BreadcrumbLink, ResourceProvider, useUrlParams } from '@components';
import { buildSearchAll, useApiListener } from '@core';
import { LinearProgress } from '@mui/material';
import type { ShopVersion } from '@services/model';
import { usePostApiShopVersionsFindBy } from '@services/shop-versions/shop-versions';
import { useGetApiShopsId } from '@services/shops/shops';
import { useMount } from 'react-use';

import { titlePageRegistry } from '../PageRegistry';
import { ShopConfiguration } from '../Shop/ShopConfiguration';
import { ShopProvider } from '../Shop/ShopProvider';
import { ShopEmpty } from './ShopEmpty';

export function ShopDetailsPage() {
    const { params } = useUrlParams();
    const { data: versions, mutate: versionSearch } = usePostApiShopVersionsFindBy();
    const { data: shop } = useGetApiShopsId(params.id || '');
    const searchVersions = () => versionSearch(buildSearchAll<ShopVersion>({ eq: { shopId: params.id || '' } }));
    useMount(searchVersions);

    return !versions || !shop ? (
        <LinearProgress />
    ) : versions?.items?.length ? (
        <ResourceProvider value="Shop">
            <ShopProvider shopId={params.id}>
                <ShopConfiguration />
            </ShopProvider>
        </ResourceProvider>
    ) : (
        <ResourceProvider value="Shop">
            <ShopEmpty shop={shop!} onVersionAdded={searchVersions} />
        </ResourceProvider>
    );
}
function Breadcrumb() {
    const { params } = useUrlParams();
    const { data, refetch } = useGetApiShopsId(params.id);
    useApiListener('store', () => refetch());
    return <BreadcrumbLink>Store ({data?.name || 'Loading...'})</BreadcrumbLink>;
}
titlePageRegistry.register({ page: ShopDetailsPage, path: 'shop-details', name: Breadcrumb });
