import { usePermissions } from '@core';
import { Controller, useFormContext } from 'react-hook-form';

import { useResource } from '../ResourceProvider';
import { ReadonlyInput } from './ReadonlyInput';

export interface InternalCommonInputProps {
    type: 'standard' | 'react-hook-form';
    label: string;
    helperText?: string;
    onBlur?: () => void;
    errorMessage?: string;
    invalid?: boolean;
    width?: 'full' | number | string;
    margin?: number;
}

export type InternalReactHookInputProps = {
    type: 'react-hook-form';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hookOnChange: (evt: { target: { value?: any; checked?: boolean } }) => void;
    name: string;
    value: any;
} & InternalCommonInputProps &
    ReactHookInputProps;
export type InternalStandardInputProps<T> = {
    type: 'standard';
    value: T;
    onChange: (value: T) => void;
} & InternalCommonInputProps;
export type InternalInputProps<T, Extras> = (InternalStandardInputProps<T> | InternalReactHookInputProps) & Extras;

export interface CommonInputProps {
    label?: string;
    helperText?: string;
    width?: 'full' | number | string;
    /**
     * Vertical margin in rem, defaults to 1rem
     */
    margin?: number;
}
export type StandardInputProps<T> = {
    value: T;
    onChange?: (value: T) => void;
    errorMessage?: string;
    invalid?: boolean;
} & CommonInputProps;
export type ReactHookInputProps = {
    name: string;
} & CommonInputProps;
export type ExternalInputProps<T, Extras> = (StandardInputProps<T> | ReactHookInputProps) & Extras;

// eslint-disable-next-line @typescript-eslint/ban-types
export function makeHookablePermission<T, Extras = {}>(Type: React.ComponentType<InternalInputProps<T, Extras>>) {
    const HookableType = makeHookable(Type);
    return function HookablePermission(props: ExternalInputProps<T, Extras>) {
        const resource = useResource();
        const perm = usePermissions(resource);
        if (!resource || perm.canMutate) {
            return <HookableType {...props} />;
        } else if (!resource || perm.canRead) {
            return <ReadonlyInput {...(props as any)} />;
        } else {
            return <></>;
        }
    };
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function makeHookable<T, Extras = {}>(Type: React.ComponentType<InternalInputProps<T, Extras>>) {
    return function Hookable(props: ExternalInputProps<T, Extras>) {
        if ('name' in props) {
            const hookProps = { type: 'react-hook-form', ...props } as unknown as InternalReactHookInputProps;
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const { control } = useFormContext();
            return (
                <Controller
                    name={hookProps.name}
                    control={control}
                    render={({ field, fieldState }) => {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        return (
                            <Type
                                {...hookProps}
                                value={field.value}
                                hookOnChange={field.onChange}
                                onBlur={field.onBlur}
                                errorMessage={fieldState.error?.message}
                                invalid={fieldState.invalid}
                            />
                        );
                    }}
                />
            );
        } else {
            const standardProps = { type: 'standard', ...props } as unknown as InternalStandardInputProps<T>;
            return <Type {...standardProps} />;
        }
    };
}

export const widthStyle = ({ width }: { width?: 'fill' | number | string }) => ({
    width: typeof width === 'number' ? `${width}px` : width === 'fill' ? undefined : width,
});
export const marginStyle = ({ margin }: { margin?: number }) => ({
    margin: typeof margin === 'number' ? `0 0 ${margin}rem 0` : '0 0 1rem 0',
});
export const styleFromProps = (props: InternalCommonInputProps) => ({
    ...widthStyle(props),
    ...marginStyle(props),
});
