import { confirm, IntegerInput, JsonInput, modal, TextInput, UnauthorizedAccess } from '@components';
import { SearchExpr, usePermissions } from '@core';
import { buildSearchAll, useApiListener } from '@core';
import { yupResolver } from '@hookform/resolvers/yup';
import { Add } from '@mui/icons-material';
import { Box, Button, IconButton, LinearProgress, List, MenuItem, Tooltip, Typography } from '@mui/material';
import { usePostApiCustomDataSchema, usePostApiCustomDataSchemaFindBy } from '@services/custom-data-schema/custom-data-schema';
import { usePostApiGameVersionsFindBy } from '@services/game-versions/game-versions';
import { CustomData as CustomDataModel, CustomDataIPagedResultList, CustomDataPermission, EntitySearch } from '@services/model';
import type { AnySchema } from 'ajv/dist/2019';
import Ajv2019 from 'ajv/dist/2019';
import { PageBody, PageContent, Toolbar } from 'features/shell/layout';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';

import type { GameVersion } from '../../../__generated__/model/gameVersion';
import { BoolInput } from '../../../shared/components/Forms/BoolInput';
import { DropdownInput } from '../../../shared/components/Forms/DropdownInput';
import { jsonSchemaDraft07 } from './jsonSchema';
import { VersionFolders } from './VersionFolders';

const ajv = new Ajv2019();
interface DefaultCustomDataSchemaValues {
    dataKey: string;
    gameVersionId: string;
    schemaName?: string;
    jsonSchema?: string;
    schemaId?: string;
    schemaVersion?: number;
    ownerPermission?: CustomDataPermission;
    othersPermission?: CustomDataPermission;
}

interface NewJsonSchemaPromptProps {
    defaultValues: DefaultCustomDataSchemaValues;
    type: string;
    entityId: string;
    onClose: (newData: CustomDataModel | undefined) => void;
    onSave: (data: { data: CustomDataModel }) => Promise<CustomDataModel>;
}

const newCustomDataSchemaValidation = yup.object().shape({
    dataKey: yup.string().required('Data Key is required!'),
    schemaName: yup.string().required('Schema Name is required!'),
    jsonSchema: yup
        .string()
        .test(function (value) {
            try {
                let valid = false;
                if (value) {
                    const schema = JSON.parse(value);
                    valid = ajv.validateSchema(schema as AnySchema) as unknown as boolean;
                }
                const errorMessage = 'Invalid JSON Schema';
                return Boolean(valid) || this.createError({ path: this.path, message: errorMessage });
            } catch (e) {
                return false;
            }
        })
        .required('JSON Schema is required'),
    gameVersionId: yup.string().required('Game Version is required'),
    schemaVersion: yup.number().required('Schema Version is required'),
});

const newCustomDataExistingSchemaValidation = yup.object().shape({
    dataKey: yup.string().required('Data Key is required!'),
    gameVersionId: yup.string().required('Game Version is required'),
});

const NewJsonSchemaPrompt = ({ defaultValues, type, entityId, onClose, onSave }: NewJsonSchemaPromptProps) => {
    const { mutateAsync: loadVersions, data: versions } = usePostApiGameVersionsFindBy();
    const { mutateAsync: loadSchemas, data: schemas } = usePostApiCustomDataSchemaFindBy();
    const { mutateAsync: addCustomDataSchema } = usePostApiCustomDataSchema();
    const newSchemaForm = useForm({
        defaultValues,
        resolver: yupResolver(newCustomDataSchemaValidation),
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });

    const existingSchemaForm = useForm({
        defaultValues: {
            dataKey: defaultValues.dataKey,
            gameVersionId: defaultValues.gameVersionId,
            schemaId: defaultValues.schemaId,
            ownerPermission: defaultValues.ownerPermission,
            othersPermission: defaultValues.othersPermission
        },
        resolver: yupResolver(newCustomDataExistingSchemaValidation),
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });
    useEffect(() => {
        const load = async () => await Promise.all([loadVersions(buildSearchAll()), loadSchemas(buildSearchAll())]);
        load();
    }, [type, loadVersions, loadSchemas]);
    const { isDirty: existingDirty } = useFormState({ control: existingSchemaForm.control });
    const { isDirty: newSchemaDirty } = useFormState({ control: newSchemaForm.control });
    const [newSchema, setNewSchema] = useState<boolean | undefined>(false);
    const newSchemaSave = useCallback(async () => {
        const values = newSchemaForm.getValues();
        await addCustomDataSchema({
            data: { schemaName: values.schemaName, jsonSchema: values.jsonSchema, type, version: values.schemaVersion },
        }).then(async (cds) => {
            const newData = await onSave({
                data: {
                    customDataSchemaId: String(cds.id),
                    entityId,
                    dataKey: values.dataKey,
                    dataField: '{}',
                    type,
                    gameVersionId: values.gameVersionId,
                    ownerPermission: values.ownerPermission,
                    othersPermission: values.othersPermission
                },
            });
            onClose(newData);
        });
    }, [onSave, addCustomDataSchema, entityId, newSchemaForm, onClose, type]);
    const existingSchemaSave = useCallback(async () => {
        const values = existingSchemaForm.getValues();
        const newData = await onSave({
            data: {
                customDataSchemaId: values.schemaId,
                entityId,
                dataKey: values.dataKey,
                dataField: '{}',
                type,
                gameVersionId: values.gameVersionId,
                ownerPermission: values.ownerPermission,
                othersPermission: values.othersPermission,
            },
        });
        onClose(newData);
    }, [onSave, entityId, existingSchemaForm, onClose, type]);
    const cancel = useCallback(() => onClose(undefined), [onClose]);

    const customDataPermissionTypes = (Object.keys(CustomDataPermission) as Array<keyof typeof CustomDataPermission>);

    return !versions || !schemas ? (
        <LinearProgress />
    ) : (
        <>
            <Box px={4}>
                <Box>
                    <BoolInput label="New Schema?" value={newSchema} onChange={setNewSchema} />
                </Box>
                {!newSchema && (
                    <>
                        <FormProvider {...existingSchemaForm}>
                            <TextInput width="full" label="Data Key Name" name="dataKey" />
                            <DropdownInput width="full" label="Select Game Version" name="gameVersionId">
                                <MenuItem value="">None</MenuItem>
                                {versions.items?.map((v) => (
                                    <MenuItem key={v.id} value={v.id || ''}>
                                        {v.name}
                                    </MenuItem>
                                ))}
                            </DropdownInput>
                            <DropdownInput width="full" label="Select Existing Schema" name="schemaId">
                                <MenuItem value="">None</MenuItem>
                                {schemas.items?.map((s) => (
                                    <MenuItem key={s.id} value={s.id || ''}>
                                        {s.schemaName}
                                    </MenuItem>
                                ))}
                            </DropdownInput>

                            <DropdownInput width="full" label="Select Owner Permission" name="ownerPermission">
                                {customDataPermissionTypes.map((s) => (
                                    <MenuItem key={s} value={s || ''}>
                                        {s}
                                    </MenuItem>
                                ))}
                            </DropdownInput>

                            <DropdownInput width="full" label="Select Others Permission" name="othersPermission">
                                {customDataPermissionTypes.map((s) => (
                                    <MenuItem key={s} value={s || ''}>
                                        {s}
                                    </MenuItem>
                                ))}
                            </DropdownInput>

                        </FormProvider>
                        <Box p={2} justifyContent="flex-end" display="flex">
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!existingDirty}
                                onClick={existingSchemaForm.handleSubmit(existingSchemaSave)}
                            >
                                Create Key
                            </Button>
                            <Button onClick={cancel}>Cancel</Button>
                        </Box>
                    </>
                )}
                {!!newSchema && (
                    <div style={{ width: '600px' }}>
                        <FormProvider {...newSchemaForm}>
                            <TextInput width="full" label="Data Key Name" name="dataKey" />
                            <DropdownInput width="full" label="Select Game Version" name="gameVersionId">
                                <MenuItem value="">None</MenuItem>
                                {versions.items?.map((v) => (
                                    <MenuItem key={v.id} value={v.id || ''}>
                                        {v.name}
                                    </MenuItem>
                                ))}
                            </DropdownInput>
                            <TextInput width="full" label="Schema Name" name="schemaName" />
                            <IntegerInput width="full" label="Schema Version" name="schemaVersion" />
                            <JsonInput height={'30vh'} label="JSON Schema" name="jsonSchema" schema={jsonSchemaDraft07} />
                        </FormProvider>
                        <Box p={2} justifyContent="flex-end" display="flex">
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!newSchemaDirty}
                                onClick={newSchemaForm.handleSubmit(newSchemaSave)}
                            >
                                Create Key
                            </Button>
                            <Button onClick={cancel}>Cancel</Button>
                        </Box>
                    </div>
                )}
            </Box>
        </>
    );
};

export type CustomDataType = 'Item' | 'Player' | 'ItemClassItem' | 'GameTitleServer' | 'GameTitleClient' | 'Bundle' | 'Container' | 'DropTable';

export interface InheritedKey {
    entityId: string;
    type: CustomDataType;
    description: string;
}

export interface KeyNavListProps {
    entityId: string;
    type: CustomDataType;
    selectedItem?: CustomDataModel | null;
    versions: GameVersion[];
    defaultKey?: string;
    disableNewKeyCreation?: boolean;
    inheritedKeys?: InheritedKey[];
    onChange: (data: CustomDataModel | undefined) => void;
    onFindBy: (data: EntitySearch) => Promise<CustomDataIPagedResultList>;
    onSave: (data: { data: CustomDataModel }) => Promise<CustomDataModel>;
    onDelete: (data: { id: string }) => void;
    canCreate: boolean | undefined;
    canRead: boolean | undefined;
    canUpdate: boolean | undefined;
    canDelete: boolean | undefined;
}

function createItemFromInherited(entityId: string, type: string, source: string, inherited: CustomDataModel) {
    return {
        customDataSchemaId: inherited.customDataSchemaId,
        dataField: inherited.dataField,
        dataKey: inherited.dataKey,
        gameEnvironmentId: inherited.gameEnvironmentId,
        gameVersionId: inherited.gameVersionId,
        entityId,
        type,
        source,
        ownerPermission: inherited.ownerPermission,
        othersPermission: inherited.othersPermission
    } as CustomDataModel;
}

function* adaptItems(entityId: string, type: string, inheritedKeys: InheritedKey[], items: CustomDataModel[]) {
    const ownKeys = new Set(items.filter((o) => o.entityId === entityId).map((o) => o.dataKey));
    const keyDescriptions = new Map(inheritedKeys?.map((o) => [o.entityId, o.description]));
    const overriddenTypes = items
        .filter((o) => o.entityId !== entityId && ownKeys.has(o.dataKey))
        .map((o) => [o.dataKey!, keyDescriptions.get(o.entityId!)] as [string, string]);
    const overriddenTypesLookup = new Map(overriddenTypes);

    for (const item of items) {
        const inheritedType = overriddenTypesLookup.get(item.dataKey!) || keyDescriptions.get(item.entityId!);
        const isEntity = item.entityId === entityId;
        const isOverriden = !isEntity && ownKeys.has(item.dataKey);
        if (!isOverriden) {
            if (isEntity) {
                yield { ...item, source: inheritedType ? `From ${inheritedType}` : 'Custom' };
            } else {
                yield createItemFromInherited(entityId, type, `From ${inheritedType}`, item);
            }
        }
    }
}

const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
    list.reduce((previous, currentItem) => {
        const group = getKey(currentItem);
        if (!previous[group]) previous[group] = [];
        previous[group].push(currentItem);
        return previous;
    }, {} as Record<K, T[]>);

export const KeyNavList = ({
    entityId,
    type,
    onChange,
    selectedItem,
    disableNewKeyCreation = false,
    defaultKey,
    inheritedKeys,
    versions,
    onFindBy,
    onSave,
    onDelete,
    canCreate,
    canRead,
    canUpdate,
    canDelete
}: KeyNavListProps) => {

    const [customData, setCustomData] = useState<(CustomDataModel & { source?: string })[]>();
    const [gameVersionLookup, setGameVersionLookup] = useState(new Map<string, GameVersion>());
    const [groupedCustomData, setGroupedCustomData] = useState<Record<string, CustomDataModel[]>>();
    
    const load = useCallback(async () => {
        const entityQuery = { or: [{ and: [{ eq: { entityId } }, { eq: { type } }] }] };
        const query: SearchExpr<CustomDataModel> = { and: [entityQuery] };
        if (inheritedKeys) {
            entityQuery.or.push(...inheritedKeys.map((k) => ({ and: [{ eq: { entityId: k.entityId } }, { eq: { type: k.type } }] })));
        }
        const searchResults = await onFindBy(buildSearchAll(query, [{ field: 'dataKey' }]).data);
        const result = inheritedKeys ? [...adaptItems(entityId, type, inheritedKeys || [], searchResults?.items || [])] : searchResults.items || [];
        const groupedResults = groupBy(result, (i) => String(i.gameVersionId));
        setGameVersionLookup(new Map((versions || []).map((o) => [o.id || '', { name: String(o.name) || '' }] as [string, GameVersion])));
        setCustomData(result);
        setGroupedCustomData(groupedResults);

        return result;
    }, [entityId, type, inheritedKeys, versions]);

    const onAdd = useCallback(async () => {
        const values: DefaultCustomDataSchemaValues = {
            dataKey: '',
            gameVersionId: '',
            schemaName: '',
            jsonSchema: '',
            schemaId: undefined,
            schemaVersion: 0,
            ownerPermission: 'None',
            othersPermission: 'None'
        };
        const onClose = async (newItem: CustomDataModel | undefined, closer: () => void) => {
            if (newItem) {
                await load();
                onChange(newItem);
            }
            closer();
        };
        await modal('Add Custom Data Key', (close) => (
            <NewJsonSchemaPrompt type={type} entityId={entityId} defaultValues={values} onClose={(m) => onClose(m, close)} onSave={onSave} />
        ));
    }, [entityId, type, onChange, load]);

    useEffect(() => {
        async function result() {
            const results = await load();
            if (results?.length && !selectedItem) {
                const initialKey = (defaultKey && results.find((o) => defaultKey === o.dataKey)) || results.find(() => true);
                onChange(initialKey);
            }
        }
        result();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultKey, load, onChange]);
    const handleDelete = useCallback(
        async (model: CustomDataModel) => {
            const didConfirm = await confirm(`Delete Data`, `Are you sure you want to delete this data?`);
            if (didConfirm) {
                onDelete({ id: String(model.id) ?? '' });
                if (model.id === selectedItem?.id) {
                    const results = await load();
                    onChange(results?.find(() => true));
                }
            }
        },
        [onDelete, onChange, selectedItem, load]
    );

    useApiListener('CustomData', load);
    return !customData ? (
        <LinearProgress />
    ) : (
        <PageContent style={{ minWidth: 200 }}>
            <Toolbar align="space-between">
                <Typography>Keys</Typography>
                {!disableNewKeyCreation && canCreate && (
                    <div>
                        <Tooltip title="Add Key">
                            <IconButton size={'small'} onClick={onAdd}>
                                <Add />
                            </IconButton>
                        </Tooltip>
                    </div>
                )}
            </Toolbar>
            <PageBody noPadding>
                <List>
                    {groupedCustomData &&
                        Object.entries(groupedCustomData).map(([k, v]) => {
                            return (
                                <VersionFolders
                                    key={k}
                                    gameVersionLookup={gameVersionLookup}
                                    customData={v}
                                    lookupValue={k}
                                    selectedItem={selectedItem}
                                    onChange={onChange}
                                    onDelete={handleDelete}
                                    canDelete={canDelete}
                                />
                            );
                        })}
                </List>
            </PageBody>
        </PageContent>
    );
};
