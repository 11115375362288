import { ActionMonitor, NavInitializer } from '@components';
import { DiContext, System, useDi, useSiteConfig } from '@core';
import { AdminSiteContent } from 'features/admin';
import { OrgSiteContent } from 'features/org';
import { Close } from 'features/shell/Close';
import { Shell } from 'features/shell/Shell';
import { TitleSiteContent } from 'features/title';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useMount } from 'react-use';
import { FusionAuthLogin } from 'shared/components/Auth/FusionAuthLogin';
import { NotificationProvider } from 'shared/components/Notification';
import { container } from 'tsyringe';

import { SiteContainer } from './features/shell/layout';

function SiteTypeSwitch() {
    const config = useSiteConfig();
    switch (config.type) {
        case 'admin':
            return <AdminSiteContent />;
        case 'game':
            return <TitleSiteContent />;
        case 'org':
            return <OrgSiteContent />;
    }
}

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } });
export function App() {
    const system = useDi(System);
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    useMount(() => system.start());
    return (
        <DiContext.Provider value={container}>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <ActionMonitor />
                    <FusionAuthLogin />
                    <SiteContainer>
                        <NavInitializer />
                        <Switch>
                            <Route path="/close" exact>
                                <Close />
                            </Route>
                            <Route path="/:siteId?">
                                <Shell />
                                <SiteTypeSwitch />
                            </Route>
                        </Switch>
                        <NotificationProvider />
                    </SiteContainer>
                </BrowserRouter>
            </QueryClientProvider>
        </DiContext.Provider>
    );
}
