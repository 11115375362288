import { ThemeAndLogoFields, UnauthorizedAccess } from '@components';
import { usePermissions } from '@core';
import { Button, LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import { useGetApiGamesId, usePutApiGamesId } from '@services/games/games';
import { PageBody, PageContent, PageHeader } from 'features/shell/layout';
import { useEffect, useState } from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

interface TitleThemeProps {
    titleId: string;
}
/*
const urlRE = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
const titleThemeSchema = yup.object().shape({
    discord: yup.string().matches(urlRE, 'Must be a valid URL'),
});
*/
export const TitleTheme = ({ titleId }: TitleThemeProps) => {
    const { canRead } = usePermissions("GameEnvironment");

    if(!canRead) {
        return (<UnauthorizedAccess />);
    }
    const { data, isLoading } = useGetApiGamesId(titleId || '');
    const { mutateAsync: update } = usePutApiGamesId();
    const [siteConfig, setSiteConfig] = useState({});
    const form = useForm({
        //resolver: yupResolver(titleThemeSchema),
        defaultValues: {
            config: siteConfig,
        },
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });

    useEffect(() => {
        if (data) {
            const siteConfigData = JSON.parse(data.siteConfigJson);
            const config = { config: siteConfigData };

            setSiteConfig(config);
        }
    }, [data]);

    useEffect(() => {
        form.reset(siteConfig);
    }, [siteConfig]);

    const { isDirty } = useFormState(form);
    const undo = () => form.reset();
    const save = async () => {
        const newConfig = form.getValues();
        await update({ id: data!.id || '', data: { ...data!, siteConfigJson: JSON.stringify(newConfig.config) } });
        form.reset(newConfig);
    };

    return isLoading ? (
        <LinearProgress />
    ) : (
        <PageContent>
            <PageBody noPadding>
                <FormProvider {...form}>
                    <ThemeAndLogoFields isTitle={true}/>
                </FormProvider>
            </PageBody>
            <PageHeader>
                <Box textAlign="right" width={400}>
                    <Button onClick={form.handleSubmit(save)} disabled={!isDirty} variant="contained">
                        Save Changes
                    </Button>
                    <Button onClick={undo} disabled={!isDirty}>
                        Undo Changes
                    </Button>
                </Box>
            </PageHeader>
        </PageContent>
    );
};
