﻿import {AdminRequest} from "@services/model";
import {PageBody, PageContent, PageHeader, Toolbar} from "../../shell/layout";
import {Box, Button, Divider} from "@mui/material";
import {JsonInput} from "@components";

export const AuditLogDetails = ({log, onClose}: AuditLogDetailsProps) =>
{
    return(
        <PageContent>
            <PageHeader>
                <Box display="flex">
                    Trace Id: {log.traceId}
                </Box>
            </PageHeader>
            <Divider />
            <PageBody>
                <JsonInput readonly height="100%" value={prettfyJson(log.body)} />
            </PageBody>
            <Divider />
            <Toolbar>                
                <Button onClick={onClose}>Close</Button>
            </Toolbar>
        </PageContent>
    );
}

const prettfyJson = (input: string | null | undefined) : string | undefined =>
{
    if(input === null || input === undefined) return undefined;
    try {
        const val = JSON.parse(input || '');
        return JSON.stringify(val, null, '\t');
    } catch {
        return input || undefined;
    }
}
                                 
export interface AuditLogDetailsProps
{
    onClose: () => void,
    log: AdminRequest
}