import type { PolicyInformation, SiteConfig, SiteTheme, SocialLinkUrls } from '@core';
import { Box } from '@mui/material';

import { TextInput } from './Forms';

export function TitlePolicyLinksForm(props: { policyInformation: PolicyInformation; onChange: (value: PolicyInformation) => void }) {
    const onChange = (change: Partial<PolicyInformation>) => {
        const newPolicyInfo = { ...props.policyInformation, ...change };
        props.onChange(newPolicyInfo);
    };
    return (
        <>
            <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                <TextInput
                    label="Privacy Policy URL"
                    value={props.policyInformation?.privacyPolicy || ''}
                    onChange={(link) => onChange({ privacyPolicy: link || '' })}
                />
                <TextInput
                    label="Terms of Service URL"
                    value={props.policyInformation?.termsOfService || ''}
                    onChange={(link) => onChange({ termsOfService: link || '' })}
                />
            </Box>
        </>
    );
}
