/**
 * Takes a string consisting of email addresses that are separated
 * by a carriage return and converts to a list of unique addresses.
 */
export const getEmailsFromInput = (inputStr: string): string[] => {
    // turn input string into array of strings
    let emails = inputStr
        .trim()
        .split('\n')
        .map((v) => v.trim().toLowerCase())
        .filter((v) => !!v);

    // eliminate any duplicate emails
    emails = emails.filter((item, index) => emails.indexOf(item) === index);
    return emails;
};

/**
 * Uses a regular expression to verify that the specified email is properly formed.
 */
export const isValidEmail = (email: string): boolean => {
    // regex is from https://www.abstractapi.com/tools/email-regex-guide
    const regexp = new RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
    const isValid = regexp.test(email);
    return isValid;
};

/**
 * Given a list of email addresses, returns a list of the invalid ones.
 */
export const getInvalidEmails = (emails: string[]): string[] => {
    const invalidEmails: string[] = [];
    emails.forEach((email) => {
        if (!isValidEmail(email)) {
            invalidEmails.push(email);
        }
    });
    return invalidEmails;
};
