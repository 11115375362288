import { AdminTable, CellNavigation, confirm, modal, useNavigator, UnauthorizedAccess } from '@components';
import { buildSearch, useApiListener, useAuthenticationService, usePermissions } from '@core';
import { Delete } from '@mui/icons-material';
import { LinearProgress } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid';
import type { Shop } from '@services/model';
import { useDeleteApiShopsId, usePostApiShopsFindBy } from '@services/shops/shops';
import { format, parseISO } from 'date-fns';
import { useMount } from 'react-use';

import { NewShopPrompt } from './NewShopPrompt';

const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', flex: 0.5, hide: true },
    {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        renderCell: (cellValues) => <CellNavigation id={String(cellValues?.id)} value={String(cellValues?.value)} to="shop-details" />,
    },
    {
        field: 'version',
        headerName: 'Version',
        flex: 1,
    },
    {
        field: 'description',
        headerName: 'Description',
        flex: 1,
    },
    { field: 'createdAt', headerName: 'Created', flex: 0.5, valueFormatter: (params) => format(parseISO(String(params.value)), 'Ppp') },
];
export function ShopGrid() {
    const { canRead } = usePermissions("Shop");
    if(!canRead) {
        return (<UnauthorizedAccess />);
    }
    const { mutate, data, isLoading } = usePostApiShopsFindBy();
    const nav = useNavigator();
    const search = () => {
        mutate(buildSearch<Shop>());
    };
    const { mutateAsync: remove } = useDeleteApiShopsId();
    useMount(search);
    const onDelete = async (e: Partial<Shop>) => {
        const didConfirm = await confirm(`Delete ${e.name ?? ''}?`, `Are you sure you want to delete this Shop?`);
        if (didConfirm) {
            await remove({ id: e.id ?? '' });
        }
    };
    const onAdd = async () => {
        let id = '';
        await modal('Add Shop', (close) => <NewShopPrompt onClose={close} onCreated={(newId) => (id = newId)} />);
        if (id) {
            nav.descend('shop-details', { id });
        }
    };
    useApiListener('Shops', search);

    return isLoading ? (
        <LinearProgress />
    ) : (
        <>
            <AdminTable<Shop>
                title="Shops"
                resource="Shop"
                columns={columns}
                data={data && data.items ? data.items : []}
                menuActions={[{ type: 'button', icon: Delete, label: 'Delete', onClick: onDelete }]}
                onAdd={onAdd}
            />
        </>
    );
}
