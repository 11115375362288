import { OrgTitles } from '@components';
import { ListAlt } from '@mui/icons-material';
import { PageBody, PageContent, PageHeader } from 'features/shell/layout';

import { useSiteConfig } from '../../../shared/core/ShellService';
import { orgPageRegistry } from '../PageRegistry';

export const Titles = () => {
    const config = useSiteConfig();
    return (
        <PageContent>
            <PageHeader></PageHeader>
            <PageBody>
                <OrgTitles orgId={config.id} />
            </PageBody>
        </PageContent>
    );
};
orgPageRegistry.register({ page: Titles, path: 'titles', name: 'Home', icon: <ListAlt /> });
