import styled from '@emotion/styled';

export const StateContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
`;
export const StateItem = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
`;
export const StateHeader = styled.div`
    font-weight: bold;
`;
export const StateDate = styled.div`
    font-size: smaller;
`;
export const CardItem = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 1rem;
`;

export const CardHeader = styled.div`
    font-size: 24px;
    font-weight: bold;
`;

export const PaymentContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
`;

export const PaymentItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const CardValue = styled.div`
    margin-top: 12px;
`;

export const BoxHeader = styled.div`
    font-weight: bold;
    margin-bottom: 3px;
    font-size: larger;
`;