import { LinkTabs } from '@components';
import { VpnKey } from '@mui/icons-material';

import { useSiteConfig } from '../../../shared/core/ShellService';
import { orgPageRegistry } from '../PageRegistry';
import { Resources, RolesPage } from '.';

export const Auth = () => {
    const config = useSiteConfig();
    return (
        <LinkTabs
            orientation="vertical"
            tabs={[
                {
                    label: 'Roles',
                    render: () => <RolesPage organizationId={config.id} />,
                    url: 'roles',
                },/*
                {
                    label: 'Resources',
                    render: () => <Resources organizationId={config.id} />,
                    url: 'resources',
                },*/
            ]}
        />
    );
};
orgPageRegistry.register({ page: Auth, path: 'auth', name: 'Authentication', icon: <VpnKey /> });
