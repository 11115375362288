import { AdminTable, UnauthorizedAccess } from '@components';
import { searchBuilder, usePermissions, useSiteConfig } from '@core';
import { Visibility } from '@mui/icons-material';
import type { GridColDef } from '@mui/x-data-grid';
import { postApiAdminRequestFindBy } from '@services/admin-request/admin-request';
import type { AdminRequest } from '@services/model';
import { format, parseISO } from 'date-fns';
import { PageBody, PageContentWithSidebar } from 'features/shell/layout';
import { useCallback, useState } from 'react';

import { titlePageRegistry } from '../PageRegistry';
import { renderAction } from './AuditLogAction';
import { AuditLogDetails } from './AuditLogDetails';

interface LogsGridProps {
    gameEnvironmentId: string;
    onViewDetails: (log: AdminRequest) => void;
}

export const LogsGrid = ({ gameEnvironmentId, onViewDetails }: LogsGridProps) => {
    const columns: GridColDef[] = [
        {
            field: 'userName',
            headerName: 'User',
            sortable: false,
            flex: 1,
            renderCell: (params) => params.row.userName ?? params.row.userId,
        },
        {
            field: 'createdAt',
            headerName: 'Created',
            sortable: false,
            flex: 0.5,
            valueFormatter: (params) => format(parseISO(String(params.value)), 'Ppp'),
        },
        {
            field: 'url',
            headerName: 'Action',
            sortable: false,
            flex: 1,
            renderCell: renderAction,
        },
        {
            field: 'traceId',
            headerName: 'Trace Id',
            sortable: false,
            hide: true,
            flex: 0.5,
        },
    ];

    const { canRead } = usePermissions('AdminRequest');
    if(!canRead) {
        return (<UnauthorizedAccess />);
    }
    const [query] = useState(() => searchBuilder('AdminRequest').prepare(postApiAdminRequestFindBy));

    return (
        <AdminTable
            title="Admin Requests"
            resource="AdminRequest"
            columns={columns}
            data={query}
            hideToolbar={true}
            sortModel={[{ field: 'createdAt', sort: 'desc' }]}
            menuActions={[{ label: 'View Details', type: 'button', icon: Visibility, onClick: onViewDetails }]}
        />
    );
};

export function AuditLogs() {
    const config = useSiteConfig();
    const [selectedLog, setSelectedLog] = useState<AdminRequest>();
    const isLogSelected = !!selectedLog;
    const closeDetails = useCallback(() => setSelectedLog(undefined), [selectedLog]);
    return (
        <PageContentWithSidebar toggle={isLogSelected}>
            <PageBody noPadding>
                <LogsGrid gameEnvironmentId={config.id} onViewDetails={setSelectedLog} />
            </PageBody>
            {isLogSelected ? <AuditLogDetails log={selectedLog} onClose={closeDetails} /> : null}
        </PageContentWithSidebar>
    );
}

titlePageRegistry.register({ page: AuditLogs, path: 'logs', name: 'Logs', icon: <Visibility /> });
