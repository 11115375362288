import { usePermissions, useUserPrefs } from '@core';
import Editor, { useMonaco } from '@monaco-editor/react';
import { FormHelperText, InputLabel } from '@mui/material';
import type { languages } from 'monaco-editor';
import { useEffect, useState } from 'react';

import { useResource } from '../ResourceProvider';
import { makeHookable } from './HookAdapter';

const isValid = (value: string) => {
    try {
        JSON.parse(value);
        return true;
    } catch {
        return false;
    }
};

interface JsonProps {
    height?: string;
    onValidityChange?: (value: boolean) => void;
    readonly?: boolean;
    schema?: any;
}
export const JsonInput = makeHookable<string | undefined, Omit<JsonProps, 'label'>>(function JsonInput(props) {
    const [valid, setValid] = useState(true);
    const { prefs } = useUserPrefs();
    const monaco = useMonaco();
    const handleChange = (value?: string) => {
        if (props.onValidityChange) {
            const validity = isValid(value || '');
            if (validity !== valid) {
                props.onValidityChange(validity);
                setValid(validity);
            }
        }
        if (props.type === 'standard') {
            props.onChange(value);
        } else {
            props.hookOnChange({ target: { value } });
        }
    };
    useEffect(() => {
        // do conditional chaining
        if (props.schema) {
            monaco?.languages.json.jsonDefaults.setDiagnosticsOptions({
                validation: true,
                schemaValidation: 'error',
                schemas: {
                    uri: 'https://json-schema.org/draft/2019-09/schema',
                    fileMatch: ['*'],
                    schema: props.schema,
                },
            } as unknown as languages.json.DiagnosticsOptions);
            // or make sure that it exists by other ways
            monaco?.languages.json.jsonDefaults.setModeConfiguration({
                colors: true,
                foldingRanges: true,
                diagnostics: true,
                tokens: true,
                completionItems: true,
            });
        }
        if (monaco) {
            console.log('here is the monaco instance:', monaco);
        }
    }, [monaco, props.schema]);
    const resource = useResource();
    const perm = usePermissions(resource);
    const readonly = props.readonly || (resource && !perm.canMutate);
    // const editorWillMount = (monaco: Monaco) => {
    //     monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
    //         validate: true,
    //         schemas: props.schema,
    //     });
    // };
    return (
        <>
            <InputLabel shrink>{props.label}</InputLabel>
            <Editor
                width={props.width === 'full' ? undefined : props.width}
                height={props.height || '60vh'}
                options={{ readOnly: !!readonly }}
                defaultLanguage="json"
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                value={props.value}
                onChange={handleChange}
                theme={prefs?.darkMode ? 'vs-dark' : 'light'}
            />
            <FormHelperText error={Boolean(props.errorMessage)} style={{ marginTop: '0rem' }}>
                {props.helperText ?? props.errorMessage}
            </FormHelperText>
        </>
    );
});
