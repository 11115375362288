import { AdminTable, BreadcrumbLink, useUrlParams } from '@components';
import { Event, useApiListener } from '@core';
import type { GridColDef, GridRowData } from '@mui/x-data-grid';
import { PackContents } from '@services/model';
import { PageContent } from 'features/shell/layout';
import { useEffect, useState } from 'react';
import { getApiPacksByNameNameNumberNumber } from '@services/packs/packs';
import { nanoid } from 'nanoid';
import { titlePageRegistry } from '../PageRegistry';
import { Person } from '@mui/icons-material';
import { Box, LinearProgress, Tab, Tabs } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';

function PackContentsGrid(props: { packConents: PackContents}) {
    const [invalidateData] = useState(() => new Event<void>());
    const [packItemGridData, setPackItemGridData] = useState<GridRowData[]>([]);
    const [packCurrencyGridData, setPackCurrencyGridData] = useState<GridRowData[]>([]);
    const [currentTab, setCurrentTab] = useState('0');

    useEffect(() => {
        if (props.packConents != null) {
            handleSetContentGridData(props.packConents);
        }
    }, [props.packConents]);
    useApiListener('pack', () => invalidateData.raise());
    
    const itemColumns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'Id',
            type: 'uuid',
            flex: 0.5,
            hide: true,
        },
        {
            field: 'packName',
            headerName: 'Pack Name',
            flex: 1,
            type: 'string',
        },
        {
            field: 'packNumber',
            headerName: 'Pack Number',
            flex: 1,
            type: 'string'
        },
        {
            field: 'itemId',
            headerName: 'Item ID',
            type: 'string',
            flex: 1,
        },
        {
            field: 'externalId',
            headerName: 'External ID',
            type: 'string',
            flex: 1,
        },
        {
            field: 'amount',
            headerName: 'Amount',
            type: 'string',
            flex: 1,
        },
        {
            field: 'redeemed',
            headerName: 'Redeemed',
            type: 'string',
            flex: 1,
        }
    ];

    const currencyColumns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'Id',
            type: 'uuid',
            flex: 0.5,
            hide: true,
        },
        {
            field: 'packName',
            headerName: 'Pack Name',
            flex: 1,
            type: 'string',
        },
        {
            field: 'packNumber',
            headerName: 'Pack Number',
            flex: 1,
            type: 'string'
        },
        {
            field: 'currencyCode',
            headerName: 'Currency Code',
            type: 'string',
            flex: 1,
        },
        {
            field: 'amount',
            headerName: 'Amount',
            type: 'string',
            flex: 1,
        },
        {
            field: 'redeemed',
            headerName: 'Redeemed',
            type: 'string',
            flex: 1,
        }
    ];

    function handleSetContentGridData(packContents: PackContents | undefined) {
        if (packContents?.grantablePackItems) {
            var grantedItems: GridRowData[] = packContents?.grantablePackItems?.map((x) => {
                return {
                    id: nanoid(),
                    itemId: x.itemId,
                    externalId: x.externalId,
                    packName: packContents.packName,
                    packNumber: packContents.packNumber,
                    redeemed: packContents.redeemed,
                    amount: x.amount
                };
            });
            setPackItemGridData(grantedItems);
        }

        if (packContents?.grantablePackCurrencies) {
            var grantedCurrencies: GridRowData[] = packContents?.grantablePackCurrencies?.map((x) => {
                return {
                    id: nanoid(),
                    currencyCode: x.currencyCode,
                    amount: x.amount,
                    packName: packContents.packName,
                    packNumber: packContents.packNumber,
                    redeemed: packContents.redeemed
                };
            });
            setPackCurrencyGridData(grantedCurrencies);
        }
    }

    return  (
            <TabContext value={currentTab ?? '0'}>
                <Box>
                    <Tabs onChange={(_, tab) => setCurrentTab(tab)} variant="scrollable" scrollButtons="auto" value={currentTab}>
                        <Tab value="0" label="Items" />
                        <Tab value="1" label="Currencies" />
                    </Tabs>
                </Box>

                <TabPanel sx={{ height: '75vh' }} value="0">
                    <AdminTable
                        title="Items in Packs"
                        resource="Item"
                        columns={itemColumns}
                        data={packItemGridData ? packItemGridData : []}
                    />
                </TabPanel>

                <TabPanel sx={{ height: '75vh' }} value="1">
                    <AdminTable
                        title="Currencies in Pack"
                        resource="Item"
                        columns={currencyColumns}
                        data={packCurrencyGridData ? packCurrencyGridData : []}
                    />
                </TabPanel>
            </TabContext>
    );
};

function Contents(props: { packName: string | undefined, packId: number | undefined }) {
    const [packContents, setPackContents] = useState<PackContents>();

    const loadPackContents= async () => {
        const packContentResults = await Promise.resolve(getApiPacksByNameNameNumberNumber(props.packName!, props.packId!));
        setPackContents(packContentResults);
    };

    useEffect(() => {
        loadPackContents();
    }, [props.packName, props.packId]);

    return !packContents ? <LinearProgress /> : <PackContentsGrid packConents={packContents} />;
}

function PackContentsBreadcrumb() {
    const { params } = useUrlParams();
    const [ packValues, setPackValues ] = useState<PackContentValues>();

    useEffect(() => {
        var values = params.id.split('&');

        var contentValues : PackContentValues = {
            packName: values[0],
            packId: Number(values[1])
        };

        setPackValues(contentValues);
    }, [params]);

    return (
        <BreadcrumbLink>
            <Person /> Pack ({packValues?.packId})
        </BreadcrumbLink>
    );
}

export function PackContentsPage() {
    const { params } = useUrlParams();
    const [ packValues, setPackValues ] = useState<PackContentValues>();

    useEffect(() => {
        var values = params.id.split('&');

        var contentValues : PackContentValues = {
            packName: values[0],
            packId: Number(values[1])
        };

        setPackValues(contentValues);
    }, [params]);

    return (
        <PageContent>
            <Contents packName={packValues?.packName} packId={packValues?.packId}/>
        </PageContent>
    );
}

interface PackContentValues {
    packName: string;
    packId: number;
}

titlePageRegistry.register({ page: PackContentsPage, path: 'pack-contents', name: PackContentsBreadcrumb });
