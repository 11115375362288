import { inject, singleton } from 'tsyringe';

import { useDi } from './DiService';
import { Event, useEventValue } from './Event';
import { Logger } from './Logger';

export interface UserPreferences {
    navBarCollapsed: boolean;
    lastSiteConfigId?: string;
    darkMode: boolean;
}

@singleton()
export class UserPreferenceService {
    private prefs: UserPreferences;
    public prefsChanged = new Event<UserPreferences>();

    public constructor(@inject(Logger) private readonly logger: Logger) {
        this.prefs = { darkMode: false, navBarCollapsed: false };
    }

    public start() {
        const existingPrefs = localStorage.getItem('prefs');
        if (existingPrefs) {
            try {
                this.logger.info('Loading user prefs');
                this.prefs = JSON.parse(existingPrefs) as UserPreferences;
                this.prefsChanged.raise(this.prefs);
                this.logger.info('User prefs loaded', { prefs: JSON.stringify(this.prefs) });
            } catch (err) {
                this.logger.error('User prefs are corrupted. ', err);
            }
        }
    }

    public getPrefs() {
        return this.prefs;
    }

    public updatePrefs(prefs: Partial<UserPreferences>) {
        this.prefs = { ...this.prefs, ...prefs };
        localStorage.setItem('prefs', JSON.stringify(this.prefs));
        this.prefsChanged.raise(this.prefs);
    }
}

export function useUserPrefs() {
    const userPrefSvc = useDi(UserPreferenceService);
    const setPrefs = (prefs: Partial<UserPreferences>) => userPrefSvc.updatePrefs(prefs);
    const prefs = useEventValue(userPrefSvc.prefsChanged, userPrefSvc.getPrefs());
    return { prefs, setPrefs };
}
