import type { SiteTheme } from '@core';
import { Box } from '@mui/material';

import { ColorInput } from './Forms';

export function ThemeEditor(props: { theme: SiteTheme; onChange: (value: SiteTheme) => void }) {
    const onChange = (change: Partial<SiteTheme>) => {
        const newTheme = { ...props.theme, ...change };
        props.onChange(newTheme);
    };
    return (
        <>
            <Box my={2} display="flex" justifyContent="space-between">
                <ColorInput
                    label="Primary Color"
                    value={props.theme.primary || ''}
                    onChange={(c) => onChange({ primary: c || '' })}
                    helperText="The main brand color"
                />
                <ColorInput
                    label="Secondary Color"
                    value={props.theme.secondary || ''}
                    onChange={(c) => onChange({ secondary: c || '' })}
                    helperText="The brand accent color"
                />
            </Box>
            <Box my={2} display="flex" justifyContent="space-between">
                <ColorInput
                    label="Dark Background"
                    value={props.theme.darkBg || ''}
                    onChange={(c) => onChange({ darkBg: c || '' })}
                    helperText="The background color when dark-mode is on"
                />
                <ColorInput
                    label="Light Background"
                    value={props.theme.lightBg || ''}
                    onChange={(c) => onChange({ lightBg: c || '' })}
                    helperText="The background color when dark-mode is off"
                />
            </Box>
        </>
    );
}
