/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.14.2.2422
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  GameEnvironmentResultIPagedResultList,
  GetApiGameEnvironmentsParams,
  GameEnvironmentResult,
  SaveGameEnvironmentRequest,
  GameEnvironmentSetting,
  SaveGameEnvironmentSettingsRequest,
  EntitySearch,
  ObjectIPagedResultList
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const getApiGameEnvironments = (
    params?: GetApiGameEnvironmentsParams,
 ) => {
      return orvalRequestor<GameEnvironmentResultIPagedResultList>(
      {url: `/api/game-environments`, method: 'get',
        params,
    },
      );
    }
  

export const getGetApiGameEnvironmentsQueryKey = (params?: GetApiGameEnvironmentsParams,) => [`/api/game-environments`, ...(params ? [params]: [])];

    
export const useGetApiGameEnvironments = <TData = AsyncReturnType<typeof getApiGameEnvironments>, TError = unknown>(
 params?: GetApiGameEnvironmentsParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiGameEnvironments>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiGameEnvironmentsQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiGameEnvironments>> = () => getApiGameEnvironments(params, );

  const query = useQuery<AsyncReturnType<typeof getApiGameEnvironments>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const postApiGameEnvironments = (
    saveGameEnvironmentRequest: SaveGameEnvironmentRequest,
 ) => {
      return orvalRequestor<GameEnvironmentResult>(
      {url: `/api/game-environments`, method: 'post',
      data: saveGameEnvironmentRequest
    },
      );
    }
  


    export const usePostApiGameEnvironments = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiGameEnvironments>, TError,{data: SaveGameEnvironmentRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiGameEnvironments>, {data: SaveGameEnvironmentRequest}> = (props) => {
          const {data} = props || {};

          return  postApiGameEnvironments(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiGameEnvironments>, TError, {data: SaveGameEnvironmentRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const putApiGameEnvironmentsId = (
    id: string,
    saveGameEnvironmentRequest: SaveGameEnvironmentRequest,
 ) => {
      return orvalRequestor<GameEnvironmentResult>(
      {url: `/api/game-environments/${id}`, method: 'put',
      data: saveGameEnvironmentRequest
    },
      );
    }
  


    export const usePutApiGameEnvironmentsId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiGameEnvironmentsId>, TError,{id: string;data: SaveGameEnvironmentRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiGameEnvironmentsId>, {id: string;data: SaveGameEnvironmentRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiGameEnvironmentsId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiGameEnvironmentsId>, TError, {id: string;data: SaveGameEnvironmentRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiGameEnvironmentsId = (
    id: string,
 ) => {
      return orvalRequestor<GameEnvironmentResult>(
      {url: `/api/game-environments/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiGameEnvironmentsIdQueryKey = (id: string,) => [`/api/game-environments/${id}`];

    
export const useGetApiGameEnvironmentsId = <TData = AsyncReturnType<typeof getApiGameEnvironmentsId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiGameEnvironmentsId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiGameEnvironmentsIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiGameEnvironmentsId>> = () => getApiGameEnvironmentsId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiGameEnvironmentsId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const deleteApiGameEnvironmentsId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/game-environments/${id}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiGameEnvironmentsId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiGameEnvironmentsId>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiGameEnvironmentsId>, {id: string}> = (props) => {
          const {id} = props || {};

          return  deleteApiGameEnvironmentsId(id,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiGameEnvironmentsId>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiGameEnvironmentsSetting = (
    saveGameEnvironmentSettingsRequest: SaveGameEnvironmentSettingsRequest,
 ) => {
      return orvalRequestor<GameEnvironmentSetting>(
      {url: `/api/game-environments/setting`, method: 'post',
      data: saveGameEnvironmentSettingsRequest
    },
      );
    }
  


    export const usePostApiGameEnvironmentsSetting = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiGameEnvironmentsSetting>, TError,{data: SaveGameEnvironmentSettingsRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiGameEnvironmentsSetting>, {data: SaveGameEnvironmentSettingsRequest}> = (props) => {
          const {data} = props || {};

          return  postApiGameEnvironmentsSetting(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiGameEnvironmentsSetting>, TError, {data: SaveGameEnvironmentSettingsRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiGameEnvironmentsSettingId = (
    id: string,
 ) => {
      return orvalRequestor<GameEnvironmentSetting>(
      {url: `/api/game-environments/setting/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiGameEnvironmentsSettingIdQueryKey = (id: string,) => [`/api/game-environments/setting/${id}`];

    
export const useGetApiGameEnvironmentsSettingId = <TData = AsyncReturnType<typeof getApiGameEnvironmentsSettingId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiGameEnvironmentsSettingId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiGameEnvironmentsSettingIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiGameEnvironmentsSettingId>> = () => getApiGameEnvironmentsSettingId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiGameEnvironmentsSettingId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const putApiGameEnvironmentsSettingId = (
    id: string,
    saveGameEnvironmentSettingsRequest: SaveGameEnvironmentSettingsRequest,
 ) => {
      return orvalRequestor<GameEnvironmentSetting>(
      {url: `/api/game-environments/setting/${id}`, method: 'put',
      data: saveGameEnvironmentSettingsRequest
    },
      );
    }
  


    export const usePutApiGameEnvironmentsSettingId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiGameEnvironmentsSettingId>, TError,{id: string;data: SaveGameEnvironmentSettingsRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiGameEnvironmentsSettingId>, {id: string;data: SaveGameEnvironmentSettingsRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiGameEnvironmentsSettingId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiGameEnvironmentsSettingId>, TError, {id: string;data: SaveGameEnvironmentSettingsRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiGameEnvironmentsFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<GameEnvironmentResultIPagedResultList>(
      {url: `/api/game-environments/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiGameEnvironmentsFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiGameEnvironmentsFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiGameEnvironmentsFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiGameEnvironmentsFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiGameEnvironmentsFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiGameEnvironmentsQuery = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ObjectIPagedResultList>(
      {url: `/api/game-environments/query`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiGameEnvironmentsQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiGameEnvironmentsQuery>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiGameEnvironmentsQuery>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiGameEnvironmentsQuery(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiGameEnvironmentsQuery>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    