/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.14.2.2422
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  UserAccount,
  SaveUserAccountRequest,
  UserAccountIPagedResultList,
  EntitySearch,
  UserAccountPrefs,
  SaveUserAccountPrefsRequest,
  MythicalIdentity
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const getApiUserAccountsId = (
    id: string,
 ) => {
      return orvalRequestor<UserAccount>(
      {url: `/api/user-accounts/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiUserAccountsIdQueryKey = (id: string,) => [`/api/user-accounts/${id}`];

    
export const useGetApiUserAccountsId = <TData = AsyncReturnType<typeof getApiUserAccountsId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiUserAccountsId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiUserAccountsIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiUserAccountsId>> = () => getApiUserAccountsId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiUserAccountsId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const putApiUserAccountsId = (
    id: string,
    saveUserAccountRequest: SaveUserAccountRequest,
 ) => {
      return orvalRequestor<UserAccount>(
      {url: `/api/user-accounts/${id}`, method: 'put',
      data: saveUserAccountRequest
    },
      );
    }
  


    export const usePutApiUserAccountsId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiUserAccountsId>, TError,{id: string;data: SaveUserAccountRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiUserAccountsId>, {id: string;data: SaveUserAccountRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiUserAccountsId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiUserAccountsId>, TError, {id: string;data: SaveUserAccountRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const deleteApiUserAccountsId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/user-accounts/${id}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiUserAccountsId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiUserAccountsId>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiUserAccountsId>, {id: string}> = (props) => {
          const {id} = props || {};

          return  deleteApiUserAccountsId(id,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiUserAccountsId>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiUserAccountsFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<UserAccountIPagedResultList>(
      {url: `/api/user-accounts/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiUserAccountsFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiUserAccountsFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiUserAccountsFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiUserAccountsFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiUserAccountsFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiUserAccounts = (
    saveUserAccountRequest: SaveUserAccountRequest,
 ) => {
      return orvalRequestor<UserAccount>(
      {url: `/api/user-accounts`, method: 'post',
      data: saveUserAccountRequest
    },
      );
    }
  


    export const usePostApiUserAccounts = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiUserAccounts>, TError,{data: SaveUserAccountRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiUserAccounts>, {data: SaveUserAccountRequest}> = (props) => {
          const {data} = props || {};

          return  postApiUserAccounts(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiUserAccounts>, TError, {data: SaveUserAccountRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const patchApiUserAccountsPrefs = (
    saveUserAccountPrefsRequest: SaveUserAccountPrefsRequest,
 ) => {
      return orvalRequestor<UserAccountPrefs>(
      {url: `/api/user-accounts/prefs`, method: 'patch',
      data: saveUserAccountPrefsRequest
    },
      );
    }
  


    export const usePatchApiUserAccountsPrefs = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof patchApiUserAccountsPrefs>, TError,{data: SaveUserAccountPrefsRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof patchApiUserAccountsPrefs>, {data: SaveUserAccountPrefsRequest}> = (props) => {
          const {data} = props || {};

          return  patchApiUserAccountsPrefs(data,)
        }

      return useMutation<AsyncReturnType<typeof patchApiUserAccountsPrefs>, TError, {data: SaveUserAccountPrefsRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiUserAccountsMyProfile = (
    
 ) => {
      return orvalRequestor<MythicalIdentity>(
      {url: `/api/user-accounts/my-profile`, method: 'get'
    },
      );
    }
  

export const getGetApiUserAccountsMyProfileQueryKey = () => [`/api/user-accounts/my-profile`];

    
export const useGetApiUserAccountsMyProfile = <TData = AsyncReturnType<typeof getApiUserAccountsMyProfile>, TError = unknown>(
  options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiUserAccountsMyProfile>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiUserAccountsMyProfileQueryKey();

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiUserAccountsMyProfile>> = () => getApiUserAccountsMyProfile();

  const query = useQuery<AsyncReturnType<typeof getApiUserAccountsMyProfile>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

