import { AdminTable, CellNavigation, confirm, DropdownInput, modal, TextInput, useNavigator } from '@components';
import { buildSearch, Event, useApiListener } from '@core';
import { yupResolver } from '@hookform/resolvers/yup';
import { Create, Delete } from '@mui/icons-material';
import { Box, Button, MenuItem } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid';
import { useDeleteApiDropTablesId, useGetApiDropTablesAggregate, usePostApiDropTables } from '@services/drop-tables/drop-tables';
import { usePostApiGameVersionsFindBy } from '@services/game-versions/game-versions';
import type { DropTableAggregateViewModelIPagedResultList, DropTableChildListViewModel, GameVersion, InventoryContainer, SaveInventoryContainerRequest } from '@services/model';
import { PageContent } from 'features/shell/layout';
import { sum } from 'lodash';
import { useEffect, useState } from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { useMount } from 'react-use';
import * as yup from 'yup';

export const DropTablesGrid = () => {
    const nav = useNavigator();
    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'Id',
            type: 'uuid',
            flex: 0.5,
            hide: true,
        },
        {
            field: 'displayName',
            headerName: 'Display Name',
            flex: 0.5,
            type: 'string',
            renderCell: (f) => <CellNavigation to="drop-table-details" id={String(f.row.id)} value={String(f.value)} tab="details" />,
        },
        {
            field: 'externalId',
            headerName: 'External Id',
            flex: 0.5,
            type: 'string',
        },
        {
            field: 'minGameVersion',
            headerName: 'Min Game Version',
            flex: 0.5,
            renderCell: (f) => (
                <CellNavigation to="versions" id={f.row.minGameVersion} value={minGameVersionLookup.get(f.row['minGameVersion']) || ''} />
            ),
        },
        {
            field: 'minGrantableItems',
            headerName: 'Min Grantable Items',
            type: 'string',
            flex: 0.5,
        },
        {
            field: 'maxGrantableItems',
            headerName: 'Max Grantable Items',
            type: 'string',
            flex: 0.5,
        },
        {
            field: 'itemsAvailable',
            headerName: 'Items Available',
            type: 'string',
            flex: 0.5,
        }
    ];

    interface DropTableGrid {
        id?: string | null;
        displayName?: string | null;
        minGameVersion?: string;
        externalId?: string | null | undefined;
        minGrantableItems?: number;
        maxGrantableItems?: number;
        itemsAvailable: number | null;
    }

    const [invalidateData] = useState(() => new Event<void>());
    const [minGameVersionLookup, setMinGameVersionLookup] = useState(new Map<string, string>());
    const { mutateAsync: findGameVersion, data: versions } = usePostApiGameVersionsFindBy();
    const { data: dropTables, refetch } = useGetApiDropTablesAggregate({ SortBy: 'Id', SortOrder: 'ASC' as 'ASC' | 'DESC', Page: 1, PageSize: 100000 });
    const [dropTableGridRowData, setDropTableGridRowData] = useState<DropTableGrid[]>([]);

    useMount(() => {
        findGameVersion(buildSearch()).then(({ items }) => {
            setMinGameVersionLookup(new Map((items || []).map((o) => [o.id || '', o.name || ''] as [string, string])));
        });
    });

    useEffect(() => {
        if (dropTables != null) {
            setDropTableGridRowData(buildRowData(dropTables));
        }
    }, [dropTables]);


    function buildRowData(entities: DropTableAggregateViewModelIPagedResultList | undefined): DropTableGrid[] {
        return (
            entities?.items?.map((x) => {
                return { 
                    ...x.dropTable, 
                    itemsAvailable: buildCountAvailable(x.items)
                };
            }) ?? []
        );
    }

    function buildCountAvailable(dropTableItems: DropTableChildListViewModel[] | null | undefined) {
        if (!dropTableItems) {
            return null;
        }

        var maxCountSum = sum(dropTableItems.filter(x => x.maxCount && x.maxCount !== undefined).map(x => x.maxCount));
        var grantedCountSum = sum(dropTableItems.filter(x => x.grantedCount && x.grantedCount !== undefined).map(x => x.grantedCount));
        return maxCountSum ? maxCountSum - (grantedCountSum ?? 0) : null;
    }

    const { mutateAsync: remove } = useDeleteApiDropTablesId();
    const { mutateAsync: add } = usePostApiDropTables();

    const onDelete = async (e: Partial<DropTableGrid>) => {
        const didConfirm = await confirm(`Delete ${e.displayName ?? ''}?`, `Are you sure you want to delete this Drop Table?`);
        if (didConfirm) {
            await remove({ id: e.id ?? '' });
            refetch();
        }
    };

    const onAdd = async () => {
        let dropTableSaveRequest = undefined;
        await modal('Add Drop Table', (close) => (
            <NewDropTablePrompt
                onClose={close}
                versions={versions && versions.items ? versions.items : []}
                onChange={(e: SaveInventoryContainerRequest) => (dropTableSaveRequest = e)}
            />
        ));
        if (dropTableSaveRequest) {
            await add({ data: { ...(dropTableSaveRequest as SaveInventoryContainerRequest) } });
            refetch();
        }
    };

    useApiListener('drop-tables', () => invalidateData.raise());

    return (
        <AdminTable<DropTableGrid>
            title="Drop Tables"
            resource="Item"
            columns={columns}
            data={dropTableGridRowData ? dropTableGridRowData : []}
            dataInvalidationEvent={invalidateData}
            menuActions={[
                { type: 'button', icon: Create, label: 'Edit', onClick: (r) => nav.descend('drop-table-details', { id: String(r.id) }) },
                { type: 'button', icon: Delete, label: 'Delete', onClick: onDelete },
            ]}
            onAdd={onAdd}
        />
    );
};

const DropTablesSchema = yup.object().shape({
    displayName: yup.string().required('Display Name is required!'),
    minGameVersion: yup.string().required('Minium Game Version required!'),
    minGrantableItems: yup.number().nullable().positive('Must be a positive number.').max(2147483647, 'Cannot be larger than max integer size.').nullable(true).transform((_, val) => (val ? Number(val) : null))
    .when("dropTableAggregateViewModel.maxGrantableItems", {
        is: (value: number) => value !== null,
        then: yup.number().required("Value is required when Maximum Grantable Items is specified.").nullable(true).transform((_, val) => (val ? Number(val) : null))
    })
    .test("minTest", "Value must be less than or equal to Maximum Grantable Items", function (value) {
        if (this.parent.maxGrantableItems !== null && value !== null) {
            return value as number <= this.parent.maxGrantableItems;
        }

        return true;
    }),
    maxGrantableItems: yup.number().nullable().positive('Must be a positive number.').max(2147483647, 'Cannot be larger than max integer size.').nullable(true).transform((_, val) => (val ? Number(val) : null))
    .when("dropTableAggregateViewModel.minGrantableItems", {
        is: (value: number) => value !== null,
        then: yup.number().required("Value is required when Minimum Grantable Items is specified.").nullable(true).transform((_, val) => (val ? Number(val) : null))
    })
    .test("maxTest", "Value must be greater than or equal to Minimum Grantable Items", function (value) {
        if (this.parent.minGrantableItems !== null && value !== null) {
            return value as number >= this.parent.minGrantableItems;
        }

        return true;
    }),
});

function NewDropTablePrompt(props: { versions: GameVersion[]; onChange: (dropTable: SaveInventoryContainerRequest) => void; onClose: () => void }) {
    const defaultValues: SaveInventoryContainerRequest = {
        displayName: '',
        minGameVersion: '',
        minGrantableItems: 1,
        maxGrantableItems: 1,
        externalId: null
    };

    const form = useForm({ resolver: yupResolver(DropTablesSchema), defaultValues, mode: 'onBlur', reValidateMode: 'onBlur' });
    const { isDirty } = useFormState({ control: form.control });
    const save = () => {
        const values = form.getValues();
        props.onChange(values);
        props.onClose();
    };

    return (
        <>
            <FormProvider {...form}>
                <Box px={4} maxWidth={500}>
                    <TextInput label="Display Name" width="full" name="displayName" helperText="What's the drop table display name?" />
                    <TextInput label="External Id" width="full" name="externalId" helperText="What's the drop table external id?" />
                    <DropdownInput
                        width="full"
                        helperText="Select version for drop table to be associated to."
                        label="Game Version"
                        name="minGameVersion"
                    >
                        <MenuItem value="">None</MenuItem>
                        {props.versions &&
                            props.versions.map((c) => (
                                <MenuItem key={c.id} value={c.id || ''}>
                                    {c.name}
                                </MenuItem>
                            ))}
                    </DropdownInput>
                    <TextInput label="Minimum Grantable Items" width="full" name="minGrantableItems" helperText="What's the minimum grantable items?" placeholder="1" />
                    <TextInput label="Maximum Grantable Items" width="full" name="maxGrantableItems" helperText="What's the maximum grantable items?" placeholder="1" />
                </Box>
            </FormProvider>
            <Box p={2} justifyContent="flex-end" display="flex">
                <Button variant="contained" color="primary" disabled={!isDirty} onClick={form.handleSubmit(save)}>
                    Create Drop Table
                </Button>
                <Button onClick={props.onClose}>Cancel</Button>
            </Box>
        </>
    );
}

export function DropTables() {
    return (
        <PageContent>
            <DropTablesGrid />
        </PageContent>
    );
}
