import { LinkTabs, ResourceProvider } from '@components';
import { Store } from '@mui/icons-material';

import { titlePageRegistry } from '../PageRegistry';
import { ShopCalendar } from './ShopCalendar';
import { ShopGrid } from './ShopGrid';

export function StoresPage() {
    return (
        <ResourceProvider value="Shop">
            <LinkTabs
                tabs={[
                    { component: ShopCalendar, label: 'Schedule', url: 'schedule' },
                    { component: ShopGrid, label: 'List', url: 'list' },
                ]}
            />
        </ResourceProvider>
    );
}

titlePageRegistry.register({ page: StoresPage, name: 'Shops', icon: <Store />, path: 'shops' });
