import type { SvgIconProps } from '@mui/material';
import { Menu } from '@mui/material';
import type { GridRowModel } from '@mui/x-data-grid';
import type { ComponentType } from 'react';
import { useCallback, useState } from 'react';

import { RowMenuItem } from '.';

export interface RowMenuActionButton<T> {
    type: 'button';
    icon: ComponentType<SvgIconProps>;
    label: string;
    disabled?: (item: T) => boolean;
    onClick: (item: T) => void;
}

interface RowMenuActionDivider {
    type: 'divider';
}

interface RowMenuActionLink {
    type: 'link';
    icon: ComponentType<SvgIconProps>;
    label: string;
    href: string;
}

export type RowMenuAction<T> = RowMenuActionButton<T> | RowMenuActionDivider | RowMenuActionLink;

interface RowMenuProps<T> extends GridRowModel {
    menuActions: RowMenuAction<T>[] | ((item: T) => RowMenuAction<T>[]);
    anchorEl?: HTMLElement;
    actionMenuItem?: T;
    onClose: () => void;
}

export function RowMenu<T>({ menuActions, anchorEl, actionMenuItem, onClose }: RowMenuProps<T>) {
    const [menuAnchor, setMenuAnchor] = useState(anchorEl);
    const [menuItem, setMenuItem] = useState(actionMenuItem);
    const handleMenuItemClick = useCallback(
        (item: T, action: RowMenuActionButton<T>) => {
            setMenuAnchor(undefined);
            setMenuItem(undefined);
            action.onClick(item);
            onClose();
        },
        [onClose]
    );

    const handleMenuOnClose = useCallback(() => {
        setMenuAnchor(undefined);
        setMenuItem(undefined);
        onClose();
    }, [onClose]);

    const renderMenuItems = useCallback(
        (item: T) => {
            if (menuActions) {
                const actions = typeof menuActions === 'function' ? menuActions(item) : menuActions;
                return actions.map((action, index) => <RowMenuItem item={item} action={action} key={index} onClick={handleMenuItemClick} />);
            }
        },
        [menuActions, handleMenuItemClick]
    );
    return (
        <>
            {menuAnchor && (
                <Menu onClose={handleMenuOnClose} anchorEl={menuAnchor} open>
                    {/* https://github.com/mui-org/material-ui/issues/15903 */}
                    <div>{menuItem && renderMenuItems(menuItem)}</div>
                </Menu>
            )}
        </>
    );
}
