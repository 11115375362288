import { AdminTable, CellNavigation, UnauthorizedAccess } from '@components';
import { searchBuilder, useApiListener, useSiteConfig, usePermissions } from '@core';
import { Outbound, PeopleAlt, Security } from '@mui/icons-material';
import { Box, Divider, LinearProgress, Link as MuiLink } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid';
import { usePostApiAdminUserFindBy } from '@services/admin-user/admin-user';
import type { AdminUser } from '@services/model';
import { format, parseISO } from 'date-fns';
import { PageBody, PageContentWithSidebar } from 'features/shell/layout';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMount } from 'react-use';

import { orgPageRegistry } from '../PageRegistry';
import { RolePicker } from './RolePicker';

const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', flex: 0.5, hide: true },
    {
        field: 'fullName',
        headerName: 'Name',
        flex: 1,
        renderCell: (cellValues) => <CellNavigation id={String(cellValues?.id)} value={String(cellValues?.value)} to="account-details" />,
    },
    {
        field: 'email',
        headerName: 'Email',
        flex: 1,
        valueGetter: (params) => String(params.row.email || ''),
        valueFormatter: (params) => String(params?.value),
    },
    {
        field: 'slackId',
        headerName: 'Slack Profile',
        flex: 1,
        renderCell: (cellValues) => (
            <MuiLink component={Link} to={{ pathname: `https://mythicalgames.slack.com/team/${String(cellValues?.value)}` }} target="_blank">
                <Box display="flex" alignItems="center">
                    <Outbound />
                    Go Slack Profile
                </Box>
            </MuiLink>
        ),
    },
    { field: 'createdAt', headerName: 'Created', flex: 0.5, valueFormatter: (params) => format(parseISO(String(params.value)), 'Ppp') },
];

export function AccountsGrid(props: { organizationId: string; onEditRole: (user: AdminUser) => void }) {

    const { canRead } = usePermissions('AdminUser');
    if(!canRead) {
        return (<UnauthorizedAccess />);
    }

    const { mutateAsync: findAdminUser, data, isLoading } = usePostApiAdminUserFindBy();

    const search = searchBuilder('AdminUser')
        .innerJoin('AdminUserOrganization', ([u, o]) => ({ eq: { [u.nameof.id]: o.adminUserId } }))
        .where(([_u, o]) => ({ eq: { [o.nameof.organizationId]: props.organizationId } }))
        .build();
    useMount(() => findAdminUser({ data: search }));

    useApiListener('adminusers', () => findAdminUser({ data: search }));
    return isLoading ? (
        <LinearProgress />
    ) : (
        <>
            <AdminTable<AdminUser>
                title="Accounts"
                resource="AdminUser"
                menuActions={[{ label: 'Edit', type: 'button', icon: Security, onClick: props.onEditRole }]}
                columns={columns}
                data={data && data?.items ? data?.items : []}
            />
        </>
    );
}

export const Accounts = () => {
    const [selectedUser, setSelectedUser] = useState<AdminUser>();
    const config = useSiteConfig();
    const closeRolePicker = useCallback(() => setSelectedUser(undefined), [setSelectedUser]);
    const isUserSelected = !!selectedUser;
    return (
        
        <PageContentWithSidebar toggle={isUserSelected}>
            <PageBody noPadding>
                <AccountsGrid organizationId={config.id} onEditRole={setSelectedUser} />
            </PageBody>
            {isUserSelected 
                ? <RolePicker name={selectedUser.fullName || ''} userId={selectedUser.id!} onClose={closeRolePicker} organizationId={config.id} /> 
                : null}           
        </PageContentWithSidebar>        

    );
};
orgPageRegistry.register({ page: Accounts, path: 'accounts', name: 'Accounts', icon: <PeopleAlt /> });
