import { FormGrid, FormSection, monitor, Notes, notify, ReadonlyInput, ResourceProvider, TextInput } from '@components';
import { yupResolver } from '@hookform/resolvers/yup';
import {Button, Grid, LinearProgress} from '@mui/material';
import type { Player } from '@services/model';
import { getApiPlayersId, putApiPlayersId, usePostApiPlayersFindBy } from '@services/players/players';
import { PageBody, PageContent, Toolbar } from 'features/shell/layout';
import { useEffect, useState } from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';

import type { UserAccount } from '../../../__generated__/model/userAccount';
import { getApiUserAccountsId, putApiUserAccountsId } from '../../../__generated__/user-accounts/user-accounts';
import { ModerationField } from '.';
import styled from "@emotion/styled";
import { buildSearch } from '@core';
import { useMount } from 'react-use';
import { isValidEmail } from './BulkUtils';

interface PlayerInfoProps {
    player: Player;
    userAccount: UserAccount;
}

export const PlayerInfoForm = ({ player, userAccount }: PlayerInfoProps) => {
    const { data, mutate } = usePostApiPlayersFindBy();

    const search = () => {
        mutate(buildSearch<Player>());
    };

    useMount(search);

    let existingPlayerDisplayNames = data?.items != undefined ? data?.items.filter(x => x.id != player.id).map(x => x.displayName) : [];

    function buildPlayerFormSchema(existingDisplayNames: (string | null | undefined)[]) {
        return yup.object().shape({ 
            player: yup.object().shape({
                displayName: yup.string().required('Display name is required!').test("Unique", "Display name taken. Please choose a different display name.", x => {return x != undefined ? !existingDisplayNames.includes(x) : true}),
                gameRoles: yup.string(),
            }),
            userAccount: yup.object().shape({}),
        })
    };

    const form = useForm({
        defaultValues: {
            player,
            userAccount,
        },
        resolver: yupResolver(buildPlayerFormSchema(existingPlayerDisplayNames)),
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });
    const { isDirty } = useFormState(form);
    const save = async () => {
        const { player, userAccount } = form.getValues();
        if(userAccount.email && !isValidEmail(userAccount.email))
        {
            notify({ type: 'error', content: `Invalid Email Address ${userAccount.email}.` });
            return
        }
        monitor({
            failureMessage: 'Error Saving Player!',
            successMessage: 'Player Saved',
            action: async () => {
                const [updatedAccount, updatedPlayer] = await Promise.all([
                    putApiUserAccountsId(userAccount.id!, userAccount),
                    putApiPlayersId(player.id!, player),
                ]);
                form.reset({ userAccount: updatedAccount, player: updatedPlayer });
            },
        });
    };

    return (
        <>
            <PageContent>
                <PageBody>
                    <FormProvider {...form}>
                        <FormGrid>
                            <FormSection header="Account Info" sm={6} xl={6}>
                                <ResourceProvider value="Player">
                                    <TextInput width="full" label="Display Name" name="player.displayName" />
                                    <ReadonlyInput width="full" label="Player ID" name="player.id" />
                                    {userAccount.email 
                                        ? <TextInput width="full" label="Email Address" name="userAccount.email" />
                                        : <ReadonlyInput width="full" label="Email Address" name="userAccount.email" />}                                    
                                    <TextInput label="Game Roles" width="full" name="player.gameRoles" />
                                    <ReadonlyInput label="Last login" format="datetime" name="player.lastLogin" />
                                    <ReadonlyInput width="full" label="Active" name="player.active" />
                                </ResourceProvider>
                            </FormSection>
                            <FormSection header="Currency Info" xs={6} md={6}>
                                {player.currencies?.map((currency, i) => {
                                    return (
                                        <CurrencyContainer>
                                            <div>{currency.currencyCode}</div>
                                            <div>{currency.amount}</div>
                                        </CurrencyContainer>
                                    );
                                })}
                            </FormSection>
                            <Grid item lg={12} md={12} xs={12}>
                                <FormSection header="Moderation" lg={12} md={12} xs={12}>
                                    <Grid container alignItems="flex-end">
                                        <ModerationField modType="ban" player={player} label="Banned" accessor="bannedUntil" />
                                        <ModerationField modType="mute" player={player} label="Muted" accessor="mutedUntil" />
                                    </Grid>
                                    <Notes typeId={String(player.id)} type={'Player'} />
                                </FormSection>
                            </Grid>
                        </FormGrid>
                    </FormProvider>
                </PageBody>
                <Toolbar>
                    <Button onClick={form.handleSubmit(save)} variant="contained" disabled={!isDirty}>
                        Save Changes
                    </Button>
                </Toolbar>
            </PageContent>
        </>
    );
};

export function PlayerInfo(props: { playerId: string }) {
    const [player, setPlayer] = useState<Player>();
    const [userAccount, setUserAccount] = useState<UserAccount>();
    const loadItem = async () => {
        const playerResult = await getApiPlayersId(props.playerId);
        const userAccountResult = await getApiUserAccountsId(String(playerResult.userAccountId));
        setPlayer(playerResult);
        setUserAccount(userAccountResult);
    };
    useEffect(() => {
        loadItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.playerId]);

    return !player || !userAccount ? <LinearProgress /> : <PlayerInfoForm player={player} userAccount={userAccount} />;
}

const CurrencyContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
