import { DiContext } from '@core';
import { Button, Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ThemeProvider } from 'features/shell/theme';
import { useEffect, useState } from 'react';
import { render } from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { container as diContainer } from 'tsyringe';

interface DialogButton {
    text: React.ReactNode;
    primary?: boolean;
    onClick: (closer: () => void) => void;
}

interface DialogProps {
    buttons?: DialogButton[];
    title: React.ReactNode;
    onClose?: () => boolean | void;
    children?: React.ReactNode;
    show?: boolean;
}

const queryClient = new QueryClient();

export const Dialog = ({ buttons, title, children, onClose, show = true }: DialogProps) => {
    const [open, setOpen] = useState(show),
        closer = () => {
            if (!onClose || onClose() !== false) {
                setOpen(false);
            }
        };
    useEffect(() => setOpen(show), [show]);

    return (
        <MuiDialog open={open} maxWidth={'xl'}>
            {typeof title === 'string' ? <DialogTitle>{title}</DialogTitle> : title}
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                {buttons?.map((b, i) => (
                    <Button key={i} variant={b.primary ?? false ? 'contained' : 'text'} onClick={() => b.onClick(closer)}>
                        {b.text}
                    </Button>
                ))}
            </DialogActions>
        </MuiDialog>
    );
};

export const confirm = (title: React.ReactNode, prompt: React.ReactNode, confirmText = 'OK', cancelText = 'Cancel') =>
    new Promise<boolean>((resolver) => {
        const container = document.createElement('div'),
            disposer = (confirm: boolean, closer: () => void) => {
                resolver(confirm);
                closer();
            },
            buttons: DialogButton[] = [
                { text: confirmText, onClick: (close) => disposer(true, close), primary: true },
                { text: cancelText, onClick: (close) => disposer(false, close), primary: false },
            ];

        document.body.appendChild(container);
        render(
            <ThemeProvider>
                <Dialog title={title} buttons={buttons} onClose={() => container.remove()}>
                    {prompt}
                </Dialog>
            </ThemeProvider>,
            container
        );
    });

export const modal = (title: React.ReactNode, content: (onClose: () => void) => React.ReactNode) => {
    return new Promise<void>((resolver) => {
        const container = document.createElement('div');
        const disposer = (closer: () => void) => {
            resolver();
            closer();
        };
        const WrappedFunc = () => {
            const [show, setShow] = useState(true);
            return (
                <DiContext.Provider value={diContainer}>
                    <ThemeProvider>
                        <QueryClientProvider client={queryClient}>
                            <Dialog title={title} onClose={() => container.remove()} show={show}>
                                {content(() => disposer(() => setShow(false)))}
                            </Dialog>
                        </QueryClientProvider>
                    </ThemeProvider>
                </DiContext.Provider>
            );
        };

        document.body.appendChild(container);
        render(<WrappedFunc />, container);
    });
};
