import { DataGrid, GridColDef, GridRowData, GridSelectionModel } from '@mui/x-data-grid';
import { ItemTokenMetadata, SagaMetadata } from '@services/model';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, FormControl, Grid, Tab, Tabs, TextField } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { UpdateType } from '../Shared/EntityModels';
import { nanoid } from 'nanoid';
import { buildMetadataAttributeListColumns } from '../Shared/EntityListService';

interface ItemTokenMetadataContentsProps {
    itemTokenMetadata: ItemTokenMetadata | undefined;
    onSave: (metadata: SagaMetadata) => void;
}

export function ItemTokenMetadataContents(props: ItemTokenMetadataContentsProps) {
    const { itemTokenMetadata, onSave } = props;
    const [attributesInMetadata, setAttributesInMetadata] = useState<GridRowData[]>([]);
    const [currentTab, setCurrentTab] = useState('0');
    const [attributesChanged, setAttributesChanged] = useState(false);

    const metadataListColumns = buildMetadataAttributeListColumns(false);
    const handleSave = useCallback(
        ({
            name,
            description,
            image,
            externalUrl,
            backgroundColor,
            animationUrl,
            youtubeUrl,
        }: {
            name: string;
            description: string;
            image: string;
            externalUrl: string;
            backgroundColor: string;
            animationUrl: string;
            youtubeUrl: string;
        }) => {
            const metadata: SagaMetadata = {
                name: name,
                description: description,
                image: image,
                externalUrl: externalUrl,
                backgroundColor: backgroundColor,
                animationUrl: animationUrl,
                youtubeUrl: youtubeUrl,
            };

            metadata.attributes = attributesInMetadata;
            onSave(metadata);
            setAttributesChanged(false);
        },
        [attributesInMetadata, setAttributesChanged]
    );

    const handleAddAttribute = useCallback(
        ({ value, traitType, displayType, maxValue }: { value: string; traitType: string; displayType: string; maxValue: number }) => {
            const attribute: GridRowData = {
                value: value,
                traitType: traitType,
                displayType: displayType,
                maxValue: maxValue,
            };

            handleSetAttributesinMetadata([attribute], UpdateType.Add);
            attributeForm.reset();
        },
        [handleSetAttributesinMetadata, attributesInMetadata]
    );
    
    function handleSetAttributesinMetadata(attributes: GridRowData[], updateType: UpdateType) {
        setAttributesChanged(true);
        if (updateType == UpdateType.Add) {
            let newAttrbutes: GridRowData[] = [];

            let attributesInState = [...attributesInMetadata];

            attributes.forEach((b) => {
                let attributeIndex = attributesInState.findIndex((x) => x.entryId === b.entryId);

                if (attributeIndex !== -1) {
                    let attributeToUpdate = attributesInMetadata[attributeIndex];
                    attributeToUpdate.count++;
                } else {
                    newAttrbutes.push({ entryId: nanoid(), ...b });
                }
            });

            setAttributesInMetadata(attributesInState.concat(newAttrbutes));
        } else {
            setAttributesInMetadata(attributes);
        }
    }

    const urlRegex =
        /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;

    const metadataForm = useForm({
        mode: 'onChange',
        resolver: yupResolver(
            yup.object({
                name: yup.string().required('Name is required.'),
                description: yup.string(),
                image: yup.string(),
                externalUrl: yup.string(),
                backgroundColor: yup.string(),
                animationUrl: yup.string(),
                youtubeUrl: yup.string(),
            })
        ),
    });

    const attributeForm = useForm({
        mode: 'onChange',
        resolver: yupResolver(
            yup.object({
                value: yup.string(),
                traitType: yup.string(),
                displayType: yup.string(),
                maxValue: yup
                    .number()
                    .transform((currentValue, originalValue) => {
                        return originalValue === '' ? null : currentValue;
                    })
                    .positive('Must be a positive number.')
                    .nullable(true),
            })
        ),
    });

    useEffect(() => {
        setAttributesInMetadata(
            itemTokenMetadata?.tokenMetadata.attributes?.map((x) => {
                return { ...x, entryId: nanoid() };
            }) ?? []
        );
    }, []);

    return (
        <Grid columns={2} container={true} xs={12}>
            <Box sx={{ width: '50%' }}>
                <TabContext value={currentTab ?? '0'}>
                    <Box>
                        <Tabs onChange={(_, tab) => setCurrentTab(tab)} variant="scrollable" scrollButtons="auto" value={currentTab}>
                            <Tab value="0" label="Metadata" />
                            <Tab value="1" label="Attributes" />
                        </Tabs>
                    </Box>

                    <TabPanel sx={{ height: '50vh' }} value="0">
                        <form onSubmit={metadataForm.handleSubmit(handleSave)}>
                            <FormControl fullWidth sx={{ marginTop: '0.85rem' }}>
                                <TextField
                                    error={Boolean(metadataForm.formState.errors?.name)}
                                    helperText={metadataForm.formState.errors?.name?.message}
                                    {...metadataForm.register('name')}
                                    label="Name"
                                    id="metadata-name"
                                    variant="outlined"
                                    defaultValue={itemTokenMetadata?.tokenMetadata?.name}
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{ marginTop: '0.85rem' }}>
                                <TextField
                                    error={Boolean(metadataForm.formState.errors?.description)}
                                    {...metadataForm.register('description')}
                                    label="Description"
                                    id="metadata-description"
                                    variant="outlined"
                                    defaultValue={itemTokenMetadata?.tokenMetadata?.description}
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{ marginTop: '0.85rem' }}>
                                <TextField
                                    error={Boolean(metadataForm.formState.errors?.image)}
                                    helperText={metadataForm.formState.errors?.image?.message}
                                    {...metadataForm.register('image')}
                                    label="Image Url"
                                    id="metadata-image"
                                    variant="outlined"
                                    defaultValue={itemTokenMetadata?.tokenMetadata?.image}
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{ marginTop: '0.85rem' }}>
                                <TextField
                                    error={Boolean(metadataForm.formState.errors?.externalUrl)}
                                    helperText={metadataForm.formState.errors?.externalUrl?.message}
                                    {...metadataForm.register('externalUrl')}
                                    label="External Url"
                                    id="metadata-external-url"
                                    variant="outlined"
                                    placeholder="https://www.google.com"
                                    defaultValue={itemTokenMetadata?.tokenMetadata?.externalUrl}
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{ marginTop: '0.85rem' }}>
                                <TextField
                                    error={Boolean(metadataForm.formState.errors?.backgroundColor)}
                                    {...metadataForm.register('backgroundColor')}
                                    label="Background Color"
                                    id="vmetadata-background-color"
                                    variant="outlined"
                                    defaultValue={itemTokenMetadata?.tokenMetadata?.backgroundColor}
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{ marginTop: '0.85rem' }}>
                                <TextField
                                    error={Boolean(metadataForm.formState.errors?.animationUrl)}
                                    helperText={metadataForm.formState.errors?.animationUrl?.message}
                                    {...metadataForm.register('animationUrl')}
                                    label="Animation Url"
                                    id="metadata-animation-url"
                                    variant="outlined"
                                    placeholder="https://www.google.com"
                                    defaultValue={itemTokenMetadata?.tokenMetadata?.animationUrl}
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{ marginTop: '0.85rem' }}>
                                <TextField
                                    error={Boolean(metadataForm.formState.errors?.youtubeUrl)}
                                    helperText={metadataForm.formState.errors?.youtubeUrl?.message}
                                    {...metadataForm.register('youtubeUrl')}
                                    label="Youtube Url"
                                    id="metadata-youtube-url"
                                    variant="outlined"
                                    placeholder="https://www.youtube.com"
                                    defaultValue={itemTokenMetadata?.tokenMetadata?.youtubeUrl}
                                />
                            </FormControl>
                        </form>
                    </TabPanel>
                    <TabPanel sx={{ height: '50vh' }} value="1">
                        <form onSubmit={attributeForm.handleSubmit(handleAddAttribute)}>
                            <FormControl fullWidth sx={{ marginTop: '0.85rem' }}>
                                <TextField
                                    error={Boolean(attributeForm.formState.errors?.value)}
                                    {...attributeForm.register('value')}
                                    label="Value"
                                    id="attribute-value"
                                    variant="outlined"
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{ marginTop: '0.85rem' }}>
                                <TextField
                                    error={Boolean(attributeForm.formState.errors?.traitType)}
                                    {...attributeForm.register('traitType')}
                                    label="Trait Type"
                                    id="attribute-traitType"
                                    variant="outlined"
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{ marginTop: '0.85rem' }}>
                                <TextField
                                    error={Boolean(attributeForm.formState.errors?.displayType)}
                                    {...attributeForm.register('displayType')}
                                    label="Display Type"
                                    id="attribute-displayType"
                                    variant="outlined"
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{ marginTop: '0.85rem' }}>
                                <TextField
                                    error={Boolean(attributeForm.formState.errors?.maxValue)}
                                    helperText={attributeForm.formState.errors?.maxValue?.message}
                                    {...attributeForm.register('maxValue')}
                                    label="Max Value"
                                    id="attribute-max-value"
                                    variant="outlined"
                                />

                                <Button
                                    sx={{ marginTop: '0.85rem' }}
                                    type="submit"
                                    variant="contained"
                                    disabled={!attributeForm.formState.isDirty || !attributeForm.formState.isValid}
                                >
                                    Add Attribute
                                </Button>
                            </FormControl>
                        </form>
                    </TabPanel>
                    <Button
                        sx={{ marginBottom: '0.85rem' }}
                        type="submit"
                        variant="contained"
                        disabled={(!metadataForm.formState.isDirty || !metadataForm.formState.isValid) && !attributesChanged}
                        onClick={metadataForm.handleSubmit(handleSave)}
                    >
                        {itemTokenMetadata?.id ? 'Update' : 'Create'} Metadata
                    </Button>
                </TabContext>
            </Box>
            <Box sx={{ width: '50%' }}>
                    <Box sx={{ paddingBottom: '5px', paddingTop: '43px' }}>Metadata Attributes</Box>
                    {currentTab === '0' && (
                        <AttributeListContents columns={metadataListColumns} handleOnClick={handleSetAttributesinMetadata} rowsInMetadata={attributesInMetadata} disableEditing={true}/>
                    )}
                
                    {currentTab === '1' && (
                        <AttributeListContents columns={metadataListColumns} handleOnClick={handleSetAttributesinMetadata} rowsInMetadata={attributesInMetadata} disableEditing={false}/>
                    )}
            </Box>
        </Grid>
    );
}

interface AttributeListContents {
    columns: GridColDef[];
    handleOnClick: (data: GridRowData[], updateType: UpdateType) => void;
    rowsInMetadata: GridRowData[];
    disableEditing: boolean;
}

export function AttributeListContents({ columns, handleOnClick, rowsInMetadata, disableEditing }: AttributeListContents) {
    const [selectedRowsToDelete, setSelectedRowsToDelete] = useState<GridRowData[]>([]);
    const [deleteSelection, setDeleteSelection] = useState(false);

    function onEntitiesToRemove(selections: GridSelectionModel) {
        if (selections.length > 0) {
            setDeleteSelection(true);
        } else {
            setDeleteSelection(false);
        }

        const selectedIDs = new Set(selections);
        const rowsToDelete = rowsInMetadata.filter((row) => selectedIDs.has(row?.entryId.toString()));
        setSelectedRowsToDelete(rowsInMetadata.filter((row) => !rowsToDelete.find((f) => f.entryId === row.entryId)));
    }

    function onDeleteSelectionFromMetadata() {
        handleOnClick(selectedRowsToDelete, UpdateType.Remove);
        setDeleteSelection(false);
    }

    return (
        <div>
            <Box sx={{ height: '43vh' }}>
                <DataGrid
                    columns={columns}
                    rows={rowsInMetadata}
                    rowCount={rowsInMetadata.length}
                    pageSize={10}
                    checkboxSelection={!disableEditing}
                    onSelectionModelChange={onEntitiesToRemove}
                    getRowId={(row) => row.entryId}
                />
                {!disableEditing && (
                    <Button sx={{ marginTop: '10px' }} disabled={!deleteSelection} onClick={onDeleteSelectionFromMetadata} variant="contained">
                        Remove from Metadata
                    </Button>
                )}
            </Box>
        </div>
    );
}
