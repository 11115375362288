import { LogoImageInput, ThemeEditor } from '@components';
import { Box, Divider, Chip } from '@mui/material';
import { Controller } from 'react-hook-form';
import { TitleSocialLinksForm } from './TitleSocialLinks';
import { TitlePolicyLinksForm } from './TitlePolicyLinks';

export const ThemeAndLogoFields = (props: {isTitle?: boolean;}) => (
    <Box px={2} py={2} width={1200} justifyContent="space-between" display="flex" component="div">
        <Box px={2} width={400}>
            <Divider><Chip label="Theme"/></Divider>
            <Controller
                name="config.theme"
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                render={({ field }) => <ThemeEditor theme={field.value || {}} onChange={(value) => field.onChange({ target: { value } })} />}
            />
            <Divider />
            <LogoImageInput name="config.theme.logo" />
        </Box>
        {props.isTitle && (<Divider orientation="vertical" flexItem />)}
        {props.isTitle && (
        <Box px={2} width={400}>
            <Divider><Chip label="Social Media Links"/></Divider>
            <Controller
                name="config.socialLinks"
                render={({ field }) => <TitleSocialLinksForm socialLinks={field.value || {}} onChange={(value) => field.onChange({ target: { value}})} />}
            />
        </Box>
        )}
        {props.isTitle && (<Divider orientation="vertical" flexItem />)}
        {props.isTitle && (
        <Box px={2} width={400}>
            <Divider><Chip label="Policy Information"/></Divider>
            <Controller
                name="config.policyInformation"
                render={({ field }) => <TitlePolicyLinksForm policyInformation={field.value || {}} onChange={(value) => field.onChange({ target: { value}})} />}
            />
        </Box>
        )}
    </Box>
);
