import { LinkTabs } from '@components';
import { useSiteConfig } from '@core';
import { SmartToy } from '@mui/icons-material';
import { Bundles } from '../Bundles/Bundles';
import { Containers } from '../Containers';
import { DropTables } from '../DropTables';
import { Packs } from '../Packs/Packs';
import { titlePageRegistry } from '../PageRegistry';
import { ItemClasses } from './ItemClasses';
import { Items } from './Items';

export function ItemsDetails() {
    
    var tabs = [
        { label: 'Classes', component: ItemClasses, url: 'classes' },
        { label: 'Items', render: () => <Items />, url: 'items' },
        { label: "Bundles", render: () => <Bundles />, url: 'bundles' },
        { label: "Containers", render: () => <Containers />, url: 'containers' },
        { label: "Drop Tables", render: () => <DropTables />, url: 'drop-tables' },
    ];

    if (DisplayPacks()) {
        tabs.push({ label: "Packs", render: () => <Packs />, url: 'packs' })
    }

    return (
        <LinkTabs
            orientation="vertical"
            tabs={tabs}
        />
    );
}

function DisplayPacks() {
    const { orgId: organizationId } = useSiteConfig();
    var currentEnvironment = window._env_ || '';

    var validEnvironments = ['', 'DEV', 'QA']

    var nitroNationOrganizations = [
    //Dev
    '31540f22-c44e-47fe-a18c-789d1dcfa926',
    '77a05030-4319-4b78-9803-cb43ffc05896',
    'efbe915b-b83a-4cbf-a414-58bd1cba2448',
    //QA
    'a1ecfe7c-6e33-4511-b859-a37dc29e6b36',
    'ecfca19a-89b6-460e-b726-55f04c0046fc',
    'ae9ccb1e-87a6-4d91-bf08-6cd2d39130c6',
    //Staging/Prod
    '58e602d8-6d2e-4431-9301-f73a2fe667b7'];
    
    return nitroNationOrganizations.includes(organizationId) || validEnvironments.includes(currentEnvironment.toUpperCase());
}

titlePageRegistry.register({ page: ItemsDetails, path: 'items', name: 'Items', icon: <SmartToy /> });
