import { notify } from '@components';
import styled from '@emotion/styled';
import { ContentCopy } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import type { ReactNode } from 'react';
import { useRef } from 'react';

interface CopyableProps {
    children: ReactNode;
    copyText?: string;
}
export function Copyable({ children, copyText }: CopyableProps) {
    const textRef = useRef<HTMLSpanElement>(null);
    const onCopyClick = () => {
        const text = copyText !== undefined ? copyText : textRef.current?.textContent;
        if (text) {
            navigator.clipboard.writeText(text);
            notify({ content: 'Copied!', type: 'success', expireMs: 2000 });
        }
    };
    return (
        <CopyContainer>
            <span ref={textRef}>{children}</span>
            <Tooltip title="Copy to clipboard">
                <IconButton className="copy-btn" onClick={onCopyClick}>
                    <ContentCopy />
                </IconButton>
            </Tooltip>
        </CopyContainer>
    );
}
const CopyContainer = styled.span`
    position: relative;
    .copy-btn {
        position: absolute;
        opacity: 0;
        left: calc(100% - 1px);
        transform: translate(-0.5rem, -0.5rem) scale(0.5);
    }
    &:hover .copy-btn {
        opacity: 1;
    }
`;
