import { useSiteConfig } from '@core';
import { GridColDef } from '@mui/x-data-grid';
import { format, parseISO } from 'date-fns';
import { SimpleOrderRecord } from '@services/model';
import { useGetApiOrderByPlayerPlayerId } from '@services/order/order'
import { PageBody, PageContent } from 'features/shell/layout';
import { AdminTable } from 'shared/components/AdminTable';
import { LinearProgress } from '@mui/material';
import { CellNavigation } from '@components';
import { OrderStateDisplay } from 'shared/components/Order/OrderStateDisplay';

const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', type: 'uuid', flex: 0.5, renderCell: (cellValues) => <CellNavigation id={String(cellValues?.id)} value={String(cellValues?.value)} to="order-detail" /> },
    { field: 'numItems', headerName: '# of Items', type: 'string', flex: 0.3 }, 
    { field: 'orderState', headerName: 'State', type: 'string', flex: 0.3, renderCell: ({row}) => <OrderStateDisplay state={row.orderState} /> }, 
    {
        field: 'total',
        headerName: 'Total',
        type: 'string',
        flex: 0.3,
        renderCell: ({ row }) => row.total == null ? '' : row.total + ' ' + row.totalCurrencyType,
    },
    { field: 'createdAt', headerName: 'Created', flex: 0.5, valueFormatter: (params) => format(parseISO(String(params.value)), 'Ppp') },
]

export function PlayerOrders(props: { playerId?: string }) {
    const { isLoading, data } = useGetApiOrderByPlayerPlayerId(String(props.playerId));

    return isLoading ? (
        <LinearProgress />
    ) : (
        <PageContent>
            <PageBody noPadding>
                <AdminTable<SimpleOrderRecord>
                    title="User's Transactions"
                    resource="Player"
                    columns={columns}
                    data={data ? data : []}
                />
            </PageBody>
        </PageContent>
    );
}


